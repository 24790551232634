import request from '../../core/api/request';

export type TRAddPost = {
  /** token */
  token: string;
};

export const addPost = request<TRAddPost>({
  method: 'content.uploadGalery',
});

export const editPost = request<TRAddPost>({
  method: 'content.editGalery',
});

export const addPostWithTus = request<TRAddPost>({
  method: 'content.uploadGaleryChunk',
});

export const editPostWithTus = request<TRAddPost>({
  method: 'content.editGaleryChunk',
});

import React from 'react';
import cn from './style.module.css';

export interface PSetBtn
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  /** текст кнопки  */
  text: string;
  /** для какой настройки использовать кнопку? наверное это костыль :)  */
  styleB?:
    | 'forsetpassword'
    | 'forlanguage'
    | 'fullwidth'
    | 'forinv'
    | 'disabled'
    | 'loading'
    | 'redhint'
    | 'greenhint'
    | 'choicecontent'
    | 'addCharacter'
    | 'addCharacterSmall'
    | 'addLandingSet';
  gray?: 'gray';
  /** кнопка  */
  icon?: React.ReactElement;
  activeClass?: string;
  className?: string;
}

const mapTypes: Map<PSetBtn['styleB'], string> = new Map([
  ['forsetpassword', cn.forsetpassword],
  ['forlanguage', cn.forlanguage],
  ['fullwidth', cn.fullwidth],
  ['forinv', cn.sss],
  ['disabled', cn.disabled],
  ['loading', cn.loading],
  ['redhint', cn.redhint],
  ['greenhint', cn.greenhint],
  ['choicecontent', cn.choicecontent],
  ['addCharacter', cn.addCharacter],
  ['addCharacterSmall', cn.addCharacterSmall],
  ['addLandingSet', cn.addLandingSet],
]);
const mapTypeGray: Map<PSetBtn['gray'], string> = new Map([
  ['gray', cn.btn_gray],
]);
export default function SetBtn({
  text,
  styleB,
  gray,
  icon,
  activeClass,
  className,
  /** опции кнопки  */
  ...options
}: PSetBtn): JSX.Element {
  return (
    <div
      className={`${cn.centrbtn} ${mapTypes.get(
        styleB
      )} ${className} ${activeClass}`}
    >
      <button
        className={`${cn.setbtn} ${mapTypeGray.get(gray)}`}
        disabled={styleB === 'disabled'}
        type="button"
        {...options}
      >
        {text}
        {icon}
      </button>
    </div>
  );
}

import React from 'react';

interface IProps {
  className?: string;
  onClick?: () => void;
}

function Icon(props: IProps): JSX.Element {
  return <div {...props}>
    <svg
      width="12"
      height="16"
      viewBox="0 0 12 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.278075 16H3.72193C3.87166 16 4 15.8669 4 15.7115V0.288488C4 0.133148 3.87166 0 3.72193 0H0.278075C0.128342 0 0 0.133148 0 0.288488V15.7115C0 15.889 0.128342 16 0.278075 16Z"
        fill="white"
      />
      <path
        d="M8.27808 16H11.7219C11.8717 16 12 15.8669 12 15.7115V0.288488C12 0.133148 11.8717 0 11.7219 0H8.27808C8.12834 0 8 0.133148 8 0.288488V15.7115C8 15.889 8.12834 16 8.27808 16Z"
        fill="white"
      />
    </svg>
  </div>
}

export default Icon;

import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { TrashIcon } from 'assets';
import { UserAvatar } from 'components/UserAvatar/UserAvata';
import { getUserName } from 'helper/function';
import { TComment } from 'types/TComment';
import { TProfile } from 'types/TProfile';

import cn from './Comment.module.scss';

interface IProps {
  comment: TComment;
  onClickDelete?: (commentId: string) => void;
}

export function Comment({ comment, onClickDelete }: IProps): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();

  const text = comment.textComment;
  const [more, setMore] = useState<boolean>(() => (text?.length ?? 0) > 60);
  const [isRemoval, setRemoval] = useState<boolean>(false);

  const stopPropagation = (e?: React.MouseEvent) => {
    e?.stopPropagation();
    e?.nativeEvent.stopImmediatePropagation();
  };

  const openText = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
    stopPropagation(event);
    setMore(false);
  }, []);

  const showText = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
    stopPropagation(event);
    setMore(true);
  }, []);

  const username = getUserName(
    comment.owner.firstName,
    comment.owner.lastName,
    comment.owner.nickname
  );

  const goToProfile = useCallback(
    (event: React.MouseEvent<HTMLDivElement>): void => {
      stopPropagation(event);
      history.push(`/u/${comment.owner.nickname}`);
    },
    [comment]
  );

  const onClickDeleteHandler = useCallback(
    (event?: React.MouseEvent<HTMLDivElement>): void => {
      stopPropagation(event);
      if (onClickDelete) {
        onClickDelete(comment.id);
        setRemoval(true);
      }
    },
    [comment, onClickDelete]
  );

  return (
    <div className={cn.coment}>
      <div onClick={goToProfile}>
        <UserAvatar profile={comment.owner as TProfile} size="27" imgSize="s" />
      </div>
      <div className={cn.overtext} onClick={stopPropagation}>
        <div className={`${cn.name}`}>
          <div className={cn.overtb}>
            <div className={cn.text}>
              <div className={cn.userlink} onClick={goToProfile}>
                {username}
              </div>
              {more ? `${text?.substring(0, 60)}...` : text}
              {more && (
                <span onClick={openText} className={cn.showtext}>
                  {t('Timeline.more')}
                </span>
              )}
              {!more && (text?.length ?? 0) > 60 && (
                <span
                  onClick={showText}
                  className={`${cn.showtext} ${cn.hidetext}`}
                >
                  {t('Timeline.less')}
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
      {onClickDelete !== undefined && !isRemoval && (
        <div className={cn.commentAction}>
          <TrashIcon onClick={onClickDeleteHandler} />
          <div className="tooltipText">{t('Post.deleteComment')}</div>
        </div>
      )}
    </div>
  );
}

import React from 'react';

interface IProps {
  className?: string;
  onClick?: () => void;
}

function Icon(props: IProps): JSX.Element {
  return <div {...props}>
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.45933 4.44019H1.66507C1.91388 4.44019 2.1244 4.22966 2.1244 3.98086V2.54545C2.1244 2.29665 2.33493 2.08612 2.58373 2.08612H4.49761C4.74641 2.08612 4.95694 1.8756 4.95694 1.62679V0.45933C4.95694 0.210526 4.74641 0 4.49761 0H0.478469C0.210526 0 0 0.210526 0 0.45933V4C0 4.2488 0.210526 4.44019 0.45933 4.44019Z"
        fill="white"
      />
      <path
        d="M4.47847 13.8565H2.56459C2.31579 13.8565 2.10526 13.6459 2.10526 13.3971V11.3301C2.10526 11.0813 1.89474 10.8708 1.64593 10.8708H0.45933C0.210526 10.8708 0 11.0813 0 11.3301V15.5215C0 15.7703 0.210526 15.9809 0.45933 15.9809H4.47847C4.72727 15.9809 4.9378 15.7703 4.9378 15.5215V14.3158C4.91866 14.067 4.72727 13.8565 4.47847 13.8565Z"
        fill="white"
      />
      <path
        d="M15.5215 10.89H14.3158C14.067 10.89 13.8565 11.1005 13.8565 11.3493V13.4163C13.8565 13.6651 13.6459 13.8756 13.3971 13.8756H11.4833C11.2345 13.8756 11.0239 14.0861 11.0239 14.3349V15.5407C11.0239 15.7895 11.2345 16 11.4833 16H15.5024C15.7512 16 15.9617 15.7895 15.9617 15.5407V11.3301C15.9809 11.0813 15.7703 10.89 15.5215 10.89Z"
        fill="white"
      />
      <path
        d="M15.5215 0H11.5024C11.2536 0 11.0431 0.210526 11.0431 0.45933V1.66507C11.0431 1.91388 11.2536 2.1244 11.5024 2.1244H13.4163C13.6651 2.1244 13.8756 2.33493 13.8756 2.58373V4.01914C13.8756 4.26794 14.0861 4.47847 14.3349 4.47847H15.5407C15.7895 4.47847 16 4.26794 16 4.01914V0.45933C15.9809 0.210526 15.7703 0 15.5215 0Z"
        fill="white"
      />
    </svg>
  </div>
}

export default Icon;

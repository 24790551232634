import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';

import SetBtn from '../../Settings/SetBtn';

import WarningIcon from '../../../assets/WarningIcon';
import CardIcon from '../../../assets/chat/CardIcon';
import CloseIcon from '../../../assets/profile/CloseIcon';

import { AddCard } from '../../AddCard/AddCard';
import { TNewCard } from '../../../domains/user/api';
import { fetchCreateCard, fetchGetCards } from '../../../domains/user/actions';

import cn from './BuyContentErrorModal.module.scss';
import { ErrorProps } from '../../../core/api/errorServeApi';
import { OopsIcon } from '../../../assets/cards';
import { PAYMENT_ERRORS } from '../../../ListErrors';

export interface IBuyContentErrorModalRef {
  open: (type: 'post' | 'subscription' | 'tips', error: ErrorProps) => void;
  close: () => void;
}

interface IInternalState {
  isOpen: boolean;
  mode?: 'failed' | 'add';
  type?: 'post' | 'subscription' | 'tips';
  error?: ErrorProps;
}

interface IProps {
  onClose?: (success: boolean, type?: string) => void;
}

export const BuyContentErrorModal = forwardRef(
  (
    { onClose }: IProps,
    ref: React.Ref<IBuyContentErrorModalRef>
  ): JSX.Element => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [state, setState] = useState<IInternalState>({
      isOpen: false,
      mode: 'failed',
    });

    useImperativeHandle(ref, () => ({
      open(type, error: ErrorProps) {
        setState({
          isOpen: true,
          mode: 'failed',
          type,
          error,
        });
      },
      close() {
        setState({
          isOpen: false,
        });
      },
    }));

    const onClickAddButtonHandler = useCallback(() => {
      setState({ ...state, mode: 'add' });
    }, [state]);

    const createNewCardHandler = (card: TNewCard): void => {
      dispatch(fetchCreateCard(card));
    };

    const onRequestCloseHandler = useCallback(() => {
      if (onClose) {
        onClose(false);
      }
      dispatch(fetchGetCards());
      setState({ isOpen: false });
    }, [onClose]);

    const onSuccessRequestCloseHandler = useCallback(() => {
      if (state.isOpen && onClose) {
        onClose(true, state.type);
        dispatch(fetchGetCards());
        setState({ isOpen: false });
      }
    }, [onClose, state]);

    return (
      <Modal
        isOpen={state.isOpen}
        onRequestClose={onRequestCloseHandler}
        className={`${cn.modal} ${state.mode === 'add' && cn.fullscreen}`}
        shouldCloseOnOverlayClick
        overlayClassName={`${cn.overlay} ${
          state.mode === 'add' && cn.overlay_fullscreen
        }`}
      >
        <div className={cn.container}>
          <div className={cn.header}>
            <div className={cn.heated__title}>
              <CloseIcon
                onClick={onRequestCloseHandler}
                className={cn.closemodal}
              />
              {state.mode === 'failed' && t('buyPostModal.linkCardTitle')}
            </div>
          </div>
          {state.mode === 'failed' &&
            state.error &&
            [303, 3081].includes(state.error.code) && (
              <>
                <div className={cn.modcont}>
                  <div className={cn.hint}>
                    <WarningIcon className={cn.warningIc} />
                    <div className={cn.hintText}>
                      {state.type === 'subscription'
                        ? t('buyPostModal.forTrialSubscription')
                        : t('buyPostModal.linkCardMessage')}
                    </div>
                  </div>
                </div>
                <div className={cn.modbot}>
                  <div className={`${cn.botbtn} ${cn.cardbtn}`}>
                    <SetBtn
                      onClick={onClickAddButtonHandler}
                      text={t('AccountBilling.AddCard')}
                      icon={<CardIcon />}
                    />
                  </div>
                </div>
              </>
            )}
          {state.mode === 'failed' &&
            state.error &&
            PAYMENT_ERRORS.includes(state.error.code) && (
              <>
                <div className={cn.modcont}>
                  <div className={cn.hint}>
                    <OopsIcon className={cn.oopsIcon} />
                    <span className={cn.errorLogCode}>
                      {state.error.errorLogCode}
                    </span>
                    <div className={cn.description}>
                      <span>{t(`error.${state.error.code}`)}</span>
                    </div>
                  </div>
                </div>
                <div className={cn.modbot}>
                  <div className={`${cn.botbtn} ${cn.cardbtn}`}>
                    <SetBtn
                      onClick={onRequestCloseHandler}
                      text={t('PaymentErrorModal.ok')}
                    />
                  </div>
                </div>
              </>
            )}
          {state.mode === 'failed' &&
            state.error &&
            ![303, 3081, ...PAYMENT_ERRORS].includes(state.error.code) && (
              <>
                <div className={cn.modcont}>
                  <div className={cn.hint}>
                    <OopsIcon className={cn.oopsIcon} />
                    <span className={cn.errorLogCode}>
                      {t('PaymentErrorModal.oops.1', {
                        logCode: state.error.errorLogCode,
                      })}
                    </span>
                    <div className={cn.hintText}>
                      {t('PaymentErrorModal.oops.0')}
                    </div>
                    <div className={cn.description}>
                      <span>{t('PaymentErrorModal.oops.d')}</span>
                    </div>
                  </div>
                </div>
                <div className={cn.modbot}>
                  <div className={`${cn.botbtn} ${cn.cardbtn}`}>
                    <SetBtn
                      onClick={onRequestCloseHandler}
                      text={t('PaymentErrorModal.ok')}
                    />
                  </div>
                </div>
              </>
            )}
          {state.mode === 'add' && (
            <AddCard
              className={cn.addCard}
              onSubmit={createNewCardHandler}
              onCanceledError={onRequestCloseHandler}
              onSuccessRequestClose={onSuccessRequestCloseHandler}
            />
          )}
        </div>
      </Modal>
    );
  }
);

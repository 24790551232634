import React from 'react';

interface IProps {
  className?: string;
  onClick?: () => void;
}

function MasterIcon(props: IProps): JSX.Element {
  return <div {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="16"
      fill="none"
      viewBox="0 0 27 16"
    >
      <path fill="#FF5F00" d="M17.159 1.71H9.852v12.58h7.307V1.71z" />
      <path
        fill="#EB001B"
        d="M10.316 7.995c0-2.55 1.257-4.817 3.179-6.285A8.556 8.556 0 008.342 0C3.727 0 0 3.572 0 7.995s3.727 7.995 8.342 7.995a8.549 8.549 0 005.142-1.71c-1.922-1.438-3.168-3.725-3.168-6.285z"
      />
      <path
        fill="#F79E1B"
        d="M27 7.995c0 4.423-3.727 7.995-8.342 7.995a8.549 8.549 0 01-5.142-1.71c1.943-1.468 3.178-3.735 3.178-6.285 0-2.55-1.256-4.817-3.178-6.285A8.549 8.549 0 0118.658 0C23.273 0 27 3.603 27 7.995z"
      />
    </svg>
  </div>
}

export default MasterIcon;

import React from 'react';

interface IProps {
  className?: string;
  onClick?: () => void;
}

function Icon(props: IProps): JSX.Element {
  return <div {...props}>
    <svg
      width="20"
      height="17"
      viewBox="0 0 20 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.9782 11.8046C10.9782 12.3789 11.5479 12.7898 12.1129 12.6217C13.7192 12.1454 14.8866 10.6932 14.8866 8.97013C14.8866 7.24705 13.7192 5.79481 12.1129 5.31852C11.5479 5.15041 10.9782 5.55666 10.9782 6.13569V11.8046Z"
        fill="white"
      />
      <path
        d="M10.9782 16.1146C10.9782 15.685 11.305 15.3161 11.744 15.2647C14.9707 14.8912 17.4782 12.2108 17.4782 8.97013C17.4782 5.72944 14.9707 3.04443 11.744 2.67554C11.305 2.62417 10.9782 2.25994 10.9782 1.82567C10.9782 1.31669 11.4311 0.915105 11.9541 0.975809C16.0493 1.44744 19.2387 4.85156 19.2387 8.97013C19.2387 13.0887 16.0493 16.4928 11.9541 16.9644C11.4311 17.0205 10.9782 16.6236 10.9782 16.1146Z"
        fill="white"
      />
      <path
        d="M3.06324 5.5053H0.877881C0.392245 5.5053 0 5.88821 0 6.35983V11.5804C0 12.052 0.392245 12.435 0.877881 12.435H3.06791L7.45265 16.6936C8.00366 17.2306 8.94691 16.8477 8.94691 16.0866V1.85369C8.94691 1.09255 8.00366 0.709644 7.45265 1.24664L3.06324 5.5053Z"
        fill="white"
      />
    </svg>
  </div>
}

export default Icon;

import request from '../../core/api/request';
import { TNotificationFilter } from './types/TNotificationsFilter';
import { TNotificationsResponse } from './types/TNotificationsResponse';

/** Получение информации есть ли не прочтенные нотификации пользователю. Авторизация обязательна */
export const haveUnread = request<{ haveUnread: boolean; lastId?: string }>({
  method: 'notify.haveUnread',
});

/** Получение нотификаций пользователя. Авторизация обязательна */
export const getList = request<TNotificationsResponse, TNotificationFilter>({
  method: 'notify.getList',
});

/** Пометка нотификации как прочитанной. Авторизация обязательна */
export const setReaded = request<{ updated: boolean }, { id: number }>({
  method: 'notify.setReaded',
});

import React from 'react';

interface IProps {
  className?: string;
  onClick?: () => void;
}

function Icon(props: IProps): JSX.Element {
  return <div {...props}>
    <svg
      width="18"
      height="16"
      viewBox="0 0 43 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.47301 10.5277H2.2513C1.12229 10.5277 0.169678 11.3907 0.169678 12.4953V24.4732C0.169678 25.5433 1.12229 26.4062 2.2513 26.4062H7.47301L17.9164 36.175C19.2219 37.4176 21.4799 36.5202 21.4799 34.7942V2.13969C21.4799 0.379245 19.2219 -0.483718 17.9164 0.758949L7.47301 10.5277Z"
        fill="white"
      />
      <path
        d="M37.8154 17.6022L42.2256 13.3219C42.7548 12.8041 42.7548 11.9412 42.2256 11.4234C41.6964 10.9056 40.8143 10.9056 40.2851 11.4234L35.8749 15.7037L31.4999 11.4234C30.9707 10.9056 30.0886 10.9056 29.5594 11.4234C29.0302 11.9412 29.0302 12.8041 29.5594 13.3219L33.9696 17.6022L29.5594 21.8825C29.0302 22.4003 29.0302 23.2633 29.5594 23.781C29.8417 24.0572 30.1945 24.1607 30.512 24.1607C30.8648 24.1607 31.2177 24.0227 31.4646 23.781L35.8749 19.5007L40.2851 23.781C40.5673 24.0572 40.8849 24.1607 41.2377 24.1607C41.5905 24.1607 41.9433 24.0227 42.1903 23.781C42.7195 23.2633 42.7195 22.4003 42.1903 21.8825L37.8154 17.6022Z"
        fill="white"
      />
    </svg>
  </div>
}

export default Icon;

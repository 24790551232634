/**
 * User Fanter API
 */

import { makeRequest } from './FanterAPICore';

type TSetComplaintParams = {
  contentId: number;
  reasonId: number;
};
type TSetComplaintResponse = {
  result: boolean;
};
export const setComplaint = async (
  contentId: number,
  reasonId: number
): Promise<TSetComplaintResponse> => makeRequest<TSetComplaintParams, TSetComplaintResponse>(
    'content.setComplaint',
    { contentId, reasonId }
  );

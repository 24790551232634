import request from '../../core/api/request';
import { TContent } from '../../types/TContent';

export type TMessageType =
  | 'private'
  | 'public'
  | 'private-and-public'
  | 'blocked'
  | 'unblocked'
  | 'payed'
  | 'free'
  | 'purchased'
  | 'not-purchased'
  | 'need-moderate'
  | 'my-moderate'
  | 'my-unmoderate'
  | 'display-only-main';
/** Get Content */
export type TPContent = {
  /** количество сообщений, которое мы хотим получить. По умолчанию: 10 */
  count: number;
  /** "смещение" ленты относительно последнего прочитанного сообщения. */
  fromMessage?: string;
  /** тип контента, который мы хотим получить. не указан - весь контент, 2 - фото-сообщения, 1 - видео-сообщения, 3 - текстовые сообщения. */
  contentType?: 1 | 2 | 3;
  /** фильтр по пользователям, сообщения, которых должны быть в ленте. Можно указать как hashUser так и email или nickname. Фильтрация по email будет работать медленнее, поэтому рекомендованный путь использовать именно хеши. */
  fromUser?: {
    /** ограничение по хешу пользователя.  */
    hashUser?: string;
    /** ограничение по имейлам пользователей. */
    email?: string;
    /** ограничение по никнейму пользователя. */
    nickname?: string;
  };
  /**
   * варианты сортировки выходного массива сообщений. По умолчанию лента будет выдана в прямом порядке. Доступные значения: popular
   * popular - лента будет отсортирована по популярности.  Подробнее Лента популярного
   */
  sorting?: string;
  /** другие фильтры по получению ленты. */
  filters?: {
    /** При поднятом флаге получает только приватные посты пользователя, под которым прошла авторизация. Не совместим с fromUser. Выдаст ошибку при совместном использовании. По умолчанию: false */
    private?: boolean;
    followedByMe?: boolean;
    likedByMe?: boolean;
    opponentHash?: string;
    messageTypes?: TMessageType[];
  };
};

export type TRContent = {
  /** количество выданных сообщений */
  count?: number;
  /** массив отсортированных сообщений */
  items: TContent[];
  maxId?: number;
  minId?: number;
};

export const content = request<TRContent, TPContent>({
  method: 'content.get',
  showError: true,
});

/** Create Post */
export type TPCreatePost = {
  /** Заголовок сообщения */
  subject?: string;
  /** Текст сообщения. */
  message: string;
  /** картинка сообщения. */
  img: FormData;
  /** флаг, который разрешает просмотр поста без оплаты вип-пользователям системы. При поднятом флаге пост становится платным и бесплатный просмотр будет не доступен. На вход принимается true/false. По умолчанию: false */
  showFanterVip: boolean;
  /** флаг, который разрешает просмотр поста без оплаты платным подписчикам пользователя. При поднятом флаге пост становится платным и бесплатный просмотр будет не доступен. На вход принимается true/false. По умолчанию: false */
  showSubscribers: boolean;
  /** цена для покупки поста в долларах. Если цена отличается от нуля то бесплатный просмотр будет не доступен. На вход принимается число, число потом преобразуется в float с точностью до 2-х знаков (центы).  По умолчанию: 0 */
  price: number;
  /** флаг приватного поста(не доступного в ленте). true/false. По умолчанию false.  */
  private: boolean;
};
export type TRCreatePost = {
  /** token */
  token: string;
};
export const createPost = request<TRCreatePost>({
  method: 'content.uploadPhoto',
});

export type TRContentBuy = {
  /** true если пост был куплен */
  purchased: string;
};
export type TPContentBuy = {
  /** число. id контента который куплен. */
  contentId: string;
};
/** купить контент демо */
export const contentBuy = request<TRContentBuy, TPContentBuy>({
  method: 'content.buy',
  showError: false,
});
export type TRContentSetLike = {
  /** true если лайкнули */
  liked: boolean;
};
export type TPContentSetLike = {
  /** число. id контента который куплен. */
  contentId: string;
};

/** Метод для установки лайка на контент.  */
export const contentSetLike = request<TRContentSetLike, TPContentSetLike>({
  method: 'content.setLike',
});

export type TRContentUnsetLike = {
  /** true если с поста был забран лайк */
  unliked: boolean;
};
export type TPContentUnsetLike = {
  /** число. id контента который куплен. */
  contentId: string;
};

/** Метод для установки лайка на контент.  */
export const contentUnsetLike = request<TRContentUnsetLike, TPContentUnsetLike>(
  {
    method: 'content.unsetLike',
  },
);

export type TRContentSetBlocked = {
  result: boolean;
};

export type TPContentSetBlocked = {
  contentId: string;
  block: 0 | 1 | 2 | 3;
  remove?: boolean;
};

/** Метод для установки и снятия блокировки контента */
export const contentSetBlocked = request<
  TRContentSetBlocked,
  TPContentSetBlocked
>({
  method: 'content.setBlocked',
});

export type TRContentSetDisplayInMainContent = {
  result: boolean;
};

export type TPContentSetDisplayInMainContent = {
  contentId: string;
  displayInMain: 0 | 1;
  remove?: boolean;
};

/** Метод для установки и снятия блокировки контента */
export const setDisplayInMainContent = request<
  TRContentSetDisplayInMainContent,
  TPContentSetDisplayInMainContent
>({
  method: 'content.setDisplayInMainContent',
});

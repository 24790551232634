import { ListErrors } from '../../ListErrors';
import i18n from '../../i18n';
import { clearToken } from '../sagas/helper';
import { showAlert } from '../../utils/utils';

export interface ErrorProps {
  code: number;
  data: {
    mnemonic: string;
    description: string;
    fields: string[];
    location: string[];
  };
  errorLogCode: string;
  detail: string;
  message: string;
}

export default (error: ErrorProps | undefined, showError = true): void => {
  const erMessage = error?.code ? ListErrors.get(error.code) : undefined;

  if ([101, 109].includes(error?.code ?? -1)) {
    clearToken();
    window.location.assign('/sign-in');

    return;
  }

  if ([105].includes(error?.code ?? -1)) {
    // window.location.reload();

    return;
  }

  if (!showError) {
    return;
  }

  if (error?.code === undefined) {
    showAlert(
      i18n.t('errors.anyErr', { logCode: error?.errorLogCode }),
      'errors.anyErr',
      'error',
    );

    return;
  }

  if (typeof erMessage === 'string') {
    showAlert(i18n.t(erMessage), error.code, 'error');

    return;
  }

  showAlert(
    i18n.t('errors.Something', { logCode: error.errorLogCode }),
    'errors.Something',
    'error',
  );
};

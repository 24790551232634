import React from 'react';

import { VideoLoadWaitIcon, VideoLoadErrorIcon } from '../../../assets/post';

import cn from './VideoInfo.module.css';

interface IProps {
  title?: string;
  text?: string;
  error?: boolean;
}
export function VideoInfo({ title, text, error }: IProps): JSX.Element {
  return (
    <div className={cn.videoinfo}>
      <div className={cn.videoinfo_content}>
        {error && <VideoLoadErrorIcon />}
        {!error && <VideoLoadWaitIcon />}

        <div className={cn.videoinfo_tit}>{title}</div>
        {text?.split(';').map((t) => (
          <div key={t} className={cn.videoinfo_text}>
            {t}
          </div>
        ))}
      </div>
    </div>
  );
}

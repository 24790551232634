import React from 'react';

interface IProps {
  className?: string;
  onClick?: () => void;
}

function Icon(props: IProps): JSX.Element {
  return <div {...props}>
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 13.6V21.6C21 22.7 20.1 23.6 19 23.6H3C1.9 23.6 1 22.7 1 21.6V5.6C1 4.5 1.9 3.6 3 3.6H10.6M10 14.6H14L23.3 5.3C23.7 4.9 23.7 4.3 23.3 3.9L20.7 1.3C20.3 0.9 19.7 0.9 19.3 1.3L10 10.6V14.6ZM10 14.6L9 15.6M17 3.6L21 7.6"
        stroke="#413E93"
        strokeWidth="1.7"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
}

export default Icon;

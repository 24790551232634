import React from 'react';

interface IProps {
  className?: string;
  onClick?: () => void;
}

function Icon(props: IProps): JSX.Element {
  return <div {...props}>
    <svg
      width="30"
      height="21"
      viewBox="0 0 30 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.2849 11.1785C17.5119 11.1785 16.0708 9.81185 16.0708 8.13041C16.0708 6.44897 17.5119 5.08237 19.2849 5.08237C20.4378 5.08237 21.4776 5.64977 22.0649 6.6012C22.3641 7.08557 22.189 7.70832 21.6782 7.99202C21.1675 8.27572 20.5108 8.10966 20.2116 7.62529C20.0146 7.30353 19.668 7.11325 19.2849 7.11325C18.6939 7.11325 18.2123 7.56993 18.2123 8.13041C18.2123 8.69089 18.6939 9.14758 19.2849 9.14758C19.876 9.14758 20.3575 9.60081 20.3575 10.1647C20.3575 10.7287 19.8796 11.1785 19.2849 11.1785Z"
        fill="#413E93"
      />
      <path
        d="M19.2849 15.2402C18.1321 15.2402 17.0923 14.6728 16.5049 13.7214C16.2058 13.237 16.3809 12.6143 16.8916 12.3306C17.4024 12.0469 18.0591 12.2129 18.3583 12.6973C18.5553 13.019 18.9019 13.2093 19.2849 13.2093C19.876 13.2093 20.3575 12.7526 20.3575 12.1922C20.3575 11.6317 19.876 11.175 19.2849 11.175C18.6939 11.175 18.2123 10.7218 18.2123 10.1578C18.2123 9.59389 18.6903 9.14066 19.2849 9.14066C21.058 9.14066 22.4991 10.5073 22.4991 12.1887C22.4991 13.8736 21.058 15.2402 19.2849 15.2402Z"
        fill="#413E93"
      />
      <path
        d="M19.2849 7.11325C18.6939 7.11325 18.2123 6.66002 18.2123 6.09608V5.07891C18.2123 4.51843 18.6903 4.06175 19.2849 4.06175C19.876 4.06175 20.3575 4.51497 20.3575 5.07891V6.09608C20.3575 6.65656 19.8796 7.11325 19.2849 7.11325Z"
        fill="#413E93"
      />
      <path
        d="M19.2849 16.2574C18.6939 16.2574 18.2123 15.8041 18.2123 15.2402V14.223C18.2123 13.6626 18.6903 13.2059 19.2849 13.2059C19.876 13.2059 20.3575 13.6591 20.3575 14.223V15.2402C20.3575 15.8007 19.8796 16.2574 19.2849 16.2574Z"
        fill="#413E93"
      />
      <path
        d="M19.2849 20.3226C13.3783 20.3226 8.56986 15.7661 8.56986 10.1613C8.56986 4.55649 13.3783 0 19.2849 0C25.1915 0 30 4.55649 30 10.1613C30 15.7661 25.1952 20.3226 19.2849 20.3226ZM19.2849 2.03087C14.5604 2.03087 10.7151 5.67745 10.7151 10.1578C10.7151 14.6382 14.5604 18.2848 19.2849 18.2848C24.0095 18.2848 27.8548 14.6382 27.8548 10.1578C27.8548 5.67745 24.0131 2.03087 19.2849 2.03087Z"
        fill="#413E93"
      />
      <path
        d="M11.9993 17.2745H4.32527C3.73424 17.2745 3.25267 16.8213 3.25267 16.2574C3.25267 15.6969 3.73059 15.2402 4.32527 15.2402H11.9993C12.5903 15.2402 13.0719 15.6934 13.0719 16.2574C13.0719 16.8179 12.5939 17.2745 11.9993 17.2745Z"
        fill="#413E93"
      />
      <path
        d="M9.87961 9.14412H1.0726C0.477928 9.14412 0 8.6909 0 8.12696C0 7.56302 0.477928 7.10979 1.0726 7.10979H9.87961C10.4706 7.10979 10.9522 7.56302 10.9522 8.12696C10.9522 8.6909 10.4706 9.14412 9.87961 9.14412Z"
        fill="#413E93"
      />
      <path
        d="M9.87961 13.2093H6.46682C5.8758 13.2093 5.39422 12.7561 5.39422 12.1922C5.39422 11.6317 5.87215 11.175 6.46682 11.175H9.87596C10.467 11.175 10.9486 11.6282 10.9486 12.1922C10.9522 12.7526 10.4706 13.2093 9.87961 13.2093Z"
        fill="#413E93"
      />
      <path
        d="M11.9993 5.07891H7.50091C6.90989 5.07891 6.42831 4.62569 6.42831 4.06175C6.42831 3.49781 6.90624 3.04458 7.50091 3.04458H12.0029C12.5939 3.04458 13.0755 3.49781 13.0755 4.06175C13.0755 4.62569 12.5939 5.07891 11.9993 5.07891Z"
        fill="#413E93"
      />
      <path
        d="M3.21416 5.07892H1.0726C0.477928 5.07892 0 4.62569 0 4.06521C0 3.50473 0.477928 3.04804 1.0726 3.04804H3.21416C3.80518 3.04804 4.28676 3.50127 4.28676 4.06521C4.28676 4.62915 3.80518 5.07892 3.21416 5.07892Z"
        fill="#413E93"
      />
    </svg>
  </div>
}

export default Icon;

import React from 'react';

interface IProps {
  className?: string;
  onClick?: () => void;
}

function Icon(props: IProps): JSX.Element {
  return <div {...props}>
    <svg
      width="62"
      height="90"
      viewBox="0 0 62 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M53.5455 39.375V22.5C53.5455 10.1025 43.4225 0 31 0C18.5775 0 8.45455 10.1025 8.45455 22.5V39.375C3.78764 39.375 0 43.155 0 47.8125V81.5625C0 86.22 3.78764 90 8.45455 90H53.5455C58.2124 90 62 86.22 62 81.5625V47.8125C62 43.155 58.2124 39.375 53.5455 39.375ZM14.0909 22.5C14.0909 13.185 21.6662 5.625 31 5.625C40.3338 5.625 47.9091 13.185 47.9091 22.5V39.375H14.0909V22.5ZM56.3636 81.5625C56.3636 83.115 55.1011 84.375 53.5455 84.375H8.45455C6.89891 84.375 5.63636 83.115 5.63636 81.5625V47.8125C5.63636 46.26 6.89891 45 8.45455 45H53.5455C55.1011 45 56.3636 46.26 56.3636 47.8125V81.5625Z"
        fill="#413E93"
      />
      <path
        d="M31 53.4375C27.8887 53.4375 25.3636 55.9575 25.3636 59.0625C25.3636 61.1325 26.5135 62.9325 28.1818 63.9V70.3125C28.1818 71.865 29.4444 73.125 31 73.125C32.5556 73.125 33.8182 71.865 33.8182 70.3125V63.9C35.4866 62.9325 36.6364 61.1325 36.6364 59.0625C36.6364 55.9575 34.1113 53.4375 31 53.4375Z"
        fill="#483F9B"
      />
    </svg>
  </div>
}

export default Icon;

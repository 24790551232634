// Styles
import 'video.js/dist/video-js.css';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import videojs from 'video.js';
import 'videojs-contrib-quality-levels';
import EventEmitter from '../../../../core/EventEmitter/EventEmitter';

import PlayIcon from '../../../../assets/post/PlayIcon';
import {
  PauseIcon,
  VolumeIcon,
  OffVolumeIcon,
  FullscreenIcon,
} from '../../../../assets/video';

import cn from './VideoItem.module.css';
import { useIsMobile } from '../../../../domains/hooks';

export type IProps = {
  videoSrc: string;
  previewSrc: string;
  autoPlay?: boolean;
  onHoverControlBar?: (isHover: boolean) => void;
};

export function VideoItem({
  videoSrc,
  previewSrc,
  autoPlay,
  onHoverControlBar,
}: IProps): JSX.Element {
  const isMobile = useIsMobile();

  const [isPlaying, setPlaying] = useState<boolean>(false);
  const [isMuted, setMuted] = useState<boolean>(!autoPlay);
  const [timeLine, setTimeLine] = useState<number>(0);
  const [volumeLine, setvolumeLine] = useState<number>(autoPlay ? 100 : 0);
  const [isFullScreen, setFullScreen] = useState<boolean>(false);

  const [qualityList, setQualityList] = useState<[number, number][]>([]);
  const videoNode = useRef<HTMLVideoElement>(null);
  const player = useRef<videojs.Player>();
  const isVisibleRef = useRef<boolean>(false);

  useEffect(() => {
    EventEmitter.on('destroyVideoItem', () => {
      player.current?.dispose();
      player.current = undefined;
    });

    return () => {
      player.current?.dispose();
    };
  }, []);

  useEffect(() => {
    const options = {
      controls: false,
      fluid: false,
      poster: previewSrc,
      techCanOverridePoster: true,
      fullscreen: { options: { navigationUI: 'show' } },
      controlBar: {
        volumePanel: {
          inline: false,
        },
        children: [
          'playToggle',
          'volumeMenuButton',
          'durationDisplay',
          'timeDivider',
          'currentTimeDisplay',
          'progressControl',
          'remainingTimeDisplay',
          'fullscreenToggle',
        ],
      },

      html5: {
        vhs: {
          overrideNative:
            videojs.browser.IS_SAFARI || videojs.browser.IS_ANDROID,
        },
        nativeAudioTracks: !videojs.browser.IS_SAFARI,
        nativeVideoTracks: !videojs.browser.IS_SAFARI,
      },
      plugins: {},
      autoplay: autoPlay,
      muted: !autoPlay,
      defaultVolume: autoPlay ? 1 : 0,
      sources: [
        {
          src: videoSrc,
          type: 'application/x-mpegURL',
        },
      ],
    };

    // @ts-ignore
    videojs(videoNode.current, options).ready(function () {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const jsPlayer = this;
      jsPlayer.bigPlayButton?.hide();
      jsPlayer.controlBar?.hide();
      jsPlayer.on('play', () => {
        setPlaying(true);
      });
      jsPlayer.on('pause', () => {
        setPlaying(false);
      });
      jsPlayer.on('timeupdate', () => {
        const whereYouAt = jsPlayer.currentTime();
        const lengthOfVideo = jsPlayer.duration();
        const timePR = (whereYouAt * 100) / lengthOfVideo;
        setTimeLine(timePR);
      });
      jsPlayer.on('volumechange', () => {
        setvolumeLine(jsPlayer.volume() * 100);
      });
      jsPlayer.controls(true);

      if (isVisibleRef.current) {
        jsPlayer.play();
      }

      // @ts-ignore
      const qualityLevels = jsPlayer.qualityLevels();
      // @ts-ignore
      qualityLevels.on('addqualitylevel', (event) => {
        const { qualityLevel } = event;
        setQualityList((prev) => {
          const nArr = [...prev];
          if (!nArr.find((_) => _[0] === qualityLevel.height)) {
            nArr.push([qualityLevel.height, prev.length]);
          }
          return nArr.sort((a, b) => b[0] - a[0]);
        });
      });

      jsPlayer.playsinline(true);
      jsPlayer.preload(true);

      player.current = jsPlayer;
    });

    return () => {
      player.current?.dispose();
    };
  }, [videoSrc]);

  const onVisible = useCallback(
    (isVisible: boolean) => {
      if (player.current?.isFullscreen()) return;
      isVisibleRef.current = isVisible;
      try {
        if (isVisible) {
          if (!isPlaying) {
            player.current?.play();
          }
        } else if (isPlaying) {
          player.current?.pause();
        }
      } catch (error) {
        console.error(error);
      }
    },
    [player.current, videoNode.current, isPlaying]
  );

  // control bar

  const onChangeTimeLine = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const lengthOfVideo = player.current?.duration() ?? 0;
      const percent = +event.currentTarget.value;
      player.current?.currentTime(lengthOfVideo * (percent / 100));
      setTimeLine(percent);
    },
    [player.current]
  );
  const timeLinePayse = useCallback(() => {
    player.current?.pause();
  }, [player.current]);

  const timeLinePlay = useCallback(() => {
    player.current?.play();
  }, [player.current]);

  const onChangeVolumeLine = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const percent = +event.currentTarget.value;
      player.current?.volume(percent / 100);
    },
    [player.current]
  );

  const onClickPlayPauseHandler = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>): void => {
      event.preventDefault();
      if (isPlaying) {
        player.current?.pause();
      } else {
        player.current?.play();
      }
    },
    [isPlaying]
  );

  const onClickVolumeOnOffHandler = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>): void => {
      event.preventDefault();
      player.current?.muted(!isMuted);
      setMuted(!isMuted);
    },
    [isMuted]
  );

  const onFullscreenToggle = useCallback(() => {
    if (player.current?.isFullscreen()) {
      player.current?.exitFullscreen();
      setFullScreen(false);
    } else {
      player.current?.requestFullscreen();
      setFullScreen(true);
    }
  }, [player.current]);

  const toggleQuality = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const v = e.target.value;

      // @ts-ignore
      const qualityLevels = player.current.qualityLevels();
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < qualityLevels.length; i++) {
        const qualityLevel = qualityLevels[i];
        if (qualityLevel.height === +v) {
          qualityLevel.enabled = true;
        } else {
          qualityLevel.enabled = false;
        }
      }
    },
    [player.current]
  );

  const onMouseEnterControlBarHandler = useCallback(() => {
    if (onHoverControlBar) {
      onHoverControlBar(true);
    }
  }, []);

  const onMouseLeaveControlBarHandler = useCallback(() => {
    if (onHoverControlBar) {
      onHoverControlBar(false);
    }
  }, []);

  return (
    <VisibilitySensor onChange={onVisible}>
      <div
        className={[
          cn.videoContainer,
          'cont-video',
          isMobile ? cn.mobile : '',
        ].join(' ')}
        data-vjs-player
      >
        <video
          id={videoSrc}
          ref={videoNode}
          className={[
            cn.video,
            'video-js',
            isFullScreen ? cn.fullscreen : '',
          ].join(' ')}
        />
        {!isPlaying && (
          <div className={cn.playBtnContainer}>
            <button
              type="button"
              className={cn.playBtn}
              onClick={() => {
                player.current?.play();
              }}
            >
              <PlayIcon />
            </button>
          </div>
        )}
        <div
          className={cn.controlBar}
          onMouseEnter={onMouseEnterControlBarHandler}
          onMouseLeave={onMouseLeaveControlBarHandler}
          onTouchStart={onMouseEnterControlBarHandler}
          onTouchEnd={onMouseLeaveControlBarHandler}
        >
          <div className={cn.controlBar__timelineContainer}>
            <input
              type="range"
              value={timeLine}
              onChange={onChangeTimeLine}
              onMouseDown={timeLinePayse}
              onMouseUp={timeLinePlay}
            />
          </div>
          <div className={cn.controlBar__buttonsRowContainer}>
            <div className={cn.controlBar__buttonsRowContainer_block}>
              <button
                type="button"
                className={cn.playpause}
                onClick={onClickPlayPauseHandler}
              >
                {isPlaying ? <PauseIcon /> : <PlayIcon />}
              </button>
              <button
                type="button"
                className={cn.mutesmallbtn}
                onClick={onClickVolumeOnOffHandler}
              >
                {isMuted ? <OffVolumeIcon /> : <VolumeIcon />}
              </button>
              <div className={cn.volume}>
                <input
                  type="range"
                  value={volumeLine}
                  onChange={onChangeVolumeLine}
                />
              </div>
            </div>
            <div className={cn.controlBar__buttonsRowContainer_emptyBlock} />
            <div className={cn.controlBar__buttonsRowContainer_block}>
              <select className={cn.quality} onChange={toggleQuality}>
                <option defaultChecked value={-1}>
                  auto
                </option>
                {qualityList.map((item) => (
                  <option key={item[0]} value={item[0]}>
                    {item[0]}p
                  </option>
                ))}
              </select>
              <button
                type="button"
                className={cn.fullscreenbtn}
                onClick={onFullscreenToggle}
              >
                <FullscreenIcon />
              </button>
            </div>
          </div>
        </div>
      </div>
    </VisibilitySensor>
  );
}

import React from 'react';

interface IProps {
  className?: string;
  onClick?: () => void;
}

function VisaIcon(props: IProps): JSX.Element {
  return <div {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="12"
      fill="none"
      viewBox="0 0 35 12"
    >
      <path
        fill="#213392"
        d="M15.18 11.178h-2.848L14.123.176h2.848l-1.79 11.002zM9.925.176l-2.731 7.56-.323-1.618-.97-4.97s-.117-1-1.35-1H.06L0 .352s1.38.294 2.995 1.265l2.496 9.56h2.965L13.008.176H9.925zM32.387 11.178H35L32.71.176h-2.29c-1.058 0-1.322.824-1.322.824L24.87 11.178h2.966l.587-1.648h3.64l.324 1.648zm-3.142-3.912l1.497-4.119.852 4.119h-2.349zM25.076 2.824l.41-2.353S24.225 0 22.904 0c-1.41 0-4.786.618-4.786 3.647 0 2.854 3.963 2.883 3.963 4.383s-3.552 1.236-4.727.294l-.41 2.471s1.262.618 3.229.618c1.938 0 4.904-1 4.904-3.765 0-2.853-3.994-3.118-3.994-4.383 0-1.294 2.76-1.118 3.994-.441z"
      />
      <path
        fill="#213392"
        d="M6.87 6.089l-.968-4.942s-.118-1-1.35-1H.058L0 .353S2.173.794 4.228 2.5C6.225 4.09 6.871 6.09 6.871 6.09z"
      />
    </svg>
  </div>
}

export default VisaIcon;

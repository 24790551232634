/** Регистрация пользователя */
import request from '../../core/api/request';
import { TProfile } from '../../types/TProfile';
import { TToken } from '../../types/TToken';

export type TRregister = {
  /** уникальный хеш пользователя, созданный при регистрации. */
  hashUser: string;
};
export type TPregister = {
  /** мя пользователя */
  firstName?: string;
  /** фамилия пользователя */
  lastName?: string;
  /** email адрес */
  email: string;
  /** никнейм пользователя. */
  nickname?: string;
  /** пароль пользователя. */
  password: string;
  /** инвайт код, по которому пришел юзер. */
  regInvite?: string;
  /** язык */
  lang: string;
  toProfile?: string;
  profileAction?: string;
  utm?: string;
};
export const register = request<TRregister, TPregister>({
  method: 'user.register',
  showError: false,
});

export type TPLogin = {
  email?: string;
  nickname?: string;
  password: string;
  screenSize?: string;
  toProfile?: string;
  profileAction?: string;
};

/** Авторизация пользователя. */
export const login = request<TToken, TPLogin>({
  method: 'user.login',
  showError: false,
});

/** Обновление токенов доступа для пользователя. */
export const refreshToken = request<
  TToken,
  { refresh_token: string; screenSize?: string }
>({
  method: 'user.refreshToken',
});
/** Получение профиля текущего пользователя */
export const getProfile = request<TProfile>({
  method: 'user.getProfile',
});
/** Выслать пользователю письмо с ссылкой подтверждения почты */
export const resendEmailConfirm = request<boolean>({
  method: 'user.resendEmailConfirm',
});
/** Подтверждение почты у пользователя. */
export const emailCommit = request<boolean, { commitToken: string }>({
  method: 'user.emailCommit',
  showError: false,
});
/** Инициация восстановления пароля пользователя. */
export const passwordRecover = request<boolean, { email: string }>({
  method: 'user.passwordRecover',
  showError: false,
});
export type TRRecoverCommit = {
  /** токен, который был выслан пользователю на почту. */
  recoverToken: string;
  /** новый пароль пользователя */
  password: string;
  /** подтверждение нового пароля (такой же как пароль) */
  confirmPassword: string;
};
/** Установка нового пароля для пользователя, который запросил процедуру восстановления. */
export const recoverCommit = request<boolean, TRRecoverCommit>({
  method: 'user.recoverCommit',
  token: false,
});

export type TPisTokenValid = {
  /**  проверяемый токен */
  token: string;
  /** тип проверяемого токена  */
  type: 'passwordRecover' | 'emailCommit';
};
export type TRisTokenValid = {
  valid: boolean;
};

/** Проверка правильности токена.  */
export const isTokenValid = request<TRisTokenValid, TPisTokenValid>({
  method: 'user.isTokenValid',
  token: false,
  showError: false,
});

export type RTelegrammVerifyInitData = {
  hashUser: string;
  access_token: string;
  expire_access: number;
  refresh_token: string;
  expire_refresh: number;
  durationAccess: number;
  durationRefresh: number;
};
export type PTelegrammVerifyInitData = {
  initData: string;
  autoLogin: boolean;
  autoRegister: boolean;
  externalClickId?: string;
};
/** авторизация через  telegramm */
export const telegrammVerifyInitData = request<
  RTelegrammVerifyInitData,
  PTelegrammVerifyInitData
>({
  method: 'telegramm.verifyInitData',
});

import React from 'react';

interface IProps {
  className?: string;
  onClick?: () => void;
}

function Icon(props: IProps): JSX.Element {
  return <div {...props}>
    <svg
      width="24"
      height="23"
      viewBox="0 0 24 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 9.87866C1 10.6352 1.41521 11.298 2.03174 11.6544C1.67944 12.0233 1.45925 12.5235 1.45925 13.0675C1.45925 14.1992 2.38404 15.1183 3.52273 15.1183H8.68773L8.0712 18.2008C7.88247 19.1262 8.12153 20.0766 8.72548 20.8019C9.32943 21.5335 10.2165 21.9524 11.1664 21.9524C11.6005 21.9524 11.978 21.646 12.0661 21.2208L12.8902 17.1191L15.3311 13.3051C15.4884 13.5739 15.7841 13.7553 16.1238 13.7553H22.0815C22.5848 13.7553 23 13.3488 23 12.8424V1.91288C23 1.41267 22.5911 1 22.0815 1H16.1238C15.6205 1 15.2053 1.40642 15.2053 1.91288V2.43184C14.0981 1.80033 12.821 1.45644 11.5376 1.45644H4.2525C3.12639 1.45644 2.2016 2.31304 2.14498 3.40724C2.11982 3.93871 2.29597 4.43892 2.64198 4.83908C1.94367 5.16422 1.45296 5.87076 1.45296 6.68984C1.45296 7.24007 1.67315 7.73402 2.02545 8.10293C1.41521 8.45932 1 9.1221 1 9.87866ZM16.1175 1.91288H22.0752V12.8424H16.1175V1.91288ZM3.06348 8.74069H3.74921C4.00086 8.74069 4.20846 8.53435 4.20846 8.28425C4.20846 8.03415 4.00086 7.82781 3.74921 7.82781H3.52273C2.89362 7.82781 2.37775 7.3151 2.37775 6.68984C2.37775 6.06459 2.89362 5.55188 3.52273 5.55188H4.20846C4.46011 5.55188 4.66771 5.34554 4.66771 5.09544C4.66771 4.84533 4.46011 4.639 4.20846 4.639C3.89391 4.639 3.59823 4.51395 3.37804 4.28885C3.15785 4.06376 3.0509 3.76364 3.06348 3.44476C3.09494 2.85076 3.62339 2.36306 4.2525 2.36306H11.5376C12.8399 2.36306 14.1358 2.76948 15.2053 3.50728V11.7919L12.1164 16.6189C12.0535 16.7127 12.0157 16.819 11.9906 16.9316L11.1601 21.0333C10.487 21.0333 9.85788 20.7394 9.43008 20.2204C9.00229 19.7015 8.83243 19.0324 8.96454 18.3759L9.80126 14.2054H3.52273C2.89362 14.2054 2.37775 13.6927 2.37775 13.0675C2.37775 12.4422 2.89362 11.9295 3.52273 11.9295H3.74921C4.00086 11.9295 4.20846 11.7232 4.20846 11.4731C4.20846 11.223 4.00086 11.0166 3.74921 11.0166H3.06348C2.43437 11.0166 1.9185 10.5039 1.9185 9.87866C1.9185 9.2534 2.42808 8.74069 3.06348 8.74069Z"
        fill="#E3374C"
        stroke="#E3374C"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
    </svg>
  </div>
}

export default Icon;

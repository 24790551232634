/**
 * Profile Fanter API
 */

import { TLandingSet } from '../../types/TLandingSet';
import { TProfile } from '../../types/TProfile';
import { makeRequest } from './FanterAPICore';

type TProfileGetParams = {
  nickname?: string;
  hashUser?: string;
};
export const profileGetBuNickname = async (
  nickname: string,
): Promise<TProfile> =>
  makeRequest<TProfileGetParams, TProfile>('profile.get', { nickname });

export const profileGetByHash = async (hashUser: string): Promise<TProfile> =>
  makeRequest<TProfileGetParams, TProfile>('profile.get', { hashUser });

type TSubscribeVipParams = {
  hashOpponent: string;
  type: 'month';
};
type TSubscribeVipResponse = {
  payHash: string;
};
export const subscribeVip = async (
  hashOpponent: string,
  type: 'month' = 'month',
): Promise<TSubscribeVipResponse> =>
  makeRequest<TSubscribeVipParams, TSubscribeVipResponse>(
    'profile.subscribeVip',
    { hashOpponent, type },
    false,
  );

type TGetLandingRolesSetParams = {
  hashUser?: string;
  rolesSetId?: string;
  nicknameUser?: string;
};
export const getLandingRolesSet = async (
  params: TGetLandingRolesSetParams,
): Promise<TLandingSet[]> =>
  makeRequest<TGetLandingRolesSetParams, TLandingSet[]>(
    'profile.getLandingRolesSet',
    params,
    false,
  );

export type TRelationship =
  | 'follow'
  | 'followers'
  | 'subsribersVip'
  | 'subsriberVip'
  | 'trials'
  | 'trial';
type TRelationshipUser = {
  nickname: string;
  firstName: string;
  lastName: string;
  hashUser: string;
  avatar: string;
};

type TGetRelationshipParams = {
  /** хеш пользователя, профиль которого хотим показать */
  hashUser?: string;
  /**
   * строка или массив строк, с указанием какие взаимоотношения получать
   * follow - на кого подписан бесплатно
   * followers - кто подписан бесплатно
   * subsribersVip - платные подписчики на профиль
   * subsribeVip - платные подписки пользователя
   * trials - триальные подписки на пользователя
   * trial - триальные подписки пользователя
   */
  type: TRelationship[];
};
export type TGetRelationshipResponse = {
  follow: TRelationshipUser[];
  followers: TRelationshipUser[];
  subsribersVip: TRelationshipUser[];
  subsriberVip: TRelationshipUser[];
  trials: TRelationshipUser[];
  trial: TRelationshipUser[];
};
export const getRelationship = async (
  params: TGetRelationshipParams,
): Promise<TGetRelationshipResponse> =>
  makeRequest<TGetRelationshipParams, TGetRelationshipResponse>(
    'profile.getRelationship',
    params,
    false,
  );

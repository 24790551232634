/* eslint-disable no-template-curly-in-string */
export default {
  translations: {
    common: {
      back: 'Atrás',
      Save: 'Guardar',
      TypeNote: 'Nota de tipo',
      Search: 'Buscar',
      Empty: 'Vacío',
      Group: 'Grupo',
      Groups: 'Grupos',
      countOfParticipants: '{{count}} miembro',
      countOfParticipants_plural: '{{count}} miembros',
      Send: 'Enviar',
      Done: 'Hecho',
      VIP_Subscription: 'Suscripción VIP',
      AmountUSD: 'Importe, USD',
      errorAmountMin: 'Ingresa un monto mínimo de ${{count}}',
      errorAmountMax: 'El importe máximo es de ${{count}}',
      SignInFanterAccount: 'Iniciar<br />sesión en la cuenta de fanter',
      CreateFanterAccount: 'Crear<br />una cuenta de fanter',
      pleaseAuth:
        "Inicie <span id='postStubSignIn'>sesión</span> o <span id='postStubSignUp'>regístrese</span> para recibir contenido.",
    },
    alerts: {
      success: {
        setNote: 'Nota guardado con éxito',
      },
    },
    landing: {
      NitaKuzminaV2: {
        ExclusiveGame: 'Juego exclusivo',
        text0: '¡Elige primero una imagen!',
        text1: '¿Quieres un contenido único que yo grabe para ti?',
        text2: 'Accede a contenidos exclusivos sólo en Fanter',
        signUp: 'Regístrate ahora',
        PlayGame: 'Empieza el juego',
      },
      Landing: 'Página de aterrizaje',
      Sets: 'Сonjunto de páginas de aterrizaje',
      AddSet: 'Añadir un conjunto de personajes para la página de aterrizaje',
      setName: 'Nombre',
      Add: 'Añadir',
      Cancel: 'Cancelar',
      AddSetCharacter: 'Añadir personaje',
      SetSuccessfullyCreated:
        'Сonjunto de {{CHARACTER_NAME}} se creó correctamente',
      SetSuccessfullyDeleted:
        'Сonjunto de {{CHARACTER_NAME}} se eliminó correctamente',
    },
    onlineStatuses: {
      '1': 'En línea',
      '2': 'Ausente',
      '3': 'Sin conexión',
      '4': 'hace menos de una hora',
      '5': 'hace una hora',
      '6': 'hace {{count}} horas',
      '6_plural': 'hace {{count}} hora',
      '7': 'hace {{count}} días',
      '7_plural': 'hace {{count}} día',
      '8': 'hace {{count}} semanas',
      '8_plural': 'hace {{count}} semana',
      '9': 'hace mucho tiempo',
    },
    authorization: {
      SNCWRS: 'Red social para conectar con nuevas estrellas',
      CAABS: 'Crea una cuenta y conviértete en estrella',
      Password: 'Contraseña',
      Confirm_password: 'Confirmar contraseña',
      Sign_up: 'Regístrate',
      invite: 'Invitación',
      Create_account: 'Crear una cuenta',
      ARL: '¿Ya estás registrado? Inicia sesión',
      Check_your_email: 'Revisa tu correo electrónico',
      letter_password:
        'Hemos enviado un mensaje a tu correo electrónico con un enlace para restablecer tu contraseña. Si el correo tarda en llegar, comprueba tu carpeta de correo no deseado.',
      Login: 'Usuario',
      Forgot_password: '¿Has olvidado tu contraseña?',
      Sign_in: 'Iniciar sesión',
      nickname: 'Nombre de usuario',
      IhaveInvideCode: '¿Tienes un código de invitación?',
      NotValidCode: 'Código no váldio',
      Activated: 'Activado',
      Activate: 'Activar',
      BackToLogin: 'Volver a la página de inicio de sesión',
    },
    invite: {
      title: '¿Tienes un código de invitación?',
      text: 'Introduce el código de invitación para registrarte si te ha invitado alguien. Puedes omitir este paso.',
      enter: 'Introduce tu clave de invitación',
      activate: 'Activar',
      skip: 'Omitir',
      succes: 'Has introducido correctamente el código de invitación:',
      succes_text: 'Bienvenido a Fanter!',
      go_to: 'Ir al timeline',
    },
    forgot_password: {
      re_send: 'Reenviar contraseña',
      title_new_p: 'Introduce la nueva contraseña',
      activate: 'Activar',
      no_valid: 'La página no es válida',
      repeat: 'Repite la solicitud para cambiar la contraseña.',
      home: 'Inicio',
    },
    e_mail_confirmed: {
      confirmed: 'Gracias, correo electrónico confirmado',
      created: 'Se ha creado tu cuenta',
      done: 'Aceptar',
      repeat: 'Repite la solicitud para confirmar el correo.',
    },
    header: {
      Timeline: 'Timeline',
      Chats: 'Chats',
      Hello: 'Hola',
    },
    Post: {
      Blocked: 'Bloqueada',
      ConvertingErrorTitle: 'Error al procesar el vídeo',
      ConvertingErrorText: 'No se ha podido cargar el vídeo',
      VideoUploadingTitle: 'Tu vídeo se está cargando',
      VideoUploadingText:
        'Pronto el vídeo estará disponible en tu feed;Solo tú puedes ver este mensaje',
      TypeComment: 'Escribe un comentario',
      Submit: 'Enviar',
      PaidContent: 'Contenido de pago',
      BuyNow: 'Comprar ahora',
      Photos: 'Fotos',
      Videos: 'Vídeos',
      FreeForFanterVIP: 'Gratis con la suscripción a Fanter',
      FreeForSubscribersVIP: 'Gratis con la suscripción a {{username}}',
      FreeForSubscribersVIPWithTrial: 'Accede gratis - {{count}} día',
      FreeForSubscribersVIPWithTrial_plural: 'Accede gratis - {{count}} días',
      ThenForSubscribersVIPWithTrial: 'entonces ${{then}}/me',
      Tips: 'Propinas',
      SendTips: 'Enviar propinas',
      BlockPost: 'Bloquear publicación',
      UnblockPost: 'Desbloquear publicación',
      ConfirmPost: 'Confirmar publicación',
      Decline: 'Rechazar publicación',
      SharePost: 'Compartir',
      EditPost: 'Redactar',
      DisplayInMainContent: 'Mostrar en la cadena',
      UnDisplayInMainContent: 'Eliminar del canal',
      SuccessfullyBlocked: 'Publicación bloqueada correctamente',
      SuccessfullyUnblocked: 'Publicación desbloqueada correctamente',
      SuccessfullyConfirmed: 'Publicación aprobada con éxito',
      SuccessfullyDecline: 'Publicación rechazada correctamente',
      SuccessfullySetDisplayInMainContent:
        'El post se ha añadido con éxito al canal',
      SuccessfullyUnSetDisplayInMainContent:
        'El post ha sido eliminado con éxito del canal',
      FreeForVIP: 'Gratis para VIP',
      deleteComment: 'Eliminar comentario',
      deleteCommentSuccessfully: 'Comentario eliminado correctamente',
      waitingPay: 'Esperando que el sistema de pago reconozca',
    },
    AddPost: {
      PublicPost: 'Post público',
      PrivatePost: 'Post privado',
      NewPost: 'Nueva publicación',
      EditPost: 'Edición de una publicación',
      Title: 'Título',
      Description: 'Descripción',
      UploadPV: 'Sube una foto o un vídeo',
      UploadP: 'Sube una foto',
      PaidContent: 'Contenido de pago',
      TextBuyButton: 'Texto en el botón Comprar',
      PaidContentDescription: 'Descripción del contenido de pago',
      PaidContentIsFreeFor: 'El contenido de pago es gratis para',
      HidePaidContent: 'Ocultar el contenido de pago',
      ChatText: 'Texto del chat',
      ChatDescription:
        'Este texto acompañará al contenido de los medios de comunicación si envías una publicación al chat',
      Post: 'Publicar',
      Prewiew: 'Previsualización',
      Prise: 'Precio',
      MedeaFormat: 'Formato de foto JPEG, JPG, PNG, MP4, MOV',
      PhotoFormat: 'Formato de foto JPEG, JPG, PNG',
      FanterVIPs: 'Fanter	VIPs',
      YourVIPs: 'Tus VIPs',
      OnlyForSubscribers: 'Sólo para suscriptores',
      HideChatText: 'Ocultar el texto del chat',
      AddPaidContent: 'Añadir contenido de pago',
      AddChatText: 'Añadir el texto del chat',
      Progress: 'Progreso {{PROGRESS}}%',
      Errors: {
        TitleRequired: 'Rellena el título de la publicación',
        TitleLong: 'El título es demasiado largo (máximo 300 caracteres)',
        DescriptionRequred: 'Rellena el texto de la publicación',
        TextLong:
          'El texto de la publicación es demasiado largo (máximo 2500 caracteres)',
        BuyButten:
          'El texto del botón es demasiado largo (máximo 250 caracteres)',
        PriceRequired:
          'Especifica el precio de la parte de pago de la publicación',
        AmountMin:
          'No puede establecer el precio del contenido de pago por debajo de 5$',
        FreeMedeaContentRequired: 'Añade fotos o vídeos gratis',
        PayedMedeaContentRequired: 'Añade fotos o vídeos de pago',
        Save: 'Guardar',
        NotFound: 'Esta página no existe. Es posible que haya sido eliminada',
      },
      AccessDeclined: 'Acceso prohibido',
      OnlyForSubscribersToolTip:
        'Selecciona primero "Tus VIPs" y/o "Fanter VIPs"',
    },
    CreatePost: {
      placeholder: 'Escribe algo',
      Publication_error: 'Error de publicación',
      successfully: 'El post se ha publicado con éxito',
      editSuccessfully: 'Publicación editada correctamente',
      New_post: 'Nueva publicación',
      Choose_image: 'Selecciona una imagen',
      Title: 'Título',
      Text: 'Texto',
      Share: 'Publicar',
      Drag_image: 'Arrastra la imagen aquí o',
      browse: 'navegar',
      text_required: 'El texto del campo es obligatorio',
      Image_required: 'La imagen de la publicación es obligatoria',
      Paid_content: 'El contenido de pago ',
      Your_VIP: 'Tus VIPs',
      Fanter_VIP: 'Fanter VIP',
      Free: 'Gratis',
      Public_post: 'Post público',
      Public_txt:
        'Una vez publicado, este post aparecerá en tu feed y estará disponible para los usuarios de forma gratuita o por una cuota/suscripción',
      Private_post: 'Post privado',
      Private_txt:
        'Una vez publicado, puedes enviar este post a un usuario en el chat de forma gratuita o con una oferta de compra',
      Choose_p_t: 'Selecciona el tipo de post',
    },
    SettingsMenu: {
      MyProfile: 'Mi perfil',
      Profile: 'Perfil',
      Profile_info: 'Información básica',
      Account: 'Configuración de la cuenta',
      BandB: 'Vincular la tarjeta',
      Invite: 'Invita a tus amigos y obtén recompensas',
      Pro_Features: 'Comercio',
      Paid_content: 'Contenido pagado',
      Get_Paid: 'Retirar el dinero',
      Status: 'Estadísticas',
      Promote: 'Promoción',
      to_your_profile: 'Enlace a tu perfil',
      log_out: 'Salir',
      Get_Business_account: 'Obtener una cuenta de negocio',
    },
    Pay: {
      Payment: 'Pago del pedido',
      PaymentMethod: 'Método de pago',
      Thank: 'Gracias,; tu pedido ha sido pagado',
      PaymentSuccessful: 'El pago ha sido completado',
      Done: 'Hecho',
    },
    AccountActions: {
      Actions: 'Acciones',
      NewFollower: 'Tienes un nuevo seguidor',
      DoNothing: 'Nada',
      SendMessage: 'Mensaje',
      TypeMessage: 'Escribe un mensaje',
      Save: 'Guardar ediciones',
      Interaction: 'Interacción con tus seguidores',
      Post: 'Post',
      ChoiceContent: 'Contenido de elección',
      Public: 'Público',
      All: 'Todo',
      NotPurchased: 'Sin comprar',
      Purchased: 'Comprados',
      PostPurchased: 'Comprado',
      NotBought: 'No comprado',
      Private: 'Privado',
      Select: 'Seleccionar',
      ChatGuisesLabel: 'Personajes de chat',
      ChatGuisesSubLabel:
        'Los usuarios pueden elegir algunos personajes en su chat privado',
      AddCharecter: 'Añadir personaje',
      EditCharecter: 'Сambiar el personaje {{CHARACTER_NAME}}',
      CharacterName: 'Nombre del personaje',
      CharacterPhoto: 'Foto del personaje',
      CharacterNamePlaceholder: 'Enter name',
      SaveCharacter: 'Guardar personaje',
      UpdateCharacter: 'Actualizar el personaje',
      CharacterSuccessfullyCreated:
        'El personaje de {{CHARACTER_NAME}} se creó correctamente',
      CharacterSuccessfullyEdited:
        'El personaje de {{CHARACTER_NAME}} se editó correctamente',
      CharacterSuccessfullyDeleted:
        'El personaje de {{CHARACTER_NAME}} se eliminó correctamente',
      DeleteRole: '¿Estás seguro de que quieres eliminar este rol?',
      WhenСhoiceСharacter: 'El usuario puede elegir un personaje',
      CharecterName: 'Nombre del personaje',
      VIP_Subscription: 'Suscripción VIP',
      UploadMedia: 'Descarga',
      Gallery: 'Galería',
    },
    AccountPromoNewsletter: {
      PromoNewsletter: 'Boletín promocional',
      Text: 'Crea una publicación para compartirla con todos',
      Send: 'Enviar a todos',
      SuccessAlert: 'Boletín promocional enviado correctamente',
      ShareTo: 'Compartir con',
      EveryoneOrChoiceGroup: 'Todo el mundo o elegir un grupo',
      CustomContentPrice: 'Precio del contenido personalizado',
      toFollowers: 'Suscriptoras',
      followers: 'Suscriptoras',
      subscribersVip: 'VIP suscriptoras',
      Everyone: 'Todos suscriptoras',
      AttachText: 'Adjuntar un texto',
      PriceUSD: 'Price, USD',
    },
    AccountPaidContent: {
      title: 'Contenido pagado',
      enable: 'Activar',
      enabled: 'Activada',
      disable: 'Desactivar',
      disabled: 'Desactivada',
      durationText:
        'después del período de prueba, se descontará el importe del mes del usuario',
      Price: 'Precio',
      UsdMonth: 'usd / mensual',
      Save: 'Guardar',
      SubscrTit: 'Suscripción',
      SuccessAlert: 'Precio de suscripción establecido correctamente',
      mo: 'me',
      discountSaved: 'El nuevo precio se ha creado con éxito',
      Trial: 'Prueba',
      Enabled: 'Activar',
      Days: 'Días',
      SuccessfullySaveTrialState: 'El cambio de la prueba fue exitoso',
      changePriceWarning:
        '¡Atención! Después que el precio de la suscripción VIP sube, la renovación automática se desactivará automáticamente para sus suscriptores',
      then: 'entonces',
      commission: 'Comisión Fanter',
      tariff: {
        standard: 'Estándar',
        free: 'Gratis',
      },
      fellText:
        'El Fanter recibe una comisión estándar del {{commission}}% del pago. Los pagos inferiores a 10 céntimos no se contabilizan en la facturación.',
      PaidMessages: 'Mensajes pagados',
      EachMessagePriceCost: 'Precio de un mensaje',
      UsersShouldByPayToMessageYou:
        'El usuario tiene que pagar por cada mensaje que te envíe',
      setMessageFeeSuccessAlert:
        'El precio del mensaje se ha establecido correctamente',
    },
    SexGame: {
      LinkToPage: 'Enlace a la página',
      SexGame: 'Juego erótico',
      WhenUserChoiceCharacter:
        'Quién está disponible para elegir un papel en el chat',
      Everyone: 'Todos',
      OnlyVIPSubscribers: 'Sólo los suscriptores VIP',
      PleaseSetPriceForYourVIPSubscription:
        'Por favor, establece el precio de tu suscripción VIP',
      AccessSettingsHaveBeenSavedSuccessfully:
        'Las configuraciones de acceso se han guardado con éxito',
    },
    AccountGetPaid: {
      Account: 'Los datos bancarios',
      FIO: 'Nombre y apellidos del beneficiario del pago',
      AccountNumber: 'Número de cuenta bancaria',
      Bank: 'Nombre del banco',
      bikBank: 'BIC del banco',
      innBank: 'CIF del banco',
      kppBank: 'C.C.A.F. del banco',
      korrBank: 'Cuenta corresponsal del banco',
      IdentityVerification: 'Verificación de la identidad',
      Upload: 'Subir la foto',
      SaveDataSuccessAlert:
        'Los datos bancarios se han configurado correctamente',
      UploadSuccessAlert: 'Documentos de identidad cargados con éxito',
      AvailableToWithdraw: 'Disponible para retirar',
      AmountInUSD: 'Importe en USD',
      withdrawBtn: 'Retirar',
      withdrawText1: '- puedes retirar no menos de $20',
      withdrawText2: '- el retiro tarda de 3 a 5 días hábiles',
      withdrawSuccessfuly: 'Solicitud de retiro enviada con éxito',
      withdrawWarn:
        'Para realizar una transacción de retiro, primero rellene los campos de ingreso de los datos.',
      Promotions: 'Promoción',
      PromoMonthlyPrice: 'Promo precio mensual',
      period: 'Período',
      DateOfBirth: 'Fecha de nacimiento',
      AddressOfResidence: 'Dirección de residencia',
      StreetAddress: 'Dirección',
      City: 'Ciudad',
      State: 'Estado',
      ZipCode: 'Código postal',
      ChoiceOneTypeWithdrawal: 'Elección de un tipo de retiro',
      BankDetails: 'Datos bancarios',
      CardDetails: 'Datos de la tarjeta',
      CardNumber: 'Número de tarjeta',
      cardHolder: 'Nombre del titular de la tarjeta',
      WeSentCodeYour: 'Enviamos un código a tu dirección de correo electrónico',
      timeToResend: '15 min para confirmar',
      SendNewCode: 'Enviar un nuevo código',
      EditConfirmation: 'Editar confirmación',
      timeIsUp: 'El tiempo se ha agotado',
      TheCodeWasSuccessfullySent: 'El código se ha enviado con éxito',
    },
    AccountBilling: {
      BillingBalance: 'Vincular la tarjeta',
      AddCard: 'Añadir una tarjeta',
      CardNumber: 'Número de tarjeta',
      CardholderName: 'Nombre del titular de la tarjeta',
      ExpirationDate: 'Fin del período de validez',
      CvcRequirements: 'Últimos tres dígitos en el reverso de la tarjeta',
      YourCards: 'Tarjetas vinculadas',
      ValidThru: 'Válido hasta',
      Active: 'Activa',
      Blocked: '',
      Expired: 'No disponible',
      Inactive: 'Inactiva',
      InvalidCardNumber: 'Número de tarjeta no válido',
      EnterCardHolderName: 'Introduce el nombre del titular de la tarjeta',
      EnterCardExpirationDate:
        'Introduce la fecha de vencimiento de la tarjeta',
      EnterCVVCode: 'Introduce el código CVV',
      InvalidCVVCode: 'Código CVV incorrecto',
      CardExpired: 'La tarjeta ha caducado',
      OrderId: 'ID de pedido',
      Sum: 'Importe',
      Send: 'Enviar',
      AccountBalance: 'Saldo',
      TopUpBalance: 'Recargar',
      YouCanUseYourFundsOnlyInMessagesContent:
        'Este saldo sólo puede gastarse para pagar los mensajes',
      fetchDepositForChatSuccessAlert: 'Tu saldo ha sido recargado con éxito',
      WeNotAcceptingRussianCard: 'No aceptamos tarjetas bancarias de Rusia',
      enterAnotherCard: 'Introduce otra tarjeta',
      enterRussianCard:
        'El número de la tarjeta no pasó la verificación. Ingrese el número de la tarjeta que pertenece al banco de la Federación rusa.',
      YouNeedConfirmEmail:
        'Para vincular la tarjeta, debe confirmar el correo.',
    },
    AccountStatistics: {
      Summary: 'Resumen',
      Period: 'Período',
      Month: 'Mes',
      Week: 'Semana',
      Day: 'Día',
      Operations: 'Operaciones',
      AllOperations: 'Todas las operaciones',
      PaidContent: 'Contenido de pago',
      Tips: 'Propina',
      Date: 'Fecha',
      Description: 'Descripción',
      Quantity: 'Cantidad',
      Amount: 'Importe',
      SummaryColumn: 'Resumen',
      Username: 'Autor',
      Content: 'Contenido',
      Pages1: '{{PAGES}} página',
      Pages234: '{{PAGES}} paginas',
      Pages: '{{PAGES}} paginas',
      payType0: 'Contenido de pago',
      payType1: 'Propina',
      payType2: 'Suscripción',
      payType3: 'Retirar',
      payType4: 'Prueba',
      payType10: 'Mensaje pagado',
      Transactions: 'Transacciones',
      YourSubscribers: 'Tus suscriptores',
      HideFilters: 'Ocultar filtros',
      ShowFilters: 'Mostrar filtros',
      User: 'Usuario',
      TotalSpent: 'Total gastado',
      SubscribersCount: '{{count}} usuarios se han suscrito a ti',
    },
    AccountSettings: {
      E_mail: 'Dirección de correo electrónico',
      Your_e_mail: 'Se ha confirmado tu correo electrónico: ',
      is_confirmed: '',
      Confirm_your_e_mail: 'Confirma tu correo electrónico',
      Confirmation_has_been_sent:
        'Se ha enviado un mensaje a tu correo electrónico',
      already_confirmation:
        'Te hemos enviado un correo electrónico de confirmación',
      Re_sendrofile: 'Reenviar confirmación',
      Change_y_password: 'Cambia tu contraseña',
      Old_password: 'Contraseña anterior',
      Current_password: 'Contraseña actual',
      New_password: 'Nueva contraseña',
      confirmPassword: 'Confirma la contraseña',
      Change_password: 'Cambiar contraseña',
      Your_language: 'Tu idioma',
      Language: 'Idioma',
      Deactivate_your_account: 'Desactivar la cuenta',
      Deactivate_txt:
        'Si desactivas tu cuenta, la gente no podrá encontrarte a ti ni a tus publicaciones hasta que vuelvas a activarla',
      Deactivate_Account: 'Desactivar la cuenta',
      Activate_txt:
        'Tu cuenta ha sido desactivada. Otros usuarios no podrán acceder directamente a tu página o a través de la búsqueda mientras no la restablezcas.',
      Activate_Account: 'Restablecer la cuenta',
      Save: 'Guardar',
      Password_changed: 'Contraseña cambiada con éxito.',
      Business_account: 'Cuenta de negocio',
      PrivacySettings: 'Configuración de la privacidad',
      WhoCanSendMessages: 'Quién puede enviarme mensajes privados',
      All: 'Todo',
      OnlyPaidSubscribers: 'Sólo suscriptores de pago',
      blockChatsSuccess:
        'Los ajustes para limitar el envío de mensajes se han guardado con éxito',
    },
    Business_account: {
      activate_txt:
        'Activa la cuenta de negocio y obtén acceso a la sección de configuraciones de Comercio',
      activate_btn: 'Activa la cuenta de negocio',
      deActivate_txt:
        'Si desactivas tu cuenta de negocio, todas las configuraciones de la sección Comercio dejarán de estar disponibles en tu cuenta',
      deActivate_btn: 'Desactiva la cuenta de negocio',
      requested_txt:
        'En moderación! A sección Comercio estará disponible inmediatamente después de que el administrador confirme tu solicitud.',
      uploadDocRequested_txt:
        'Para cambiar el tipo de cuenta, es imprescindible pasar por el procedimiento de verificación de identidad, en el bloque de abajo puedes subir los documentos.',
      uploadDocRequested_btn: 'Cancelar solicitud',
      ConfirmCancelRequestTitle: 'Cancelar solicitud',
      ConfirmCancelRequestDescription:
        'Se eliminarán todos los documentos cargados',
      docsRejected:
        'Los documentos no han pasado la verificación. Por favor, inténtalo de nuevo más tarde.',
      docsApproved:
        'Activa una cuenta de negocios y obtén acceso a la sección de configuraciones "Comercio"',
      SendOnReview: 'Enviar para verificación',
      requirementsTit: 'Sube dos fotos para verificar tu identidad:',
      requirementsTitDoc: 'Una foto de uno de los siguientes documentos:',
      requirementsTextId: 'documento nacional de identidad (DNI)',
      requirementsTextId2: 'permiso de conducir',
      requirementsRulesId: 'Requisito del documento:',
      requirementsRules1:
        'la foto debe ser tomada con suficiente luz y no debe estar borrosa.',
      requirementsRules2:
        'el tamaño de la foto debe ser superior a 800 x 600 píxeles',
      requirementsPhotoHand: 'Sube una foto con el documento en la mano:',
      requirementsPhotoHand1:
        'sube una foto en la que se vea claramente tu rostro y el propio documento',
      requirementsPhotoHand2: 'sólo tú debes estar en la foto',
      requirementsPhotoHand3:
        'el número del documento de identidad debe ser claramente visible',
      requirementsPhotoHand4:
        'la foto no debe estar oscurecida ni borrosa (sin aplicar filtros)',
      requirementsPhotoHand5:
        'la foto debe ser tomada no antes de un mes de presentar la solicitud',
      requirementsLastTit:
        'La verificación de tu cuenta tarda hasta cinco días laborables. El servicio de asistencia técnica se pondrá en contacto contigo en caso de que surjan preguntas.',
      photoYourID: 'Foto de su identificación',
      publishedPost: 'Publicación publicada',
      selfieYourID: 'Selfie con su identificación',
    },
    ConfirmModal: {
      Confirm: 'Confirmar',
      Cancel: 'Cancelar',
    },
    ConfirmTransactionModal: {
      title: 'Confirmación de la transacción',
      text: 'Te hemos cargado una pequeña cantidad de tu tarjeta bancaria. Para usar su tarjeta en el sistema, ingrese la cantidad que se cargó en su tarjeta bancaria a continuación.',
      successAlert:
        'La cantidad introducida es correcta. Tu tarjeta ha sido vinculada con éxito.',
    },
    InviteSettings: {
      m_title: 'Invita a tus amigos y obtén recompensas',
      title_InviteBlock: '¡Invita a tus amigos a fanter y gana dinero!',
      txt_InviteBlock:
        'Recibirás un 50% de la comisión de Fanter por los amigos que invites.',
      Invite_code_copied: 'Se ha copiado el código de invitación.',
      footer_txt_InviteBlock:
        'Comparte este enlace con tus amigos. Tendrán que introducir este código de invitación.',
      Statistic: 'Estadísticas',
      Registred_by_your_invite: 'Registrados a través de una invitación tuya',
      users: 'usuarios',
      Earned: 'Ganancias a través de tus amigos',
      Last: 'Últimos usuarios registrados',
      Showing: 'Se muestran los últimos 10 usuarios',
      Share_link: 'Compartir enlace',
      Copy: 'Copiar',
      Invite_code: 'Código de invitación',
      Link_copied: 'Enlace copiado',
    },
    TimelineMenu: {
      Popular: 'Popular',
      PopularForIsView: 'Popular',
      Following: 'Siguiendo',
      Subscriptions: 'Suscripciones',
      Starred: 'Patrocinado',
      Liked: 'Te ha gustado',
      Private: 'Privado',
      Moderation: 'Moderación',
    },
    Timeline: {
      Show: 'Mostrar comentarios',
      comments: '',
      less: 'ocultar',
      more: 'más',
      Paid_content: 'El contenido de pago',
      Buy: 'Comprar ahora por {{price}}$',
      comment: 'Escribe un comentario',
      Submit: 'Enviar',
    },
    Profile_info: {
      Profile_image: 'Imagen de perfil',
      User_picture: 'Foto de usuario',
      Upload: 'Sube una imagen JPEG o PNG.',
      Drag1: 'Arrastra una imagen aquí',
      Drag2: 'o elige un archivo',
      Maximum: 'Tamaño máximo de archivo: 2 MB',
      Progress: 'Progreso',
      background: 'Fondo de perfil',
      Save: 'Guardar',
      Cancel: 'Cancelar',
      Personal: 'Datos personales',
      Public: 'ID público de la cuenta',
      Your_ID: 'Tu ID',
      Name: 'Nombre',
      First_name: 'Nombre',
      Last_name: 'Apellidos',
      birthday: 'Fecha de nacimiento',
      Select: 'Seleccionar',
      Save_public_ID: 'Guardar ID público',
      Edition_information: 'Información de la edición',
      Location: 'Ubicación',
      Country: 'País',
      City: 'Ciudad',
      About_you: 'Sobre ti',
      loading: 'Verificando el ID',
      redhint: 'ID está ocupado',
      fullwidth: 'Guardar ID público',
      greenhint: 'Se ha cambiado con éxito el ID',
      copied: 'Enlace copiado',
      SocialNetworks: 'Redes sociales',
      InstagramProfile: 'Perfil en Instagram',
      TwitterProfile: 'Perfil en Twitter',
      TiktokProfile: 'Perfil en TikTok',
      FacebookProfile: 'Perfil en Facebook',
    },
    Profile: {
      Available: 'Disponible por suscripción ',
      Photos: 'Fotos',
      Videos: 'Vídeos',
      And: 'y',
      ContentNotPurchad: 'Contenido de pago, {{PRICE}}$, no comprado',
      ContentPurchad:
        'Contenido de pago, {{PRICE}}$, comprado {{BUYED_PRICE}}$',
      Become: 'Hazte suscriptor de Fanter por {{price}}$ al mes',
      Special: 'Oferta especial',
      Posts: 'Publicaciones',
      Followers: 'Suscriptores',
      Follow: 'Suscríbete',
      Unfollow: 'Dejar de seguir',
      Message: 'Enviar mensaje',
      Premium: 'Prima ',
      content: 'contenido para VIP',
      month: 'mes',
      Subscribe: 'Suscríbete',
      AvailableUN: 'Disponible por suscripción de {{USERNAME}}',
      RateText: 'Disponible por ${{AMOUNT}}/mes',
      RateUntil: '${{AMOUNT}}/mes hasta {{DATE}}',
      StubRateText: 'Pronto estará disponible para comprar',
      RateInfo: '{{PHOTOS}} fotos y {{VIDEOS}} videos',
      StubRateInfo: '',
      years: 'años',
      noPostsForMe: 'Aún no tienes publicaciones',
      noPosts: 'Este usuario todavía no tiene publicaciones',
      subscribedTxt: 'Suscripción realizada',
      BuySubscription: 'Comprar una suscripción',
      AccessToPremium: 'Acceso a Premium',
      NotFound_1: 'Oops, usuario no encontrado',
      NotFound_2: '',
    },
    chat: {
      MemberInfo: 'Información del miembro',
      NewMessage: 'Nuevo mensaje',
      Select_chat: 'Selecciona un chat',
      Online: 'Online',
      Support: 'Ayuda',
      CreatorsSupport: 'Apoyo a los creadores',
      Search: 'Buscar',
      Type_message: 'Escribe un mensaje',
      Start_chatting: 'Comenzar a chatear',
      Public_chats: 'Chats públicos',
      Private_chats: 'Chats privados',
      min: 'min',
      Your_message: 'Su historial de mensajes se mostrará aquí. ',
      YouSelectedCharacter:
        'Has seleccionado el personaje {{CHARACTER_NAME}}, {{DATE}}',
      OpponentSelectedCharacter:
        '{{OPPONENT}} ha seleccionado el personaje {{CHARACTER_NAME}}, {{DATE}}',
      Today: 'Hoy',
      SelectCharacter: 'Seleccionar personaje',
      SendPost: 'Enviar post',
      SendTipp: 'Send tipp', /// ???
      Select: 'Seleccionar',
      Cancel: 'Cancelar',
      RolePlayOver: 'El juego de roles ha terminado',
      ArchiveCharacter: 'El personaje archivado ya no está disponible',
      message: {
        read: 'Leído',
        unread: 'No leído',
      },
      UnblockChat: 'Desbloquear al usuario',
      BlockChat: 'Bloquear al usuario',
      blockedSuccessfully: 'El usuario ha sido bloqueado con éxito',
      unblockedSuccessfully: 'El usuario ha sido desbloqueado con éxito',
      youBlocked:
        'El interlocutor ha limitado la posibilidad de enviar mensajes',
      youUnblocked: 'Ahora puedes enviar mensajes',
      chatIsBlocked:
        'Sólo puedes enviar un mensaje después de haber comprado una suscripción VIP',
      Changed: 'Cambiado',
      MessageSuccessfullyDeleted: 'El mensaje ha sido eliminado con éxito',
      MessageSuccessfullyEdited: 'El mensaje ha sido editado con éxito',
      cannotDeletePurchasedPost: 'No puedes eliminar el post que has comprado',
      MessageIsDeleted: 'El mensaje ha sido borrado',
      MessageIsEdited: 'El mensaje ha sido editado',
      Recover: 'Restablecer',
      AddToGroup: 'Añadir al grupo',
      AddDescription: 'Add description',
      userSuccessfullyAdded: 'El usuario ha sido añadido al grupo con éxito',
      userSuccessfullyRemoved:
        'El usuario ha sido eliminado del grupo con éxito',
      groupSuccessfullyCreated: 'El grupo se ha creado con éxito',
      groupSuccessfullyRemoved: 'El grupo ha sido eliminado con éxito',
      CostPerMessage: 'Precio de un mensaje {{messageFee}}',
      SetCostPerMessage:
        'El usuario ha cambiado el precio del mensaje. El precio de un mensaje es de {{messageFee}}',
      YouSetCostPerMessage:
        'Has cambiado el precio de los mensajes. El costo de un mensaje {{messageFee}}',
      fetchPaidMessageSuccessfully: 'Mensaje pagado enviado correctamente',
      SetPaidMessages: 'Establecer el precio de los mensajes',
    },
    Information: {
      Information: 'Información',
      EmailAddress: 'Correo electrónico:',
      PhoneNumber: 'Número de teléfono:',
      TermsOfUse: 'Condiciones de uso',
      PrivacyPolicy: 'Política de privacidad',
      ReturnRefund: 'Politica de reembolso',
      PaymentMethod: 'Método de pago',
      US18: 'Cumplimiento del código 18 U.S. §2247',
      ContentManagement: 'Política de gestión de contenidos',
      ContentPolicy: 'Política y procedimiento de reclamación',
      Documents: 'Documentos',
      OtherDocs: 'Otros documentos',
      DMCAPilicy: 'Política de eliminación de la DMCA',
    },
    fanterInformation: {
      SubsterInc: 'Subster Inc.',
      ContactUs: 'Contactos',
      SpecialRulesForCreators: 'Reglas especiales para los creadores',
      AgreeRules: 'Al enviar una solicitud, usted acepta las reglas',
      CaliforniaCorporation: 'una corporación de California',
      Address:
        'ADS BOOST LTD. Dirección: Prodromou, 75, Oneworld Parkview House, 4th floor, 2063, Nicosia',
    },
    fanterxInformation: {
      SubsterInc: 'ARAY HUNGARY Kft.',
      CaliforniaCorporation: '',
      Address: 'Dirección: Hungría, 2161 Csomád, Kossuth Lajos út 79.',
    },
    fanterUKInformation: {
      SubsterInc: 'Subster Uk Ltd',
      CaliforniaCorporation: '',
      Address:
        'Dirección: 41 Devonshire Street, Ground Floor, London, England, W1G 7AJ',
    },
    buyPostModal: {
      linkCardTitle: 'No hemos podido completar el pago',
      linkCardMessage:
        'Por favor, añade una tarjeta para hacer los pagos y enviar tips',
      forTrialSubscription:
        'Por favor, añade una tarjeta para tramitar el período de prueba',
    },
    SendTips: {
      SendTips: 'Enviar propinas',
      QuickAmount: 'Importe rápido',
      EnterAmount: 'Ingresa el importe, en USD',
      Comment: 'Comentario',
      Success: '¡Con éxito!',
      HasBeenSentInTips: 'Se han enviado {{AMOUNT}}$ de propina!',
      OK: 'OK!',
      InsufficientFundsInYourAccount: 'No hay fondos suficientes en tu cuenta',
      YourBalance: 'Tu saldo: {{BALANCE}}',
      TopUpYourBalance: 'Recargar saldo',
      errorAmountRequired: 'Se requiere un importe de',
      errorAmountMin: 'Ingresa un monto mínimo de $5',
      errorAmountMax: 'El importe máximo es de 500$',
      InvalidCardNumber: 'Número de tarjeta no válido',
      EnterCVVCode: 'Introduce el código CVV',
      InvalidCVVCode: 'Código CVV incorrecto',
      EnterCardHolderName: 'Introduce el nombre del titular de la tarjeta',
      CardNumber: 'Número de tarjeta',
      CardholderName: 'Nombre del titular de la tarjeta',
      cardDateRequired: 'Введите срок действия',
      cardDateInvalid: 'Неверный срок действия',
      CardExpired: 'La tarjeta ha caducado',
      Required: 'Campo obligatorio',
      IncorrectData: 'datos incorrectos',
      ErrorClientReferenceId: 'Enlace inválido',
    },
    Notifications: {
      Notifications: 'Notificaciones',
      NoNotifications: 'Tus notificaciones se mostrarán aquí',
      NothingMore: 'Eso es todo',
      ShowMore: 'Mostrar más',
      t_0: 'A <UserLink>{{USER_NAME}}</UserLink> le gustó tu publicación <PostLink>{{POST_SUBJECT}}</PostLink>',
      t_1: '<UserLink>{{USER_NAME}}</UserLink> se suscribió a ti',
      t_2: '<UserLink>{{USER_NAME}}</UserLink> obtuvo una suscripción de pago por ${{PAY_SUM}}',
      t_3: '<UserLink>{{USER_NAME}}</UserLink> dejó una propina de ${{PAY_SUM}} por tu publicación <PostLink>{{POST_SUBJECT}}</PostLink>',
      t_4: '<UserLink>{{USER_NAME}}</UserLink> ha comprado el post <PostLink>{{POST_SUBJECT}}</PostLink>',
      t_5: '<UserLink>{{USER_NAME}}</UserLink> dejó un comentario en tu publicación <PostLink>{{POST_SUBJECT}}</PostLink>',
      t_6: 'cuenta business activada',
      t_7: 'cuenta business desactivada',
      t_8: 'solicitud de cuenta business rechazada',
    },
    Subscriptions: {
      title: 'Suscripciones activas',
      Active: 'Activa',
      Expired: 'Caducada',
      Pay: 'Pagar',
      Cancel: 'Cancelar',
      PriceForMonth: 'Precio por mes {{PRICE}}',
      AutoRenewal: 'Autorenovación',
      until: 'hasta {{DATE}}',
      newPriceTitlt: 'Precio cambiado',
      newPriceDiscription: 'El precio de la suscripción cambia a {{PRICE}}$',
      paySuccess: '{{USER_NAME}} VIP ¡Suscripción pagada!',
      trial: 'Gratis {{count}} día - entonces ${{then}}/me',
      trial_plural: 'Gratis {{count}} días - entonces ${{then}}/me',
    },
    errors: {
      anyErr: 'Se ha producido un error. {{logCode}}',
      40: 'Este correo electrónico ya está en uso',
      Something:
        'Servidor no disponible, inténtalo de nuevo más tarde. {{logCode}}',
      Try_again: 'Inténtalo de nuevo',
      Repeat_your_request: 'Repite la solicitud',
      3000: 'El idioma seleccionado no está disponible actualmente',
      3004: 'Introduce al menos 5 caracteres',
      Required: 'Obligatorio',
      3011: 'Correo electrónico incorrecto',
      wrong_password: 'Contraseña incorrecta',
      Chars: 'La contraseña debe contener 5 caracteres como mínimo',
      match: 'Las contraseñas no coinciden',
      3001: 'Código no váldio',
      emailNot: 'El usuario con este correo electrónico no está registrado',
      Profile_not_found: 'Perfil no encontrado',
      10011: 'Código no váldio',
      10014: 'Contraseña actual no válida',
      300: 'El usuario con esta dirección no está registrado',
      3006: 'Introduce al menos 2 caracteres',
      3024: 'Introduce al menos 2 caracteres',
      3010: 'Símbolos inaceptables',
      3016: 'Te hemos enviado un correo electrónico. Vuelve a intentarlo más tarde si es necesario.',
      3019: 'Correo electrónico incorrecto',
      3021: 'Tipo de archivo incorrecto para el contenido de la imagen',
      a3021: 'Tamaño máximo de descarga de archivos 2GB',
      3029: 'El texto de la publicación es demasiado largo: redúcelo a 2600 caracteres',
      3030: 'El texto del título es demasiado largo: redúcelo a 300 caracteres',
      3061: 'La creación de la publicación de pago solo está disponible para la cuenta business.',
      3066: 'Código CVV incorrecto',
      10019:
        'La imagen {{NAME}} es demasiado pequeña. El tamaño mínimo permitido es de 600x600 píxeles.',
      10020:
        'La imagen es demasiado pequeña. El tamaño mínimo permitido es de 600x600 píxeles.',
      10021: 'Dimensiones de imagen {{NAME}} no válidas',
      10022: 'Dimensiones de imagen no válidas',
      10023: 'Dimensiones de imagen no válidas',
      10025: 'Tipo de archivo incorrecto para el contenido de la imagen',
      10017: 'Caracteres no válidos',
      10066:
        'El banco que emitió la tarjeta, ha rechazado el pago, por favor, contacta con tu banco o prueba con otra tarjeta',
      10091: 'Error de red #10091: "El subsistema de red no se puede utilizar"',
      10106:
        'Error de red #10106: "No se puede cargar o inicializar el proveedor de servicios deseado"',
      10110: 'A duplicate customer payment profile already exists.',
      10111: 'Datos incorrectos',
      10128: 'Este alias ya está en uso',
      10129: 'Este correo electrónico ya está en uso',
      10140:
        'La fecha que has seleccionado ya está ocupada. Por favor, elige otra fecha.',
      10180: 'Sólo pueden chatear los suscriptores VIP',
      10204: 'Por favor, rellene el saldo interno para enviar un mensaje.',
      10213:
        'La cantidad introducida no es correcta. Por favor, inténtalo de nuevo.',
      10215: 'El tiempo de entrada ha expirado.',
      10216: 'Número de intentos de entrada agotados.',
      10220: 'El código de confirmación ha caducado.',
      10221:
        'Se ha agotado el límite en la cantidad de entrada de código para confirmar.',
      10223: 'El código es incorrecto. Por favor, inténtelo de nuevo.',
      10225:
        'Tratamos de cobrar una pequeña cantidad de Su tarjeta, pero recibimos un rechazo de su banco. Por favor, compruebe que hay suficientes fondos en su cuenta. Si tiene suficientes fondos en su cuenta, póngase en contacto con su banco para aclarar el motivo por el que se rechazó la transacción.',
      10228:
        'Tratamos de cobrar una pequeña cantidad de Su tarjeta, pero recibimos un rechazo de su banco. Por favor, compruebe que hay suficientes fondos en su cuenta. Si tiene suficientes fondos en su cuenta, póngase en contacto con su banco para aclarar el motivo por el que se rechazó la transacción.',
      10229:
        'Tratamos de cobrar una pequeña cantidad de Su tarjeta, pero recibimos un rechazo de su banco. Por favor, compruebe que hay suficientes fondos en su cuenta. Si tiene suficientes fondos en su cuenta, póngase en contacto con su banco para aclarar el motivo por el que se rechazó la transacción.',
      10233:
        'Intentamos cancelar una pequeña cantidad de Su tarjeta, pero recibimos un rechazo de su banco. Por favor, póngase en contacto con su banco para aclarar el motivo de la denegación de la transacción.',
      10230:
        'Desafortunadamente, en este momento el sistema de pago no responde. Por lo general, toma un tiempo. Por favor, intente volver a pagar en 10-30 minutos.',
      10235:
        'Desafortunadamente, en este momento el sistema de pago no responde. Por lo general, toma un tiempo. Por favor, intente volver a pagar en 10-30 minutos.',
      10224:
        'Desafortunadamente, la nueva tarjeta no funcionará. Intente eliminar las tarjetas antiguas en su perfil o envíenos un correo electrónico al chat de soporte.',
      10231:
        'Desafortunadamente, la nueva tarjeta no funcionará. Intente eliminar las tarjetas antiguas en su perfil o envíenos un correo electrónico al chat de soporte.',
      10232:
        'Desafortunadamente, la nueva tarjeta no funcionará. Intente eliminar las tarjetas antiguas en su perfil o envíenos un correo electrónico al chat de soporte.',
      10234:
        'Se produjo un error al intentar enlazar el mapa. Por favor envíenos un correo electrónico al chat de soporte y le ayudaremos.',
      10227:
        'La tarjeta ha caducado. Por favor, intente vincular otra tarjeta o póngase en contacto con su banco para obtener más detalles.',
      10236:
        'Desafortunadamente, este tipo de tarjeta no es compatible. Por favor, intente vincular otra tarjeta.',
      10226:
        'Por favor, compruebe el número de tarjeta. Es posible que haya cometido un error.',
      unavailebleCharacters:
        'Ingresa tu nombre de usuario compuesto por letras, cifras o uno de los símbolos: - , _ . Al ingresar el nombre de usuario, sólo se permiten las letras latinas (A-z). Está prohibido dejar espacios en el medio del inicio de sesión.',
      10251: 'Payment has not yet been paid',
    },
    PaymentErrorModal: {
      tryAgain: 'Intentar otra vez',
      cancel: 'Cancelar',
      ok: 'Ok',
      10111: {
        0: 'Datos incorrectos',
        1: '#{{logCode}}',
        d: 'Comprueba que los datos introducidos sean correctos',
      },
      10211: {
        0: 'Para vincular la tarjeta, debe confirmar el correo.',
        1: '',
        d: '',
      },
      oops: {
        0: 'Not sufficient funds o la transacción fue rechazada por el banco',
        1: '#{{logCode}}',
        d: 'Contacta con tu banco / o utiliza otra tarjeta',
      },
    },
    SelectCharacterModal: {
      header: 'Elige tu personaje',
      text0: '¡Interactúa con {{name}} en cualquier personaje!',
      text1: 'Suscríbete para desbloquear la función Personajes',
      button: 'Suscríbete ahora por {{amount}} $/mes',
      addMode: {
        header: 'Suscripción mensual {{amount}} $',
        subHeader:
          'Puedes cancelar la revelación en cualquier momento en tu configuración',
        text: 'Por favor, añade tu tarjeta de pago',
        button: 'Añade una tarjeta y paga {{amount}} $',
        trialButton: 'Añade una tarjeta y accede gratis - {{count}} día',
        trialButton_plural:
          'Añade una tarjeta y accede gratis - {{count}} días',
        placeholder: 'Introduce el nombre o elige de la lista',
      },
    },
    ModeratorFilters: {
      label: 'Showing',
      all: 'Todos los posts',
      myUnModerated: 'Mis posts sin moderación',
      moderated: 'Aprobados',
      unModerated: 'Posts sin moderación',
    },
    Rate: {
      left: 'Queda {{count}} día',
      left_plural: 'Queda {{count}} días',
      trialDurationDays: 'Gratis {{count}} día',
      trialDurationDays_plural: 'Gratis {{count}} días',
      then: 'entonces ${{amount}}/me',
    },
    UploadMediaModal: {
      header: 'Descarga el archivo',
      Drag1: 'Arrastre el archivo multimedia aquí o',
      Drag2: 'elija',
      // FormatsMessage: "Para subir imágenes sólo se aceptan archivos jpg, png. Para subir vídeos sólo se aceptan archivos mp4, mov",
      FormatsMessage: 'Para subir imágenes sólo se aceptan archivos jpg, png.',
      Attach: 'Descarga',
      Cancel: 'Cancelar',
    },
    Promote: {
      title: 'Conexión de la agencia',
      label:
        'Colaboración con la agencia te ofrece la posibilidad de aumentar tus beneficios',
      selectAgency: 'Seleccionar la agencia',
      SuccessfullyConnectAA: 'La conexión con la agencia fue exitosa',
      SuccessfullyDisconnectAA: 'La desconexión de la agencia fue exitosa',
    },
    Complaint: {
      Complaint: 'Reclamación',
      Report: 'Reclamar',
      PleaseSelectReason: 'Por favor, seleccione el motivo de la reclamación',
      Send: 'Enviar',
      ReportSent: 'Su reclamación ha sido enviada',
      ThankYouForYourMessage: 'Gracias por sus comentarios',
      NotOriginalContent: 'Сontenido no original (violación de mis derechos)',
      Extremism: 'Extremismo',
      SpamOrMisleading: 'Spam o tergiversación',
      Fraud: 'Fraude',
      ChildPornography: 'Pornografía infantil',
      ViolentOrUnpleasantScenes: 'Escenas violentas o repulsivas',
      TerrorismPropaganda: 'Promoción del terrorismo',
    },
  },
};

import Emitter from 'events';

const eventEmitter = new Emitter();

// eslint-disable-next-line @typescript-eslint/no-empty-function
const defaultListener = () => {};

const EventEmitter = {
  on: (event: string, fn: (value: any) => void) => eventEmitter.on(event, fn),
  once: (event: string, fn: (value: any) => void) =>
    eventEmitter.once(event, fn),
  off: (event: string, fn?: (value: any) => void) =>
    eventEmitter.off(event, fn || defaultListener),
  emit: (event: string, payload: any) => eventEmitter.emit(event, payload),
  listenerCount: (event: string) => eventEmitter.listenerCount(event),
};

eventEmitter.setMaxListeners(100000);

Object.freeze(EventEmitter);

export default EventEmitter;

export const toShortStr = (
  str: string | undefined | null,
  maxLenght: number
): string => {
  if (!str) {
    return '';
  }

  return str.length > maxLenght ? `${str.substr(0, maxLenght)}...` : str;
};

type ToLocaleStringValue = number | string | undefined | null;

export const toLocaleString = (
  value: ToLocaleStringValue,
  minimumFractionDigits = 0,
  maximumFractionDigits = 2
): string | null => {
  const _v = typeof value === 'string' ? parseFloat(value) : value;

  if (typeof _v === 'number' && !Number.isNaN(value)) {
    return _v.toLocaleString('en-EN', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits,
      minimumFractionDigits,
    });
  }

  return null;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
export const removeEmpty = (obj: any) => {
  if (obj === undefined) {
    return undefined;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const newObj: any = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key] === Object(obj[key])) newObj[key] = removeEmpty(obj[key]);
    else if (obj[key] !== undefined) newObj[key] = obj[key];
  });

  return newObj;
};

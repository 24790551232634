import React from 'react';

interface IProps {
  className?: string;
  onClick?: () => void;
}

function CardIcon(props: IProps): JSX.Element {
  return <div {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="23"
      fill="none"
      viewBox="0 0 28 23"
    >
      <path
        fill="#fff"
        d="M14.338 18.267H2.4a2.397 2.397 0 01-2.4-2.4V2.4C0 1.072 1.072 0 2.4 0h19.803c1.329 0 2.4 1.072 2.4 2.4v6.054a.557.557 0 01-.558.558.567.567 0 01-.559-.558V2.4c0-.71-.573-1.283-1.283-1.283H2.4c-.71 0-1.283.574-1.283 1.283v13.466c0 .71.574 1.283 1.283 1.283h11.938c.302 0 .558.257.558.559a.548.548 0 01-.558.559z"
      />
      <path
        fill="#fff"
        d="M24.06 4.77H.559A.567.567 0 010 4.212c0-.302.257-.559.559-.559h23.5c.303 0 .56.257.56.559a.567.567 0 01-.56.558zM24.06 7.609H.559A.567.567 0 010 7.05c0-.317.257-.559.559-.559h23.5c.303 0 .56.257.56.559a.567.567 0 01-.56.559zM9.586 12.877H3.261a.567.567 0 01-.559-.558c0-.302.257-.559.559-.559h6.325c.302 0 .559.257.559.559a.567.567 0 01-.559.558zM21.81 22.026a6.198 6.198 0 01-6.189-6.19c0-3.397 2.778-6.16 6.19-6.16 3.411 0 6.189 2.763 6.189 6.16a6.198 6.198 0 01-6.19 6.19zm0-11.232c-2.792 0-5.072 2.264-5.072 5.042a5.083 5.083 0 005.073 5.072 5.083 5.083 0 005.072-5.072c0-2.778-2.265-5.042-5.072-5.042z"
      />
      <path
        fill="#fff"
        d="M21.81 18.433a.567.567 0 01-.558-.559v-4.076c0-.317.257-.559.559-.559.301 0 .558.257.558.559v4.076a.548.548 0 01-.558.559z"
      />
      <path
        fill="#fff"
        d="M23.864 16.395h-4.076a.567.567 0 01-.559-.559c0-.302.257-.559.559-.559h4.076c.302 0 .558.257.558.559a.567.567 0 01-.558.559z"
      />
    </svg>
  </div>
}

export default CardIcon;

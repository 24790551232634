import { SagaIterator } from 'redux-saga';
import { call, fork, put, SagaReturnType } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  delProfile,
  setActions,
  setAutoRenewalRequestStatus,
  setBankDocsState,
  setBankOptionsState,
  setCards,
  setChangePassword,
  setComplaintsList,
  setCreateCard,
  setDepositForChat,
  setDropCard,
  setFollow,
  setIsNicknameAvailable,
  setloadAvatar,
  setloadCover,
  setPayments,
  setPaysStatistic,
  setProfile,
  setPurchases,
  setReferals,
  setResendEmailConfirm,
  setSetActive,
  setSubscriptions,
  setThatProfile,
  setUpdateNickname,
  setUpdateUserActionsStatus,
  setUploadBankDocs,
} from '../reduser';
import { makeReqWithRD, TMakeReqWithRD } from '../../../core/api/makeReqWithRD';
import {
  getProfile,
  refreshToken,
  resendEmailConfirm,
} from '../../registrations/api';
import {
  clearToken,
  getScreenSize,
  getToken,
  setToken,
} from '../../../core/sagas/helper';
import {
  fetchChangePassword,
  fetchCreateCard,
  fetchDepositForChat,
  fetchDropCard,
  fetchFollow,
  fetchGetBankDocs,
  fetchGetBankOptions,
  fetchGetCards,
  fetchGetComplaintsList,
  fetchGetComplaintsListNotReload,
  fetchGetPayments,
  fetchGetPaysStatistic,
  fetchGetPurchases,
  fetchGetReferals,
  fetchGetSubscriptions,
  fetchGetThatProfile,
  fetchGetThatProfileNotReload,
  fetchGetUserActions,
  fetchNicknameAvailable,
  fetchSaveAvatar,
  fetchSaveCover,
  fetchSend,
  fetchSetActive,
  fetchSetAutoRenewal,
  fetchSetBankOptions,
  fetchSetBusiness,
  fetchSetCost,
  fetchSetLanguage,
  fetchSetMessageFee,
  fetchSetNewFee,
  fetchSetUserActions,
  fetchUpdateNickname,
  fetchUpdateProfile,
  fetchUploadBankDoc,
  impersonate,
} from '../actions';
import {
  changePassword,
  createCard,
  depositForChat,
  dropCard,
  follow,
  getBankDocs,
  getBankOptions,
  getComplaintsList,
  getListCards,
  getPayments,
  getPaysStatistic,
  getPurchases,
  getReferals,
  getSubscribeVip,
  getThatProfile,
  getUserActions,
  isNicknameAvailable,
  logout,
  setActive,
  setAutoRenewal,
  setBankOptions,
  setBusiness,
  setCost,
  setLanguage,
  setMessageFee,
  setNewFee,
  TComplaint,
  TCreateCardResponse,
  updateProfile,
  updateUserActions,
  uploadAvatar,
  uploadBackground,
  uploadBankDoc,
} from '../api';
import { FetchedData, genFetchedData } from '../../../core/api/fetchedData';
import { sendFile } from '../../../core/api/sendFile';
import { book, publicBook } from '../../../books';
import { createChat, getChatList } from '../../chat/api';
import { delUnreadCount, setEmptyChat } from '../../chat/reduser';
import { TPayments } from '../../../types/TPayments';
import { TPurchases } from '../../../types/TPurchases';
import { TProfile } from '../../../types/TProfile';
import { TBankOptions } from '../../../types/TBankOptions';
import { TBankDoc } from '../../../types/TBankDoc';
import i18n from '../../../i18n';
import { delNotifications } from '../../notifications/reduser';
import { ErrorProps } from '../../../core/api/errorServeApi';
import { showAlert } from '../../../utils/utils';
import { TPayStatistic } from '../../../types/TPayStatistic';
import { fetchSoftUpdateChatList } from '../../chat/actions';
import { userDepositForChatTG } from '../../../core/api/payWallet';
import { openTelegramLink } from '../../../core/openTelegramLink';

export function* workerFetchGetProfile(): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof getProfile>>(makeReqWithRD, {
      fetcher: getProfile,
      fill: setProfile,
    });
  } catch (e) {
    console.error(e);
  }
}

export function* workerFetchGetProfileNotReload(): SagaIterator<void> {
  yield call(getToken);
  let receivedData: FetchedData<TProfile> = yield call(genFetchedData, null);
  try {
    const res: SagaReturnType<typeof getProfile> = yield call(getProfile);
    receivedData = receivedData.set('data', res);
    yield put(setProfile(receivedData));
  } catch (e) {
    console.error(e);
  }
}

export function* workerFetchGetThatProfileNotReload({
  payload,
}: ReturnType<typeof fetchGetThatProfileNotReload>): SagaIterator<void> {
  yield call(getToken);
  let receivedData: FetchedData<TProfile> = yield call(genFetchedData, null);
  try {
    const res: SagaReturnType<typeof getThatProfile> = yield call(
      getThatProfile,
      { nickname: payload },
    );
    receivedData = receivedData.set('data', res);
    yield put(setThatProfile(receivedData));
  } catch (e) {
    console.error(e);
  }
}

export function* workerFetchSetLanguage({
  payload,
}: ReturnType<typeof fetchSetLanguage>): SagaIterator<void> {
  try {
    yield call(getToken);
    yield call(setLanguage, { lang: payload });
    yield call(workerFetchGetProfileNotReload);
  } catch (e) {
    console.error(e);
  }
}

export function* workerResendEmailConfirm(): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof resendEmailConfirm>>(makeReqWithRD, {
      fetcher: resendEmailConfirm,
      fill: setResendEmailConfirm,
    });
  } catch (e) {
    console.error(e);
  }
}

export function* workerLogOut(): SagaIterator<void> {
  try {
    yield call(logout);
    yield call(clearToken);
    yield put(delProfile());
    yield put(delNotifications());
    yield put(delUnreadCount());
    yield put(push(publicBook.signIn));
  } catch (e) {
    console.error(e);
  }
}

export function* workerImpersonateUser({
  payload,
}: ReturnType<typeof impersonate>): SagaIterator<void> {
  try {
    yield call(clearToken);
    yield put(delProfile());

    const rfToken: SagaReturnType<typeof refreshToken> = yield call(
      refreshToken,
      {
        refresh_token: payload.impersonateToken,
        screenSize: getScreenSize(),
      },
    );
    setToken(rfToken);
    yield put(push(book.root));
    // setTimeout(() => window.location.reload(), 100);
  } catch (e) {
    console.error(e);
    yield put(push(publicBook.signIn));
  }
}

export function* workerChangePassword({
  payload,
}: ReturnType<typeof fetchChangePassword>): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof changePassword>>(makeReqWithRD, {
      fetcher: changePassword,
      fill: setChangePassword,
      parameters: payload,
    });
  } catch (e) {
    console.error(e);
  }
}

export function* workerSetActive({
  payload,
}: ReturnType<typeof fetchSetActive>): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof setActive>>(makeReqWithRD, {
      fetcher: setActive,
      fill: setSetActive,
      parameters: { active: payload },
    });
    yield fork(workerFetchGetProfileNotReload);
  } catch (e) {
    console.error(e);
  }
}

export function* workerGetReferals({
  payload,
}: ReturnType<typeof fetchGetReferals>): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof getReferals>>(makeReqWithRD, {
      fetcher: getReferals,
      fill: setReferals,
      parameters: payload,
    });
    yield fork(workerFetchGetProfileNotReload);
  } catch (e) {
    console.error(e);
  }
}

export function* workerSaveAvatar({
  payload,
}: ReturnType<typeof fetchSaveAvatar>): SagaIterator<void> {
  let receivedData: FetchedData<boolean> = yield call(genFetchedData, null);
  receivedData = receivedData.set('isLoading', true);
  try {
    yield put(setloadAvatar(receivedData));
    yield call(getToken);
    const res: SagaReturnType<typeof uploadAvatar> = yield call(uploadAvatar);

    yield call(sendFile, res.token, payload);
    receivedData = receivedData.set('data', true);
    yield fork(workerFetchGetProfileNotReload);
  } catch (err) {
    const error = err as ErrorProps;
    receivedData = receivedData.set('error', {
      isError: true,
      message: error.message,
      code: error.code,
    });
  } finally {
    receivedData = receivedData.set('isLoading', false).set('LTU', Date.now());
    yield put(setloadAvatar(receivedData));
  }
}

export function* workerSaveCover({
  payload,
}: ReturnType<typeof fetchSaveCover>): SagaIterator<void> {
  let receivedData: FetchedData<boolean> = yield call(genFetchedData, null);
  receivedData = receivedData.set('isLoading', true);
  try {
    yield put(setloadCover(receivedData));
    yield call(getToken);
    const res: SagaReturnType<typeof uploadBackground> =
      yield call(uploadBackground);

    yield call(sendFile, res.token, payload);
    receivedData = receivedData.set('data', true);
    yield fork(workerFetchGetProfileNotReload);
  } catch (err) {
    const error = err as ErrorProps;
    receivedData = receivedData.set('error', {
      isError: true,
      message: error.message,
      code: error.code,
    });
  } finally {
    receivedData = receivedData.set('isLoading', false).set('LTU', Date.now());
    yield put(setloadCover(receivedData));
  }
}

export function* workerNicknameAvailable({
  payload,
}: ReturnType<typeof fetchNicknameAvailable>): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof isNicknameAvailable>>(makeReqWithRD, {
      fetcher: isNicknameAvailable,
      fill: setIsNicknameAvailable,
      parameters: { nickname: payload },
    });
  } catch (e) {
    console.error(e);
  }
}

export function* workerUpdateNickname({
  payload,
}: ReturnType<typeof fetchUpdateNickname>): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof updateProfile>>(makeReqWithRD, {
      fetcher: updateProfile,
      fill: setUpdateNickname,
      parameters: { nickname: payload },
    });
    yield fork(workerFetchGetProfileNotReload);
  } catch (e) {
    console.error(e);
  }
}

export function* workerUpdateProfile({
  payload,
}: ReturnType<typeof fetchUpdateProfile>): SagaIterator<void> {
  try {
    yield call(getToken);
    yield call(updateProfile, payload);
    yield fork(workerFetchGetProfileNotReload);
  } catch (e) {
    console.error(e);
  }
}

export function* workerThatProfile({
  payload,
}: ReturnType<typeof fetchGetThatProfile>): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof getThatProfile>>(makeReqWithRD, {
      fetcher: getThatProfile,
      fill: setThatProfile,
      parameters: { nickname: payload },
    });
  } catch (e) {
    console.error(e);
  }
}

export function* workerFetchSendMessage({
  payload,
}: ReturnType<typeof fetchSend>): SagaIterator<void> {
  try {
    yield call(getToken);
    const list: SagaReturnType<typeof getChatList> = yield call(getChatList);

    let chatId: string;

    const itemList = list.chats.find(
      (item) =>
        item.chatName === payload.hashUser ||
        item.creatorId === payload.hashUser,
    );
    if (itemList?.chatId) {
      chatId = itemList.chatId;
    } else {
      const nChat: SagaReturnType<typeof createChat> = yield call(createChat, {
        hashUser: payload.hashUser,
      });
      yield put(setEmptyChat({ ...payload }));
      chatId = nChat.chatId;
    }

    yield put(
      push(
        `/chat/${chatId}${payload.openCharacters ? '?a=openCharacters' : ''}`,
      ),
    );
  } catch (e) {
    console.error(e);
  }
}

export function* workerSetBusiness({
  payload,
}: ReturnType<typeof fetchSetBusiness>): SagaIterator<void> {
  try {
    yield call(getToken);
    yield call(setBusiness, { isBusiness: payload });
    yield fork(workerFetchGetProfileNotReload);
  } catch (e) {
    console.error(e);
  }
}

export function* workerSetCost({
  payload,
}: ReturnType<typeof fetchSetCost>): SagaIterator<void> {
  try {
    yield call(getToken);
    yield call(setCost, payload);
    showAlert(
      i18n.t('AccountPaidContent.SuccessAlert'),
      'AccountPaidContent.SuccessAlert',
      'success',
    );
    yield fork(workerFetchGetProfileNotReload);
  } catch (e) {
    console.error(e);
  }
}

export function* workerSetMessageFee({
  payload,
}: ReturnType<typeof fetchSetMessageFee>): SagaIterator<void> {
  try {
    yield call(getToken);
    yield call(setMessageFee, payload);
    showAlert(
      i18n.t('AccountPaidContent.setMessageFeeSuccessAlert'),
      'AccountPaidContent.setMessageFeeSuccessAlert',
      'success',
    );
    yield fork(workerFetchGetProfileNotReload);
  } catch (e) {
    console.error(e);
  }
}

export function* workerSetNewFee({
  payload,
}: ReturnType<typeof fetchSetNewFee>): SagaIterator<void> {
  try {
    yield call(getToken);
    yield call(setNewFee, payload);
    yield put(fetchSoftUpdateChatList());
    showAlert(
      i18n.t('AccountPaidContent.setMessageFeeSuccessAlert'),
      'AccountPaidContent.setMessageFeeSuccessAlert',
      'success',
    );
    yield fork(workerFetchGetProfileNotReload);
  } catch (e) {
    console.error(e);
  }
}

export function* workerDepositForChat({
  payload,
}: ReturnType<typeof fetchDepositForChat>): SagaIterator<void> {
  let receivedData: FetchedData<null> = yield call(genFetchedData, null);

  const { amount, isTelegram = false } = payload;
  try {
    yield call(getToken);
    receivedData = receivedData.set('isLoading', true);
    yield put(setDepositForChat(receivedData));

    if (!isTelegram) {
      yield call(depositForChat, { amount: payload.amount });
    }
    if (isTelegram) {
      const { directPayLink } = yield call(userDepositForChatTG, {
        amount: payload.amount,
      });
      openTelegramLink(directPayLink);
    }

    showAlert(
      i18n.t('AccountBilling.fetchDepositForChatSuccessAlert'),
      'AccountBilling.fetchDepositForChatSuccessAlert',
      'success',
    );

    yield fork(workerFetchGetProfileNotReload);
  } catch (e) {
    console.error(e);
  } finally {
    receivedData = receivedData.set('isLoading', false);
    yield put(setDepositForChat(receivedData));
  }
}

export function* workerThatProfileSoftUpdai({
  payload,
}: ReturnType<typeof fetchGetThatProfile>): SagaIterator<void> {
  let receivedData: FetchedData<TProfile> = yield call(genFetchedData, null);
  receivedData = receivedData.set('isLoading', false);
  try {
    yield call(getToken);
    const res: SagaReturnType<typeof getThatProfile> = yield call(
      getThatProfile,
      { nickname: payload },
    );
    receivedData = receivedData.set('data', res);
  } catch (err) {
    const error = err as ErrorProps;
    receivedData = receivedData.set('error', {
      isError: true,
      message: error.message,
      code: error.code,
    });
  } finally {
    receivedData = receivedData.set('isLoading', false).set('LTU', Date.now());
    yield put(setThatProfile(receivedData));
  }
}

export function* workerFollow({
  payload,
}: ReturnType<typeof fetchFollow>): SagaIterator<void> {
  let receivedData: FetchedData<boolean> = yield call(genFetchedData, null);
  receivedData = receivedData.set('isLoading', true);
  try {
    yield put(setFollow(receivedData));
    yield call(getToken);
    yield call(follow, { hashUser: payload.hashUser, follow: payload.follow });
    yield call(
      workerThatProfileSoftUpdai,
      fetchGetThatProfile(payload.nickname),
    );
    receivedData = receivedData.set('data', true);
    // @ts-ignore
    window.dataLayer?.push({
      event: payload.follow ? 'Subscribe_free' : 'Unsubscribe_free',
    });
  } catch (err) {
    const error = err as ErrorProps;
    receivedData = receivedData.set('error', {
      isError: true,
      message: error.message,
      code: error.code,
    });
  } finally {
    receivedData = receivedData.set('isLoading', false).set('LTU', Date.now());
    yield put(setFollow(receivedData));
  }
}

export function* workerGetUserActions({
  payload,
}: ReturnType<typeof fetchGetUserActions>): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof getUserActions>>(makeReqWithRD, {
      fetcher: getUserActions,
      fill: setActions,
      parameters: payload,
    });
  } catch (e) {
    console.error(e);
  }
}

export function* workerSetUserActions({
  payload,
}: ReturnType<typeof fetchSetUserActions>): SagaIterator<void> {
  let receivedData: FetchedData<boolean> = yield call(genFetchedData, null);
  receivedData = receivedData.set('isLoading', true);
  try {
    yield put(setUpdateUserActionsStatus(receivedData));
    yield call(getToken);
    const res: SagaReturnType<typeof updateUserActions> = yield call(
      updateUserActions,
      payload,
    );
    receivedData = receivedData.set('data', res.update);
  } catch (err) {
    const error = err as ErrorProps;
    receivedData = receivedData.set('error', {
      isError: true,
      message: error.message,
      code: error.code,
    });
  } finally {
    receivedData = receivedData.set('isLoading', false).set('LTU', Date.now());
    yield put(setUpdateUserActionsStatus(receivedData));
  }
}

export function* workerGetCards({
  payload,
}: ReturnType<typeof fetchGetCards>): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof getListCards>>(makeReqWithRD, {
      fetcher: getListCards,
      fill: setCards,
      parameters: payload,
    });
  } catch (e) {
    console.error(e);
  }
}

export function* workerCreateCard({
  payload,
}: ReturnType<typeof fetchCreateCard>): SagaIterator<void> {
  let receivedData: FetchedData<TCreateCardResponse> = yield call(
    genFetchedData,
    null,
  );
  receivedData = receivedData.set('isLoading', true);
  try {
    yield put(setCreateCard(receivedData));
    yield call(getToken);
    const res: SagaReturnType<typeof createCard> = yield call(
      createCard,
      payload,
    );
    receivedData = receivedData.set('data', res);
    if (res.created) {
      // @ts-ignore
      window.dataLayer?.push({ event: 'AddPaymentInfo' });
      yield call(workerGetCards, fetchGetCards());
    }
  } catch (err) {
    const error = err as ErrorProps;
    receivedData = receivedData.set('error', {
      isError: true,
      message: error.message,
      code: error.code,
      errorLogCode: error.errorLogCode,
    });
  } finally {
    receivedData = receivedData.set('isLoading', false).set('LTU', Date.now());
    yield put(setCreateCard(receivedData));
  }
}

export function* workerDropCard({
  payload,
}: ReturnType<typeof fetchDropCard>): SagaIterator<void> {
  let receivedData: FetchedData<boolean> = yield call(genFetchedData, null);
  receivedData = receivedData.set('isLoading', true);
  try {
    yield put(setDropCard(receivedData));
    yield call(getToken);
    const res: SagaReturnType<typeof dropCard> = yield call(dropCard, payload);
    receivedData = receivedData.set('data', res.updated);
    if (res.updated) {
      yield call(workerGetCards, fetchGetCards());
    }
  } catch (err) {
    const error = err as ErrorProps;
    receivedData = receivedData.set('error', {
      isError: true,
      message: error.message,
      code: error.code,
    });
  } finally {
    receivedData = receivedData.set('isLoading', false).set('LTU', Date.now());
    yield put(setDropCard(receivedData));
  }
}

export function* workerGetSubscriptions({
  payload,
}: ReturnType<typeof fetchGetSubscriptions>): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof getSubscribeVip>>(makeReqWithRD, {
      fetcher: getSubscribeVip,
      fill: setSubscriptions,
      parameters: payload,
    });
  } catch (e) {
    console.error(e);
  }
}

export function* workerSetAutoRenewal({
  payload,
}: ReturnType<typeof fetchSetAutoRenewal>): SagaIterator<void> {
  let receivedData: FetchedData<boolean> = yield call(genFetchedData, null);
  receivedData = receivedData.set('isLoading', true);
  try {
    yield put(setAutoRenewalRequestStatus(receivedData));
    yield call(getToken);
    const res: SagaReturnType<typeof setAutoRenewal> = yield call(
      setAutoRenewal,
      payload,
    );
    receivedData = receivedData.set('data', res.updated);
    if (res.updated) {
      yield call(workerGetSubscriptions, fetchGetSubscriptions());
    }
  } catch (err) {
    const error = err as ErrorProps;
    receivedData = receivedData.set('error', {
      isError: true,
      message: error.message,
      code: error.code,
    });
  } finally {
    receivedData = receivedData.set('isLoading', false).set('LTU', Date.now());
    yield put(setAutoRenewalRequestStatus(receivedData));
  }
}

export function* workerGetPayments({
  payload,
}: ReturnType<typeof fetchGetPayments>): SagaIterator<void> {
  let receivedData: FetchedData<TPayments> = yield call(genFetchedData, null);
  receivedData = receivedData.set('isLoading', true);
  try {
    yield put(setPayments(receivedData));
    yield call<TMakeReqWithRD<typeof getPayments>>(makeReqWithRD, {
      fetcher: getPayments,
      fill: setPayments,
      parameters: payload,
    });
  } catch (e) {
    console.error(e);
  }
}

export function* workerGetPurchases({
  payload,
}: ReturnType<typeof fetchGetPurchases>): SagaIterator<void> {
  let receivedData: FetchedData<TPurchases> = yield call(genFetchedData, null);
  receivedData = receivedData.set('isLoading', true);
  try {
    yield put(setPurchases(receivedData));
    yield call<TMakeReqWithRD<typeof getPurchases>>(makeReqWithRD, {
      fetcher: getPurchases,
      fill: setPurchases,
      parameters: payload,
    });
  } catch (e) {
    console.error(e);
  }
}

export function* workerGetPaysStatistic({
  payload,
}: ReturnType<typeof fetchGetPaysStatistic>): SagaIterator<void> {
  let receivedData: FetchedData<TPayStatistic[]> = yield call(
    genFetchedData,
    null,
  );
  receivedData = receivedData.set('isLoading', true);
  try {
    yield put(setPaysStatistic(receivedData));
    yield call<TMakeReqWithRD<typeof getPaysStatistic>>(makeReqWithRD, {
      fetcher: getPaysStatistic,
      fill: setPaysStatistic,
      parameters: payload,
    });
  } catch (e) {
    console.error(e);
  }
}

export function* workerSetBankOptions({
  payload,
}: ReturnType<typeof fetchSetBankOptions>): SagaIterator<void> {
  let receivedData: FetchedData<TBankOptions> = yield call(
    genFetchedData,
    null,
  );
  receivedData = receivedData.set('data', payload).set('isLoading', true);
  try {
    yield put(setBankOptionsState(receivedData));
    yield call(setBankOptions, payload);
    receivedData = receivedData.set('data', payload).set('saved', true);
  } catch (err) {
    const error = err as ErrorProps;
    receivedData = receivedData.set('error', {
      isError: true,
      message: error.message,
      code: error.code,
    });
  } finally {
    receivedData = receivedData.set('isLoading', false);
    yield put(setBankOptionsState(receivedData));
  }
}

export function* workerGetBankOptions({
  payload,
}: ReturnType<typeof fetchGetBankOptions>): SagaIterator<void> {
  let receivedData: FetchedData<TBankOptions> = yield call(
    genFetchedData,
    null,
  );
  receivedData = receivedData.set('isLoading', true);

  try {
    yield put(setBankOptionsState(receivedData));
    yield call<TMakeReqWithRD<typeof getBankOptions>>(makeReqWithRD, {
      fetcher: getBankOptions,
      fill: setBankOptionsState,
      parameters: payload,
    });
  } catch (e) {
    console.error(e);
  }
}

export function* workerGetBankDocs({
  payload,
}: ReturnType<typeof fetchGetBankDocs>): SagaIterator<void> {
  let receivedData: FetchedData<TBankDoc[]> = yield call(genFetchedData, null);
  receivedData = receivedData.set('isLoading', true);

  try {
    yield put(setBankDocsState(receivedData));
    yield call<TMakeReqWithRD<typeof getBankDocs>>(makeReqWithRD, {
      fetcher: getBankDocs,
      fill: setBankDocsState,
      parameters: payload,
    });
  } catch (e) {
    console.error(e);
  }
}

export function* workerUploadBankDoc({
  payload,
}: ReturnType<typeof fetchUploadBankDoc>): SagaIterator<void> {
  let receivedData: FetchedData<{ doc?: string; selfie?: string }> = yield call(
    genFetchedData,
    null,
  );
  receivedData = receivedData.set('isLoading', true);

  try {
    yield put(setUploadBankDocs(receivedData));
    yield call(getToken);
    const result: { doc?: string; selfie?: string } = {};

    if (payload.doc) {
      const resDoc: SagaReturnType<typeof uploadBankDoc> =
        yield call(uploadBankDoc);
      const dataDoc = new FormData();
      dataDoc.append('file', payload.doc, payload.doc.name);
      dataDoc.append('docType', '0');
      const resDocFile: SagaReturnType<typeof sendFile> = yield call(
        sendFile,
        resDoc.token,
        dataDoc,
      );
      if (resDocFile.result) {
        result.doc = resDocFile.result;
      }
    }

    if (payload.selfie) {
      const resSelfie: SagaReturnType<typeof uploadBankDoc> =
        yield call(uploadBankDoc);
      const dataSelfie = new FormData();
      dataSelfie.append('file', payload.selfie, payload.selfie.name);
      dataSelfie.append('docType', '1');
      const resSelfieFile: SagaReturnType<typeof sendFile> = yield call(
        sendFile,
        resSelfie.token,
        dataSelfie,
      );
      if (resSelfieFile.result) {
        result.selfie = resSelfieFile.result;
      }
    }

    showAlert(
      i18n.t('AccountGetPaid.UploadSuccessAlert'),
      'AccountGetPaid.UploadSuccessAlert',
      'success',
    );
    receivedData = receivedData.set('data', result);
    yield fork(workerFetchGetProfileNotReload);
    yield fork(workerGetBankDocs, fetchGetBankDocs());
  } catch (err) {
    const error = err as ErrorProps;
    receivedData = receivedData.set('error', {
      isError: true,
      message: error.message,
      code: error.code,
    });
    yield put(setUploadBankDocs(receivedData));
  } finally {
    receivedData = receivedData.set('isLoading', false).set('LTU', Date.now());
    yield put(setUploadBankDocs(receivedData));
  }
}

export function* workerFetchGetComplaintsList({
  payload,
}: ReturnType<typeof fetchGetComplaintsList>): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof getComplaintsList>>(makeReqWithRD, {
      fetcher: getComplaintsList,
      fill: setComplaintsList,
      parameters: {
        ...payload,
        dateFrom: 0,
        dateTo: new Date().getTime(),
      },
    });
  } catch (e) {
    console.error(e);
  }
}

export function* workerFetchGetComplaintsListNotReload({
  payload,
}: ReturnType<typeof fetchGetComplaintsListNotReload>): SagaIterator<void> {
  yield call(getToken);
  let receivedData: FetchedData<TComplaint[]> = yield call(
    genFetchedData,
    null,
  );
  try {
    const parameters = {
      ...payload,
      dateFrom: 0,
      dateTo: new Date().getTime(),
    };
    const res: SagaReturnType<typeof getComplaintsList> = yield call(
      getComplaintsList,
      parameters,
    );
    receivedData = receivedData.set('data', res);
    yield put(setComplaintsList(receivedData));
  } catch (e) {
    console.error(e);
  }
}

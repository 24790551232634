import { call, put, SagaReturnType } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/types';

import { FetchedData, genFetchedData } from 'core/api/fetchedData';
import { getToken } from 'core/sagas/helper';
import { sendFile } from 'core/api/sendFile';
import { ErrorProps } from 'core/api/errorServeApi';
import { TContentFile } from 'types/TContent';

import {
  fetchAddPost,
  fetchAddPostWithTus,
  fetchEditPost,
  fetchEditPostWithTus,
} from '../actions';
import { addPost, addPostWithTus, editPost, editPostWithTus } from '../api';
import { setAddPost } from '../reducer';
import { TPost } from '../TPost';

const prepareData = (
  payload: TPost & {
    leftFreeFiles: TContentFile[];
    leftPayedFiles: TContentFile[];
  },
): FormData => {
  const data = new FormData();

  if (payload.subject) {
    data.append('subject', payload.subject.trim());
  }
  if (payload.message) {
    data.append('message', payload.message.trim());
  }
  if (payload.messageChat) {
    data.append('messageChat', payload.messageChat);
  }
  if (payload.messagePayed) {
    data.append('messagePayed', payload.messagePayed);
  }
  if (payload.payButtonText) {
    data.append('payButtonText', payload.payButtonText);
  }
  if (payload.showFanterVip) {
    data.append('showFanterVip', payload.showFanterVip.toString());
  }
  if (payload.showSubscribers) {
    data.append('showSubscribers', payload.showSubscribers.toString());
  }
  if (payload.price) {
    data.append('price', payload.price.toString());
  }
  if (payload.private) {
    data.append('private', payload.private.toString());
  }
  if (['dev.fanter.com', 'localhost'].includes(window.location.hostname)) {
    data.append('isDev', '1');
  }
  if (payload.editId) {
    data.append('editId', payload.editId);
  }

  if (
    payload.freeFiles.length > 0 &&
    typeof payload.freeFiles[0] === 'string'
  ) {
    payload.freeFiles.forEach((file: File | string) =>
      data.append('freeFiles[]', file),
    );
  } else if (payload.freeFiles.length > 0) {
    payload.freeFiles.forEach((file: File | string) =>
      data.append('freeFiles[]', file as File, (file as File).name),
    );
  }

  if (
    payload.payedFiles.length > 0 &&
    typeof payload.payedFiles[0] === 'string'
  ) {
    payload.payedFiles.forEach((file: File | string) =>
      data.append('payedFiles[]', file),
    );
  } else if (payload.payedFiles.length > 0) {
    payload.payedFiles.forEach((file: File | string) =>
      data.append('payedFiles[]', file as File, (file as File).name),
    );
  }

  payload.leftFreeFiles.forEach((_) => data.append('leftFreeFiles[]', _.path));
  payload.leftPayedFiles.forEach((_) =>
    data.append('leftPayedFiles[]', _.path),
  );

  return data;
};

export function* workerAddPost({
  payload,
}: ReturnType<typeof fetchAddPost>): SagaIterator<void> {
  let receivedData: FetchedData<boolean> = yield call(genFetchedData, null);
  receivedData = receivedData.set('isLoading', true);
  try {
    yield call(getToken);
    yield put(setAddPost(receivedData));
    const res: SagaReturnType<typeof addPost> = yield call(addPost);
    const data = prepareData(payload);
    yield call(sendFile, res.token, data);
    receivedData = receivedData.set('data', true);
  } catch (err) {
    console.error(err);
    const error = err as ErrorProps;
    receivedData = receivedData.set('error', {
      isError: true,
      message: error.message,
      code: error.code,
    });
    yield put(setAddPost(receivedData));
  } finally {
    receivedData = receivedData.set('isLoading', false).set('LTU', Date.now());
    yield put(setAddPost(receivedData));
  }
}

export function* workerEditPost({
  payload,
}: ReturnType<typeof fetchEditPost>): SagaIterator<void> {
  let receivedData: FetchedData<boolean> = yield call(genFetchedData, null);
  receivedData = receivedData.set('isLoading', true);

  try {
    yield put(setAddPost(receivedData));
    yield call(getToken);
    const res: SagaReturnType<typeof editPost> = yield call(editPost);
    const data = prepareData(payload);
    yield call(sendFile, res.token, data);
    receivedData = receivedData.set('data', true);
  } catch (err) {
    const error = err as ErrorProps;
    receivedData = receivedData.set('error', {
      isError: true,
      message: error.message,
      code: error.code,
    });
    yield put(setAddPost(receivedData));
  } finally {
    receivedData = receivedData.set('isLoading', false).set('LTU', Date.now());
    yield put(setAddPost(receivedData));
  }
}

export function* workerAddPostWithTus({
  payload,
}: ReturnType<typeof fetchAddPostWithTus>): SagaIterator<void> {
  let receivedData: FetchedData<boolean> = yield call(genFetchedData, null);
  receivedData = receivedData.set('isLoading', true);

  try {
    yield put(setAddPost(receivedData));
    yield call(getToken);
    const res: SagaReturnType<typeof addPostWithTus> =
      yield call(addPostWithTus);
    const data = prepareData(payload);
    yield call(sendFile, res.token, data);
    receivedData = receivedData.set('data', true);
  } catch (err) {
    const error = err as ErrorProps;
    receivedData = receivedData.set('error', {
      isError: true,
      message: error.message,
      code: error.code,
    });
    yield put(setAddPost(receivedData));
  } finally {
    receivedData = receivedData.set('isLoading', false).set('LTU', Date.now());
    yield put(setAddPost(receivedData));
  }
}

export function* workerEditPostWithTus({
  payload,
}: ReturnType<typeof fetchEditPostWithTus>): SagaIterator<void> {
  let receivedData: FetchedData<boolean> = yield call(genFetchedData, null);
  receivedData = receivedData.set('isLoading', true);

  try {
    yield put(setAddPost(receivedData));
    yield call(getToken);
    const res: SagaReturnType<typeof editPostWithTus> =
      yield call(editPostWithTus);
    const data = prepareData(payload);
    yield call(sendFile, res.token, data);
    receivedData = receivedData.set('data', true);
  } catch (err) {
    const error = err as ErrorProps;
    receivedData = receivedData.set('error', {
      isError: true,
      message: error.message,
      code: error.code,
    });
    yield put(setAddPost(receivedData));
  } finally {
    receivedData = receivedData.set('isLoading', false).set('LTU', Date.now());
    yield put(setAddPost(receivedData));
  }
}

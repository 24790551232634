import React from 'react';

interface IProps {
  className?: string;
  onClick?: (event?: React.MouseEvent<HTMLDivElement>) => void;
}

function TrashIcon(props: IProps): JSX.Element {
  return <div {...props}>
    <svg
      width="15"
      height="17"
      viewBox="0 0 15 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.16 5.96V14.02C12.16 14.702 11.602 15.26 10.92 15.26H3.48C2.798 15.26 2.24 14.702 2.24 14.02V5.96M5.67385 7.82V12.4462M8.72615 7.82V12.4462M13.4 5.67385H1V4.1C1 3.418 1.558 2.86 2.24 2.86H12.16C12.842 2.86 13.4 3.418 13.4 4.1V5.67385ZM10.3 2.86H4.1C4.1 1.806 4.906 1 5.96 1H8.44C9.494 1 10.3 1.806 10.3 2.86Z"
        stroke="#ABABD1"
        strokeWidth="1.6"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
}

export default TrashIcon;

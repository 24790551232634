import React from 'react';

interface IProps {
  className?: string;
  onClick?: () => void;
}

function MoreVertIcon(props: IProps): JSX.Element {
  return <div {...props}>
    <svg
      width="4"
      height="18"
      viewBox="0 0 4 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.83105 15.2421C3.83105 14.2193 3.01016 13.3895 1.99837 13.3895C0.986571 13.3895 0.165678 14.2193 0.165678 15.2421C0.165678 16.2649 0.986571 17.0947 1.99837 17.0947C3.01016 17.0975 3.83105 16.2677 3.83105 15.2421Z"
        fill="#413E93"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.83105 8.92891C3.83105 7.90611 3.01016 7.0763 1.99837 7.0763C0.986571 7.0763 0.165678 7.90611 0.165678 8.92891C0.165678 9.9517 0.986571 10.7815 1.99837 10.7815C3.01016 10.7843 3.83105 9.95446 3.83105 8.92891Z"
        fill="#413E93"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.83105 2.76733C3.83105 1.74454 3.01016 0.914726 1.99837 0.914726C0.986571 0.914726 0.165678 1.74454 0.165678 2.76733C0.165678 3.79013 0.986571 4.61994 1.99837 4.61994C3.01016 4.6227 3.83105 3.79288 3.83105 2.76733Z"
        fill="#413E93"
      />
    </svg>
  </div>
}

export default MoreVertIcon;

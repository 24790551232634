import React, { FC } from 'react';

const Preload: FC = () => (
    <div className="stub">
      <svg
        width="251"
        height="84"
        viewBox="0 0 251 84"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.63877 82.9592H19.9163V44.4047H29.2833V34.6756H19.598V31.4627C19.598 28.0235 21.5078 26.123 25.873 26.123C27.1917 26.123 28.5103 26.2135 29.4652 26.3945V17.6609C27.9192 17.3441 25.1455 17.0726 22.69 17.0726C11.1404 17.0726 6.63877 21.5073 6.63877 31.0102V34.6756H0V44.4047H6.63877V82.9592Z"
          fill="#3A3B8D"
        />
        <path
          d="M52.5645 74.1804C48.0629 74.1804 45.0618 71.9178 45.0618 68.2976C45.0618 64.8585 47.881 62.6412 52.8373 62.2791L63.1138 61.6456V65.1753C63.1138 70.4245 58.3393 74.1804 52.5645 74.1804ZM48.1993 83.7285C54.2924 83.7285 60.34 80.6966 63.0683 75.5379H63.3411V82.9592H76.164V49.6086C76.164 39.8343 68.0246 33.4085 55.5201 33.4085C42.6063 33.4085 34.558 39.8795 34.0578 49.2919H46.1986C46.8351 45.717 49.9726 43.2734 54.929 43.2734C59.9308 43.2734 63.1138 45.898 63.1138 50.4232V53.6361L50.8366 54.3601C38.6958 55.0841 31.8752 60.1976 31.8752 69.0217C31.8752 77.7553 38.9232 83.7285 48.1993 83.7285Z"
          fill="#3A3B8D"
        />
        <path
          d="M84.758 82.9592H98.0355V55.1294C98.0355 48.8394 101.81 44.4952 107.766 44.4952C113.723 44.4952 116.724 48.1153 116.724 54.4506V82.9592H130.002V51.6902C130.002 40.332 123.909 33.5443 113.086 33.5443C105.584 33.5443 100.445 37.0739 97.8536 43.0924H97.5808V34.4493H84.758V82.9592Z"
          fill="#3A3B8D"
        />
        <path
          d="M141.961 23.2721V34.4493H135.094V44.4047H141.961V69.8814C141.961 79.3391 146.417 83.1402 157.875 83.1402C160.558 83.1402 162.832 82.914 164.196 82.5972V72.8681C163.377 73.0038 162.15 73.0943 160.967 73.0943C157.057 73.0943 155.238 71.3295 155.238 67.6189V44.4047H164.241V34.4493H155.238V23.2721H141.961Z"
          fill="#3A3B8D"
        />
        <path
          d="M192.661 43.1829C198.572 43.1829 202.619 47.4366 202.892 53.6361H182.202C182.657 47.5723 186.84 43.1829 192.661 43.1829ZM203.074 68.1166C201.846 71.8273 198.117 74.2256 193.252 74.2256C186.477 74.2256 182.066 69.4742 182.066 62.5507V61.7361H215.669V57.7087C215.669 42.9566 206.711 33.4085 192.524 33.4085C178.11 33.4085 169.016 43.4544 169.016 58.9305C169.016 74.4519 178.019 84 193.024 84C205.074 84 213.805 77.6195 215.26 68.1166H203.074Z"
          fill="#3A3B8D"
        />
        <path
          d="M222.535 82.9592H235.813V55.8534C235.813 49.0204 239.632 45.0382 246.18 45.0382C248.09 45.0382 249.909 45.355 251 45.8075V34.1778C250.091 33.9063 248.772 33.68 247.226 33.68C241.497 33.68 237.359 37.0287 235.631 43.1829H235.358V34.4493H222.535V82.9592Z"
          fill="#3A3B8D"
        />
        <path
          d="M65.3481 28.7218L56.4513 23.0083L47.5114 28.6575L49.9646 18.0403L42.0638 10.9L52.4794 10.0581L56.5312 0L60.5154 10.0902L70.9248 11.0157L62.9748 18.0917L65.3481 28.7218Z"
          fill="#3A3B8D"
        />
      </svg>
    </div>
  );
export default Preload;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FetchedData, genFetchedData } from '../../core/api/fetchedData';
import type {
  TCard,
  TComplaint,
  TRGetReferals,
  TRisNicknameAvailable,
  TUserActions,
} from './api';
import { TPayments } from '../../types/TPayments';
import { TPurchases } from '../../types/TPurchases';
import { TProfile } from '../../types/TProfile';
import { TBankOptions } from '../../types/TBankOptions';
import { TBankDoc } from '../../types/TBankDoc';
import { TSubscription } from '../../types/TSubscription';
import { TPayStatistic } from '../../types/TPayStatistic';
import { TCreateCardResponse } from './api';

export interface UserState {
  profile: FetchedData<TProfile>;
  resendEmailConfirm: FetchedData<boolean>;
  changePassword: FetchedData<boolean>;
  setActive: FetchedData<boolean>;
  referals: FetchedData<TRGetReferals>;
  loadAvatar: FetchedData<boolean>;
  loadCover: FetchedData<boolean>;
  isNicknameAvailable: FetchedData<TRisNicknameAvailable>;
  updateNickname: FetchedData<boolean>;
  thatProfile: FetchedData<TProfile>;
  follow: FetchedData<boolean>;
  actions: FetchedData<TUserActions>;
  updateUserActionsStatus: FetchedData<boolean>;
  cards: FetchedData<TCard[]>;
  createCard: FetchedData<TCreateCardResponse>;
  dropCard: FetchedData<boolean>;
  payments: FetchedData<TPayments>;
  purchases: FetchedData<TPurchases>;
  paysStatistic: FetchedData<TPayStatistic[]>;
  bankOptions: FetchedData<TBankOptions>;
  bankDocs: FetchedData<TBankDoc[]>;
  uploadBankDocs: FetchedData<{ doc?: string; selfie?: string }>;
  subscriptions: FetchedData<TSubscription[]>;
  autoRenewalRequestStatus: FetchedData<boolean>;
  isBusiness: FetchedData<boolean>;
  isBusinessRequest: FetchedData<boolean>;
  complaintsList: FetchedData<TComplaint[]>;
  depositForChat: FetchedData<null>;
}

export const initialState: UserState = {
  profile: genFetchedData<TProfile>(null),
  resendEmailConfirm: genFetchedData<boolean>(null),
  changePassword: genFetchedData<boolean>(null),
  setActive: genFetchedData<boolean>(null),
  referals: genFetchedData<TRGetReferals>(null),
  loadAvatar: genFetchedData<boolean>(null),
  loadCover: genFetchedData<boolean>(null),
  isNicknameAvailable: genFetchedData<TRisNicknameAvailable>(null),
  updateNickname: genFetchedData<boolean>(null),
  thatProfile: genFetchedData<TProfile>(null),
  follow: genFetchedData<boolean>(null),
  actions: genFetchedData<TUserActions>(null),
  updateUserActionsStatus: genFetchedData<boolean>(null),
  cards: genFetchedData<TCard[]>(null),
  createCard: genFetchedData<TCreateCardResponse>(null),
  dropCard: genFetchedData<boolean>(null),
  payments: genFetchedData<TPayments>(null),
  purchases: genFetchedData<TPurchases>(null),
  paysStatistic: genFetchedData<TPayStatistic[]>(null),
  bankOptions: genFetchedData<TBankOptions>(null),
  bankDocs: genFetchedData<TBankDoc[]>(null),
  uploadBankDocs: genFetchedData<{ doc?: string; selfie?: string }>(null),
  subscriptions: genFetchedData<TSubscription[]>(null),
  autoRenewalRequestStatus: genFetchedData<boolean>(null),
  isBusiness: genFetchedData<boolean>(null),
  isBusinessRequest: genFetchedData<boolean>(null),
  complaintsList: genFetchedData<TComplaint[]>(null),
  depositForChat: genFetchedData<null>(null),
};
const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setProfile(state, action: PayloadAction<UserState['profile']>) {
      state.profile = action.payload;
      state.isBusiness = genFetchedData<boolean>(
        action.payload.get('data')?.isBusiness === 1,
      );
      state.isBusinessRequest = genFetchedData<boolean>(
        action.payload.get('data')?.isBusinessRequest || null,
      );
    },
    delProfile(state) {
      state.profile = initialState.profile;
    },
    setBusinessStatus(state, action: PayloadAction<UserState['isBusiness']>) {
      state.isBusiness = action.payload;
    },
    setBusinessRequestStatus(
      state,
      action: PayloadAction<UserState['isBusinessRequest']>,
    ) {
      state.isBusinessRequest = action.payload;
    },
    setResendEmailConfirm(
      state,
      action: PayloadAction<UserState['resendEmailConfirm']>,
    ) {
      state.resendEmailConfirm = action.payload;
    },
    delResendEmailConfirm(state) {
      state.resendEmailConfirm = initialState.resendEmailConfirm;
    },
    setChangePassword(
      state,
      action: PayloadAction<UserState['changePassword']>,
    ) {
      state.changePassword = action.payload;
    },
    delChangePassword(state) {
      state.changePassword = initialState.changePassword;
    },
    setSetActive(state, action: PayloadAction<UserState['setActive']>) {
      state.setActive = action.payload;
    },
    delSetActive(state) {
      state.setActive = initialState.setActive;
    },
    setReferals(state, action: PayloadAction<UserState['referals']>) {
      state.referals = action.payload;
    },
    delReferals(state) {
      state.referals = initialState.referals;
    },
    setloadAvatar(state, action: PayloadAction<UserState['loadAvatar']>) {
      state.loadAvatar = action.payload;
    },
    delloadAvatars(state) {
      state.loadAvatar = initialState.loadAvatar;
    },
    setloadCover(state, action: PayloadAction<UserState['loadCover']>) {
      state.loadCover = action.payload;
    },
    delloadCover(state) {
      state.loadCover = initialState.loadCover;
    },
    setIsNicknameAvailable(
      state,
      action: PayloadAction<UserState['isNicknameAvailable']>,
    ) {
      state.isNicknameAvailable = action.payload;
    },
    delIsNicknameAvailable(state) {
      state.isNicknameAvailable = initialState.isNicknameAvailable;
    },
    setUpdateNickname(
      state,
      action: PayloadAction<UserState['updateNickname']>,
    ) {
      state.updateNickname = action.payload;
    },
    delUpdateNickname(state) {
      state.updateNickname = initialState.updateNickname;
    },
    setThatProfile(state, action: PayloadAction<UserState['thatProfile']>) {
      state.thatProfile = action.payload;
    },
    delThatProfile(state) {
      state.thatProfile = initialState.thatProfile;
    },
    setFollow(state, action: PayloadAction<UserState['follow']>) {
      state.follow = action.payload;
    },
    delFollow(state) {
      state.thatProfile = initialState.thatProfile;
    },
    setActions(state, action: PayloadAction<UserState['actions']>) {
      state.actions = action.payload;
    },
    setUpdateUserActionsStatus(
      state,
      action: PayloadAction<UserState['updateUserActionsStatus']>,
    ) {
      state.updateUserActionsStatus = action.payload;
    },
    setCards(state, action: PayloadAction<UserState['cards']>) {
      state.cards = action.payload;
    },
    setCreateCard(state, action: PayloadAction<UserState['createCard']>) {
      state.createCard = action.payload;
    },
    setDropCard(state, action: PayloadAction<UserState['dropCard']>) {
      state.dropCard = action.payload;
    },
    setSubscriptions(state, action: PayloadAction<UserState['subscriptions']>) {
      state.subscriptions = action.payload;
    },
    setAutoRenewalRequestStatus(
      state,
      action: PayloadAction<UserState['autoRenewalRequestStatus']>,
    ) {
      state.autoRenewalRequestStatus = action.payload;
    },
    setPayments(state, action: PayloadAction<UserState['payments']>) {
      state.payments = action.payload;
    },
    setPurchases(state, action: PayloadAction<UserState['purchases']>) {
      state.purchases = action.payload;
    },
    setPaysStatistic(state, action: PayloadAction<UserState['paysStatistic']>) {
      state.paysStatistic = action.payload;
    },
    setBankOptionsState(
      state,
      action: PayloadAction<UserState['bankOptions']>,
    ) {
      state.bankOptions = action.payload;
    },
    setBankDocsState(state, action: PayloadAction<UserState['bankDocs']>) {
      state.bankDocs = action.payload;
    },
    setUploadBankDocs(
      state,
      action: PayloadAction<UserState['uploadBankDocs']>,
    ) {
      state.uploadBankDocs = action.payload;
    },
    setComplaintsList(
      state,
      action: PayloadAction<UserState['complaintsList']>,
    ) {
      state.complaintsList = action.payload;
    },
    setDepositForChat(
      state,
      action: PayloadAction<UserState['depositForChat']>,
    ) {
      state.depositForChat = action.payload;
    },
  },
});

export const {
  setProfile,
  delProfile,
  setBusinessStatus,
  setBusinessRequestStatus,
  setResendEmailConfirm,
  delResendEmailConfirm,
  setChangePassword,
  delChangePassword,
  setSetActive,
  delSetActive,
  setReferals,
  delReferals,
  setloadAvatar,
  delloadAvatars,
  setloadCover,
  delloadCover,
  setIsNicknameAvailable,
  delIsNicknameAvailable,
  setUpdateNickname,
  delUpdateNickname,
  setThatProfile,
  delThatProfile,
  setFollow,
  delFollow,
  setActions,
  setUpdateUserActionsStatus,
  setCards,
  setCreateCard,
  setSubscriptions,
  setAutoRenewalRequestStatus,
  setDropCard,
  setPayments,
  setPurchases,
  setPaysStatistic,
  setBankOptionsState,
  setBankDocsState,
  setUploadBankDocs,
  setComplaintsList,
  setDepositForChat,
} = userSlice.actions;
export const userReducer = userSlice.reducer;

/* eslint-disable no-template-curly-in-string */

import { ErrorClientReferenceId } from '../../components/ModalDonate/ErrorClientReferenceId';

export default {
  translations: {
    common: {
      back: 'Назад',
      Save: 'Сохранить',
      TypeNote: 'Введите комментарий',
      Search: 'Найти',
      Empty: 'Пусто',
      Group: 'Группа',
      Groups: 'Группы',
      countOfParticipants_0: '{{count}} участник',
      countOfParticipants_1: '{{count}} участника',
      countOfParticipants_2: '{{count}} участников',
      Send: 'Отправить',
      Done: 'Готово',
      VIP_Subscription: 'VIP Подписка',
      AmountUSD: 'Сумма, USD',
      errorAmountMin: 'Введите сумму не менее ${{count}}',
      errorAmountMax: 'Максимальная сумма - ${{count}}',
      SignInFanterAccount: 'Вход в<br />аккаунт fanter',
      CreateFanterAccount: 'Создать<br />аккаунт в fanter',
      pleaseAuth:
        "Пожалуйста, <span id='postStubSignIn'>войдите</span> или <span id='postStubSignUp'>зарегистрируйтесь</span>, чтобы получать контент.",
    },
    alerts: {
      success: {
        setNote: 'Комментарий успешно сохранен',
      },
    },
    landing: {
      NitaKuzminaV2: {
        ExclusiveGame: 'Эксклюзивная игра',
        text0: 'Для начала выбери образ!',
        text1: 'Хочешь уникальный контент, который я сниму для тебя?',
        text2: 'Получи доступ к эксклюзивному контенту только на fanter',
        signUp: 'Зарегистрируйся сейчас',
        PlayGame: 'Начать Игру',
      },
      Landing: 'Лендинг',
      Sets: 'Набор лендингов',
      AddSet: 'Добавить набор персонажей для лендинга',
      setName: 'Наименование',
      Add: 'Добавить',
      Cancel: 'Отменить',
      AddSetCharacter: 'Добавить персонажа',
      SetSuccessfullyCreated: 'Набор {{name}} успешно создан',
      SetSuccessfullyDeleted: 'Набор {{name}} успешно удален',
    },
    onlineStatuses: {
      '1': 'Онлайн',
      '2': 'Отошел',
      '3': 'Офлайн',
      '4': 'был меньше часа назад',
      '5': 'был час назад',
      '6_0': 'был {{count}} час назад',
      '6_1': 'был {{count}} часа назад',
      '6_2': 'был {{count}} часов назад',
      '7_0': 'был {{count}} днь назад',
      '7_1': 'был {{count}} дня назад',
      '7_2': 'был {{count}} дней назад',
      '8_0': 'был {{count}} неделю назад',
      '8_1': 'был {{count}} недели назад',
      '8_2': 'был {{count}} недель назад',
      '9': 'был давно',
    },
    authorization: {
      SNCWRS: 'Познакомься с восходящими звездами',
      CAABS: 'Создай аккаунт и стань звездой',
      Password: 'Пароль',
      Confirm_password: 'Подтверждение пароля',
      Sign_up: 'Регистрация',
      invite: 'Инвайт',
      Create_account: 'Создать аккаунт',
      ARL: 'Уже зарегистрированы? Войти',
      Check_your_email: 'Проверьте свою электронную почту',
      letter_password:
        'На вашу электронную почту отправлено письмо со ссылкой для сброса пароля. Если письмо долго не приходит, проверьте папку Спам.',
      Login: 'Вход',
      Forgot_password: 'Забыли пароль?',
      Sign_in: 'Вход',
      nickname: 'Псевдоним/Никнейм',
      IhaveInvideCode: 'У вас есть код приглашения?',
      NotValidCode: 'Недействительный код',
      Activated: 'Активирован',
      Activate: 'Активировать',
      BackToLogin: 'Вернуться на страницу авторизации',
    },
    invite: {
      title: 'У вас есть код инвайта?',
      text: 'Укажите код инвайта, если вы регистрируетесь по приглашению. Вы можете пропустить этот шаг.',
      enter: 'Введите код инвайта',
      activate: 'Активировать',
      skip: 'Пропустить',
      succes: 'Вы успешно ввели инвайт-код:',
      succes_text: 'Добро пожаловать в Fanter!',
      go_to: 'Перейти к Ленте',
    },
    forgot_password: {
      re_send: 'Сбросить пароль',
      title_new_p: 'Введите новый пароль',
      activate: 'Активировать',
      no_valid: 'Страница недействительна',
      repeat: 'Повторите запрос на смену пароля.',
      home: 'Домой',
    },
    e_mail_confirmed: {
      confirmed: 'Спасибо, e-mail подтвержден',
      created: 'Аккаунт создан',
      done: 'Готово',
      repeat: 'Повторите запрос на подтверждение почты.',
    },
    header: {
      Timeline: 'Хроника',
      Chats: 'Чаты',
      Hello: 'Привет',
    },
    Post: {
      Blocked: 'Заблокировано',
      ConvertingErrorTitle: 'Ошибка обработки видео',
      ConvertingErrorText: 'Видео не может быть загружено',
      VideoUploadingTitle: 'Ваше видео загружается',
      VideoUploadingText:
        'Скоро видео будет доступно в вашей ленте;Это сообщение видите только вы',
      TypeComment: 'Напишите комментарий',
      Submit: 'Отправить',
      PaidContent: 'Платный контент',
      BuyNow: 'Купить сейчас',
      Photos: 'Фото',
      Videos: 'Видео',
      FreeForFanterVIP: 'Бесплатно по подписке Fanter',
      FreeForSubscribersVIP: 'Бесплатно по подписке',
      FreeForSubscribersVIPWithTrial_0:
        'Получите доступ бесплатно - {{count}} день',
      FreeForSubscribersVIPWithTrial_1:
        'Получите доступ бесплатно - {{count}} дня',
      FreeForSubscribersVIPWithTrial_2:
        'Получите доступ бесплатно - {{count}} дней',
      ThenForSubscribersVIPWithTrial: 'затем {{then}}$/месяц',
      Tips: 'Чаевые',
      SendTips: 'Отправить чаевые',
      BlockPost: 'Заблокировать пост',
      UnblockPost: 'Разблокировать пост',
      ConfirmPost: 'Подтвердить пост',
      Decline: 'Отклонить пост',
      SharePost: 'Поделиться',
      EditPost: 'Редактировать',
      DisplayInMainContent: 'Показывать в ленте',
      UnDisplayInMainContent: 'Убрать из ленты',
      SuccessfullyBlocked: 'Пост успешно заблокирован',
      SuccessfullyUnblocked: 'Пост успешно разаблокирован',
      SuccessfullyConfirmed: 'Пост успешно утверждён',
      SuccessfullyDecline: 'Пост успешно отклонен',
      SuccessfullySetDisplayInMainContent: 'Пост успешно добавлен в ленту',
      SuccessfullyUnSetDisplayInMainContent: 'Пост успешно удален из ленты',
      FreeForVIP: 'Бесплатно для VIP',
      deleteComment: 'Удалить комментарий',
      deleteCommentSuccessfully: 'Комментарий успешно удален',
      waitingPay: 'Ожидаем статус транзакции от платежной системы',
    },
    AddPost: {
      PublicPost: 'Публичный пост',
      PrivatePost: 'Приватный пост',
      NewPost: 'Новая публикация',
      EditPost: 'Редактирование публикации',
      Title: 'Заголовок',
      Description: 'Описание',
      UploadPV: 'Загрузите фото или видео',
      UploadP: 'Загрузите фото',
      PaidContent: 'Платный контент',
      TextBuyButton: 'Текст на кнопке Купить',
      PaidContentDescription: 'Описание платного контента',
      PaidContentIsFreeFor: 'Платный контент бесплатен для',
      HidePaidContent: 'Скрыть платный контет',
      ChatText: 'Текст для чата',
      ChatDescription:
        'Этот текст будет сопровождать медиаматериалы, если вы отправите пост в чате',
      Post: 'Опубликовать',
      Prewiew: 'Предпросмотр',
      Prise: 'Цена',
      MedeaFormat: 'Формат фотографий JPEG, JPG, PNG, MOV, MP4',
      PhotoFormat: 'Формат фотографий JPEG, JPG, PNG',
      FanterVIPs: 'Fanter	VIPs',
      YourVIPs: 'Ваши VIPs',
      OnlyForSubscribers: 'Только для подписчиков',
      HideChatText: 'Скрыть текст для чата',
      AddPaidContent: 'Добавить платный контент',
      AddChatText: 'Добавить текст для чата',
      Progress: 'Загрузка {{PROGRESS}}%',
      Errors: {
        TitleRequired: 'Заполните заголовок поста',
        TitleLong: 'Слишком дллиный заголовок (максимум 300 символов)',
        DescriptionRequred: 'Заполните текст поста',
        TextLong: 'Слишком длинное сообщение (максимум 2500 символов)',
        BuyButten: 'Слишком длинный текст кнопки (максимум 250 символов)',
        PriceRequired: 'Укажите цену платной части поста',
        AmountMin: 'Вы не можете установить цену платного контента ниже 5$',
        FreeMedeaContentRequired: 'Добавьте бесплатные фото или видео',
        PayedMedeaContentRequired: 'Добавьте платные фото или видео',
      },
      Save: 'Сохранить изменения',
      NotFound: 'Такой страницы не существует. Возможно, она была удалена.',
      AccessDeclined: 'Доступ запрещен',
      OnlyForSubscribersToolTip:
        'Выберите сначала "Ваши VIP-персоны" и/или "Фантеры VIP"',
    },
    CreatePost: {
      placeholder: 'Напишите что-нибудь',
      Publication_error: 'Ошибка публикации',
      successfully: 'Запись успешно опубликована',
      editSuccessfully: 'Запись успешно отредактирована',
      New_post: 'Новая публикация',
      Choose_image: 'Выберите изображение',
      Title: 'Заголовок',
      Text: 'Текст',
      Share: 'Опубликовать',
      Drag_image: 'Перетащите картинку, или ',
      browse: 'выберите',
      text_required: 'Поле текст обязательно',
      Image_required: 'Картинка обязательна',
      Paid_content: 'Платный контент ',
      Your_VIP: 'Ваши подписчики',
      Fanter_VIP: 'Подписка Fanter',
      Free: 'Бесплатно',
      Public_post: 'Публичный пост',
      Public_txt:
        'После публикации этот пост попадет в вашу ленту \n' +
        'и будет доступен юзерам бесплатно или за оплату/подписку',
      Private_post: 'Приватный пост',
      Private_txt:
        'После публикации вы сможете отправить этот\n' +
        ' пост пользователю в чате бесплатно или с предложением купить',
      Choose_p_t: 'Выберите тип поста',
    },
    SettingsMenu: {
      MyProfile: 'Мой профиль',
      Profile: 'Профиль',
      Profile_info: 'Основная информация',
      Account: 'Настройки аккаунта',
      BandB: 'Привязать карту',
      Invite: 'Приглашайте людей и зарабатывайте',
      Pro_Features: 'Коммерция',
      Paid_content: 'Платный контент',
      Get_Paid: 'Вывести деньги',
      Status: 'Статистика',
      Promote: 'Продвижение',
      to_your_profile: 'Ссылка на ваш профиль',
      log_out: 'Выйти',
      Get_Business_account: 'Получить Бизнес аккаунт',
    },
    Pay: {
      Payment: 'Оплата заказа',
      PaymentMethod: 'Способ оплаты',
      Thank: 'Спасибо,; ваш заказ оплачен',
      PaymentSuccessful: 'Платеж успешно проведен',
      Done: 'Готово',
    },
    AccountActions: {
      Actions: 'Действия',
      NewFollower: 'Вы получили нового подписчика ',
      DoNothing: 'Ничего',
      SendMessage: 'Cообщение',
      TypeMessage: 'Напишите сообщение',
      Save: 'Сохранить изменения',
      Interaction: 'Взаимодействие с вашими подписчиками',
      Post: 'Пост',
      ChoiceContent: 'Выберите контент',
      Public: 'Публичные',
      All: 'Все',
      NotPurchased: 'Не купленные',
      Purchased: 'Купленные',
      PostPurchased: 'Куплено',
      NotBought: 'Не куплено',
      Private: 'Приватные',
      Select: 'Выбрать',
      PromoNewsletter: 'Промо рассылка',
      ChatGuisesLabel: 'Персонажи чата',
      ChatGuisesSubLabel:
        'Пользователям доступен выбор персонажей в приватном чате',
      AddCharecter: 'Добавить персонажа',
      EditCharecter: 'Изменить персонажа {{CHARACTER_NAME}}',
      CharacterName: 'Имя персонажа',
      CharacterPhoto: 'Изображение персонажа',
      CharacterNamePlaceholder: 'Enter name',
      SaveCharacter: 'Сохранить персонажа',
      UpdateCharacter: 'Обновить персонажа',
      CharacterSuccessfullyCreated:
        'Персонаж {{CHARACTER_NAME}} успешно создан',
      CharacterSuccessfullyEdited:
        'Персонаж {{CHARACTER_NAME}} успешно отредактирован',
      CharacterSuccessfullyDeleted:
        'Персонаж {{CHARACTER_NAME}} успешно удален',
      DeleteRole: 'Вы действительно хотите удалить данную роль?',
      WhenСhoiceСharacter: 'Когда пользователь выбрал персонажа',
      CharecterName: 'Имя персонажа',
      VIP_Subscription: 'VIP Подписка',
      UploadMedia: 'Загрузить',
      Gallery: 'Галерея',
    },
    AccountPromoNewsletter: {
      PromoNewsletter: 'Промо рассылка',
      Text: 'Создайте пост, чтобы поделиться со всеми',
      Send: 'Отправить всем',
      SuccessAlert: 'Промо рассылка отправлена успешно',
      ShareTo: 'Поделиться с',
      EveryoneOrChoiceGroup: 'Cо всеми или с выбранной группой пользователей',
      CustomContentPrice: 'Кастомная цена',
      toFollowers: 'Подписчиками',
      followers: 'Подписчики',
      subscribersVip: 'VIP подписчики',
      Everyone: 'Cо всеми подписчиками',
      AttachText: 'Прикрепить текст',
      PriceUSD: 'Цена, USD',
    },
    AccountPaidContent: {
      title: 'Платный контент',
      enable: 'Включить',
      enabled: 'Включено',
      disable: 'Отключить',
      disabled: 'Отключено',
      durationText:
        'после пробного периода, сумма за месяц спишется у пользователя',
      Price: 'Цена',
      UsdMonth: 'usd / месяц',
      Save: 'Сохранить',
      SubscrTit: 'Подписка',
      SuccessAlert: 'Цена подписки установлена успешно',
      mo: 'мес',
      discountSaved: 'Новая цена успешно создана',
      Trial: 'Пробный период',
      Enabled: 'Включить',
      Days: 'Дней',
      SuccessfullySaveTrialState: 'Изменение триала прошло успешно',
      changePriceWarning:
        'Внимание! После повышения цены на ВИП подписку авто продление автоматически отключится.',
      then: 'затем',
      commission: 'Комиссия Fanter',
      tariff: {
        standard: 'Стандарт',
        free: 'Бесплатно',
      },
      fellText:
        'Fanter получает стандартную комиссию в {{commission}}% от суммы платежа. Платежи менее 10 центов не учитываются при выставлении счетов.',
      PaidMessages: 'Платные сообщения',
      EachMessagePriceCost: 'Стоимость одного сообщения',
      UsersShouldByPayToMessageYou:
        'Пользователь должен вам заплатить за каждое сообщение',
      setMessageFeeSuccessAlert: 'Цена на сообщение была успешно установлена',
    },
    SexGame: {
      LinkToPage: 'Ссылка на страницу',
      SexGame: 'Секс игра',
      WhenUserChoiceCharacter: 'Кому доступен выбор роли в чате',
      Everyone: 'Всем',
      OnlyVIPSubscribers: 'Только ВИП подписчикам',
      PleaseSetPriceForYourVIPSubscription:
        'Пожалуйста, установите цену для вашей ВИП подписки',
      AccessSettingsHaveBeenSavedSuccessfully:
        'Настройки доступа успешно сохранены',
    },
    AccountGetPaid: {
      Account: 'Банковские реквизиты',
      FIO: 'ФИО получателя платежа',
      AccountNumber: 'Номер банковского счета',
      Bank: 'Наименование банка',
      bikBank: 'БИК банка',
      innBank: 'ИНН банка',
      kppBank: 'КПП банка',
      korrBank: 'Корр. счет банка',
      IdentityVerification: 'Верификация личности',
      Upload: 'Загрузить фото',
      SaveDataSuccessAlert: 'Банковские реквизиты установлены успешно',
      UploadSuccessAlert:
        'Документы для подтверждения личности загружены успешно',
      AvailableToWithdraw: 'Доступно для вывода',
      AmountInUSD: 'Сумма в $',
      withdrawBtn: 'Вывод',
      withdrawText1: '- можно вывести не менее 20$',
      withdrawText2: '- вывод занимает от 3 до 5 рабочих дней',
      withdrawSuccessfuly: 'Заявка на вывод успешно отправлена',
      withdrawWarn:
        'Чтобы произвести транзакцию вывода, сначала заполните поля ввода реквизитов.',
      Promotions: 'Акции',
      PromoMonthlyPrice: 'Eжемесячная цена акции',
      period: 'Период',
      DateOfBirth: 'Дата рождения',
      AddressOfResidence: 'Адрес проживания',
      StreetAddress: 'Улица',
      City: 'Город',
      State: 'Штат',
      ZipCode: 'Почтовый индекс',
      ChoiceOneTypeWithdrawal: 'Выбор одного из типов вывода средств',
      BankDetails: 'Банковские реквизиты',
      CardDetails: 'Реквизиты карты',
      CardNumber: 'Номер карты',
      cardHolder: 'Имя держателя карты',
      WeSentCodeYour: 'Мы отправили код на ваш адрес электронной почты',
      timeToResend: '15 минут для подтверждения',
      SendNewCode: 'Отправить новый код',
      EditConfirmation: 'Редактировать подтверждение',
      timeIsUp: 'Время истекло',
      TheCodeWasSuccessfullySent: 'Код успешно отправлен',
    },
    AccountBilling: {
      BillingBalance: 'Привязать карту',
      AddCard: 'Добавить карту',
      CardNumber: 'Номер карты',
      CardholderName: 'Имя владельца карты',
      ExpirationDate: 'Окончание периода действия',
      CvcRequirements: 'Последние три символа на обороте карты',
      YourCards: 'Привязанные карты',
      ValidThru: 'Действительна до',
      Active: 'Активна',
      Blocked: '',
      Expired: 'Недоступна',
      Inactive: 'Неактивная',
      InvalidCardNumber: 'Неверный номер карты',
      EnterCardHolderName: 'Введите имя владельца карты',
      EnterCardExpirationDate: 'Введите дату окончания срока действия карты',
      EnterCVVCode: 'Введите CVV код',
      InvalidCVVCode: 'Неверный CVV код',
      CardExpired: 'Срок действия карты истек',
      OrderId: 'Айди заказа',
      Sum: 'Сумма',
      Send: 'Отправить',
      AccountBalance: 'Баланс',
      TopUpBalance: 'Пополнить',
      YouCanUseYourFundsOnlyInMessagesContent:
        'Данный баланс может быть использован только для оплаты сообщений',
      fetchDepositForChatSuccessAlert: 'Ваш баланс был успешно пополнен',
      WeNotAcceptingRussianCard: 'Мы не принимаем банковские карты России',
      enterAnotherCard: 'Введите другую карту',
      enterRussianCard:
        'Номер карты не прошел проверку. Введите номер карты, пренадлежащей банку РФ.',
      YouNeedConfirmEmail:
        'Для того, чтобы привязать карту, необходимо подтвердить почту.',
    },
    AccountStatistics: {
      Summary: 'Общая',
      Period: 'Период',
      Month: 'Месяц',
      Week: 'Неделя',
      Day: 'День',
      Operations: 'Операции',
      AllOperations: 'Все операции',
      PaidContent: 'Платный контент',
      Tips: 'Чаевые',
      Date: 'Дата',
      Description: 'Описание',
      Quantity: 'Количество',
      Amount: 'Сумма',
      SummaryColumn: 'Общая',
      Username: 'Автор',
      Content: 'Контент',
      Pages1: '{{PAGES}} страница',
      Pages234: '{{PAGES}} страницы',
      Pages: '{{PAGES}} страниц',
      payType0: 'Платный контент',
      payType1: 'Чаевые',
      payType2: 'Подписка',
      payType3: 'Вывод',
      payType4: 'Пробный период',
      payType10: 'Платное сообщение',
      Transactions: 'Транзакции',
      YourSubscribers: 'Ваши подписчики',
      HideFilters: 'Скрыть фильтры',
      ShowFilters: 'Показать фильтры',
      User: 'Пользователь',
      TotalSpent: 'Всего потрачено',
      SubscribersCount_0: '{{count}} пользователь подписано на вас',
      SubscribersCount_1: '{{count}} пользователя подписано на вас',
      SubscribersCount_2: '{{count}} пользователей подписано на вас',
    },
    AccountSettings: {
      E_mail: 'Адрес электронной почты',
      Your_e_mail: 'Вы подтвердили свой адрес электронной почты: ',
      is_confirmed: '',
      Confirm_your_e_mail: 'Подтвердите свой адрес электронной почты',
      Confirmation_has_been_sent: 'Письмо отправлено на вашу почту',
      already_confirmation:
        'Мы отправили запрос подтверждения на вашу электронную почту',
      Re_sendrofile: 'Отправить подтверждение еще раз',
      Change_y_password: 'Сменить пароль',
      Old_password: 'Старый пароль',
      Current_password: 'Текущий пароль',
      New_password: 'Новый пароль',
      confirmPassword: 'Подтвердите пароль',
      Change_password: 'Сменить пароль',
      Your_language: 'Ваш язык',
      Language: 'Язык',
      Deactivate_your_account: 'Деактивировать аккаунт',
      Deactivate_txt:
        'Если вы удалите ваш аккаунт, люди не смогут найти вас или ваши записи, пока вы не восстановите свой аккаунт',
      Deactivate_Account: 'Деактивировать аккаунт',
      Activate_txt:
        'Ваш аккаунт деактивирован. Другие пользователи не смогут попасть на вашу страницу напрямую или через поиск, пока вы не восстановите его.',
      Activate_Account: 'Восстановить аккаунт',
      Save: 'Сохранить',
      Password_changed: 'Пароль успешно изменен.',
      Business_account: 'Бизнес-аккаунт',
      PrivacySettings: 'Настройки конфеденциальности',
      WhoCanSendMessages: 'Кто может отправлять мне личные сообщения',
      All: 'Все',
      OnlyPaidSubscribers: 'Только подписчики, имеющие платную подписку',
      blockChatsSuccess:
        'Настройки по ограничению отправки сообщений успешно сохранены',
    },
    Business_account: {
      activate_txt:
        'Активируйте бизнес-аккаунт и получите доступ к разделу настроек Коммерция ',
      activate_btn: 'Активировать бизнес-аккаунт',
      deActivate_txt:
        'При деактивации бизнес аккаунта все настройки раздела Коммерция перестанут быть доступны в вашем аккаунте',
      deActivate_btn: 'Деактивировать бизнес аккаунт',
      requested_txt:
        'На модерации! Раздел Коммерция станет доступен сразу после того как ваш запрос подтвердит администратор.',
      uploadDocRequested_txt:
        'Для изменения типа аккаунта требуется пройти процедуру верификации личности, в блоке ниже вы можете загрузить документы и подать заявку.',
      uploadDocRequested_btn: 'Отменить заявку',
      ConfirmCancelRequestTitle: 'Отменить заявку',
      ConfirmCancelRequestDescription:
        'Все загруженные документы будут удалены',
      docsRejected:
        'Документы не прошли верификацию. Повторите попытку позднее.',
      docsApproved:
        'Активируйте бизнес-аккаунт и получите доступ к разделу настроек "Коммерция"',
      SendOnReview: 'Отправить на проверку',
      requirementsTit:
        'Для верификации вашей личности загрузите две фотографии:',
      requirementsTitDoc: 'Фотографию одного из следующих документов:',
      requirementsTextId: 'удостоверение личности',
      requirementsTextId2: 'водительские права',
      requirementsRulesId: 'Требование к документу :',
      requirementsRules1:
        'фото должно быть сделано при достаточном освещении и не должно быть размытым',
      requirementsRules2: 'размер фото должно быть более 800 х 600 пикселей',
      requirementsPhotoHand: 'Загрузите фото с документом в руках:',
      requirementsPhotoHand1:
        'загрузите фото, на котором четко видно ваше лицо и сам документ',
      requirementsPhotoHand2: 'в кадре не должно быть никого, кроме вас',
      requirementsPhotoHand3: 'номер паспорта должен быть отчетливо виден',
      requirementsPhotoHand4:
        'фотография должна быть не затемнена и не размыта (без применения фильтров)',
      requirementsPhotoHand5:
        'фото должно быть сделано не раньше чем за месяц до подачи заявки',
      requirementsLastTit:
        'Сроки верификации аккаунта до пяти бизнес дней. В случае вопросов поддержка свяжется с вами дополнительно.',
      photoYourID: 'Фотография с вашим документом',
      publishedPost: 'Опубликованный пост',
      selfieYourID: 'Селфи с вашим документом',
    },
    ConfirmModal: {
      Confirm: 'Подтвердить',
      Cancel: 'Отменить',
    },
    ConfirmTransactionModal: {
      title: 'Подтверждение транзакции',
      text: 'Мы выполнили списание небольшой суммы с вашей банковской карты (до 99 цента). Чтобы использовать вашу карту в системе, введите сумму, которую списали с вашей банковской карты ниже.',
      successAlert: 'Сумма введена корректно. Ваша карта успешно привязана.',
    },
    InviteSettings: {
      m_title: 'Приглашайте людей и зарабатывайте',
      title_InviteBlock:
        'Приглашайте своих друзей в fanter и зарабатывайте деньги!',
      txt_InviteBlock:
        'Вы получите 50% от комиссии Fanter за друзей, которых Вы пригласили.',
      Invite_code_copied: 'Код инвайта скопирован.',
      footer_txt_InviteBlock:
        'Поделитесь этой ссылкой со своими друзьями, они должны ввести этот пригласительный код',
      Statistic: 'Статистика',
      Registred_by_your_invite: 'Зарегистрировано по вашему приглашению',
      users: 'пользователей',
      Earned: 'Заработано благодаря вашим друзьям',
      Last: 'Недавние зарегистрированные пользователи',
      Showing: 'Показаны 10 последних пользователей',
      Share_link: 'Поделиться ссылкой',
      Copy: 'Копировать',
      Invite_code: 'Пригласительный код',
      Link_copied: 'Ссылка скопирована',
    },
    TimelineMenu: {
      Popular: 'Популярное',
      PopularForIsView: 'Популярное',
      Following: 'Отслеживать',
      Subscriptions: 'Подписки',
      Starred: 'Сохранить в закладках',
      Liked: 'Понравилось',
      Private: 'Приватные',
      Moderation: 'Модерация',
    },
    Timeline: {
      Show: 'Показать комментарии',
      comments: '',
      less: 'скрыть',
      more: 'ещё',
      Paid_content: 'Платный контент',
      Buy: 'Купить сейчас за {{price}}$',
      comment: 'Напишите комментарий',
      Submit: 'Отправить',
    },
    Profile_info: {
      Profile_image: 'Изображения профиля',
      User_picture: 'Аватар пользователя',
      Upload: 'Загрузите фотографию JPEG, PNG.',
      Drag1: 'Перетащите изображение или',
      Drag2: 'выберите файл',
      Maximum: 'Максимальный вес файла 2MB',
      Progress: 'Загрузка',
      background: 'Обложка профиля',
      Save: 'Сохранить',
      Cancel: 'Отменить',
      Personal: 'Личные данные',
      Public: 'Публичный ID ',
      Your_ID: 'Ваш ID',
      Name: 'ФИО',
      First_name: 'Имя',
      Last_name: 'Фамилия',
      birthday: 'Дата рождения',
      Select: 'Выбрать',
      Save_public_ID: 'Сохранить публичный ID',
      Edition_information: 'Дополнительная информация',
      Location: 'Местоположение',
      Country: 'Страна',
      City: 'Город',
      About_you: 'О себе',
      loading: 'Идет проверка ID',
      redhint: 'ID занят',
      fullwidth: 'Сохранить публичный ID',
      greenhint: 'ID успешно изменен',
      copied: 'Ссылка скопирована',
      SocialNetworks: 'Социальные сети',
      InstagramProfile: 'Профиль Instagram',
      TwitterProfile: 'Профиль Twitter',
      TiktokProfile: 'Профиль Tiktok',
      FacebookProfile: 'Профиль Facebook',
    },
    Profile: {
      Available: 'Доступно по подписке ',
      Photos: 'Фото',
      Videos: 'Видео',
      And: 'и',
      ContentNotPurchad: 'Платный контент, {{PRICE}}$, не куплено',
      ContentPurchad: 'Платный контент, {{PRICE}}$, куплено {{BUYED_PRICE}}$',
      Become: 'Стань подписчиков Fanter за {{price}}$ в месяц',
      Special: 'Специальное предложение',
      Posts: 'Посты',
      Followers: 'Подписчики',
      Follow: 'Подписаться',
      Unfollow: 'Отписаться',
      Message: 'Написать',
      Premium: 'Премиум',
      content: 'контент для VIP-персон',
      month: 'месяц',
      Subscribe: 'Купить подписку ',
      AvailableUN: 'Доступно по подписке {{USERNAME}}',
      RateText: 'Доступно за ${{AMOUNT}}/месяц',
      RateUntil: '${{AMOUNT}}/месяц до {{DATE}}',
      StubRateText: 'Скоро станет доступно к покупке',
      RateInfo: '{{PHOTOS}} фото и {{VIDEOS}} видео',
      StubRateInfo: '',
      years: 'лет',
      noPostsForMe: 'У вас ещё нет постов',
      noPosts: 'У пользователя ещё нет постов',
      subscribedTxt: 'Подписка оформлена',
      BuySubscription: 'Купить подписку',
      AccessToPremium: 'Доступ к премиум подписке',
      NotFound_1: 'Упс, пользователь не найден',
      NotFound_2: '',
    },
    chat: {
      MemberInfo: 'Информация об участнике',
      NewMessage: 'Новое сообщение',
      Select_chat: 'Выберите чат',
      Online: 'Онлайн',
      Support: 'Помощь',
      CreatorsSupport: 'Помощь создателям',
      Search: 'Поиск',
      Type_message: 'Напишите сообщение',
      Start_chatting: 'Начать чат',
      Public_chats: 'Публичные чаты',
      Private_chats: 'Личные чаты',
      min: 'мин',
      Your_message: 'Здесь будет отображаться ваша история сообщений.',
      YouSelectedCharacter: 'Вы выбрали персонажа {{CHARACTER_NAME}}, {{DATE}}',
      OpponentSelectedCharacter:
        '{{OPPONENT}} выбрал персонажа {{CHARACTER_NAME}}, {{DATE}}',
      Today: 'Сегодня',
      SelectCharacter: 'Выбрать персонажа',
      SendPost: 'Отправить пост',
      SendTipp: 'Send tipp', /// ???
      Select: 'Выбрать', /// ???
      Cancel: 'Отменить', /// ???
      RolePlayOver: 'Ролевая игра закончена',
      ArchiveCharacter: 'Архивный персонаж больше не доступен',
      message: {
        read: 'Прочитано',
        unread: 'Не прочитано',
      },
      UnblockChat: 'Разблокировать пользователя',
      BlockChat: 'Заблокировать пользователя',
      blockedSuccessfully: 'Пользователь успешно заблокирован',
      unblockedSuccessfully: 'Пользователь успешно разблокирован',
      youBlocked: 'Собеседник ограничил возможность отправки сообщений',
      youUnblocked: 'Теперь вы можете отправлять сообщения',
      chatIsBlocked:
        'Отправить сообщение вы сможете только после покупки VIP подписки',
      Changed: 'Изменено',
      MessageSuccessfullyDeleted: 'Сообщение успешно удалено',
      MessageSuccessfullyEdited: 'Сообщение успешно отредактировано',
      cannotDeletePurchasedPost: 'Вы не можете удалить купленный пост',
      MessageIsDeleted: 'Сообщение удалено',
      MessageIsEdited: 'Сообщение отредактировано',
      Recover: 'Восстановить',
      AddToGroup: 'Добавить в группу',
      AddDescription: 'Add description',
      userSuccessfullyAdded: 'Пользователь успешно добавлен в группу',
      userSuccessfullyRemoved: 'Пользователь успешно удален из группы',
      groupSuccessfullyCreated: 'Группа успешно создана',
      groupSuccessfullyRemoved: 'Группа успешно удалена',
      CostPerMessage: 'Стоимость одного сообщения {{messageFee}}',
      SetCostPerMessage:
        'Пользователь изменил цену на сообщения. Стоимость одного сообщения {{messageFee}}',
      YouSetCostPerMessage:
        'Вы изменили цену на сообщения. Стоимость одного сообщения {{messageFee}}',
      fetchPaidMessageSuccessfully: 'Платное сообщение успешно отправлено',
      SetPaidMessages: 'Установить цену на сообщения',
    },
    Information: {
      Information: 'Информация',
      EmailAddress: 'Адрес электронной почты:',
      PhoneNumber: 'Номер телефона:',
      TermsOfUse: 'Правила использования',
      PrivacyPolicy: 'Конфиденциальность',
      ReturnRefund: 'Политика возврата',
      PaymentMethod: 'Способ оплаты',
      US18: 'Соблюдение кода §2247 18 U.S.',
      ContentManagement: 'Политика управления контентом',
      ContentPolicy: 'Политика и процедура подачи жалоб',
      Documents: 'Документы',
      OtherDocs: 'Другие документы',
      DMCAPilicy: 'Политика удаления DMCA',
    },
    fanterInformation: {
      SubsterInc: 'Сабстер Инк.',
      ContactUs: 'Контакты',
      SpecialRulesForCreators: 'Специальные правила для создателей',
      AgreeRules: 'Отправляя заявку, вы соглашаетесь с правилами',
      CaliforniaCorporation: 'Калифорнийская корпорация',
      Address:
        'ADS BOOST LTD. Адрес: Prodromou, 75, Oneworld Parkview House, 4th floor, 2063, Nicosia',
    },
    fanterxInformation: {
      SubsterInc: 'ARAY HUNGARY Kft.',
      CaliforniaCorporation: '',
      Address: 'Адрес: Венгрия, 2161 , Kossuth Lajos út 79.',
    },
    fanterUKInformation: {
      SubsterInc: 'Subster Uk Ltd',
      CaliforniaCorporation: '',
      Address:
        'Адрес: 41 Devonshire Street, Ground Floor, London, England, W1G 7AJ',
    },
    buyPostModal: {
      linkCardTitle: 'Мы не смогли провести оплату',
      linkCardMessage:
        'Добавьте карту, чтобы совершать покупки и отправлять чаевые',
      forTrialSubscription: 'Пожалуйста, добавьте карту для оформления триала',
    },
    SendTips: {
      SendTips: 'Отправить чаевые',
      QuickAmount: 'Быстрый выбор',
      EnterAmount: 'Введите сумму, USD',
      Comment: 'Комментарий',
      Success: 'Успешно!',
      HasBeenSentInTips: '${{AMOUNT}} чаевые отправлены!',
      OK: 'OK!',
      InsufficientFundsInYourAccount: 'Недостаточно средств на вашем счете',
      YourBalance: 'Ваш баланс: {{BALANCE}}',
      TopUpYourBalance: 'Пополнить баланс',
      errorAmountRequired: 'Требуется сумма',
      errorAmountMin: 'Введите сумму не менее 5$',
      errorAmountMax: 'Максимальная сумма - $500',
      InvalidCardNumber: 'Неверный номер карты',
      EnterCVVCode: 'Введите CVV код',
      InvalidCVVCode: 'Неверный CVV код',
      EnterCardHolderName: 'Введите имя владельца карты',
      CardNumber: 'Номер карты',
      CardholderName: 'Имя владельца карты',
      cardDateRequired: 'Введите срок действия',
      cardDateInvalid: 'Неверный срок действия',
      CardExpired: 'Срок действия карты истек',
      Required: 'Обязательное поле',
      IncorrectData: 'Некорректные данные',
      ErrorClientReferenceId: 'Некорректная ссылка',
    },
    Notifications: {
      Notifications: 'Уведомления',
      NoNotifications: 'Здесь будут показаны ваши уведомления',
      NothingMore: 'На этом все',
      ShowMore: 'Показать больше',
      t_0: '<UserLink>{{USER_NAME}}</UserLink> понравился ваш пост <PostLink>{{POST_SUBJECT}}</PostLink>',
      t_1: '<UserLink>{{USER_NAME}}</UserLink> подписался на вас',
      t_2: '<UserLink>{{USER_NAME}}</UserLink> оформил платную подписку ${{PAY_SUM}}',
      t_3: '<UserLink>{{USER_NAME}}</UserLink> отправил чаевые ${{PAY_SUM}} к вашему посту <PostLink>{{POST_SUBJECT}}</PostLink>',
      t_4: '<UserLink>{{USER_NAME}}</UserLink> купил пост <PostLink>{{POST_SUBJECT}}</PostLink>',
      t_5: '<UserLink>{{USER_NAME}}</UserLink> оставил комментарий к вашему посту <PostLink>{{POST_SUBJECT}}</PostLink>',
      t_6: 'включен бизнес-аккаунт',
      t_7: 'выключен бизнес-аккаунт',
      t_8: 'запрос на бизнес-аккаунт отклонен',
    },
    Subscriptions: {
      title: 'Активные подписки',
      Active: 'Активна',
      Expired: 'Истекла',
      Pay: 'Оплатить',
      Cancel: 'Отменить',
      PriceForMonth: 'Цена за месяц ${{PRICE}}',
      AutoRenewal: 'Автопродление',
      until: 'до {{DATE}}',
      newPriceTitlt: 'Новая цена',
      newPriceDiscription: 'Цена подписки составляет ${{PRICE}}',
      paySuccess: '{{USER_NAME}} VIP Подписка оплачена',
      trial_0: 'Бесплатно {{count}} день  - затем {{then}}$/мес',
      trial_1: 'Бесплатно {{count}} дня - затем {{then}}$/мес',
      trial_2: 'Бесплатно {{count}} дней - затем {{then}}$/мес',
    },
    errors: {
      anyErr: 'Произошла ошибка. {{logCode}}',
      40: 'Указанный адрес уже зарегистрирован',
      Something: 'Сервер недоступен, попробуйте позже. {{logCode}}',
      Try_again: 'Попробуйте еще раз',
      Repeat_your_request: 'Повторите запрос',
      3000: 'Выбранный язык сейчас не доступен',
      3004: 'Введите не менее 5 символов',
      Required: 'Заполните поле',
      3011: 'Неверный адрес',
      wrong_password: 'Неверный пароль',
      Chars: 'Введите пароль длиннее 5 символов',
      match: 'Пароли не совпадают',
      3001: 'Недействительный код',
      emailNot: 'Пользователь с таким адресом не зарегистрирован',
      Profile_not_found: 'Профиль не найден',
      10011: 'Недействительный код',
      10014: 'Неверный текущий пароль',
      300: 'Пользователь с таким адресом не зарегистрирован',
      3006: 'Введите не менее 2 символов ',
      3024: 'Введите не менее 2 символов ',
      3016: 'Письмо отправлено на вашу почту. Если потребуется, потворите попытку позже',
      3010: 'Недопустимые символы',
      3019: 'Неправильный адрес электронной почты',
      3021: 'Неправильный тип файла для изображения',
      a3021: 'Максимальный размер загрузки файлов 2Гб',
      3029: 'Слишком длинный текст публикации: сократите до 2600 символов',
      3030: 'Слишком длинный текст заголовка: сократите до 300 символов',
      3061: 'Создание платного поста доступно только для бизнес-аккаунта.',
      3066: 'Неверный CVV код',
      10019:
        'Изображение {{NAME}} слишком маленькое. Минимальный допустимый размер 600x600 пикселей',
      10020:
        'Изображение слишком маленькое. Минимальный допустимый размер 600x600 пикселей',
      10021: 'Недопустимые пропорции изображения {{NAME}}',
      10022: 'Недопустимые пропорции изображения',
      10023: 'Недопустимые пропорции изображения',
      10025: 'Неправильный тип файла для изображения',
      10017: 'Неверный символ',
      10066:
        'Банк выпустивший карту, отклонил платеж, пожалуйста, свяжитесь со своим банком  или попробуйте другую карту',
      10091:
        'Сетевая ошибка #10091: "Сетевая подсистема непригодна для использования нужного поставщика услуг"',
      10106:
        'Cетевая ошибка #10106: "Не удается загрузить или инициализировать"',
      10110: 'A duplicate customer payment profile already exists.',
      10111: 'Введены некорректные данные',
      10128: 'Указанный псевдоним уже зарегистрирован',
      10129: 'Указанный адрес уже зарегистрирован',
      10140:
        'Выбранная вами дата уже занята. Пожалуйста, выберите другую дату.',
      10180: 'Общаться могут только VIP-подписчики',
      10204:
        'Пожалуйста, пополните внутренний баланс для того, чтобы отправить сообщение.',
      10213: 'Введенная сумма не верная. Пожалуйста, повторите попытку.',
      10215: 'Время для ввода истекло.',
      10216: 'Количество попыток ввода исчерпано.',
      10220: 'Истек срок действия кода подтверждения.',
      10221: 'Исчерпан лимит по количеству ввода кода для подтверждения.',
      10223: 'Код введен неверно. Пожалуйста, повторите попытку.',
      10225:
        'Мы попытались списать с Вашей карты небольшую сумму, но получили отказ от Вашего банка. Пожалуйста, проверьте, что на счету достаточно средств. Если на счету достаточно средств, пожалуйста, свяжитесь со своим банком для уточнения причины отклонения транзакции.',
      10228:
        'Мы попытались списать с Вашей карты небольшую сумму, но получили отказ от Вашего банка. Пожалуйста, проверьте, что на счету достаточно средств. Если на счету достаточно средств, пожалуйста, свяжитесь со своим банком для уточнения причины отклонения транзакции.',
      10229:
        'Мы попытались списать с Вашей карты небольшую сумму, но получили отказ от Вашего банка. Пожалуйста, проверьте, что на счету достаточно средств. Если на счету достаточно средств, пожалуйста, свяжитесь со своим банком для уточнения причины отклонения транзакции.',
      10233:
        'Мы попробовали списать небольшую сумму с Вашей карты, но получили отказ от Вашего банка. Пожалуйста, свяжитесь со своим банком для уточнения причины отклонения транзакции.',
      10230:
        'К сожалению, на данный момент платежная система не отвечает. Обычно это занимает некоторое время. Пожалуйста, попробуйте вернуться к оплате через 10-30 минут.',
      10235:
        'К сожалению, на данный момент платежная система не отвечает. Обычно это занимает некоторое время. Пожалуйста, попробуйте вернуться к оплате через 10-30 минут.',
      10224:
        'К сожалению, привязать новую карту не получится. Попробуйте в своем профиле удалить старые карты или напишите нам в чат поддержки.',
      10231:
        'К сожалению, привязать новую карту не получится. Попробуйте в своем профиле удалить старые карты или напишите нам в чат поддержки.',
      10232:
        'К сожалению, привязать новую карту не получится. Попробуйте в своем профиле удалить старые карты или напишите нам в чат поддержки.',
      10234:
        'При попытке привязки карта возникла ошибка. Пожалуйста, напишите нам в чат поддержки и мы Вам поможем.',
      10227:
        'Срок действия карты истек. Пожалуйста, попробуйте привязать другую карту или свяжитесь с вашим банком для уточнения деталей.',
      10236:
        'К сожалению, данный тип карты не поддерживается. Пожалуйста, попробуйте привязать другую карту.',
      10226:
        'Пожалуйста, проверьте номер карты. Возможно, вы допустили ошибку.',
      unavailebleCharacters:
        'Введите никнейм, состоящий из букв, цифр или одного из символов: - , _ . При вводе никнейма разрешены только латинские буквы (A-z). Запрещены пробелы в середине логина.',
      10251: 'Платеж еще не оплачен',
    },
    PaymentErrorModal: {
      tryAgain: 'Попробовать снова',
      cancel: 'Отмена',
      ok: 'Ok',
      10111: {
        0: 'Введены',
        1: 'некорректные данные #{{logCode}}',
        d: 'Проверьте правильность ваших данных',
      },
      10211: {
        0: 'Для того, чтобы успешно привязать карту, необходимо подтвердить почту.',
        1: '',
        d: '',
      },
      oops: {
        0: 'Недостаточно средств или операция была отклонена банком',
        1: '#{{logCode}}',
        d: 'Обратитесь в свой банк / или воспользуйтесь другой картой',
      },
    },
    SelectCharacterModal: {
      header: 'Выберите роль',
      text0: 'Общайтесь с {{name}} в любом образе!',
      text1: 'Подпишитесь, чтобы разблокировать новые характеристики Персонажа',
      button: 'Подпишитесь сейчас за {{amount}} $ в месяц',
      addMode: {
        header: 'Подписка на 1 месяц - {{amount}} $',
        subHeader: 'Вы можете отменить подписку в любое время в настройках',
        text: 'Пожалуйста, добавьте банковскую карту',
        button: 'Добавьте карту и заплатите {{amount}} $',
        trialButton_0:
          'Добавьте карту и получите доступ бесплатно - {{count}} день',
        trialButton_1:
          'Добавьте карту и получите доступ бесплатно - {{count}} дня',
        trialButton_2:
          'Добавьте карту и получите доступ бесплатно - {{count}} дней',
      },
    },
    ModeratorFilters: {
      label: 'Showing',
      all: 'Все посты',
      myUnModerated: 'Мои необработанные посты',
      moderated: 'Обработанные ',
      unModerated: 'Необработанные посты',
    },
    Rate: {
      left_0: 'Остался {{count}} день',
      left_1: 'Осталось {{count}} дня',
      left_2: 'Осталось {{count}} дней',
      trialDurationDays_0: 'Бесплатно {{count}} день',
      trialDurationDays_1: 'Бесплатно {{count}} дня',
      trialDurationDays_2: 'Бесплатно {{count}} дней',
      then: 'затем {{amount}}$/мес',
    },
    UploadMediaModal: {
      header: 'Загрузите файл',
      Drag1: 'Перетащите медиафайл сюда или',
      Drag2: 'выберите',
      // FormatsMessage: "Для загрузки изображения принимаются только файлы jpg, png. Для загрузки видео принимаются только файлы mp4, mov",
      FormatsMessage:
        'Для загрузки изображения принимаются только файлы jpg, png.',
      Attach: 'Загрузить',
      Cancel: 'Отменить',
    },
    Promote: {
      title: 'Подключение агенства',
      label: 'Cотрудничество с агентством дает вам шанс увеличить вашу прибыль',
      selectAgency: 'Выбрать агентство',
      SuccessfullyConnectAA: 'Подключение к агентству прошло успешно',
      SuccessfullyDisconnectAA: 'Отключение от агентства прошло успешно',
      placeholder: 'Введите наименование или выберите из списка',
    },
    Complaint: {
      Complaint: 'Жалоба',
      Report: 'Пожаловаться',
      PleaseSelectReason: 'Пожалуйста, выберите причину жалобы',
      Send: 'Отправить',
      ReportSent: 'Ваша жалоба отправлена',
      ThankYouForYourMessage: 'Спасибо за ваше обращение',
      NotOriginalContent: 'Не оригинальный контент (нарушение моих прав)',
      Extremism: 'Экстремизм',
      SpamOrMisleading: 'Спам или введение в заблуждение',
      Fraud: 'Мошенничество',
      ChildPornography: 'Детская порнография',
      ViolentOrUnpleasantScenes: 'Жестокие или отталкивающие сцены',
      TerrorismPropaganda: 'Пропаганда терроризма',
    },
  },
};

import { TPgetMessages, TRgetMessag } from '../../domains/chat/api';
import { TMessageType } from '../../domains/timeLine/api';
import { TComment } from '../../types/TComment';
import { TContent } from '../../types/TContent';
import { TDiscount } from '../../types/TDiscount';
import { makeRequest } from './FanterAPICore';

// Payments

type TGetTransactionParams = {
  transactionCode: string;
};
type TGetTransactionResponse = {
  //  - хеш транзакции из методов оплаты
  transactionCode: string;
  // - сумма платежа
  price: string;
  // - тип платежа
  payType: string;
  /*
   * - текущий статус. Варианты значений:
   * 0 - транзакция только что создана
   * 10 - транзакция подтверждена.Оплата прошла.
   * 20 - транзакция отменена.Оплата не прошла.
   */
  nowState: string;
};
export const getTransaction = async (
  transactionCode: string,
): Promise<TGetTransactionResponse> =>
  makeRequest<TGetTransactionParams, TGetTransactionResponse>(
    'payments.getTransaction',
    { transactionCode },
  );

export type SendFrontTipParams = {
  email: string;
  cardNumber: string;
  cardHolder: string;
  cardCvv: string;
  cardMonth: number;
  cardYear: number;
  nicknameUser: string;
  tippSumm: number;
  cardType?: 'visa';
  duplicateExternal?: string;
  externalClientReferenceId?: string;
};
export const paymentsSendFrontTip = async (
  params: SendFrontTipParams,
): Promise<boolean> =>
  makeRequest<SendFrontTipParams, boolean>(
    'payments.sendFrontTip',
    {
      cardType: 'visa',
      ...params,
    },
    true,
    false,
  );

type TСreateWithdrawParams = {
  /**  число. Сколько денег хочет пользователь вывести из системы. */
  amount: number;
};
type TСreateWithdrawResponse = {
  /** порядковый номер платежа (вместе с другими платежами, а не отдельный) */
  withdrawId: string;
};
export const createWithdraw = async (
  amount: number,
): Promise<TСreateWithdrawResponse> =>
  makeRequest<TСreateWithdrawParams, TСreateWithdrawResponse>(
    'payments.createWithdraw',
    { amount },
  );

// Comment

type TGetCommentsParams = {
  postId: string;
  options: {
    offset?: number;
    limit?: number;
    order?: 'asc' | 'desc';
    direction?: 'forward' | 'backward';
  };
};
type TGetCommentsResponse = {
  items: TComment[];
  count: number;
};
export const getComments = async (
  postId: string,
): Promise<TGetCommentsResponse> =>
  makeRequest<TGetCommentsParams, TGetCommentsResponse>('comment.get', {
    postId,
    options: { limit: 3, order: 'desc' },
  });

type TDeleteCommentParams = {
  commentId: string;
};
export const deleteComment = async (commentId: string): Promise<boolean> =>
  makeRequest<TDeleteCommentParams, boolean>('comment.delete', {
    commentId,
  });

type TGetCommentParams = {
  commentId: string;
};
export const getComment = async (commentId: string): Promise<TComment> =>
  makeRequest<TGetCommentParams, TComment>(
    'comment.getById',
    { commentId },
    false,
  );

type TCreateCommentParams = {
  postId: string;
  text: string;
};
type TCreateCommentResponse = {
  commentId: number;
};
export const createComment = async (
  postId: string,
  text: string,
): Promise<TCreateCommentResponse> =>
  makeRequest<TCreateCommentParams, TCreateCommentResponse>('comment.create', {
    postId,
    text,
  });

// Chat
export type TCreateChatParams = {
  /** хеш пользователя, с которым мы хотим создать чат. Если чат с тех.поддержкой вместо хеша использовать - “support” */
  hashUser: string;
};
export type TCreateChatResponse = {
  /** id нового чата */
  chatId: string;
};
export const createChat = async (
  hashUser: string,
): Promise<TCreateChatResponse> =>
  makeRequest<TCreateChatParams, TCreateChatResponse>('chat.createChat', {
    hashUser,
  });

type TGetMedeaParams = {
  messageId: string;
};
export const getMedea = async (messageId: string): Promise<TContent> =>
  makeRequest<TGetMedeaParams, TContent>('chat.getMedia', { messageId });

export const getMessages = async (
  chatId: string,
  limit: number | undefined,
  offset: string | undefined,
  order?: 'asc' | 'desc' | undefined,
  direction?: 'forward' | 'backward',
): Promise<TRgetMessag[]> =>
  makeRequest<TPgetMessages, TRgetMessag[]>(
    'chat.getMessages',
    {
      chatId,
      limit,
      offset,
      order,
      direction,
    },
    true,
  );

export type TSendMessageParams = {
  chatId: string;
  message?: string;
  postId?: string;
  filePath?: string;
  serviceCode?: number;
  price?: number;
};
export type TSendMessageResponse = {
  messageId: string;
};
export const sendMessage = async (
  chatId: string,
  message: string | undefined,
  postId: string | undefined,
  filePath: string | undefined,
  serviceCode: number | undefined,
  price: number | undefined,
): Promise<TSendMessageResponse> =>
  makeRequest<TSendMessageParams, TSendMessageResponse>('chat.sendMessage', {
    chatId,
    message,
    postId,
    filePath,
    serviceCode,
    price,
  });

export type TChooseRoleinChatParams = {
  chatId: string;
  chatRoleId: string;
};
export type TChooseRoleinChatResponse = {
  serviceMessage: {
    error: boolean;
    data: string;
  };
};
export const chooseRoleinChat = async (
  chatId: string,
  chatRoleId: string,
): Promise<TChooseRoleinChatResponse> =>
  makeRequest<TChooseRoleinChatParams, TChooseRoleinChatResponse>(
    'chat.chooseRoleinChat',
    {
      chatId,
      chatRoleId,
    },
  );

// Content

type TGetPostParams = {
  count: number;
  fromMessage?: number;
  fromUser?: {
    hashUser?: string;
    email?: string;
    nickname?: string;
  };
  filters?: {
    isPrivate?: boolean;
    messageTypes?: TMessageType[];
  };
  isDev?: string;
};
type TGetPostResponse = {
  items: TContent[];
  count: number;
};
export const getPost = async (
  fromMessage: number,
  isPrivate: boolean,
  hashUser?: string,
  count = 1,
): Promise<TGetPostResponse> => {
  const params: TGetPostParams = {
    count,
    fromMessage,
    fromUser: { hashUser },
    filters: {
      messageTypes: isPrivate ? ['private'] : undefined,
    },
    isDev:
      window.location.hostname !== 'fanter.com' &&
      window.location.hostname !== 'fanterx.com'
        ? '1'
        : undefined,
  };

  return makeRequest<TGetPostParams, TGetPostResponse>('content.get', params);
};

export const getMyFreePublicPosts = async (): Promise<TGetPostResponse> => {
  const params: TGetPostParams = {
    count: 1,
    filters: {
      messageTypes: ['public', 'free'],
    },
    isDev:
      window.location.hostname !== 'fanter.com' &&
      window.location.hostname !== 'fanterx.com'
        ? '1'
        : undefined,
  };

  return makeRequest<TGetPostParams, TGetPostResponse>('content.get', params);
};

type TContentBuyParams = {
  contentId: number;
  fromMessage?: number;
};
type TContentBuyResponse = {
  purchased: string;
};
export const contentBuy = async (
  contentId: number,
  fromMessage?: number,
): Promise<TContentBuyResponse> =>
  makeRequest<TContentBuyParams, TContentBuyResponse>(
    'content.buy',
    { contentId, fromMessage },
    false,
  );

type TSendTipsParams = {
  tippSumm: number;
  contentId: number;
  text?: string;
};
type TSendTipsResponse = {
  tipped: string;
};
export const sendTipsApi = async (
  tippSumm: number,
  contentId: number,
  text?: string,
): Promise<TSendTipsResponse> =>
  makeRequest<TSendTipsParams, TSendTipsResponse>(
    'content.sendTipp',
    { tippSumm, contentId, text },
    false,
  );

// eslint-disable-next-line @typescript-eslint/ban-types
type TUploadPhotoVideoParams = {};
type TUploadPhotoVideoResponse = {
  token: string;
};
export const uploadPhoto = async (): Promise<TUploadPhotoVideoResponse> =>
  makeRequest<TUploadPhotoVideoParams, TUploadPhotoVideoResponse>(
    'content.uploadPhoto',
    {},
  );
export const uploadVideo = async (): Promise<TUploadPhotoVideoResponse> =>
  makeRequest<TUploadPhotoVideoParams, TUploadPhotoVideoResponse>(
    'content.uploadVideo',
    {},
  );

// Notifications

type TSetNotificationAsReadedParams = {
  id: number;
};
type TSetNotificationAsReadedResponse = {
  updated: boolean;
};
export const setNotificationAsReaded = async (
  id: number,
): Promise<TSetNotificationAsReadedResponse> =>
  makeRequest<TSetNotificationAsReadedParams, TSetNotificationAsReadedResponse>(
    'notify.setReaded',
    { id },
    false,
  );

// Discounts

/**
 * Создание новой акции по изменению цены. Авторизация обязательна
 */
type TCreateDiscountResponse = {
  discountId: string;
};
export const createDiscount = async (
  payload: TDiscount,
): Promise<TCreateDiscountResponse> =>
  makeRequest<TDiscount, TCreateDiscountResponse>(
    'discount.createDiscount',
    payload,
  );

/**
 * Удаление существующей подписки. Авторизация обязательна. Скидка должна принадлежать авторизованному пользователю.
 */
type TDeleteDiscountResponse = {
  result: boolean;
};
type TDeleteDiscountPayload = {
  discountId: string;
};
export const deleteDiscount = async (
  discountId: string,
): Promise<TDeleteDiscountResponse> =>
  makeRequest<TDeleteDiscountPayload, TDeleteDiscountResponse>(
    'discount.deleteDiscount',
    { discountId },
  );

/**
 * Получение списка акций для пользователя. Авторизация обязательна.
 * Для сокращения списка можно получить только активные в данный момент (без удаленных и прошедших акций).
 */

type TGetListPayload = {
  /** Флаг запроса только активных акций. По умолчанию false */
  onlyActive?: boolean;
  /** Смещение сколько записей будет пропущено при выводе. */
  offset?: number;
  /** По какому типу платежа будет отфильтрован список. Идентичен выводу. */
  payType?: number;
  /** Будут выданы записи начиная с полуночи указанного дня.Например: 11.06.2021. */
  startDate?: string;
  /** Будут выданы записи до 23: 59: 59 указанного дня.Например: 11.06.2021. */
  endDate?: string;
  /** фильтровка по статусу платежа.Идентичен выводу. */
  paymentState?: number;
};
export const getList = async (payload: TGetListPayload): Promise<TDiscount[]> =>
  makeRequest<TGetListPayload, TDiscount[]>('discount.getList', payload);

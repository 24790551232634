import { createAction } from '@reduxjs/toolkit';
import { TPLogin, TPregister, TRRecoverCommit } from './api';

export const fetchRegister = createAction<
  TPregister,
  'registration/fetchRegister'
>('registration/fetchRegister');

export const fetchLogin = createAction<TPLogin, 'registration/fetchLogin'>(
  'registration/fetchLogin',
);
export type PFetchLoginTelegramm = {
  toProfile?: string;
  profileAction?: string;
};
export const fetchLoginTelegramm = createAction<
  PFetchLoginTelegramm,
  'registration/fetchLoginTelegramm'
>('registration/fetchLoginTelegramm');

export const fetchEmailCommit = createAction<
  { commitToken: string },
  'registration/fetchEmailCommit'
>('registration/fetchEmailCommit');

export const fetchPasswordRecover = createAction<
  { email: string },
  'registration/fetchPasswordRecover'
>('registration/fetchPasswordRecover');

export const fetchRecoverCommit = createAction<
  TRRecoverCommit,
  'registration/fetchRecoverCommit'
>('registration/fetchRecoverCommit');

export const fetchIsTokenValidPass = createAction<
  string,
  'registration/fetchIsTokenValidPass'
>('registration/fetchIsTokenValidPass');

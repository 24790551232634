import errorServeApi, { ErrorProps } from '../api/errorServeApi';
import { ApiUrlSingleton } from '../api/ApiUrlSingleton';
import {
  clearToken,
  getScreenSize,
  getTokenFromLS,
  setToken,
} from '../sagas/helper';
import { Locker } from '../Locker';
import { TToken } from '../../types/TToken';
import { removeEmpty } from '../utils';

export const refreshToken = async (refresh_token: string): Promise<TToken> => {
  const body = {
    jsonrpc: '2.0',
    method: 'user.refreshToken',
    params: {
      refresh_token,
      screenSize: getScreenSize(),
    },
    id: 1,
  };

  const res = await fetch(
    `${ApiUrlSingleton.getInstance().getURL()}?user.refreshToken`,
    {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        LogReferer: window.location.href,
      },
    },
  );

  const resData = await res.json();
  if (resData.error) {
    errorServeApi(resData.error, false);
    throw resData.error;
  }

  return resData.result as TToken;
};

async function checkTokens() {
  await Locker.waitUnlock();
  Locker.lock();

  try {
    const token: TToken = getTokenFromLS();

    if (
      !token.access_token ||
      !token.refresh_token ||
      !token.expire_access ||
      !token.expire_refresh
    ) {
      Locker.unlock();
      return;
    }

    if (token.expire_refresh - 5 * 60 * 1000 <= +new Date()) {
      throw new Error('Refresh token expired');
    }

    if (token.expire_access - 5 * 60 * 1000 <= +new Date()) {
      const rfToken = await refreshToken(token.refresh_token);
      setToken(rfToken);
      Locker.unlock();
      return;
    }

    Locker.unlock();
    return;
  } catch (e) {
    console.error(e);
    clearToken();
    Locker.unlock();
    // window.location.reload();
  }
}

/**
 * @throws {ErrorProps}
 */
export const makeRequest = async <P, R>(
  method: string,
  params: P,
  showError = true,
  withAuth = true,
): Promise<R> => {
  const body = {
    jsonrpc: '2.0',
    method,
    params: removeEmpty(params),
    id: 1,
  };

  if (withAuth) {
    await checkTokens();
  }

  const access_token: string | null =
    window.localStorage.getItem('access_token');

  try {
    const res = await fetch(
      `${ApiUrlSingleton.getInstance().getURL()}?${method}`,
      {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          Authorization: `Bearer ${access_token}`,
          LogReferer: window.location.href,
        },
      },
    );

    const resData = await res.json();
    if (resData.error) {
      errorServeApi(resData.error, showError);
      throw resData.error;
    }

    return resData.result as R;
  } catch (error) {
    errorServeApi(error as ErrorProps, true);
    throw error;
  }
};

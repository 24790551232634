import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import { ConnectedRouter } from 'connected-react-router/immutable';
import { I18nextProvider } from 'react-i18next';

import store from './core/store';
import { history } from './core/rootReducer';

import './pwaInstallHandler';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import i18n from './i18n';
import { App } from './App';

import './index.css';

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = ReactDOM.createRoot(container!);

root.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <I18nextProvider i18n={i18n}>
        <App />
      </I18nextProvider>
    </ConnectedRouter>
  </Provider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onSuccess: (reg) => {
    console.log('onSuccess', reg);
  },
  onUpdate: (reg) => {
    const registrationWaiting = reg.waiting;
    if (registrationWaiting) {
      registrationWaiting.addEventListener('statechange', (event) => {
        const target = event?.target;
        if (target instanceof ServiceWorker && target.state === 'activated') {
          window.location.reload();
        }
      });
      registrationWaiting.postMessage({ type: 'SKIP_WAITING' });
    }
  },
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

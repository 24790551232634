import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FetchedData, genFetchedData } from '../../core/api/fetchedData';
import { TToken } from '../../types/TToken';
import type { TRisTokenValid, TRregister } from './api';

export interface RegistrationState {
  hashUser: string | null;
  emailCommit: FetchedData<boolean>;
  passwordRecover: FetchedData<boolean>;
  recoverCommit: FetchedData<boolean>;
  login: FetchedData<TToken>;
  register: FetchedData<TRregister>;
  isTokenValid: FetchedData<TRisTokenValid>;
}
export const initialState: RegistrationState = {
  hashUser: null,
  emailCommit: genFetchedData<boolean>(null),
  passwordRecover: genFetchedData<boolean>(null),
  recoverCommit: genFetchedData<boolean>(null),
  login: genFetchedData<TToken>(null),
  register: genFetchedData<TRregister>(null),
  isTokenValid: genFetchedData<TRisTokenValid>(null),
};
const registrationSlice = createSlice({
  name: 'registration',
  initialState,
  reducers: {
    setHashUser(state, action: PayloadAction<RegistrationState['hashUser']>) {
      state.hashUser = action.payload;
    },
    delList(state) {
      state.hashUser = initialState.hashUser;
    },
    setEmailCommit(
      state,
      action: PayloadAction<RegistrationState['emailCommit']>
    ) {
      state.emailCommit = action.payload;
    },
    delEmailCommit(state) {
      state.emailCommit = initialState.emailCommit;
    },
    setPasswordRecover(
      state,
      action: PayloadAction<RegistrationState['passwordRecover']>
    ) {
      state.passwordRecover = action.payload;
    },
    delPasswordRecover(state) {
      state.passwordRecover = initialState.passwordRecover;
    },

    setRecoverCommit(
      state,
      action: PayloadAction<RegistrationState['recoverCommit']>
    ) {
      state.recoverCommit = action.payload;
    },
    delRecoverCommit(state) {
      state.recoverCommit = initialState.recoverCommit;
    },

    setLogin(state, action: PayloadAction<RegistrationState['login']>) {
      state.login = action.payload;
    },
    delLogin(state) {
      state.login = initialState.login;
    },
    setRegister(state, action: PayloadAction<RegistrationState['register']>) {
      state.register = action.payload;
    },
    delRegister(state) {
      state.register = initialState.register;
    },
    setIsTokenValid(
      state,
      action: PayloadAction<RegistrationState['isTokenValid']>
    ) {
      state.isTokenValid = action.payload;
    },
    delIsTokenValid(state) {
      state.isTokenValid = initialState.isTokenValid;
    },
  },
});

export const {
  setHashUser,
  delList,
  setEmailCommit,
  delEmailCommit,
  delPasswordRecover,
  setPasswordRecover,
  setRecoverCommit,
  delRecoverCommit,
  setLogin,
  delLogin,
  setRegister,
  delRegister,
  setIsTokenValid,
  delIsTokenValid,
} = registrationSlice.actions;
export const registrationReducer = registrationSlice.reducer;

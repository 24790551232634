import React from 'react';

interface IProps {
  className?: string;
  onClick?: () => void;
}

function GroupIcon(props: IProps): JSX.Element {
  return <div {...props}>
    <svg
      width="21"
      height="18"
      viewBox="0 0 21 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.7778 12.8514C18.2376 13.431 20 14.9949 20 16.8333M1 16.8333C1 14.5015 3.83553 12.6111 7.33333 12.6111C10.8311 12.6111 13.6667 14.5015 13.6667 16.8333M13.6667 9.44444C15.9985 9.44444 17.8889 7.55409 17.8889 5.22222C17.8889 2.89035 15.9985 1 13.6667 1M11.5556 5.22222C11.5556 7.55409 9.6652 9.44444 7.33333 9.44444C5.00146 9.44444 3.11111 7.55409 3.11111 5.22222C3.11111 2.89035 5.00146 1 7.33333 1C9.6652 1 11.5556 2.89035 11.5556 5.22222Z"
        stroke="#413E93"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
}

export default GroupIcon;

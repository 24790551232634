import { SagaIterator } from 'redux-saga';
import {
  put,
  call,
  SagaReturnType,
  select,
  cancelled,
} from 'redux-saga/effects';
import i18n from '../../../i18n';
import { showAlert } from '../../../utils/utils';
import {
  fetchContentSetLike,
  fetchContentUnsetLike,
  fetchCreatePost,
  fetchSetBlocked,
  fetchSetDisplayInMainContent,
  fetchUpdatePost,
  fetchСontent,
  fetchСontentBuy,
  PUpdatePost,
  TRfetchСontent,
} from '../actions';
import {
  content,
  contentBuy,
  contentSetBlocked,
  contentSetLike,
  contentUnsetLike,
  createPost,
  setDisplayInMainContent,
  TRContent,
  TRContentBuy,
} from '../api';
import { FetchedData, genFetchedData } from '../../../core/api/fetchedData';
import {
  setContent,
  TimelineState,
  setCreatePost,
  delContent,
  setСontentBuy,
} from '../reduser';
import { RootState } from '../../../core/rootReducer';
import { sendFile } from '../../../core/api/sendFile';
import { getToken } from '../../../core/sagas/helper';
import { ErrorProps } from '../../../core/api/errorServeApi';
import EventEmmiter from '../../../core/EventEmitter/EventEmitter';

const getContent = ({ timelineReducer }: RootState): TimelineState['content'] =>
  timelineReducer.content;

export function* workerFetchСontent({
  payload,
}: ReturnType<typeof fetchСontent>): SagaIterator<void> {
  const { reload } = payload;
  const oldContent: TimelineState['content'] = yield select(getContent);
  const oldData = oldContent.get('data');

  yield call(getToken);
  let receivedData: FetchedData<TRContent> = yield call(
    genFetchedData,
    oldData
  );

  receivedData = receivedData.set('isLoading', true);
  yield put(setContent(receivedData));

  const data = payload;
  delete data.reload;

  if (
    window.location.hostname !== 'fanter.com' &&
    window.location.hostname !== 'fanterx.com'
  ) {
    data.isDev = '1';
  }

  try {
    const result: SagaReturnType<typeof content> = yield call(content, data);
    const newData = {
      ...oldData,
      items: [...(reload ? [] : oldData?.items ?? []), ...(result.items ?? [])],
      maxId: result.maxId,
      minId: result.minId,
    };
    receivedData = receivedData.set('data', newData);

    yield put(setContent(receivedData));
  } catch (e) {
    const error = e as ErrorProps;
    console.error(error);
    receivedData = receivedData.set('error', {
      isError: true,
      message: error.message,
      code: error.code,
    });
    yield put(setContent(receivedData));
  } finally {
    const iscancelled = yield cancelled();

    if (!iscancelled) {
      receivedData = receivedData
        .set('isLoading', false)
        .set('LTU', Date.now());
      yield put(setContent(receivedData));
    }
  }
}

export function* workerCreatePost({
  payload,
}: ReturnType<typeof fetchCreatePost>): SagaIterator<void> {
  let receivedData: FetchedData<boolean> = yield call(genFetchedData, null);
  receivedData = receivedData.set('isLoading', true);
  try {
    yield put(setCreatePost(receivedData));
    yield call(getToken);
    const res: SagaReturnType<typeof createPost> = yield call(createPost);

    if (payload.subject) {
      payload.img.append('subject', payload.subject.trim());
    }

    if (payload.message) {
      payload.img.append('message', payload.message.trim());
    }

    if (payload.price > 0) {
      payload.img.append('price', payload.price.toString());
      payload.img.append(
        'showFanterVip',
        payload.showFanterVip ? 'true' : 'false'
      );
      payload.img.append(
        'showSubscribers',
        payload.showSubscribers ? 'true' : 'false'
      );
    }

    payload.img.append('private', payload.private ? 'true' : 'false');

    yield call(sendFile, res.token, payload.img);

    receivedData = receivedData.set('data', true);

    yield put(delContent());
    yield call(
      workerFetchСontent,
      fetchСontent({
        count: 10,
        reload: true,
      })
    );
  } catch (e) {
    const error = e as ErrorProps;
    console.error(error);
    receivedData = receivedData.set('error', {
      isError: true,
      message: error.message,
      code: error.code,
    });
    // yield put(setCreatePost(receivedData));
  } finally {
    receivedData = receivedData.set('isLoading', false).set('LTU', Date.now());
    yield put(setCreatePost(receivedData));
  }
}

export function* workerUpdatePost({
  payload,
}: ReturnType<typeof fetchUpdatePost>): SagaIterator<void> {
  try {
    const oldContent: TimelineState['content'] = yield select(getContent);
    const oldData = oldContent.get('data');
    yield call(getToken);
    if (oldData === null) return;
    let receivedData: FetchedData<TRContent> = yield call(
      genFetchedData,
      oldData
    );

    const ind = oldData.items.findIndex((item) => item.id === payload.id);
    if (ind === undefined) return;

    const requestData: TRfetchСontent = {
      count: 1,
      fromMessage: payload.id,
      filters: {
        private: payload.private,
        messageTypes: payload.filters?.messageTypes,
      },
      fromUser: payload.private ? undefined : payload.fromUser,
    };

    if (
      window.location.hostname !== 'fanter.com' &&
      window.location.hostname !== 'fanterx.com'
    ) {
      requestData.isDev = '1';
    }

    const res = yield call(content, requestData);

    // workaround
    const _oldContent: TimelineState['content'] = yield select(getContent);
    const _oldData = _oldContent.get('data');
    if (_oldData !== null) {
      const _ind = _oldData.items.findIndex((item) => item.id === payload.id);

      const newData = {
        ...oldData,
        items: [
          ..._oldData.items.slice(0, _ind),
          ...res.items,
          ..._oldData.items.slice(_ind + 1),
        ],
      };
      receivedData = receivedData.set('data', newData);
      yield put(setContent(receivedData));
    }
  } catch (e) {
    console.error(e);
  }
}

export function* workerContentBuy({
  payload,
}: ReturnType<typeof fetchСontentBuy>): SagaIterator<void> {
  let receivedData: FetchedData<TRContentBuy, PUpdatePost> = yield call(
    genFetchedData,
    null
  );
  receivedData = receivedData.set('isLoading', true);
  receivedData = receivedData.set('payload', payload);
  try {
    yield put(setСontentBuy(receivedData));
    yield call(getToken);
    const res: SagaReturnType<typeof contentBuy> = yield call(contentBuy, {
      contentId: payload.id,
    });
    receivedData = receivedData.set('data', res);
    yield call(workerUpdatePost, fetchUpdatePost(payload));
  } catch (e) {
    const error = e as ErrorProps;
    console.error(error);
    receivedData = receivedData.set('error', {
      isError: true,
      message: error.message,
      code: error.code,
    });
    yield put(setСontentBuy(receivedData));
  } finally {
    // receivedData = receivedData.set('isLoading', false).set('LTU', Date.now());
    yield put(setСontentBuy(receivedData));
  }
}

export function* workerContentSetLike({
  payload,
}: ReturnType<typeof fetchContentSetLike>): SagaIterator<void> {
  try {
    yield call(getToken);
    yield call(contentSetLike, { contentId: payload.id });
  } catch (e) {
    console.error(e);
  } finally {
    // yield call(workerUpdatePost, fetchUpdatePost(payload));
    EventEmmiter.emit('UpdatePost', payload.id);
  }
}

export function* workerContentUnsetLike({
  payload,
}: ReturnType<typeof fetchContentUnsetLike>): SagaIterator<void> {
  try {
    yield call(getToken);
    yield call(contentUnsetLike, { contentId: payload.id });
  } catch (e) {
    console.error(e);
  } finally {
    // yield call(workerUpdatePost, fetchUpdatePost(payload));
    EventEmmiter.emit('UpdatePost', payload.id);
  }
}

export function* workerSetBlocked({
  payload,
}: ReturnType<typeof fetchSetBlocked>): SagaIterator<void> {
  try {
    yield call(getToken);
    yield call(contentSetBlocked, payload);
    if (payload.block === 0) {
      showAlert(
        i18n.t('Post.SuccessfullyUnblocked'),
        'Post.SuccessfullyUnblocked',
        'success'
      );
    }
    if (payload.block === 1) {
      showAlert(
        i18n.t('Post.SuccessfullyBlocked'),
        'Post.SuccessfullyBlocked',
        'success'
      );
    }
    if (payload.block === 2) {
      showAlert(
        i18n.t('Post.SuccessfullyConfirmed'),
        'Post.SuccessfullyConfirmed',
        'success'
      );
    }
    if (payload.block === 3) {
      showAlert(
        i18n.t('Post.SuccessfullyDecline'),
        'Post.SuccessfullyDecline',
        'success'
      );
    }
    if (payload.remove) {
      const oldContent: TimelineState['content'] = yield select(getContent);
      const oldData = oldContent.get('data');
      const ind = oldData?.items.findIndex(
        (item) => item.id === payload.contentId
      );
      if (oldData === null || ind === undefined || ind === -1) {
        return;
      }
      oldData.items.splice(ind, 1);
      const receivedData: FetchedData<TRContent> = yield call(
        genFetchedData,
        oldData
      );
      yield put(setContent(receivedData));
    }
  } catch (e) {
    console.error(e);
  } finally {
    if (!payload.remove) {
      yield call(
        workerUpdatePost,
        fetchUpdatePost({
          id: payload.contentId,
          filters: { messageTypes: ['need-moderate'] },
        })
      );
    }
  }
}

export function* workerSetDisplayInMainContent({
  payload,
}: ReturnType<typeof fetchSetDisplayInMainContent>): SagaIterator<void> {
  try {
    yield call(getToken);
    yield call(setDisplayInMainContent, payload);
    if (payload.displayInMain === 1) {
      showAlert(
        i18n.t('Post.SuccessfullySetDisplayInMainContent'),
        'Post.SuccessfullySetDisplayInMainContent',
        'success'
      );
    } else {
      showAlert(
        i18n.t('Post.SuccessfullyUnSetDisplayInMainContent'),
        'Post.SuccessfullyUnSetDisplayInMainContent',
        'success'
      );
    }

    if (payload.remove) {
      const oldContent: TimelineState['content'] = yield select(getContent);
      const oldData = oldContent.get('data');
      const ind = oldData?.items.findIndex(
        (item) => item.id === payload.contentId
      );
      if (oldData === null || ind === undefined || ind === -1) {
        return;
      }
      oldData.items.splice(ind, 1);
      const receivedData: FetchedData<TRContent> = yield call(
        genFetchedData,
        oldData
      );
      yield put(setContent(receivedData));
    }
  } catch (e) {
    console.error(e);
  } finally {
    yield call(
      workerUpdatePost,
      fetchUpdatePost({
        id: payload.contentId,
        filters: { messageTypes: ['need-moderate'] },
      })
    );
  }
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FetchedData, genFetchedData } from '../../core/api/fetchedData';
import { TNotification } from './types/TNotification';

export interface NotificationsState {
  notifications: FetchedData<TNotification[]>;
  haveUnread: FetchedData<boolean>;
}
export const initialState: NotificationsState = {
  notifications: genFetchedData<TNotification[]>(null),
  haveUnread: genFetchedData<boolean>(null),
};

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setNotifications(
      state,
      action: PayloadAction<NotificationsState['notifications']>
    ) {
      state.notifications = action.payload;
    },
    setHaveUnread(
      state,
      action: PayloadAction<NotificationsState['haveUnread']>
    ) {
      state.haveUnread = action.payload;
    },
    delNotifications(state) {
      state.notifications = initialState.notifications;
    },
  },
});

export const { setNotifications, setHaveUnread, delNotifications } =
  notificationsSlice.actions;

export const notificationsReducer = notificationsSlice.reducer;

import React from 'react';

interface IProps {
  className?: string;
  onClick?: () => void;
}

function TrashIcon(props: IProps): JSX.Element {
  return <div {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="16"
      fill="none"
      viewBox="0 0 14 16"
    >
      <path
        stroke="#413E93"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M11.8 5.8v7.8c0 .66-.54 1.2-1.2 1.2H3.4c-.66 0-1.2-.54-1.2-1.2V5.8m2.4 1.8V13m4.8-5.4V13M7 7.6V13m6-8.4H1V4c0-.66.54-1.2 1.2-1.2h9.6c.66 0 1.2.54 1.2 1.2v.6zm-3-1.8H4C4 1.78 4.78 1 5.8 1h2.4c1.02 0 1.8.78 1.8 1.8z"
      />
    </svg>
  </div>
}

export default TrashIcon;

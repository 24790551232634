import { SagaIterator } from 'redux-saga';
import {
  call,
  fork,
  put,
  SagaReturnType,
  select,
  cancelled,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  ChatState,
  setChatList,
  setChatNewMessages,
  setCreateChat,
  setGetNotified,
  setMessages,
  setSendMessage,
  setUnreadCount,
} from '../reduser';
import { makeReqWithRD, TMakeReqWithRD } from '../../../core/api/makeReqWithRD';

import {
  createChat,
  getChatList,
  getMessages,
  getNotified,
  getUnreadCount,
  sendMessage,
  setReaded,
  TRgetChatList,
  TRgetMessag,
} from '../api';
import {
  fetchCreateChat,
  fetchGetCollocutor,
  fetchGetMessages,
  fetchSendMessage,
  fetchSetReaded,
  fetchSoftUpdateChatList,
  fetchUpdateMessage,
  optimistUpdateMessage,
} from '../actions';
import { getToken } from '../../../core/sagas/helper';
import { FetchedData, genFetchedData } from '../../../core/api/fetchedData';
import { RootState } from '../../../core/rootReducer';
import { UserState } from '../../user/reduser';
import { COUNT_MESSAGE } from '../const';
import { getThatProfile } from '../../user/api';
import { ErrorProps } from '../../../core/api/errorServeApi';

const getchatListR = ({ chatReducer }: RootState): ChatState['chatList'] =>
  chatReducer.chatList;

const getThisChatR = ({ chatReducer }: RootState): ChatState['thisChat'] =>
  chatReducer.thisChat;

const getProfileR = ({ userReducer }: RootState): UserState['profile'] =>
  userReducer.profile;

const getEmptyChat = ({ chatReducer }: RootState): ChatState['emptyChat'] =>
  chatReducer.emptyChat;

export function* workerGetChatList(): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof getChatList>>(makeReqWithRD, {
      fetcher: getChatList,
      fill: setChatList,
    });
  } catch (e) {
    console.error(e);
  }
}

export function* softUpdateChatList(): SagaIterator<void> {
  let receivedData: FetchedData<TRgetChatList> = yield call(
    genFetchedData,
    null
  );
  try {
    yield call(getToken);
    const res: SagaReturnType<typeof getChatList> = yield call(getChatList);
    receivedData = receivedData.set('data', res);

    const thisChat: ChatState['thisChat'] = yield select(getThisChatR);
    if (!thisChat) return;

    const item = res.chats.find((i) => i.chatId === thisChat);
    const chatNewMessages: number = item?.chatNewMessages
      ? +item.chatNewMessages
      : 0;
    yield put(setChatNewMessages(chatNewMessages));
    if (chatNewMessages) {
      // yield put(fetchUpdateMessage({ chatId: thisChat }));
    }
  } catch (error) {
    const e = error as ErrorProps;
    console.error(e);
    receivedData = receivedData.set('error', {
      isError: true,
      message: e.message,
      code: e?.code,
    });
  } finally {
    const iscancelled = yield cancelled();
    if (!iscancelled) {
      receivedData = receivedData
        .set('isLoading', false)
        .set('LTU', Date.now());
      yield put(setChatList(receivedData));
    }
  }
}

export function* workerCreateChat({
  payload,
}: ReturnType<typeof fetchCreateChat>): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof createChat>>(makeReqWithRD, {
      fetcher: createChat,
      fill: setCreateChat,
      parameters: { hashUser: payload },
    });

    yield put(fetchSoftUpdateChatList());
  } catch (e) {
    console.error(e);
  }
}
export function* workerGetNotified(): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof getNotified>>(makeReqWithRD, {
      fetcher: getNotified,
      fill: setGetNotified,
    });
  } catch (e) {
    console.error(e);
  }
}

const getMessagesR = ({ chatReducer }: RootState): ChatState['messages'] =>
  chatReducer.messages;

export function* workerGetMessages({
  payload,
}: ReturnType<typeof fetchGetMessages>): SagaIterator<void> {
  const { reload } = payload;
  const oldMessages: ChatState['messages'] = yield select(getMessagesR);
  const oldData = oldMessages.get('data');

  let receivedData: FetchedData<TRgetMessag[]> = yield call(
    genFetchedData,
    oldData
  );
  try {
    yield call(getToken);

    const data = payload;
    delete data.reload;

    const result: SagaReturnType<typeof getMessages> = yield call(getMessages, {
      direction: 'backward',
      ...data,
    });

    receivedData = receivedData.set('data', [
      ...result.sort((a, b) => +a.id - +b.id),
      ...(reload ? [] : oldData ?? []),
    ]);
  } catch (error) {
    const e = error as ErrorProps;
    console.error(e);
    receivedData = receivedData.set('error', {
      isError: true,
      message: e.message,
      code: e?.code,
    });
    yield put(setMessages(receivedData));
  } finally {
    const iscancelled = yield cancelled();

    if (!iscancelled) {
      receivedData = receivedData
        .set('isLoading', false)
        .set('LTU', Date.now());
      yield put(setMessages(receivedData));
    }
  }
}

export function* workerOptimistUpdate({
  payload,
}: ReturnType<typeof optimistUpdateMessage>): SagaIterator<void> {
  const oldMessages: ChatState['messages'] = yield select(getMessagesR);
  const oldData = oldMessages.get('data');
  let receivedData: FetchedData<TRgetMessag[]> = yield call(
    genFetchedData,
    null
  );
  try {
    const nData: TRgetMessag[] = oldData ? [...oldData, payload] : [payload];
    receivedData = receivedData.set('data', nData);
  } catch (error) {
    const e = error as ErrorProps;
    console.error(e);
    receivedData = receivedData.set('error', {
      isError: true,
      message: e.message,
      code: e?.code,
    });
    yield put(setMessages(receivedData));
  } finally {
    receivedData = receivedData.set('isLoading', false).set('LTU', Date.now());
    yield put(setMessages(receivedData));
  }
}
export function* workerSendMessage({
  payload,
}: ReturnType<typeof fetchSendMessage>): SagaIterator<void> {
  try {
    const profile: UserState['profile'] = yield select(getProfileR);
    const emptyChat: ChatState['emptyChat'] = yield select(getEmptyChat);
    const profileData = profile.get('data');
    let { chatId } = payload;
    yield fork(
      workerOptimistUpdate,
      optimistUpdateMessage({
        id: 'none',
        creatorId: profileData?.hashUser ?? '',
        chatId: payload.chatId,
        message: payload.message,
        postId: payload.postId,
        tStamp: (+new Date() / 1000).toString(),
      })
    );

    if (emptyChat?.hashUser === payload.chatId) {
      //  проверить есть личат
      // если нет создать и предать в чат ид
      yield call(getToken);
      const nChat: SagaReturnType<typeof createChat> = yield call(createChat, {
        hashUser: emptyChat.hashUser,
      });
      chatId = nChat.chatId;
    }

    yield call<TMakeReqWithRD<typeof sendMessage>>(makeReqWithRD, {
      fetcher: sendMessage,
      fill: setSendMessage,
      parameters: { ...payload, chatId },
    });

    yield fork(
      workerGetMessages,
      fetchGetMessages({
        chatId: payload.chatId,
        reload: true,
        limit: COUNT_MESSAGE,
      })
    );

    if (emptyChat?.hashUser === payload.chatId) {
      // перебросить на ссылку на новый чат
      yield put(push(`/chat/${chatId}`));
    }
    yield put(fetchSoftUpdateChatList());
  } catch (e) {
    console.error(e);
  }
}

export function* workerUpdateMessage({
  payload,
}: ReturnType<typeof fetchUpdateMessage>): SagaIterator<void> {
  const oldMessages: ChatState['messages'] = yield select(getMessagesR);
  const oldData = oldMessages.get('data') ?? [];
  let receivedData: FetchedData<TRgetMessag[]> = yield call(
    genFetchedData,
    null
  );
  try {
    yield call(getToken);

    const lastID = oldData[oldData.length - 1].id ?? '0';
    const result: SagaReturnType<typeof getMessages> = yield call(getMessages, {
      chatId: payload.chatId,
      limit: 100,
      // order: 'asc',
    });

    const ind = result.findIndex((item) => item.id === lastID);
    if (ind < 0) return;

    const newMessage = result.slice(0, ind);

    const chatList: ChatState['chatList'] = yield select(getchatListR);

    const chatListData = chatList.get('data');

    if (!chatListData) return;

    const chat = chatListData.chats.find((i) => i.chatId === payload.chatId);
    if (!chat) return;
    if (oldData[oldData.length - 1].tStamp >= chat.lastMessageTStamp) return;

    receivedData = receivedData.set('data', [
      ...(oldData ?? []),
      ...newMessage.sort((a, b) => +a.id - +b.id),
    ]);

    receivedData = receivedData.set('isLoading', false).set('LTU', Date.now());
    yield put(setMessages(receivedData));
  } catch (e) {
    console.error(e);
  }
}

export function* workerSetReaded({
  payload,
}: ReturnType<typeof fetchSetReaded>): SagaIterator<void> {
  try {
    yield call(getToken);
    yield call(setReaded, payload);

    // yield put(fetchSoftUpdateChatList());
    // yield put(fetchGetUnreadCount());
  } catch (e) {
    console.error(e);
  }
}

export function* workerUnreadCount(): SagaIterator<void> {
  try {
    yield call(getToken);
    const result: SagaReturnType<typeof getUnreadCount> =
      yield call(getUnreadCount);

    yield put(setUnreadCount(result.unreaded));
  } catch (e) {
    console.error(e);
  }
}

export function* workerGetCollocutor({
  payload,
}: ReturnType<typeof fetchGetCollocutor>): SagaIterator<void> {
  try {
    yield call(getToken);

    yield call(getThatProfile, { nickname: payload });
  } catch (e) {
    console.error(e);
  }
}

import { useCallback, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { UserState } from './reduser';
import { RootState } from '../../core/rootReducer';
import {
  fetchGetComplaintsList,
  fetchGetProfile,
  fetchSetLanguage,
} from './actions';

export type TUseFetchPersonalData = {
  selected: string;
  onSelectLg: (v: string) => void;
  setSelected: (s: string) => void;
  onSaveLn: () => void;
};

/**  возврощает функцию по пользователю
 * @isHardFetch флаг стоит ли делать запрос если по этому ид уже есть инфа
 * */
export default function useFetchPersonalData(isHardFetch = false): () => void {
  const dispatch = useDispatch();
  const profile = useSelector<RootState, UserState['profile']>(
    ({ userReducer }) => userReducer.profile,
    shallowEqual
  );

  return useCallback(() => {
    if (isHardFetch) {
      dispatch(fetchGetProfile());
      dispatch(fetchGetComplaintsList({}));
      return;
    }
    const data = profile.get('data');
    if (data) {
      return;
    }
    dispatch(fetchGetProfile());
    dispatch(fetchGetComplaintsList({}));
  }, [profile]);
}

/** возврощает логин пользователя  */
export function useLogin(): string {
  const profile = useSelector<RootState, UserState['profile']>(
    ({ userReducer }) => userReducer.profile,
    shallowEqual
  );

  const [login, setLogin] = useState<string>(() => {
    const data = profile.get('data');
    if (!data) return '';
    if (data.firstName && data.lastName)
      return `${data.lastName} ${data.firstName}`;
    return data.email;
  });

  useEffect(() => {
    const data = profile.get('data');
    if (!data) return setLogin('');
    if (data.firstName && data.lastName)
      return setLogin(`${data.lastName} ${data.firstName}`);
    return setLogin(data.email);
  }, [profile]);

  return login;
}

const mapLang = new Map([
  ['RU', 'ru'],
  ['ES', 'es'],
  ['GB', 'en'],
]);
const mapLangE = new Map([
  ['ru', 'RU'],
  ['es', 'ES'],
  ['en', 'GB'],
]);

/** для смены языка  */
export function useSelectLg(): TUseFetchPersonalData {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const profile = useSelector<RootState, UserState['profile']>(
    ({ userReducer }) => userReducer.profile,
    shallowEqual
  );

  const [selected, setSelected] = useState<string>(() => {
    const ln = i18n.language || navigator.language.split('-')[0];

    if (!['RU', 'ES', 'GB'].includes(mapLangE.get(ln) ?? '')) return 'GB';
    return mapLangE.get(ln) ?? '';
  });

  const onSelectLg = useCallback(
    (v: string) => {
      setSelected(v);
      const lg = mapLang.get(v) ?? '';
      i18n.changeLanguage(lg);
      if (!profile.get('data')) return;
      dispatch(fetchSetLanguage(lg));
    },
    [profile]
  );
  const onSaveLn = useCallback(() => {
    const lg = mapLang.get(selected) ?? '';
    i18n.changeLanguage(lg);
    if (!profile.get('data')) return;
    dispatch(fetchSetLanguage(lg));
  }, [profile, selected]);

  useEffect(() => {
    const lg = profile.get('data')?.lang;
    if (!lg) return;
    // const language =
    // if (lg !== i18n.language)

    i18n.changeLanguage(lg);

    setSelected(mapLangE.get(lg) ?? '');
  }, [profile]);

  return {
    selected,
    onSelectLg,
    setSelected,
    onSaveLn,
  };
}

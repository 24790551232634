import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';

import * as FanterApi from '../../core/FanterAPI/FanterAPI';
import SetBtn from '../Settings/SetBtn';
import Input from '../input';

import CloseIcon from '../../assets/post/CloseIcon';

import cn from './SendTips.module.css';
import { Loader } from '../Loader/Loader';
import { ErrorProps } from '../../core/api/errorServeApi';
import { blockScroll, removeBlockScroll } from '../../helper/domManipulation';
import { contentSendTippTG } from '../../core/api/payWallet';
import { openTelegramLink } from '../../core/openTelegramLink';

export interface ISendTipsRef {
  open: () => void;
  close: () => void;
}
function isValidNumber(str: string) {
  const regex = /^[0-9]+[.,]?[0-9]*$/;
  return regex.test(str);
}
function toStringNumber(value: string): string {
  const maxCount = 3;
  if (value.includes('.')) {
    const [f, s] = value.split('.');
    const slenght = s?.length ?? 0;
    if (!slenght) return `${f}.`;
    return `${f}.${slenght > maxCount ? s.slice(0, maxCount - slenght) : s}`;
  }
  if (value.includes(',')) {
    const [f, s] = value.split(',');
    const slenght = s?.length ?? 0;
    if (!slenght) return `${f},`;
    return `${f},${slenght > maxCount ? s.slice(0, maxCount - slenght) : s}`;
  }

  return value;
}

interface IProps {
  className?: string;
  contentId: string;
  onSuccess: (amount: number) => void;
  onFailed: (error: ErrorProps) => void;
}

const minAmount = 0.01;
export const SendTips = forwardRef(
  (
    { className, contentId, onSuccess, onFailed }: IProps,
    ref: React.Ref<ISendTipsRef>,
  ): JSX.Element => {
    const { t } = useTranslation();
    const [isOpen, setOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>();

    const contentBuyTimer = useRef<ReturnType<typeof setInterval> | null>(null);

    const isPayByTG = useRef(false);

    const formatAmount = (value?: string): number | undefined => {
      if (value === undefined) {
        return undefined;
      }
      const val = value.replaceAll(',', '.');
      const formattedStr = val.replace(/(\.\d{2})\d+/g, '$1');
      const formattedValue = parseFloat(formattedStr);

      return !Number.isNaN(formattedValue) ? formattedValue : undefined;
    };

    const checkPurchased = (purchased: string, amount: number): void => {
      if (contentBuyTimer.current !== null) {
        clearInterval(contentBuyTimer.current);
      }

      contentBuyTimer.current = setInterval(async () => {
        try {
          const { nowState } = await FanterApi.getTransaction(purchased);

          if (nowState === '10' || nowState === '20') {
            onSuccess(amount);
            setLoading(false);
          }
          if (contentBuyTimer.current !== null) {
            clearInterval(contentBuyTimer.current);
          }
        } catch (e) {
          console.error(e);

          setLoading(false);
          if (contentBuyTimer.current !== null) {
            clearInterval(contentBuyTimer.current);
          }
        }
      }, 1000);
    };

    const formik = useFormik<{ amount?: string; comment?: string }>({
      initialValues: {
        amount: undefined,
        comment: undefined,
      },
      validate: (v) => {
        const amount = v?.amount?.replaceAll(',', '.');

        if (amount && +amount > 500) {
          return { amount: t('SendTips.errorAmountMax') };
        }

        if (amount && +amount < minAmount) {
          return { amount: t('SendTips.errorAmountMin') };
        }

        return {};
      },
      // validateOnBlur: true,
      validateOnMount: false,
      validateOnChange: true,
      onSubmit: async (v) => {
        if (v.amount === undefined) {
          formik.setFieldError('amount', t('SendTips.errorAmountRequired'));
          return;
        }

        const amountStr = v.amount.replaceAll(',', '.').trim();
        const amount = +amountStr;
        if (Number.isNaN(amount)) {
          formik.setFieldError('amount', `"${amountStr}" - not number`);
          return;
        }
        if (amount < minAmount) {
          formik.setFieldError('amount', t('SendTips.errorAmountMin'));
          return;
        }

        try {
          if (amount) {
            setLoading(true);

            let purchased = '';
            if (isPayByTG.current) {
              const { payHash, directPayLink } = await contentSendTippTG({
                contentId: +contentId,
                tippSumm: amount,
                text: v.comment,
              });
              purchased = payHash;

              openTelegramLink(directPayLink);
            }

            if (!isPayByTG.current) {
              const { tipped } = await FanterApi.sendTipsApi(
                amount,
                +contentId,
                v.comment,
              );
              purchased = tipped;
              checkPurchased(purchased, amount);
            }

            // @ts-ignore
            window.dataLayer?.push({ event: 'Donate' });
          }
        } catch (err) {
          // console.error("sendTips", err);
          // setError(err);
          // setLoading(false);
          if (contentBuyTimer.current !== null) {
            clearInterval(contentBuyTimer.current);
          }
          setLoading(false);
          onFailed(err as ErrorProps);
        } finally {
          if (isPayByTG.current) {
            closeModal();
          }
          isPayByTG.current = false;
          setLoading(false);
        }
      },
    });

    useEffect(
      () => () => {
        if (contentBuyTimer.current !== null)
          clearInterval(contentBuyTimer.current);
      },
      [],
    );
    useImperativeHandle(ref, () => ({
      open() {
        blockScroll();
        formik.setValues({ amount: undefined, comment: undefined });
        formik.setErrors({});
        setLoading(undefined);
        setOpen(true);
      },
      close() {
        removeBlockScroll();
        closeModal();
      },
    }));

    const amountInputOnChangeHandler = (
      event: React.ChangeEvent<HTMLInputElement>,
    ): void => {
      const value = event.target.value;
      if (value === '') formik.setFieldValue('amount', value);
      if (!isValidNumber(value)) return;
      formik.setFieldValue('amount', toStringNumber(value));
    };

    const closeModal = (): void => {
      removeBlockScroll();
      setOpen(false);
      if (contentBuyTimer.current) clearInterval(contentBuyTimer.current);
      formik.resetForm();
    };
    const onWalletPay = () => {
      isPayByTG.current = true;
      formik.submitForm();
    };

    if (!isOpen) {
      return <></>;
    }

    const disabled = !formik.isValid;

    return (
      <div className={className} onClick={(event) => event.stopPropagation()}>
        <div className={cn.overlay} onClick={closeModal} />
        <div className={cn.tips}>
          <form onSubmit={formik.handleSubmit}>
            <div className={cn.t_header}>
              {loading === undefined && <span>{t('SendTips.SendTips')}</span>}
              <span className={cn.t_close} onClick={closeModal}>
                <CloseIcon />
              </span>
            </div>

            {loading && (
              <div className={cn.loaderContainer}>
                <Loader />
              </div>
            )}

            {loading === undefined && (
              <div className={cn.content}>
                <div className={cn.t_quick}>{t('SendTips.QuickAmount')}</div>
                <div className={cn.t_btns}>
                  <SetBtn
                    text="5$"
                    gray="gray"
                    onClick={() => formik.setFieldValue('amount', '5')}
                  />
                  <SetBtn
                    text="10$"
                    gray="gray"
                    onClick={() => formik.setFieldValue('amount', '10')}
                  />
                  <SetBtn
                    text="15$"
                    gray="gray"
                    onClick={() => formik.setFieldValue('amount', '15')}
                  />
                  <SetBtn
                    text="20$"
                    gray="gray"
                    onClick={() => formik.setFieldValue('amount', '20')}
                  />
                </div>
                <div className={cn.t_inp}>
                  <Input
                    inputOpt={{
                      name: 'amount',
                      type: 'text',
                      inputMode: 'decimal',
                      placeholder: t('SendTips.EnterAmount'),
                      value: formik.values.amount,
                      onChange: amountInputOnChangeHandler,
                      onBlur: formik.handleBlur,
                      autoComplete: 'off',
                      id: 'SendTips_Amount',
                    }}
                    width="100%"
                  />
                  {formik.errors.amount && (
                    <div className={cn.t_error}>{formik.errors.amount}</div>
                  )}
                </div>
                <div className={cn.t_inp}>
                  <Input
                    inputOpt={{
                      name: 'comment',
                      type: 'text',
                      placeholder: t('SendTips.Comment'),
                      value: formik.values.comment,
                      onChange: formik.handleChange,
                      onBlur: formik.handleBlur,
                      autoComplete: 'off',
                      id: 'SendTips_Comment',
                      maxLength: 500,
                    }}
                    width="100%"
                  />
                </div>
              </div>
            )}
            {loading === false && (
              <div className={cn.aftersend}>
                <div className={cn.successStatus}>{t('SendTips.Success')}</div>
                <div className={cn.aftertit}>
                  {t('SendTips.HasBeenSentInTips', {
                    AMOUNT: formik.values.amount,
                  })}
                </div>
              </div>
            )}
            <div className={cn.t_sendbtn}>
              {!Telegram?.WebApp?.initData && loading === undefined && (
                <SetBtn
                  text={t('SendTips.SendTips')}
                  styleB="fullwidth"
                  type="submit"
                  // disabled={disabled}
                  disabled
                />
              )}
              {loading === false && (
                <SetBtn
                  text={t('SendTips.OK')}
                  styleB="fullwidth"
                  onClick={closeModal}
                />
              )}
            </div>

            {Telegram?.WebApp?.initData && loading === undefined && (
              <div className={`${cn.mt10} ${cn.t_sendbtn}`}>
                <SetBtn
                  text="👛 Pay via Wallet"
                  styleB="fullwidth"
                  onClick={onWalletPay}
                  disabled={disabled}
                />
              </div>
            )}
          </form>
        </div>
      </div>
    );
  },
);

import React from 'react';

interface IProps {
  className?: string;
  onClick?: () => void;
}

function InfoCircleIcon(props: IProps): JSX.Element {
  return <div {...props}>
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.44444 11.8056C9.44444 12.3272 9.0216 12.75 8.5 12.75C7.9784 12.75 7.55556 12.3272 7.55556 11.8056V8.02778C7.55556 7.50618 7.9784 7.08333 8.5 7.08333C9.0216 7.08333 9.44444 7.50618 9.44444 8.02778V11.8056Z"
        fill="#B4B4D2"
      />
      <path
        d="M9.44444 5.19444C9.44444 5.71605 9.0216 6.13889 8.5 6.13889C7.9784 6.13889 7.55556 5.71605 7.55556 5.19444V4.72222C7.55556 4.20062 7.9784 3.77778 8.5 3.77778C9.0216 3.77778 9.44444 4.20062 9.44444 4.72222V5.19444Z"
        fill="#B4B4D2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 8.5C17 13.1944 13.1944 17 8.5 17C3.80558 17 0 13.1944 0 8.5C0 3.80558 3.80558 0 8.5 0C13.1944 0 17 3.80558 17 8.5ZM15.1111 8.5C15.1111 12.1512 12.1512 15.1111 8.5 15.1111C4.84878 15.1111 1.88889 12.1512 1.88889 8.5C1.88889 4.84878 4.84878 1.88889 8.5 1.88889C12.1512 1.88889 15.1111 4.84878 15.1111 8.5Z"
        fill="#B4B4D2"
      />
    </svg>
  </div>
}

export default InfoCircleIcon;

import { createAction } from '@reduxjs/toolkit';
import { TNotificationFilter } from './types/TNotificationsFilter';

export const fetchHaveUnread = createAction<
  undefined,
  'notify/fetchHaveUnread'
>('notify/fetchHaveUnread');

export const fetchGetNotifications = createAction<
  TNotificationFilter,
  'notify/fetchGetNotificationsList'
>('notify/fetchGetNotificationsList');

export const fetchSetReaded = createAction<number, 'notify/fetchSetReaded'>(
  'notify/fetchSetReaded'
);

import React from 'react';

interface IProps {
  className?: string;
  onClick?: () => void;
}

function Icon(props: IProps): JSX.Element {
  return <div {...props}>
    <svg
      width="188"
      height="190"
      viewBox="0 0 188 190"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.12"
        d="M15.7085 166.348C10.6018 160.872 -0.87534 148.533 0.0531471 133.083C0.813991 120.628 8.88667 118.635 12.3556 102.273C15.7987 85.9875 8.84798 82.9412 11.7366 67.35C12.1622 65.062 17.0754 40.3705 35.5033 31.5144C50.9265 24.098 59.3087 35.7175 76.9241 28.661C93.0695 22.2085 90.7741 10.5247 106.494 3.8923C121.994 -2.66298 144.82 -1.54473 157.006 11.9257C169.025 25.2291 166.201 45.4862 165.801 48.3782C163.867 62.2086 157.29 65.2291 157.922 78.1855C158.399 88.1212 162.719 95.769 166.536 102.517C174.454 116.527 179.935 116.643 183.855 126.707C186.576 133.7 190.122 149.509 182.617 157.209C171.681 168.443 143.737 155.808 121.633 164.085C115.547 166.36 111.846 168.803 111.846 168.803C102.715 174.972 100.975 182.581 85.8866 187.067C61.7202 194.24 32.8081 184.728 15.7085 166.348Z"
        fill="#B4B4D2"
      />
      <path
        d="M118.105 141.918V81.5715H102.225V65.6975H62.5909V141.918"
        stroke="#26237C"
        strokeWidth="1.91685"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M102.225 81.572H118.105L102.225 65.824V81.572Z"
        fill="#E2DFF5"
        stroke="#26237C"
        strokeWidth="1.91685"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M123.694 60.4061L129.156 54.8628"
        stroke="#26237C"
        strokeWidth="1.91685"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M113.15 54.3586L114.167 50.7051"
        stroke="#CF142B"
        strokeWidth="1.91685"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M101.082 54.3591L99.0495 46.926"
        stroke="#26237C"
        strokeWidth="1.91685"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M129.665 82.7061L137.16 84.7218"
        stroke="#26237C"
        strokeWidth="1.91685"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M129.665 70.7364L133.476 69.7285"
        stroke="#CF142B"
        strokeWidth="1.91685"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="62.95"
        y1="142.05"
        x2="118.05"
        y2="142.05"
        stroke="#26237C"
        strokeWidth="1.9"
        strokeLinecap="round"
      />
    </svg>
  </div>
}

export default Icon;

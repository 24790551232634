import { createAction } from '@reduxjs/toolkit';
import type {
  TNewCard,
  TPChangePassword,
  TPDepositForChat,
  TPFollow,
  TPGetReferals,
  TPSetCost,
  TPSetNewFee,
  TPUpdateProfile,
  TUserAction,
} from './api';
import { TPGetComplaintsList, TPSetMessageFee } from './api';
import type { TEmptyChat } from '../chat/reduser';
import { TGetPaymentsFilter } from '../../types/TPayments';
import { TGetPurchasesFilter } from '../../types/TPurchases';
import { TBankOptions } from '../../types/TBankOptions';
import { TGetPayStatisticFilter } from '../../types/TPayStatistic';

export const fetchGetProfile = createAction<undefined, 'user/fetchGetProfile'>(
  'user/fetchGetProfile',
);
export const fetchGetProfileNotReload = createAction<
  undefined,
  'user/fetchGetProfileNotReload'
>('user/fetchGetProfileNotReload');
export const fetchSetLanguage = createAction<string, 'user/fetchSetLanguage'>(
  'user/fetchSetLanguage',
);
export const logOut = createAction<undefined, 'user/logOut'>('user/logOut');
export const impersonate = createAction<
  { impersonateToken: string },
  'user/impersonate'
>('user/impersonate');

export const fetchResendEmailConfirm = createAction<
  undefined,
  'user/fetchResendEmailConfirm'
>('user/fetchResendEmailConfirm');

export const fetchChangePassword = createAction<
  TPChangePassword,
  'user/changePassword'
>('user/changePassword');

export const fetchSetActive = createAction<boolean, 'user/fetchSetActive'>(
  'user/fetchSetActive',
);
export const fetchGetReferals = createAction<
  TPGetReferals,
  'user/fetchGetReferals'
>('user/fetchGetReferals');

export const fetchSaveAvatar = createAction<FormData, 'user/fetchSaveAvatar'>(
  'user/fetchSaveAvatar',
);
export const fetchSaveCover = createAction<FormData, 'user/fetchSaveCover'>(
  'user/fetchSaveCover',
);
export const fetchNicknameAvailable = createAction<
  string,
  'user/fetchNicknameAvailable'
>('user/fetchNicknameAvailable');
export const fetchUpdateProfile = createAction<
  TPUpdateProfile,
  'user/updateProfile'
>('user/updateProfile');

export const fetchUpdateNickname = createAction<
  string,
  'user/fetchUpdateNickname'
>('user/fetchUpdateNickname');

export const fetchGetThatProfile = createAction<
  string,
  'user/fetchGetThatProfile'
>('user/fetchGetThatProfile');

export const fetchGetThatProfileNotReload = createAction<
  string,
  'user/fetchGetThatProfileNotReload'
>('user/fetchGetThatProfileNotReload');

export const fetchSend = createAction<TEmptyChat, 'user/fetchSend'>(
  'user/fetchSend',
);

export const fetchSetBusiness = createAction<boolean, 'user/fetchSetBusiness'>(
  'user/fetchSetBusiness',
);
export const fetchFollow = createAction<
  TPFollow & { nickname: string },
  'user/fetchFollow'
>('user/fetchFollow');

export const fetchGetUserActions = createAction<
  undefined,
  'user/fetchGetUserAction'
>('user/fetchGetUserAction');

export const fetchSetUserActions = createAction<
  TUserAction,
  'user.setUserAction'
>('user.setUserAction');

export const fetchGetCards = createAction<undefined, 'user.getCards'>(
  'user.getCards',
);

export const fetchCreateCard = createAction<TNewCard, 'user.createCard'>(
  'user.createCard',
);

export const fetchDropCard = createAction<{ id: string }, 'user.dropCard'>(
  'user.dropCard',
);

export const fetchGetSubscriptions = createAction<
  undefined,
  'user.getSubscriptions'
>('user.getSubscriptions');

export const fetchSetAutoRenewal = createAction<
  { reneval: boolean; hashOpponent: string },
  'user.setAutoRenewal'
>('user.setAutoRenewal');

export const fetchGetPayments = createAction<
  TGetPaymentsFilter,
  'user.getPayments'
>('user.getPayments');

export const fetchGetPurchases = createAction<
  TGetPurchasesFilter,
  'user.getPurchased'
>('user.getPurchased');

export const fetchGetPaysStatistic = createAction<
  TGetPayStatisticFilter,
  'user.getPaysStatistic'
>('user.getPaysStatistic');

export const fetchSetCost = createAction<TPSetCost, 'user.setCost'>(
  'user.setCost',
);

export const fetchSetMessageFee = createAction<
  TPSetMessageFee,
  'user.setMessageFee'
>('user.setMessageFee');

export const fetchSetNewFee = createAction<TPSetNewFee, 'chat.setNewFee'>(
  'chat.setNewFee',
);

export const fetchDepositForChat = createAction<
  TPDepositForChat & { isTelegram?: boolean },
  'user.depositForChat'
>('user.depositForChat');

export const fetchSetBankOptions = createAction<
  TBankOptions,
  'user.setBankOptions'
>('user.setBankOptions');

export const fetchGetBankOptions = createAction<
  undefined,
  'user.getBankOptions'
>('user.getBankOptions');

export const fetchGetBankDocs = createAction<undefined, 'user.getBankDoc'>(
  'user.getBankDoc',
);

export const fetchUploadBankDoc = createAction<
  { doc: File | null; selfie: File | null },
  'user.uploadBankDoc'
>('user.uploadBankDoc');

export const fetchGetComplaintsList = createAction<
  TPGetComplaintsList,
  'user/fetchGetComplaintsList'
>('user/fetchGetComplaintsList');
export const fetchGetComplaintsListNotReload = createAction<
  TPGetComplaintsList,
  'user/fetchGetComplaintsListNotReload'
>('user/fetchGetComplaintsListNotReload');

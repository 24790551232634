import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { HornIcon, MoreVertIcon } from '../../../assets';
import {
  ComplaintModal,
  IComplaintModalRef,
} from './ComplaintModal/ComplaintModal';

import cn from './MoreActions.module.scss';

interface IProps {
  className?: string;
  contentId: number;
}

export function MoreActions({ className, contentId }: IProps): JSX.Element {
  const { t } = useTranslation();

  const complaintModalRef = useRef<IComplaintModalRef>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const onClickMoreVertIconHandler = useCallback((event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setOpen(true);
  }, []);

  const onClickOverlayHandler = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.preventDefault();
      event.stopPropagation();
      setOpen(false);
    },
    []
  );

  const onClickReportHandler = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.preventDefault();
      event.stopPropagation();
      setOpen(false);
      complaintModalRef.current?.open();
    },
    []
  );

  return (
    <div className={className} onClick={(event) => event.stopPropagation()}>
      <ComplaintModal ref={complaintModalRef} contentId={contentId} />
      <div className={cn.moreActions} onClick={onClickMoreVertIconHandler}>
        <MoreVertIcon />
        {isOpen && (
          <>
            <div
              className={cn.moreActions__overlay}
              onClick={onClickOverlayHandler}
            />
            <div className={cn.moreActions__actions}>
              <div
                className={cn.moreActions__actions_item}
                onClick={onClickReportHandler}
              >
                <HornIcon
                  className={`${cn.moreActions__actions_icon} ${cn.moreActions__actions_hornIcon}`}
                />
                <span>{t('Complaint.Report')}</span>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import CloseIcon from 'assets/post/CloseIcon';
import { publicBook } from 'books';
import {
  fetchContentSetLike,
  fetchContentUnsetLike,
} from 'domains/timeLine/actions';
import { blockScroll, removeBlockScroll } from 'helper/domManipulation';
import { TContent } from 'types/TContent';

import { Iconlist } from '../Iconlist/Iconlist';
import { SwiperWrapper } from '../SwiperWrapper/SwiperWrapper';

interface IProps {
  content: TContent;
  isFree?: boolean;
  onlyFreeText?: boolean;
  isPrivate?: boolean;
  cn: { [key: string]: string };
  tipsOnClickHandler?: () => void;
  isAuth?: boolean;
  isSingle?: boolean;
  isModerationMode?: boolean;
}

export function Part({
  content,
  isFree,
  onlyFreeText,
  isPrivate,
  cn,
  tipsOnClickHandler,
  isAuth,
  isSingle,
  isModerationMode,
}: IProps): JSX.Element {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const text = isFree ? content.message : content.messagePayed;
  const [more, setMore] = useState<boolean>(() => (text?.length ?? 0) > 200);
  const [moreFree, setMoreFree] = useState<boolean>(
    () => (content.message?.length ?? 0) > 200,
  );
  const [isOpenFullScreenSwiper, setOpenFullScreenSwiper] =
    useState<boolean>(false);
  const [activeSlideIndex, setActiveSlideIndex] = useState<number>(0);
  const [islLkesActive, setLikesActive] = useState<boolean>(
    content.likes.likedByMe,
  );
  const [likes, setLikes] = useState<number>(content.likes.allLikes);
  const targetElement = useRef<HTMLDivElement>(null);

  const wasOpenFul = useRef(false);
  useEffect(
    () => () => {
      if (wasOpenFul.current) removeBlockScroll();
    },
    [],
  );
  const isShowFreePart = useMemo(() => {
    if (!isFree) {
      if (content.freeFiles !== undefined && content.freeFiles.length === 0) {
        return true;
      }
    }
    return false;
  }, [content, isFree]);

  if (
    onlyFreeText &&
    content.freeFiles !== undefined &&
    content.freeFiles.length > 0
  ) {
    return <></>;
  }

  if (
    isFree &&
    !onlyFreeText &&
    (content.freeFiles === undefined || content.freeFiles.length === 0) &&
    content.payedFiles !== undefined &&
    content.payedFiles.length > 0
  ) {
    return <></>;
  }

  const files = [];
  if (content.contentPath) {
    files.push({ path: content.contentPath, concept: +content.concept });
  } else if (isFree && content.freeFiles) {
    files.push(...content.freeFiles);
  } else if (!isFree && content.payedFiles) {
    files.push(...content.payedFiles);
  }

  const openText = useCallback(() => {
    setMore(false);
  }, []);

  const showText = useCallback(() => {
    setMore(true);
  }, []);

  const openFreeText = useCallback(() => {
    setMoreFree(false);
  }, []);

  const showFreeText = useCallback(() => {
    setMoreFree(true);
  }, []);

  const onClickLikeHandler = useCallback(() => {
    if (!isAuth) {
      history.push(
        `${publicBook.signIn}?u=${
          content.nickname
        }&${window.location.search.substring(1)}`,
      );
      return;
    }

    if (islLkesActive) {
      setLikesActive(false);
      setLikes((l) => l - 1);
      dispatch(fetchContentUnsetLike({ id: content.id, private: isPrivate }));
      return;
    }
    setLikesActive(true);
    setLikes((l) => l + 1);
    dispatch(fetchContentSetLike({ id: content.id, private: isPrivate }));
  }, [content, isPrivate, islLkesActive, isAuth]);

  const onClickSlideHandler = useCallback(() => {
    setOpenFullScreenSwiper(true);
    wasOpenFul.current = true;
    blockScroll();

    const ProfileOverContentInfo = document.getElementById(
      'ProfileOverContentInfo',
    );
    if (ProfileOverContentInfo) {
      ProfileOverContentInfo.style.zIndex = '16';
    }

    const fanterHeaderStub = document.getElementById('fanterHeaderStub');
    if (fanterHeaderStub) {
      fanterHeaderStub.style.display = 'none';
    }

    const fanterHeader = document.getElementsByTagName('header')[0];
    if (fanterHeader) {
      fanterHeader.style.zIndex = '15';
    }
  }, []);

  const onCloseFullScreen = useCallback(() => {
    setOpenFullScreenSwiper(false);

    if (!isSingle) {
      removeBlockScroll();
    }

    const ProfileOverContentInfo = document.getElementById(
      'ProfileOverContentInfo',
    );
    if (ProfileOverContentInfo) {
      ProfileOverContentInfo.style.zIndex = '17';
    }

    const fanterHeaderStub = document.getElementById('fanterHeaderStub');
    if (fanterHeaderStub) {
      fanterHeaderStub.style.display = 'unset';
    }

    const fanterHeader = document.getElementsByTagName('header')[0];
    if (fanterHeader) {
      fanterHeader.style.zIndex = '18';
    }
  }, [isSingle]);

  const onSlideChangeHandler = useCallback((index: number) => {
    setActiveSlideIndex(index);
  }, []);

  useEffect(() => {
    setLikesActive(content.likes.likedByMe);
    setLikes(content.likes.allLikes);
  }, [content.likes.likedByMe]);

  return (
    <div ref={targetElement}>
      {files.length > 0 && (
        <div
          className={cn.post_img}
          onClick={(event) => event.stopPropagation()}
        >
          <SwiperWrapper
            files={files}
            scrollToIndex={activeSlideIndex}
            onClickSlide={onClickSlideHandler}
            onSlideChange={onSlideChangeHandler}
            isFullScreen={isOpenFullScreenSwiper}
            onOutsideClick={onCloseFullScreen}
          />
          {isOpenFullScreenSwiper && (
            <div className={cn.closevideomodal} onClick={onCloseFullScreen}>
              <CloseIcon />
            </div>
          )}

          {!isFree && isModerationMode && (
            <div className={cn.post_priceContainer}>
              {content.paidOptions?.price !== undefined &&
                content.paidOptions.price > 0 && (
                  <div className={cn.post_price}>
                    ${content.paidOptions?.price}
                  </div>
                )}
              {content.paidOptions?.subscribeVip &&
                content.paidOptions.price > 0 && (
                  <div className={cn.post_price}>{t('Post.FreeForVIP')}</div>
                )}
              {content.paidOptions?.fanterVip &&
                content.paidOptions.price > 0 && (
                  <div className={cn.post_price}>{t('AddPost.FanterVIPs')}</div>
                )}
              {(content.paidOptions?.subscribeVip ||
                content.paidOptions?.fanterVip) &&
                content.paidOptions.price === 0 && (
                  <div className={cn.post_price}>
                    {t('AddPost.OnlyForSubscribers')}
                  </div>
                )}
            </div>
          )}
        </div>
      )}
      {(isFree || isShowFreePart) && (
        <div className={cn.tit}>
          {content.subject}
          <Iconlist
            islLkesActive={islLkesActive}
            likes={likes}
            likesOnClickHandler={onClickLikeHandler}
            tipsOnClickHandler={tipsOnClickHandler}
            tips={content.tipps?.summ}
          />
        </div>
      )}
      {isShowFreePart && content.message?.length > 0 && (
        <div className={cn.text}>
          {moreFree ? `${content.message?.substr(0, 200)}...` : content.message}
          {moreFree && (
            <span onClick={openFreeText} className={cn.showtext}>
              {t('Timeline.more')}
            </span>
          )}
          {!moreFree && (content.message?.length ?? 0) > 200 && (
            <span
              onClick={showFreeText}
              className={`${cn.showtext} ${cn.hidetext}`}
            >
              {t('Timeline.less')}
            </span>
          )}
        </div>
      )}
      {text !== undefined && text.length > 0 && (
        <div className={cn.text}>
          {more ? `${text?.substr(0, 200)}...` : text}
          {more && (
            <span onClick={openText} className={cn.showtext}>
              {t('Timeline.more')}
            </span>
          )}
          {!more && (text?.length ?? 0) > 200 && (
            <span
              onClick={showText}
              className={`${cn.showtext} ${cn.hidetext}`}
            >
              {t('Timeline.less')}
            </span>
          )}
        </div>
      )}
    </div>
  );
}

import React from 'react';

interface IProps {
  className?: string;
  onClick?: () => void;
}

function UserCircleIcon(props: IProps): JSX.Element {
  return <div {...props}>
    <svg
      width="91"
      height="91"
      viewBox="0 0 91 91"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="45.5" cy="45.5" r="45.5" fill="#5B56A5" />
      <path
        d="M45.997 53.1475C36.2875 53.1475 27.994 54.6783 27.994 60.8016C27.994 66.9272 36.2357 68.512 45.997 68.512C55.7065 68.512 64 66.9835 64 60.8579C64 54.7323 55.7606 53.1475 45.997 53.1475"
        fill="white"
      />
      <path
        opacity="0.4"
        d="M45.9969 47.3148C52.611 47.3148 57.9104 42.0132 57.9104 35.4014C57.9104 28.7895 52.611 23.4879 45.9969 23.4879C39.3851 23.4879 34.0835 28.7895 34.0835 35.4014C34.0835 42.0132 39.3851 47.3148 45.9969 47.3148"
        fill="white"
      />
    </svg>
  </div>
}

export default UserCircleIcon;

import request from '../../core/api/request';
import { TChat } from '../../types/TChat';

export type TRgetChatList = { chats: TChat[] };
/** Получения списка чатов, на которые подписан пользователь. */
export const getChatList = request<TRgetChatList>({
  method: 'chat.getChatList',
});

export type TPcreateChat = {
  /** хеш пользователя, с которым мы хотим создать чат. Если чат с тех.поддержкой вместо хеша использовать - “support” */
  hashUser: string;
};
export type TRcreateChat = {
  /** id нового чата */
  chatId: string;
};
/** Создание нового чата. */
export const createChat = request<TRcreateChat, TPcreateChat>({
  method: 'chat.createChat',
});

export type TPsendMessage = {
  /** Номер чата куда отправлять сообщение */
  chatId: string;
  /** сообщение, которое хочет отправить пользователь. 900 символов */
  message?: string;
  postId?: string;
};
export type TRsendMessage = {
  /** id нового сообщения */
  messageId: string;
};

/** Отправка сообщения в уже созданный чат.  */
export const sendMessage = request<TRsendMessage, TPsendMessage>({
  method: 'chat.sendMessage',
});

export type TPgetMessages = {
  /** Номер чата куда отправлять сообщение */
  chatId: string;
  /** сколько получить последних сообщений из чата (по умолчанию 200) */
  limit?: number;
  /** id сообщения, относительно которого нужно сместить получение сообщений. */
  offset?: string;
  /** слртировка о умолчанию desc */
  order?: 'asc' | 'desc';
  /** Если задан параметр offset, то можно задать “направление” получения сообщений от указанного. Можно получить сообщения раньше или позже указанного в offset.  */
  direction?: 'forward' | 'backward';
};

export type TRgetMessag = {
  /** id сообщения */
  id: string;
  /** хеш пользователя, который опубликовал сообщение */
  creatorId: string;
  /** id чата, в котором было опубликовано сообщение */
  chatId: string;
  /** время, в которое было опубликовано сообщение */
  tStamp: string;
  /** текст сообщения */
  message?: string;
  postId?: string;
  /** текст сообщения */
  messageType?: string;
  opponentReaded?: '0' | '1';
  contentCost?: string;
};

/** Получение сообщений из чата.   */
export const getMessages = request<TRgetMessag[], TPgetMessages>({
  method: 'chat.getMessages',
  showError: false,
});

export type TRgetNotified = {
  /** id чата, в котором было опубликовано сообщение */
  chatId: string;
  /** имя чата */
  chatName: string;
  /** время последнего сообщения в чате */
  lastMessageTStamp: string;
  /** время последнего прочитанного сообщения в чате (может быть ноль, если чат еще целиком не прочтен) */
  tStampReaded: string;
  /** тип чата (0 - чат с тех.поддержкой, 1-личный чат, 2-групповой чат) */
  chatType: '0' | '1' | '2';
  /** текст последнего сообщения */
  lastMessageText: string;
  /** количество не прочтенных сообщений пользователем */
  chatNewMessages: number;
};

/** Получение чатов, которые не прочтены пользователем.   */
export const getNotified = request<TRgetNotified[]>({
  method: 'chat.getNotified',
});

export type TPsetReaded = {
  /** id чата, который был “прочитан” */
  chatId: string;
  /** время прочитанного сообщения */
  tStamp?: number;
};

export type TRsetReaded = {
  read: boolean;
};

/** Установка для чата времени прочитанного сообщения. Необходимо учесть, что методом специально оставлена возможность “сдвинуть” прочитанное назад. Таким образом например установив время прочитанного сообщения в ноль можно “пометить” все сообщения чата как не прочитанные.   */
export const setReaded = request<TRsetReaded, TPsetReaded>({
  method: 'chat.setReaded',
});

export type TRGetUnreadCount = {
  unreaded: number;
};

/** Получение общего количества не прочитанных сообщений в чатах пользователя.   */
export const getUnreadCount = request<TRGetUnreadCount>({
  method: 'chat.getUnreadedCount',
});

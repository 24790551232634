import errorServeApi, { ErrorProps } from './errorServeApi';
import { ApiUrlSingleton } from './ApiUrlSingleton';

export type TsendImmageRes = {
  file?: string;
  result?: string;
};
export type TsendImage = (
  token: string,
  data?: FormData,
) => Promise<TsendImmageRes>;

export const sendFile: TsendImage = async (
  token,
  data,
  showError = true,
): Promise<TsendImmageRes> => {
  try {
    const response = await fetch(
      `${ApiUrlSingleton.getInstance().getURL()}uploads/?token=${token}`,
      {
        method: 'POST',
        body: data,
      },
    );
    const result = await response.json();
    if (result.error) {
      throw result;
      // throw new Error(result.error.message);
      // if (response?.data?.error) {
      //   throw response.data.error;
      // }
    }
    return result;
  } catch (error) {
    console.error({ error });
    // throw new Error(error);
    errorServeApi(error as ErrorProps, showError);
    throw error;
  }
};

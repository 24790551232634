import { combineReducers } from '@reduxjs/toolkit';
import { createBrowserHistory, History } from 'history';
import { connectRouter } from 'connected-react-router';
import { registrationReducer } from '../domains/registrations/reduser';
import { userReducer } from '../domains/user/reduser';
import { timelineReducer } from '../domains/timeLine/reduser';
import { chatReducer } from '../domains/chat/reduser';
import { notificationsReducer } from '../domains/notifications/reduser';
import { addPostReducer } from '../domains/AddPost/reducer';

export const history = createBrowserHistory();
const createRootReducer = (h: History) =>
  combineReducers({
    timelineReducer,
    registrationReducer,
    userReducer,
    chatReducer,
    addPostReducer,
    notificationsReducer,
    router: connectRouter(h),
  });

const RootReducer = createRootReducer(history);

export type RootState = ReturnType<typeof RootReducer>;
export default RootReducer;

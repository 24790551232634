import { makeRequest } from '../FanterAPI/FanterAPICore';

export type PContentSendTippTG = {
  contentId: number;
  tippSumm: number;
  text?: string;
};

export type TelegramWallet = {
  payHash: string;
  directPayLink: string;
  payLink: string;
};
export type RContentSendTippTG = TelegramWallet;

// Метод для отправки чаевых за сообщение c оплатой через телеграмм кошелек.
//   При оплате если указан параметр text добавляется комментарий к платежу. Коммент показывается сразу, при отсутствии оплаты - коммент не удаляется.
export const contentSendTippTG = async (
  param: PContentSendTippTG,
): Promise<RContentSendTippTG> =>
  makeRequest<PContentSendTippTG, RContentSendTippTG>(
    'content.sendTippTG',
    param,
  );

export type PContentBuyTG = {
  contentId: number;
  fromMessage?: number;
};

export type RContentBuyTG = TelegramWallet;

// Метод для покупки контента через телеграмм. Генерирует платеж и ссылки для платежа через wallet телеграмма.
export const contentBuyTG = async (
  param: PContentBuyTG,
): Promise<RContentBuyTG> =>
  makeRequest<PContentBuyTG, RContentBuyTG>('content.buyTG', param);

export type PProfileSubscribeVip = {
  hashOpponent: string;
  type: 'month';
};

export type RProfileSubscribeVip = TelegramWallet;
// Купить платную подписку для пользователя. Обязательная авторизация.
export const profileSubscribeVip = async (
  param: PProfileSubscribeVip,
): Promise<RProfileSubscribeVip> =>
  makeRequest<PProfileSubscribeVip, RProfileSubscribeVip>(
    'profile.subscribeVipTG',
    param,
  );

export type PUserDepositForChatTG = { amount: number };
export type RUserDepositForChatTG = TelegramWallet;
// Пополнение с бота wallet телеграмма внутреннего баланса для чата. Данный баланс используется в данное время только для отправки сообщений в чаты. Обязательная авторизация.
export const userDepositForChatTG = async (
  param: PUserDepositForChatTG,
): Promise<RUserDepositForChatTG> =>
  makeRequest<PUserDepositForChatTG, RUserDepositForChatTG>(
    'user.depositForChatTG',
    param,
  );

export type PUserSetUTM = {
  utm_source?: string;
  utm_campaign?: string;
  utm_content?: string;
};

export type RUserSetUTM = boolean;
export const userSetUTM = async (param: PUserSetUTM): Promise<RUserSetUTM> =>
  makeRequest<PUserSetUTM, RUserSetUTM>('user.setUTM', param);

export type TypeLog =
  | 'content.get'
  | 'content.getByProfile'
  | 'profile.get'
  | 'chat.openChat'
  | 'chat.sendMessage';
export type PLogSetLogForUTM = {
  type: TypeLog;
};

export type RLogSetLogForUTM = boolean;
export const logSetLogForUTM = async (
  param: PLogSetLogForUTM,
): Promise<RLogSetLogForUTM> =>
  makeRequest<PLogSetLogForUTM, RLogSetLogForUTM>('log.setLogForUTM', param);

let instance: ApiUrlSingleton | null = null;
export class ApiUrlSingleton {
  private readonly defaultApiUrl = 'https://api-dev.fanter.com/';

  private url: string;

  private readonly _isProd: boolean;

  private constructor() {
    this.url = this.defaultApiUrl;
    this._isProd =
      (localStorage.getItem('isProd') ??
        process.env.REACT_APP_IS_PRODUCTION) === 'true';
    this.changeUrl(this._isProd);
  }

  public static getInstance(): ApiUrlSingleton {
    if (!instance) {
      instance = new ApiUrlSingleton();
    }

    return instance;
  }

  public getURL(): string {
    return this.url;
  }

  // eslint-disable-next-line class-methods-use-this
  public setProd(_isProd: boolean): void {
    localStorage.setItem('isProd', _isProd.toString());
    window.location.reload();
  }

  public isProd(): boolean {
    return this._isProd;
  }

  private changeUrl(isProd: boolean): boolean {
    if (isProd && process.env.REACT_APP_API_PROD) {
      this.url = process.env.REACT_APP_API_PROD;
      return true;
    }

    if (process.env.REACT_APP_API_DEV) {
      this.url = process.env.REACT_APP_API_DEV;
      return false;
    }

    this.url = this.defaultApiUrl;
    console.warn('API url is default');
    return false;
  }

  public getUrlTusUpload() {
    if (this.isProd()) return process.env.REACT_APP_UPLOAD_PROD;
    return process.env.REACT_APP_UPLOAD_DEV;
  }
}

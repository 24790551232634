import React from 'react';
import RS, { NamedProps } from 'react-select';
import cn from './Select.module.scss';

export interface IRSelectOption {
  value: string;
  label: string;
}

interface IProps {
  ClassName?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: any[];
  placeholder?: string;
  selectProps?: NamedProps;
}

export function RSelect({
  ClassName,
  options,
  placeholder,
  selectProps,
}: IProps): JSX.Element {
  return (
    <RS
      options={options}
      placeholder={placeholder}
      className={`${cn.fanter_select} ${ClassName}`}
      {...selectProps}
      styles={{
        menuPortal: (base) => ({ ...base, zIndex: 1100, touchAction: 'none' }),
        indicatorSeparator: (base) => ({
          ...base,
          backgroundColor: 'transparent',
        }),
      }}
    />
  );
}

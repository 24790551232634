import React from 'react';

interface IProps {
  className?: string;
  onClick?: () => void;
}

function CardRepeatIcon(props: IProps): JSX.Element {
  return <div {...props}>
    <svg
      width="75"
      height="56"
      viewBox="0 0 75 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34.8696 48.5176H6.1087C3.83522 48.5176 2 46.6843 2 44.4131V6.1045C2 3.83334 3.83522 2 6.1087 2H60.8913C63.1648 2 65 3.83334 65 6.1045V27.9951M10.2174 38.9405H32.1304M10.2174 32.0996H23.913M56.7826 17.0498H48.5652C47.7983 17.0498 47.1957 16.4478 47.1957 15.6817V10.209C47.1957 9.44282 47.7983 8.84083 48.5652 8.84083H56.7826C57.5496 8.84083 58.1522 9.44282 58.1522 10.209V15.6817C58.1522 16.4478 57.5496 17.0498 56.7826 17.0498Z"
        stroke="#413E93"
        strokeWidth="2.7"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M59.5 37C50.665 37 44 40.3325 44 44.75C44 48.5475 48.9445 51.539 55.9505 52.314L55.2995 52.9495C55.1542 53.0936 55.0389 53.265 54.9602 53.4539C54.8815 53.6428 54.841 53.8454 54.841 54.05C54.841 54.2546 54.8815 54.4572 54.9602 54.6461C55.0389 54.835 55.1542 55.0064 55.2995 55.1505C55.4436 55.2958 55.615 55.4111 55.8039 55.4898C55.9928 55.5685 56.1954 55.609 56.4 55.609C56.6046 55.609 56.8072 55.5685 56.9961 55.4898C57.185 55.4111 57.3564 55.2958 57.5005 55.1505L60.6005 52.0505C60.7416 51.9031 60.8522 51.7293 60.926 51.539C61.081 51.1616 61.081 50.7384 60.926 50.361C60.8522 50.1707 60.7416 49.9969 60.6005 49.8495L57.5005 46.7495C57.2086 46.4576 56.8128 46.2937 56.4 46.2937C55.9872 46.2937 55.5914 46.4576 55.2995 46.7495C55.0076 47.0414 54.8437 47.4372 54.8437 47.85C54.8437 48.2628 55.0076 48.6586 55.2995 48.9505L55.4855 49.121C50.2 48.377 47.1 46.3 47.1 44.75C47.1 42.859 51.936 40.1 59.5 40.1C67.064 40.1 71.9 42.859 71.9 44.75C71.9 46.0365 69.6525 47.85 65.3745 48.78C65.172 48.8188 64.9793 48.8976 64.8076 49.0118C64.636 49.126 64.4888 49.2733 64.3748 49.445C64.2608 49.6168 64.1822 49.8096 64.1436 50.0121C64.105 50.2146 64.1071 50.4228 64.15 50.6245C64.1923 50.8242 64.2737 51.0136 64.3894 51.1817C64.5051 51.3499 64.6529 51.4936 64.8243 51.6045C64.9957 51.7154 65.1873 51.7913 65.3881 51.828C65.5889 51.8646 65.795 51.8612 65.9945 51.818C71.652 50.578 75 47.943 75 44.75C75 40.3325 68.335 37 59.5 37Z"
        fill="#413E93"
      />
    </svg>
  </div>
}

export default CardRepeatIcon;

import React from 'react';

interface IProps {
  className?: string;
  onClick?: () => void;
}

function SmallEditIcon(props: IProps): JSX.Element {
  return <div {...props}>
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6827 3.97004L15.1807 8.46807L16.4535 7.19528L11.9554 2.69725L10.6827 3.97004ZM17.6806 6.04397L7.38195 16.3426C7.36233 16.3622 7.33795 16.3764 7.3112 16.3838L1.10392 18.0931C0.98408 18.1261 0.873944 18.0159 0.906945 17.8961L2.6162 11.6888C2.62356 11.6621 2.63775 11.6377 2.65737 11.6181L12.956 1.31939C13.1099 1.22081 13.6007 0.943658 14.2661 0.904613C14.9205 0.866211 15.8489 1.04974 16.8996 2.10042C17.9503 3.15109 18.1338 4.0795 18.0954 4.73392C18.0563 5.39928 17.7792 5.89013 17.6806 6.04397Z"
        stroke="#413E93"
        strokeWidth="1.8"
      />
    </svg>
  </div>
}

export default SmallEditIcon;

import React from 'react';

interface IProps {
  className?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

function CloseIcon(props: IProps): JSX.Element {
  return (
    <div {...props}>
      <svg
        width="13"
        height="13"
        viewBox="0 0 13 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 1L12 12M1 12L12 1"
          stroke="#413E93"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}

export default CloseIcon;

import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import classnames from 'classnames';

import packageJson from '../../../../package.json';
import CloseIcon from '../../../assets/profile/CloseIcon';
import { IncorectIcon, OopsIcon } from '../../../assets/cards';

import cn from './PaymentErrorModal.module.scss';
import { book } from '../../../books';
import { PAYMENT_ERRORS } from '../../../ListErrors';

export interface IPaymentErrorModalRef {
  open: () => void;
  close: () => void;
}

interface IProps {
  code?: number;
  logCode?: string;
  onCancelClick?: () => void;
  onTryAgainClick?: () => void;
}

export const PaymentErrorModal = forwardRef(
  (
    { code, logCode, onCancelClick, onTryAgainClick }: IProps,
    ref: React.Ref<IPaymentErrorModalRef>
  ): JSX.Element => {
    const { t } = useTranslation();
    const history = useHistory();

    const [isOpen, setOpen] = useState<boolean>(false);

    useImperativeHandle(ref, () => ({
      open() {
        setOpen(true);
      },
      close() {
        setOpen(false);
      },
    }));

    const { icon, text, description } = useMemo(() => {
      let _icon: JSX.Element = <IncorectIcon />;
      let _text: string[];
      let _description: string;

      if (code !== undefined && PAYMENT_ERRORS.includes(code)) {
        return {
          icon: _icon,
          text: logCode ? [`#${logCode}`] : [],
          description: t(`errors.${code}`),
        };
      }

      switch (code) {
        case 10111: {
          _text = [
            t('PaymentErrorModal.10111.0'),
            t('PaymentErrorModal.10111.1', { logCode }),
          ];
          _description = t('PaymentErrorModal.10111.d');
          break;
        }
        case 10211: {
          _text = [
            t('PaymentErrorModal.10211.0'),
            t('PaymentErrorModal.10211.1', { logCode }),
          ];
          _description = t('PaymentErrorModal.10211.d');
          break;
        }
        default: {
          _icon = <OopsIcon />;
          _text = [
            t('PaymentErrorModal.oops.0'),
            t('PaymentErrorModal.oops.1', { logCode }),
          ];
          _description = t('PaymentErrorModal.oops.d');
        }
      }

      return {
        icon: _icon,
        text: _text,
        description: _description,
      };
    }, [code]);

    const onClickAccount = useCallback(() => {
      history.push(book.userAccount);
    }, []);

    return (
      <Modal className={cn.modal} overlayClassName={cn.overlay} isOpen={isOpen}>
        <div className={cn.modalContent}>
          <CloseIcon
            onClick={onTryAgainClick}
            className={cn.modalContent__closeIcon}
          />
          <div className={cn.modalContent__column}>
            <div className={cn.modalContent__icon}>{icon}</div>
            <div className={cn.modalContent__text}>
              {!!text[0] && <span>{text[0]}</span>}
              {!!text[1] && <span>{text[1]}</span>}
            </div>
            <div className={cn.modalContent__description}>
              <span>{description}</span>
            </div>
            <div className={classnames(cn.modalContent__actions, cn.actions)}>
              <div className={cn.actions__row}>
                {code !== undefined && [10211].includes(code) && (
                  <>
                    <button
                      type="button"
                      className={cn.actions__tryAgainBtn}
                      onClick={onClickAccount}
                    >
                      {t('SettingsMenu.Account')}
                    </button>
                    <button
                      type="button"
                      className={cn.actions__cancelBtn}
                      onClick={onCancelClick}
                    >
                      {t('PaymentErrorModal.cancel')}
                    </button>
                  </>
                )}
                {code !== undefined && [10111].includes(code) && (
                  <>
                    <button
                      type="button"
                      className={cn.actions__tryAgainBtn}
                      onClick={onTryAgainClick}
                    >
                      {t('PaymentErrorModal.tryAgain')}
                    </button>
                    <button
                      type="button"
                      className={cn.actions__cancelBtn}
                      onClick={onCancelClick}
                    >
                      {t('PaymentErrorModal.cancel')}
                    </button>
                  </>
                )}
                {(code === undefined ||
                  ![10111, 10211].includes(code) ||
                  PAYMENT_ERRORS.includes(code)) && (
                  <button
                    type="button"
                    className={cn.actions__tryAgainBtn}
                    onClick={onTryAgainClick}
                  >
                    {t('PaymentErrorModal.ok')}
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className={cn.modalContent__version}>
            version: {packageJson.version}
          </div>
        </div>
      </Modal>
    );
  }
);

import { TProfile } from '../types/TProfile';

export function validateEmail(email: string): boolean {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email.toLowerCase());
}

export function getPrevId(id: string): string {
  const numId = +id;
  if (!numId) {
    return '0';
  }

  return (numId - 1).toString();
}

export function getUserName(
  fN: string | undefined,
  lN: string | undefined,
  nickname?: string
): string {
  if (fN && lN) {
    return `${fN} ${lN}`;
  }

  return nickname ?? '';
}

export const profileGetUserName = (profile: TProfile | null): string => {
  if (profile === null) {
    return '';
  }

  if (profile.firstName || profile.lastName) {
    return `${profile.firstName ?? ''} ${profile.lastName ?? ''}`.trim();
  }

  return profile.nickname ?? '';
};

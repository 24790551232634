import { call, put, SagaReturnType } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/types';

import { FetchedData, genFetchedData } from '../../../core/api/fetchedData';
import { getToken } from '../../../core/sagas/helper';

import { fetchAddPost, fetchEditPost } from '../actions';
import { addPost, editPost } from '../api';
import { setAddPost } from '../reducer';
import { sendFile } from '../../../core/api/sendFile';
import { TPost } from '../TPost';
import { TContentFile } from '../../../types/TContent';
import { ErrorProps } from '../../../core/api/errorServeApi';

const prepareData = (
  payload: TPost & {
    leftFreeFiles: TContentFile[];
    leftPayedFiles: TContentFile[];
  },
): FormData => {
  const data = new FormData();
  if (payload.subject) {
    data.append('subject', payload.subject.trim());
  }
  if (payload.message) {
    data.append('message', payload.message.trim());
  }
  if (payload.messageChat) {
    data.append('messageChat', payload.messageChat);
  }
  if (payload.messagePayed) {
    data.append('messagePayed', payload.messagePayed);
  }
  if (payload.payButtonText) {
    data.append('payButtonText', payload.payButtonText);
  }
  if (payload.showFanterVip) {
    data.append('showFanterVip', payload.showFanterVip.toString());
  }
  if (payload.showSubscribers) {
    data.append('showSubscribers', payload.showSubscribers.toString());
  }
  if (payload.price) {
    data.append('price', payload.price.toString());
  }
  if (payload.private) {
    data.append('private', payload.private.toString());
  }
  if (['dev.fanter.com', 'localhost'].includes(window.location.hostname)) {
    data.append('isDev', '1');
  }
  if (payload.editId) {
    data.append('editId', payload.editId);
  }
  payload.freeFiles.forEach((file) =>
    data.append('freeFiles[]', file, file.name),
  );
  payload.payedFiles.forEach((file) =>
    data.append('payedFiles[]', file, file.name),
  );
  payload.leftFreeFiles.forEach((_) => data.append('leftFreeFiles[]', _.path));
  payload.leftPayedFiles.forEach((_) =>
    data.append('leftPayedFiles[]', _.path),
  );

  return data;
};

export function* workerAddPost({
  payload,
}: ReturnType<typeof fetchAddPost>): SagaIterator<void> {
  console.log({ payload });
  let receivedData: FetchedData<boolean> = yield call(genFetchedData, null);
  receivedData = receivedData.set('isLoading', true);

  try {
    yield put(setAddPost(receivedData));
    yield call(getToken);
    const res: SagaReturnType<typeof addPost> = yield call(addPost);
    const data = prepareData(payload);
    yield call(sendFile, res.token, data);
    receivedData = receivedData.set('data', true);
  } catch (err) {
    const error = err as ErrorProps;
    receivedData = receivedData.set('error', {
      isError: true,
      message: error.message,
      code: error.code,
    });
    yield put(setAddPost(receivedData));
  } finally {
    receivedData = receivedData.set('isLoading', false).set('LTU', Date.now());
    yield put(setAddPost(receivedData));
  }
}

export function* workerEditPost({
  payload,
}: ReturnType<typeof fetchEditPost>): SagaIterator<void> {
  let receivedData: FetchedData<boolean> = yield call(genFetchedData, null);
  receivedData = receivedData.set('isLoading', true);

  try {
    yield put(setAddPost(receivedData));
    yield call(getToken);
    const res: SagaReturnType<typeof editPost> = yield call(editPost);
    const data = prepareData(payload);
    yield call(sendFile, res.token, data);
    receivedData = receivedData.set('data', true);
  } catch (err) {
    const error = err as ErrorProps;
    receivedData = receivedData.set('error', {
      isError: true,
      message: error.message,
      code: error.code,
    });
    yield put(setAddPost(receivedData));
  } finally {
    receivedData = receivedData.set('isLoading', false).set('LTU', Date.now());
    yield put(setAddPost(receivedData));
  }
}

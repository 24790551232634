import 'swiper/swiper-bundle.css';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/navigation/navigation.scss';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { isDesktop } from 'react-device-detect';
import { TContentFile } from '../../../types/TContent';
import { Slide } from '../Slide/Slide';

SwiperCore.use([Navigation, Pagination]);

interface IProps {
  files: TContentFile[];
  isFullScreen?: boolean;
  onClickSlide?: () => void;
  onSlideChange?: (index: number) => void;
  scrollToIndex?: number;
  clickedSlideIndex?: number;
  forceScrollToFirst?: boolean;
  onOutsideClick: () => void;
}

export function SwiperWrapper({
  files,
  isFullScreen,
  onClickSlide,
  onSlideChange,
  scrollToIndex,
  clickedSlideIndex,
  forceScrollToFirst,
  onOutsideClick,
}: IProps): JSX.Element {
  const swiper = useRef<SwiperCore>();
  const [isDetached, setDetached] = useState<boolean>(false);
  const [allowSwipe, setAllowSwipe] = useState<boolean>(true);

  useEffect(() => {
    if (
      scrollToIndex !== undefined &&
      swiper.current &&
      swiper.current.activeIndex !== scrollToIndex
    ) {
      try {
        swiper.current.slideTo(scrollToIndex, 0);
      } catch (error) {
        console.error(error);
      }
    }
  }, [scrollToIndex]);

  useEffect(() => {
    swiper.current?.updateSize();
    swiper.current?.update();
    if (
      forceScrollToFirst &&
      isFullScreen &&
      swiper.current &&
      swiper.current.activeIndex !== 0
    ) {
      try {
        swiper.current.slideTo(0, 0);
      } catch (error) {
        console.error(error);
      }
    }
  }, [isFullScreen]);

  const onInitHandler = useCallback((_swiper: SwiperCore): void => {
    if (scrollToIndex) {
      _swiper.slideTo(scrollToIndex, 0);
    }
  }, []);

  const onSwiperHandler = useCallback((_swiper: SwiperCore): void => {
    swiper.current = _swiper;
    _swiper.update();
  }, []);

  const onClickHandler = useCallback((): void => {
    if (!isFullScreen && onClickSlide) {
      onClickSlide();
    }
  }, [isFullScreen]);

  const onSwipeLeftHandler = useCallback((): void => {
    if (isFullScreen) {
      swiper.current?.slidePrev(300);
    }
  }, [isFullScreen, swiper.current]);

  const onSwipeRightHandler = useCallback((): void => {
    if (isFullScreen) {
      swiper.current?.slideNext(300);
    }
  }, [isFullScreen, swiper.current]);

  const onZoomHandler = useCallback(
    (scale: number): void => {
      if (isFullScreen) {
        if (scale > 1 && !isDetached) {
          swiper.current?.detachEvents();
          setDetached(true);
        }
        if (scale === 1 && isDetached) {
          swiper.current?.attachEvents();
          setDetached(false);
        }
      }
    },
    [isFullScreen, isDetached]
  );

  const onSlideChangeHandler = useCallback(
    (_swiper: SwiperCore): void => {
      if (onSlideChange) {
        onSlideChange(_swiper.activeIndex);
      }

      if (isFullScreen && files[_swiper.activeIndex].concept === 3) {
        swiper.current?.attachEvents();
        setDetached(false);
      }
    },
    [onSlideChange, isFullScreen, files]
  );

  return (
    <Swiper
      className={['carousel', isFullScreen ? 'fullscreen' : ''].join(' ')}
      onInit={onInitHandler}
      onSwiper={onSwiperHandler}
      onSlideChange={onSlideChangeHandler}
      allowSlidePrev={allowSwipe}
      allowSlideNext={allowSwipe}
      navigation={isDesktop && files.length > 1}
      pagination={
        files.length > 1 ? { clickable: true, type: 'bullets' } : false
      }
    >
      {files.map((file, index) => (
        <SwiperSlide key={`${file.path}#${index}`}>
          <Slide
            file={file}
            isFullScreen={isFullScreen}
            onSwipeLeft={onSwipeLeftHandler}
            onSwipeRight={onSwipeRightHandler}
            onZoom={onZoomHandler}
            onClick={onClickHandler}
            autoPlay={clickedSlideIndex === index && file.concept === 3}
            onHoverVideoControlBar={(isHover) => {
              setAllowSwipe(!isHover);
            }}
            onOutsideClick={onOutsideClick}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AmexIcon, DiscoverIcon, MasterIcon, VisaIcon } from 'assets/cards';
import * as CardApi from 'core/FanterAPI/CardAPI';
import { getCardType, showAlert } from 'utils/utils';

interface IProps {
  className?: string;
  cardIconClassName: string;
  value: string;
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onError: (errorText?: string) => void;
  disabled?: boolean;
  onlyRussian?: boolean;
}

export function CardNumberInput({
  className,
  cardIconClassName,
  value,
  onBlur,
  onChange,
  onError,
  disabled,
  onlyRussian = false,
}: IProps): JSX.Element {
  const { t } = useTranslation();

  const [checkedCardNumber, setCheckedCardNumber] = useState<string>('');
  const [isChecking, setChecking] = useState<boolean>(false);

  const cardIcon: JSX.Element | null = useMemo(() => {
    if (value?.startsWith('4')) {
      return <VisaIcon className={cardIconClassName} />;
    }

    if (value?.startsWith('51') || value?.startsWith('55')) {
      return <MasterIcon className={cardIconClassName} />;
    }
    if (value?.startsWith('34') || value?.startsWith('37')) {
      return <AmexIcon className={cardIconClassName} />;
    }
    if (
      value?.startsWith('6011') ||
      value?.startsWith('64') ||
      value?.startsWith('65') ||
      getCardType(value) === 'discover'
    ) {
      return <DiscoverIcon className={cardIconClassName} />;
    }

    return null;
  }, [value]);

  const checkCardNumber = async (cN: string): Promise<void> => {
    try {
      const isR = await CardApi.isRussian(cN);
      if ((isR && !onlyRussian) || (!isR && onlyRussian)) {
        if (!onlyRussian) {
          showAlert(
            t('AccountBilling.enterAnotherCard'),
            'AccountBilling.enterAnotherCard',
            'error'
          );
          onError(t('AccountBilling.WeNotAcceptingRussianCard'));
        } else {
          showAlert(
            t('AccountBilling.enterRussianCard'),
            'AccountBilling.enterRussianCard',
            'error'
          );
          onError(t('AccountBilling.enterRussianCard'));
        }
      }
    } catch (ignore) {
      showAlert(
        t('AccountBilling.enterAnotherCard'),
        'AccountBilling.enterAnotherCard',
        'error'
      );
      onError(t('AccountBilling.InvalidCardNumber'));
    }
  };

  useEffect(() => {
    const cN = value
      ? value
          .replace(/[^0-9]/gi, '')
          .replace(/\s/g, '')
          .trim()
      : '';
    const isTestCard = cN.startsWith('0');

    if (
      cN.length >= 6 &&
      !isTestCard &&
      cN.substring(0, 6) !== checkedCardNumber.substring(0, 6) &&
      !isChecking
    ) {
      setChecking(true);
      setCheckedCardNumber(cN);
      checkCardNumber(cN).finally(() => setChecking(false));
    }

    if (cN.length < 6) {
      onError(undefined);
      setCheckedCardNumber('');
    }
  }, [value, isChecking, checkedCardNumber]);

  return (
    <div className={className}>
      <input
        id="cardNumber"
        placeholder="XXXX XXXX XXXX XXXX"
        type="text"
        maxLength={19}
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        disabled={disabled}
      />
      {cardIcon}
    </div>
  );
}

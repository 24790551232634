import React from 'react';

interface IProps {
  className?: string;
  onClick?: () => void;
}

function SecureEmailIcon(props: IProps): JSX.Element {
  return <div {...props}>
    <svg
      width="68"
      height="57"
      viewBox="0 0 68 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.7826 37.1652L2.41739 54.487M11.4783 21.4783H3.3913C2.62609 21.4783 2 22.087 2 22.8348V53.4957C2 54.2435 2.62609 54.8522 3.3913 54.8522H53.4783C54.2435 54.8522 54.8696 54.2435 54.8696 53.4957V34.9565M40.9565 34.487L34.7826 40.2609C34.2783 40.713 33.6348 40.9565 32.9739 40.9565H23.8957C23.2348 40.9565 22.5913 40.713 22.087 40.2609L2.41739 21.8609M38.087 37.1652L54.4522 54.487M27.0435 9.70435V19.3391M22.8696 12.1043L31.2174 16.9391M31.2174 12.1043L22.8696 16.9391M40.9565 9.70435V19.3391M36.7826 12.1043L45.1304 16.9391M45.1304 12.1043L36.7826 16.9391M54.8696 9.70435V19.3391M50.6957 12.1043L59.0435 16.9391M59.0435 12.1043L50.6957 16.9391M15.913 6.17391V22.8696C15.913 25.1826 17.7739 27.0435 20.087 27.0435H46.5217V35.3913L54.5217 27.0435H61.8261C64.1391 27.0435 66 25.1826 66 22.8696V6.17391C66 3.86087 64.1391 2 61.8261 2H20.087C17.7739 2 15.913 3.86087 15.913 6.17391Z"
        stroke="#413E93"
        strokeWidth="2.78261"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
}

export default SecureEmailIcon;

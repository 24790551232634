import React from 'react';

interface IProps {
  className?: string;
  onClick?: () => void;
}

function Icon(props: IProps): JSX.Element {
  return <div {...props}>
    <svg
      width="46"
      height="51"
      viewBox="0 0 46 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.97817 50.1539H11.9345V26.9388H17.5476V21.0805H11.7438V19.1459C11.7438 17.0751 12.8882 15.9307 15.504 15.9307C16.2942 15.9307 17.0843 15.9852 17.6565 16.0942V10.8354C16.7301 10.6446 15.068 10.4812 13.5966 10.4812C6.6757 10.4812 3.97817 13.1514 3.97817 18.8735V21.0805H0V26.9388H3.97817V50.1539Z"
        fill="url(#paint0_linear_5198:920)"
      />
      <path
        d="M31.4984 44.8678C28.8009 44.8678 27.0025 43.5054 27.0025 41.3256C27.0025 39.2548 28.6919 37.9196 31.6619 37.7017L37.8199 37.3202V39.4455C37.8199 42.6063 34.9589 44.8678 31.4984 44.8678ZM28.8826 50.6171C32.5338 50.6171 36.1578 48.7915 37.7926 45.6853H37.9561V50.1539H45.64V30.0723C45.64 24.1868 40.7626 20.3176 33.2695 20.3176C25.5312 20.3176 20.7083 24.214 20.4086 29.8816H27.6837C28.0652 27.729 29.9453 26.2576 32.9153 26.2576C35.9126 26.2576 37.8199 27.838 37.8199 30.5627V32.4973L30.463 32.9333C23.1878 33.3693 19.1007 36.4483 19.1007 41.7616C19.1007 47.0204 23.3241 50.6171 28.8826 50.6171Z"
        fill="url(#paint1_linear_5198:920)"
      />
      <path
        d="M39.1587 17.2944L33.8274 13.8541L28.4703 17.2557L29.9404 10.8627L25.206 6.5633L31.4473 6.05634L33.8753 0L36.2628 6.07569L42.5004 6.63296L37.7365 10.8937L39.1587 17.2944Z"
        fill="url(#paint2_linear_5198:920)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5198:920"
          x1="-19"
          y1="12"
          x2="57"
          y2="60"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7359D9" />
          <stop offset="1" stopColor="#2F2693" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_5198:920"
          x1="-19"
          y1="12"
          x2="57"
          y2="60"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7359D9" />
          <stop offset="1" stopColor="#2F2693" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_5198:920"
          x1="-19"
          y1="12"
          x2="57"
          y2="60"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7359D9" />
          <stop offset="1" stopColor="#2F2693" />
        </linearGradient>
      </defs>
    </svg>
  </div>
}

export default Icon;

import { SagaIterator } from 'redux-saga';
import {
  put,
  call,
  SagaReturnType,
  select,
  cancelled,
} from 'redux-saga/effects';

import { FetchedData, genFetchedData } from '../../../core/api/fetchedData';
import { getToken } from '../../../core/sagas/helper';
import { RootState } from '../../../core/rootReducer';

import {
  NotificationsState,
  setHaveUnread,
  setNotifications,
} from '../reduser';
import { getList, haveUnread } from '../api';
import { fetchGetNotifications } from '../actions';
import { TNotification } from '../types/TNotification';
// import { setBusinessRequestStatus, setBusinessStatus } from '../../user/reduser';
import { workerFetchGetProfileNotReload } from '../../user/sagas/workers';
import { ErrorProps } from '../../../core/api/errorServeApi';

const getNotificationsListFromState = ({
  notificationsReducer,
}: RootState): NotificationsState['notifications'] =>
  notificationsReducer.notifications;

export function* workerFetchGetNotifications({
  payload,
}: ReturnType<typeof fetchGetNotifications>): SagaIterator<void> {
  const { reload } = payload;
  const oldNotifications: NotificationsState['notifications'] = yield select(
    getNotificationsListFromState
  );
  const oldData = oldNotifications.get('data');

  yield call(getToken);
  let receivedData: FetchedData<TNotification[]> = yield call(
    genFetchedData,
    oldData
  );

  receivedData = receivedData.set('isLoading', true);
  yield put(setNotifications(receivedData));

  const data = payload;
  data.options.limit = data.options.limit
    ? data.options.limit + 1
    : data.options.limit;
  delete data.reload;

  try {
    const result: SagaReturnType<typeof getList> = yield call(getList, data);
    if (data.options.limit && result.count > data.options.limit - 1) {
      result.items.pop();
    }

    const newData = [...(reload ? [] : oldData ?? []), ...result.items]
      .sort((a, b) => +b.tStamp - +a.tStamp)
      .filter(
        (thing, index, self) =>
          index === self.findIndex((t) => t.id === thing.id)
      );

    const unread = newData.filter((_) => _.tStampReaded === '0');
    /* eslint-disable-next-line */
    for (const notification of unread) {
      if (
        notification.notifyType === '6' ||
        notification.notifyType === '7' ||
        notification.notifyType === '8'
      ) {
        yield call(workerFetchGetProfileNotReload);
      }
      // if (notification.notifyType === "6") {
      //     yield put(setBusinessStatus(genFetchedData<boolean>(true)));
      //     yield put(setBusinessRequestStatus(genFetchedData<boolean>(false)));
      //     break;
      // }
      // if (notification.notifyType === "7") {
      //     yield put(setBusinessStatus(genFetchedData<boolean>(false)));
      //     yield put(setBusinessRequestStatus(genFetchedData<boolean>(false)));
      //     break;
      // }
    }

    receivedData = receivedData
      .set(
        'hasMore',
        payload.options.direction === 'forward' && oldData !== null
          ? oldNotifications.get('hasMore')
          : !!data.options.limit && result.count > data.options.limit - 1
      )
      .set('data', newData)
      .set('isLoading', false);

    yield put(setNotifications(receivedData));
  } catch (error) {
    const e = error as ErrorProps;
    console.error(e);
    receivedData = receivedData.set('error', {
      isError: true,
      message: e.message,
      code: e?.code,
    });
    yield put(setNotifications(receivedData));
  } finally {
    const iscancelled = yield cancelled();

    if (!iscancelled) {
      receivedData = receivedData
        .set('isLoading', false)
        .set('LTU', Date.now());
      yield put(setNotifications(receivedData));
    }
  }
}

export function* workerHaveUnread(): SagaIterator<void> {
  let receivedData: FetchedData<boolean> = yield call(genFetchedData, null);
  try {
    yield call(getToken);
    const res: SagaReturnType<typeof haveUnread> = yield call(haveUnread);
    receivedData = receivedData.set('data', res.haveUnread);
    if (res.haveUnread) {
      const oldNotifications: NotificationsState['notifications'] =
        yield select(getNotificationsListFromState);
      const oldData = oldNotifications.get('data');
      const lastId = oldData !== null ? +oldData[0].id : undefined;

      if ((res.lastId && lastId && lastId < +res.lastId) || !lastId) {
        yield call(
          workerFetchGetNotifications,
          fetchGetNotifications({
            options: {
              limit: lastId ? 1000 : 10,
              offset: lastId ? lastId + 1 : undefined,
              order: 'desc',
              direction: 'forward',
            },
          })
        );
      }
    }
  } catch (error) {
    const e = error as ErrorProps;
    console.error(e);
    receivedData = receivedData.set('error', {
      isError: true,
      message: e.message,
      code: e?.code,
    });
    yield put(setHaveUnread(receivedData));
  } finally {
    yield put(setHaveUnread(receivedData));
  }
}

import React from 'react';

interface IProps {
  className?: string;
  onClick?: () => void;
}

function Icon(props: IProps): JSX.Element {
  return <div {...props}>
    <svg
      width="37"
      height="26"
      viewBox="0 0 37 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.7847 14.3013C21.598 14.3013 19.8206 12.5529 19.8206 10.4018C19.8206 8.25059 21.598 6.50221 23.7847 6.50221C25.2066 6.50221 26.489 7.22812 27.2134 8.44535C27.5824 9.06503 27.3664 9.86176 26.7365 10.2247C26.1065 10.5877 25.2966 10.3752 24.9276 9.75553C24.6847 9.34389 24.2572 9.10044 23.7847 9.10044C23.0558 9.10044 22.4619 9.68471 22.4619 10.4018C22.4619 11.1188 23.0558 11.7031 23.7847 11.7031C24.5137 11.7031 25.1076 12.2829 25.1076 13.0044C25.1076 13.7259 24.5182 14.3013 23.7847 14.3013Z"
        fill="#413E93"
      />
      <path
        d="M23.7847 19.4978C22.3629 19.4978 21.0805 18.7719 20.3561 17.5546C19.9871 16.935 20.2031 16.1382 20.833 15.7753C21.463 15.4123 22.2729 15.6248 22.6419 16.2445C22.8848 16.6561 23.3123 16.8996 23.7847 16.8996C24.5137 16.8996 25.1076 16.3153 25.1076 15.5982C25.1076 14.8812 24.5137 14.2969 23.7847 14.2969C23.0558 14.2969 22.4619 13.7171 22.4619 12.9956C22.4619 12.2741 23.0513 11.6942 23.7847 11.6942C25.9715 11.6942 27.7489 13.4426 27.7489 15.5938C27.7489 17.7494 25.9715 19.4978 23.7847 19.4978Z"
        fill="#413E93"
      />
      <path
        d="M23.7847 9.10044C23.0558 9.10044 22.4619 8.5206 22.4619 7.79911V6.49778C22.4619 5.78073 23.0513 5.19646 23.7847 5.19646C24.5137 5.19646 25.1076 5.7763 25.1076 6.49778V7.79911C25.1076 8.51617 24.5182 9.10044 23.7847 9.10044Z"
        fill="#413E93"
      />
      <path
        d="M23.7847 20.7991C23.0558 20.7991 22.4619 20.2193 22.4619 19.4978V18.1965C22.4619 17.4794 23.0513 16.8951 23.7847 16.8951C24.5137 16.8951 25.1076 17.475 25.1076 18.1965V19.4978C25.1076 20.2148 24.5182 20.7991 23.7847 20.7991Z"
        fill="#413E93"
      />
      <path
        d="M23.7847 26C16.4999 26 10.5695 20.1706 10.5695 13C10.5695 5.82942 16.4999 0 23.7847 0C31.0696 0 37 5.82942 37 13C37 20.1706 31.0741 26 23.7847 26ZM23.7847 2.59823C17.9578 2.59823 13.2152 7.26353 13.2152 12.9956C13.2152 18.7276 17.9578 23.3929 23.7847 23.3929C29.6117 23.3929 34.3542 18.7276 34.3542 12.9956C34.3542 7.26353 29.6162 2.59823 23.7847 2.59823Z"
        fill="#413E93"
      />
      <path
        d="M14.7991 22.1004H5.3345C4.60556 22.1004 4.01162 21.5206 4.01162 20.7991C4.01162 20.0821 4.60107 19.4978 5.3345 19.4978H14.7991C15.528 19.4978 16.122 20.0776 16.122 20.7991C16.122 21.5162 15.5325 22.1004 14.7991 22.1004Z"
        fill="#413E93"
      />
      <path
        d="M12.1848 11.6987H1.32287C0.589444 11.6987 0 11.1188 0 10.3973C0 9.67586 0.589444 9.09602 1.32287 9.09602H12.1848C12.9138 9.09602 13.5077 9.67586 13.5077 10.3973C13.5077 11.1188 12.9138 11.6987 12.1848 11.6987Z"
        fill="#413E93"
      />
      <path
        d="M12.1848 16.8996H7.97575C7.24682 16.8996 6.65287 16.3197 6.65287 15.5982C6.65287 14.8812 7.24232 14.2969 7.97575 14.2969H12.1803C12.9093 14.2969 13.5032 14.8767 13.5032 15.5982C13.5077 16.3153 12.9138 16.8996 12.1848 16.8996Z"
        fill="#413E93"
      />
      <path
        d="M14.7991 6.49779H9.25112C8.52219 6.49779 7.92825 5.91794 7.92825 5.19646C7.92825 4.47497 8.51769 3.89513 9.25112 3.89513H14.8036C15.5325 3.89513 16.1265 4.47497 16.1265 5.19646C16.1265 5.91794 15.5325 6.49779 14.7991 6.49779Z"
        fill="#413E93"
      />
      <path
        d="M3.96413 6.49779H1.32287C0.589444 6.49779 0 5.91794 0 5.20089C0 4.48383 0.589444 3.89956 1.32287 3.89956H3.96413C4.69306 3.89956 5.287 4.4794 5.287 5.20089C5.287 5.92237 4.69306 6.49779 3.96413 6.49779Z"
        fill="#413E93"
      />
    </svg>
  </div>
}

export default Icon;

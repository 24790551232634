import React from 'react';

interface IProps {
  className?: string;
  onClick?: () => void;
}

function AmexIcon(props: IProps): JSX.Element {
  return <div {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="65"
      height="16"
      fill="none"
      viewBox="0 0 65 16"
    >
      <path
        fill="#008BB6"
        d="M41.3 15.9v-1.5s-.1-1.2-1.3-1.2h-2v2.7h-2V8.3h4.9s2.5-.3 2.5 2.2c0 1.3-1.1 1.8-1.1 1.8s1 .4 1 1.7v1.9h-2zM38 11.4h2.1c.6 0 1.1-.3 1.1-.7 0-.4-.5-.7-1.1-.7H38v1.4zm-10.1 4.5h-1.8L24 13.6l-2.1 2.3h-7.5V8.2h7.3l2.3 2.4 2.3-2.4h6.4s2.5-.3 2.5 2.2c0 2.3-.8 3-3.4 3h-2v2.5h-1.9zm-2.6-3.8l2.5 2.7V9.5l-2.5 2.6zm-8.9 2.1h4.2l2-2.1-2-2.1h-4.2v1.2h4.1v1.7h-4.1v1.3zm13.4-2.8h2.1c.6 0 1.1-.3 1.1-.7 0-.4-.5-.7-1.1-.7h-2.1v1.4zM55 15.9h-3.6v-1.8h3.1s1.1.1 1.1-.6-1.7-.6-1.7-.6-2.8.2-2.8-2.3 2.5-2.4 2.5-2.4h3.9V10h-3.1s-1.1-.2-1.1.5c0 .6 1.5.5 1.5.5s3.1-.2 3.1 2.1c0 2.5-2 2.7-2.7 2.7-.1.1-.2.1-.2.1zm-10.8 0V8.3h6.3v1.8h-4.2v1.2h4.1V13h-4.1v1.3h4.2V16h-6.3v-.1zm17.8 0h-3.6v-1.8h3.1s1.1.1 1.1-.6-1.7-.6-1.7-.6-2.8.2-2.8-2.3 2.5-2.4 2.5-2.4h3.8V10h-3.1s-1.1-.2-1.1.5c0 .6 1.5.5 1.5.5s3.1-.2 3.1 2.1c0 2.5-2 2.7-2.7 2.7 0 .1-.1.1-.1.1zM34 7.7V6.2S33.9 5 32.7 5h-2v2.7h-2V.1h4.9s2.5-.3 2.5 2.2c0 1.3-1.1 1.8-1.1 1.8s1 .4 1 1.7v1.9h-2zm-3.3-4.5h2.1c.6 0 1.1-.3 1.1-.7 0-.4-.5-.7-1.1-.7h-2.1v1.4zm27 4.5l-3.2-5.1v5.1h-3.9l-.7-1.6h-3.7l-.7 1.6h-3.1s-2.7-.4-2.7-3.6c0-4.2 3.1-4.1 3.2-4.1l2.5.1v1.6h-2s-1.3 0-1.5 1.7v.5c0 2.6 2.3 1.8 2.4 1.8L46.8 0h2.8l3 6.9V0h2.8l3.1 5V0h2v7.7h-2.8zM47.1 4.1h2l-1-2.3-1 2.3zM18 7.7V2.4l-2.6 5.3h-1.6l-2.5-5.3v5.3h-4l-.7-1.6H2.9l-.7 1.6H0L3.3.1h2.8l3.1 7v-7h3.2l2.2 4.8L16.8.1H20v7.6h-2zM3.8 4.2h2l-1-2.3-1 2.3zm17.6 3.5V.1h6.3v1.8h-4.2v1.2h4.1v1.6h-4.1V6h4.2v1.7h-6.3zm15.6 0V0h2v7.7h-2z"
      />
    </svg>
  </div>
}

export default AmexIcon;

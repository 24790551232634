import React from 'react';
import classnames from 'classnames';

import cn from './Loader.module.scss';

interface IProps {
  className?: string;
}

export function Loader({ className }: IProps): JSX.Element {
  return <div className={classnames(cn.ldsEllipsis, className)}>
    <div />
    <div />
    <div />
    <div />
  </div>
}

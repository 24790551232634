import React from 'react';

interface IProps {
  className?: string;
  onClick?: () => void;
}

function OkCircleIcon(props: IProps): JSX.Element {
  return <div {...props}>
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.54 5.0925L6.3225 8.3175L5.085 7.08C5.01777 7.00149 4.93503 6.93772 4.84198 6.8927C4.74893 6.84769 4.64758 6.82239 4.54429 6.8184C4.441 6.81441 4.338 6.83182 4.24176 6.86952C4.14552 6.90723 4.05811 6.96442 3.98501 7.03751C3.91192 7.1106 3.85473 7.19801 3.81702 7.29426C3.77932 7.3905 3.76191 7.4935 3.7659 7.59679C3.76989 7.70008 3.79519 7.80143 3.84021 7.89448C3.88523 7.98753 3.94899 8.07026 4.0275 8.1375L5.79 9.9075C5.86008 9.97701 5.94319 10.032 6.03457 10.0693C6.12595 10.1066 6.2238 10.1256 6.3225 10.125C6.51926 10.1242 6.70781 10.0461 6.8475 9.9075L10.5975 6.1575C10.6678 6.08778 10.7236 6.00483 10.7617 5.91343C10.7997 5.82204 10.8194 5.72401 10.8194 5.625C10.8194 5.52599 10.7997 5.42796 10.7617 5.33657C10.7236 5.24517 10.6678 5.16222 10.5975 5.0925C10.457 4.95281 10.2669 4.8744 10.0688 4.8744C9.87061 4.8744 9.68052 4.95281 9.54 5.0925ZM7.5 0C6.01664 0 4.56659 0.439867 3.33323 1.26398C2.09986 2.08809 1.13856 3.25943 0.570907 4.62987C0.00324963 6.00032 -0.145275 7.50832 0.144114 8.96318C0.433503 10.418 1.14781 11.7544 2.1967 12.8033C3.2456 13.8522 4.58197 14.5665 6.03683 14.8559C7.49168 15.1453 8.99968 14.9968 10.3701 14.4291C11.7406 13.8614 12.9119 12.9001 13.736 11.6668C14.5601 10.4334 15 8.98336 15 7.5C15 6.51508 14.806 5.53982 14.4291 4.62987C14.0522 3.71993 13.4997 2.89314 12.8033 2.1967C12.1069 1.50026 11.2801 0.947814 10.3701 0.570903C9.46019 0.193993 8.48492 0 7.5 0ZM7.5 13.5C6.31331 13.5 5.15328 13.1481 4.16658 12.4888C3.17989 11.8295 2.41085 10.8925 1.95673 9.7961C1.5026 8.69974 1.38378 7.49334 1.61529 6.32946C1.8468 5.16557 2.41825 4.09647 3.25736 3.25736C4.09648 2.41824 5.16557 1.8468 6.32946 1.61529C7.49335 1.38378 8.69975 1.5026 9.7961 1.95672C10.8925 2.41085 11.8295 3.17988 12.4888 4.16658C13.1481 5.15327 13.5 6.31331 13.5 7.5C13.5 9.0913 12.8679 10.6174 11.7426 11.7426C10.6174 12.8679 9.0913 13.5 7.5 13.5Z"
        fill="white"
      />
    </svg>
  </div>
}

export default OkCircleIcon;

import React, { useCallback, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import Modal from 'react-modal';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';
import packageJson from '../package.json';

import EventEmitter from './core/EventEmitter/EventEmitter';
import { SinglePost } from './components/SinglePost/SinglePost';
import { EnvBtn } from './components/EnvBtn/ENVBtn';
import { ChekedUser } from './domains/user/cheked/ChekedUser';
import { impersonate } from './domains/user/actions';
import { FanterRoutes } from './utils/routes';

import './normalize.css';
import './ReactToastify.css';

Modal.setAppElement('#rootModal');

console.log('App version >>> ', packageJson.version);

function AppComponent(): JSX.Element {
  const dispatch = useDispatch();

  const updateHTMLTagSize = (): void => {
    const html = window.document.getElementsByTagName('html').item(0);
    if (html) {
      html.style.height = 'unset';
      setTimeout(() => {
        html.style.height = '100%';
      }, 200);
    }
  };

  const forceUpdateSW = useCallback((activeSW: ServiceWorker | null) => {
    activeSW?.postMessage({ type: 'FORCE_UPDATE' });
  }, []);

  useEffect(() => {
    const initialLink = localStorage.getItem('INITIAL_LINK');
    if (initialLink === null) {
      localStorage.setItem('INITIAL_LINK', window.location.href);
    }

    window.addEventListener('orientationchange', updateHTMLTagSize);
    navigator?.serviceWorker?.ready.then((reg) => {
      EventEmitter.on('FORCE_UPDATE_SW', () => forceUpdateSW(reg.active));
    });

    return () => {
      window.removeEventListener('orientationchange', updateHTMLTagSize);
      EventEmitter.off('FORCE_UPDATE_SW');
    };
  }, []);

  // Check Impersonate user
  const search = queryString.parse(window.location.search);
  if (search.impersonateUserToken) {
    dispatch(
      impersonate({ impersonateToken: search.impersonateUserToken as string }),
    );
    return <></>;
  }

  // Store UTM markers to session
  Object.keys(search).forEach((key) => {
    if (
      [
        'utm_source',
        'utm_medium',
        'utm_campaign',
        'utm_term',
        'utm_content',
        'esub',
        'set',
        'land',
      ].includes(key)
    ) {
      localStorage.setItem(key, decodeURIComponent(search[key] as string));
      localStorage.setItem(`${key}_time`, new Date().getTime().toString());
    }
  });

  return (
    <>
      <EnvBtn />
      <SinglePost />
      <ChekedUser>
        <FanterRoutes />
      </ChekedUser>
      <ToastContainer
        autoClose={3000}
        progressStyle={{
          background: 'linear-gradient(270deg, #1c2478 0%, #8b7ac6 100%)',
        }}
      />
    </>
  );
}

export const App = AppComponent;

import React from 'react';

interface IProps {
  className?: string;
  onClick?: () => void;
}

function Icon(props: IProps): JSX.Element {
  return <div {...props}>
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle opacity="0.6" cx="19" cy="19" r="19" fill="black" />
      <path
        d="M14.648 11.1497L27.4512 18.5462C28.1829 18.9708 28.1829 20.0292 27.4512 20.4538L14.648 27.8503C13.9163 28.2749 13 27.7457 13 26.8965V12.1035C13 11.2543 13.9163 10.7251 14.648 11.1497Z"
        fill="white"
      />
    </svg>
  </div>
}

export default Icon;

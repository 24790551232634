import { useState, useEffect } from 'react';
import { useWindowSize } from 'react-use';

export const useIsMobile = (): boolean => {
  const { width } = useWindowSize();

  if (width < 1024) {
    return true;
  }

  return false;
};

const getOrientation = (): string => window.orientation === 90 || window.orientation === -90
    ? 'landscape'
    : 'portrait';

export const useScreenOrientation = (): number | string | undefined => {
  const [orientation, setOrientation] = useState<string>();

  const updateOrientation = (): void => {
    setOrientation(getOrientation());
  };

  useEffect(() => {
    window.addEventListener('orientationchange', updateOrientation);

    return () => {
      window.removeEventListener('orientationchange', updateOrientation);
    };
  }, []);

  return orientation;
};

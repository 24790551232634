import { cancel, fork, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { Task } from '@redux-saga/types';
import {
  canselСontent,
  fetchContentSetLike,
  fetchContentUnsetLike,
  fetchCreatePost,
  fetchSetBlocked,
  fetchSetDisplayInMainContent,
  fetchUpdatePost,
  fetchСontent,
} from '../actions';
import {
  workerFetchСontent,
  workerCreatePost,
  workerContentSetLike,
  workerContentUnsetLike,
  workerUpdatePost,
  workerSetBlocked,
  workerSetDisplayInMainContent,
} from './workers';
import { fetchSaveAvatar, fetchSaveCover } from '../../user/actions';
import { workerSaveAvatar, workerSaveCover } from '../../user/sagas/workers';

export default function* watchTimeLine(): SagaIterator {
  let contents: Task[] = [];
  yield takeEvery<typeof fetchСontent>(
    fetchСontent,
    function* (action: ReturnType<typeof fetchСontent>) {
      const f: Task = yield fork(workerFetchСontent, action);
      contents.push(f);
    }
  );
  yield takeEvery<typeof fetchCreatePost>(fetchCreatePost, workerCreatePost);
  yield takeEvery<typeof fetchContentSetLike>(
    fetchContentSetLike,
    workerContentSetLike
  );
  yield takeEvery<typeof fetchContentUnsetLike>(
    fetchContentUnsetLike,
    workerContentUnsetLike
  );
  yield takeEvery<typeof fetchUpdatePost>(
    fetchUpdatePost,
    function* (action: ReturnType<typeof fetchUpdatePost>) {
      const f: Task = yield fork(workerUpdatePost, action);
      contents.push(f);
    }
  );
  yield takeEvery<typeof fetchSaveAvatar>(fetchSaveAvatar, workerSaveAvatar);
  yield takeEvery<typeof fetchSaveCover>(fetchSaveCover, workerSaveCover);
  yield takeEvery<typeof canselСontent>(canselСontent, function* () {
    yield cancel(contents);
    contents = [];
  });
  yield takeEvery<typeof fetchSetBlocked>(fetchSetBlocked, workerSetBlocked);
  yield takeEvery<typeof fetchSetDisplayInMainContent>(
    fetchSetDisplayInMainContent,
    workerSetDisplayInMainContent
  );
}

// Кодировать строку в URL-безопасный Base64
export function encodeURLSafeBase64(str: string) {
  return btoa(str)
    .replace(/\+/g, '-') // Заменить + на -
    .replace(/\//g, '_') // Заменить / на _
    .replace(/=+$/g, ''); // Удалить = в конце
}
// Декодировать строку из URL-безопасного Base64
export function decodeURLSafeBase64(str: string) {
  // Добавить = в конец, если длина строки не кратна 4

  // const s = str.padEnd(str.length + (str.length % 4), '=');

  return atob(str);
}

import React from 'react';
import { useTranslation } from 'react-i18next';

import SetBtn from '../../../Settings/SetBtn';
import { TPaidOptions } from '../../../../types/TContent';
import { Loader } from '../../../Loader/Loader';

import { RarIcon } from '../../../../assets/post/addpost';
import { CoronaIcon, WhiteStarIcon } from '../../../../assets/post';

import cn from './PayStubBtns.module.scss';

export type PPayStubBtns = {
  paidOptions?: TPaidOptions;
  username?: string;
  payButtonText?: string;
  onClickBuy?: (event: React.MouseEvent) => void;
  onClickSubscribersVIP?: (event: React.MouseEvent) => void;
  contentBuyInProgress?: boolean;
  subscriptionBuyInProgress?: boolean;
  subscribeVipCostMonth?: number;
  trialUser?: {
    trialActive: 0 | 1;
    trialDurationDays: number;
  };
  isPayByTG: React.MutableRefObject<boolean>;
  price?: number;
};

export function PayStubBtns({
  paidOptions,
  username,
  payButtonText,
  onClickBuy,
  contentBuyInProgress,
  subscriptionBuyInProgress,
  onClickSubscribersVIP,
  subscribeVipCostMonth,
  trialUser,
  isPayByTG,
  price,
}: PPayStubBtns): JSX.Element {
  const { t } = useTranslation();

  if (trialUser?.trialActive === 1 && paidOptions?.subscribeVip) {
    return (
      <div className={cn.trial}>
        <div className={cn.trial__column}>
          {!Telegram?.WebApp?.initData && (
            <button type="button" onClick={onClickSubscribersVIP} disabled>
              {!subscriptionBuyInProgress && (
                <>
                  <span className={cn.free}>
                    {t('Post.FreeForSubscribersVIPWithTrial', {
                      count: trialUser.trialDurationDays,
                    })}
                  </span>
                  <span className={cn.then}>
                    {t('Post.ThenForSubscribersVIPWithTrial', {
                      then: subscribeVipCostMonth,
                    })}
                  </span>
                </>
              )}
              {subscriptionBuyInProgress && (
                <div className={cn.loaderContainer}>
                  <Loader />
                </div>
              )}
            </button>
          )}
          {Telegram?.WebApp?.initData && (
            <>
              <div className={cn.spase} />
              <SetBtn
                text="👛 Pay via Wallet"
                styleB="fullwidth"
                onClick={(e) => {
                  // eslint-disable-next-line no-param-reassign
                  isPayByTG.current = true;
                  onClickSubscribersVIP?.(e);
                }}
              />
            </>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className={`${cn.paydbtns}`}>
      {paidOptions?.price !== undefined && paidOptions.price !== 0 && (
        <div className={cn.paybtn}>
          {!contentBuyInProgress && (
            <>
              {!Telegram?.WebApp?.initData && (
                <SetBtn
                  onClick={onClickBuy}
                  text={payButtonText ?? ''}
                  icon={<RarIcon />}
                  disabled
                />
              )}
              {Telegram?.WebApp?.initData && (
                <>
                  <div className={cn.spase} />
                  <SetBtn
                    text={`👛 Pay via Wallet ${price}$`}
                    styleB="fullwidth"
                    onClick={(e) => {
                      // eslint-disable-next-line no-param-reassign
                      isPayByTG.current = true;
                      onClickBuy?.(e);
                    }}
                  />
                </>
              )}
            </>
          )}
          {contentBuyInProgress && (
            <div className={cn.loaderContainer}>
              <Loader />
            </div>
          )}
        </div>
      )}
      <div className={cn.vipbtns}>
        {paidOptions?.fanterVip && (
          <div className={cn.vipbtn}>
            <button type="button">
              <CoronaIcon />
              {t('Post.FreeForFanterVIP')}
            </button>
          </div>
        )}
        {paidOptions?.subscribeVip && !Telegram?.WebApp?.initData && (
          <div className={`${cn.vipbtn} ${cn.vipbtnActive}`}>
            {!subscriptionBuyInProgress && (
              <>
                <button type="button" onClick={onClickSubscribersVIP} disabled>
                  <WhiteStarIcon />
                  <div>
                    <span>
                      {t('Post.FreeForSubscribersVIP')} ${subscribeVipCostMonth}
                      /{t('AccountPaidContent.mo')}
                    </span>
                    <span>{username}</span>
                  </div>
                </button>
              </>
            )}
            {subscriptionBuyInProgress && (
              <div className={cn.loaderContainer}>
                <Loader />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

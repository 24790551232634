import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FetchedData, genFetchedData } from '../../core/api/fetchedData';
import type {
  TRcreateChat,
  TRgetChatList,
  TRgetNotified,
  TRsendMessage,
} from './api';
import { TRgetMessag } from './api';
import Notification from '../../assets/sound/Notification.mp3';
import i18n from '../../i18n';

export type TEmptyChat = {
  avatar?: string;
  uN: string;
  nickname?: string;
  hashUser: string;
  openCharacters?: boolean;
};

export interface ChatState {
  chatList: FetchedData<TRgetChatList>;
  createChat: FetchedData<TRcreateChat>;
  getNotified: FetchedData<TRgetNotified[]>;
  sendMessage: FetchedData<TRsendMessage>;
  messages: FetchedData<TRgetMessag[]>;
  unreadCount: null | number;
  thisChat: null | string;
  chatNewMessages: number;
  emptyChat: TEmptyChat | null;
}

export const initialState: ChatState = {
  chatList: genFetchedData<TRgetChatList>(null),
  createChat: genFetchedData<TRcreateChat>(null),
  getNotified: genFetchedData<TRgetNotified[]>(null),
  sendMessage: genFetchedData<TRsendMessage>(null),
  messages: genFetchedData<TRgetMessag[]>(null),
  unreadCount: null,
  thisChat: null,
  chatNewMessages: 0,
  emptyChat: JSON.parse(localStorage.getItem('emptyChat') ?? 'null'),
};

let timeoutBlink: ReturnType<typeof setInterval> | null = null;

const clearBlink = (message: string): void => {
  if (timeoutBlink !== null) {
    clearInterval(timeoutBlink);
  }
  timeoutBlink = null;
  document.title = message;
};

const blinkTab = (message: string): void => {
  const blink = (): void => {
    document.title =
      document.title === message ? i18n.t('chat.NewMessage') : message;
  };

  if (timeoutBlink !== null) {
    clearBlink(message);
  }

  timeoutBlink = setInterval(blink, 500);

  new Audio(Notification).play().then().catch(console.error);
};

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setChatList(state, action: PayloadAction<ChatState['chatList']>) {
      state.chatList = action.payload;
    },
    delChatList(state) {
      state.chatList = initialState.chatList;
    },
    setCreateChat(state, action: PayloadAction<ChatState['createChat']>) {
      state.createChat = action.payload;
    },
    delCreateChat(state) {
      state.createChat = initialState.createChat;
    },
    setGetNotified(state, action: PayloadAction<ChatState['getNotified']>) {
      state.getNotified = action.payload;
    },
    delGetNotified(state) {
      state.getNotified = initialState.getNotified;
    },
    setSendMessage(state, action: PayloadAction<ChatState['sendMessage']>) {
      state.sendMessage = action.payload;
    },
    delSendMessage(state) {
      state.sendMessage = initialState.sendMessage;
    },
    setMessages(state, action: PayloadAction<ChatState['messages']>) {
      state.messages = action.payload;
    },
    delMessages(state) {
      state.messages = initialState.messages;
    },
    clearBlinkTab(state) {
      if (state.unreadCount !== null && state.unreadCount !== 0) {
        clearBlink(`Fanter (${state.unreadCount})`);
      } else {
        clearBlink('Fanter');
      }
    },
    setUnreadCount(state, action: PayloadAction<ChatState['unreadCount']>) {
      if (
        state.unreadCount === null &&
        action.payload !== null &&
        +action.payload !== 0
      ) {
        document.title = `Fanter (${action.payload})`;
      }
      if (
        state.unreadCount !== null &&
        action.payload !== null &&
        action.payload !== 0 &&
        state.unreadCount < action.payload
      ) {
        blinkTab(`Fanter (${action.payload})`);
      }
      if (action.payload !== null && +action.payload === 0) {
        clearBlink('Fanter');
      }
      state.unreadCount = action.payload;
    },
    delUnreadCount(state) {
      state.unreadCount = initialState.unreadCount;
      clearBlink('Fanter');
    },
    setThisChat(state, action: PayloadAction<ChatState['thisChat']>) {
      state.thisChat = action.payload;
    },
    delThisChat(state) {
      state.thisChat = initialState.thisChat;
    },
    setChatNewMessages(
      state,
      action: PayloadAction<ChatState['chatNewMessages']>
    ) {
      state.chatNewMessages = action.payload;
    },
    delChatNewMessages(state) {
      state.chatNewMessages = initialState.chatNewMessages;
    },
    setEmptyChat(state, action: PayloadAction<ChatState['emptyChat']>) {
      state.emptyChat = action.payload;
      if (action.payload !== null) {
        localStorage.setItem('emptyChat', JSON.stringify(action.payload));
      }
    },
    delEmptyChat(state) {
      state.emptyChat = initialState.emptyChat;
      localStorage.removeItem('emptyChat');
    },
  },
});

export const {
  setChatList,
  delChatList,
  setCreateChat,
  delCreateChat,
  setGetNotified,
  delGetNotified,
  setSendMessage,
  delSendMessage,
  setMessages,
  delMessages,
  clearBlinkTab,
  setUnreadCount,
  delUnreadCount,
  setThisChat,
  delThisChat,
  setChatNewMessages,
  delChatNewMessages,
  setEmptyChat,
  delEmptyChat,
} = chatSlice.actions;
export const chatReducer = chatSlice.reducer;

import React from 'react';

interface IProps {
  className?: string;
  onClick?: () => void;
}

function Icon(props: IProps): JSX.Element {
  return <div {...props}>
    <svg
      width="99"
      height="98"
      viewBox="0 0 99 98"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M89.0694 26.7645C92.8611 33.3053 95 40.9042 95 48.984C95 73.8646 74.6481 94 49.5 94C24.3843 94 4 73.8646 4 48.984C4.03241 24.1674 24.4167 4 49.5324 4C58.6389 4 67.0648 6.62914 74.1944 11.15M34.3981 34.0427L64.6991 64.0214M34.3981 64.0214L64.6991 34.0427"
        stroke="#413E93"
        strokeWidth="7"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
}

export default Icon;

import { makeRequest } from './FanterAPICore';

interface IIsRussianParams {
  cardNumber: string;
}

export const isRussian = async (cardNumber: string): Promise<boolean> => makeRequest<IIsRussianParams, boolean>(
    'card.isRussian',
    { cardNumber },
    false,
    false
  );

interface IApproveCardParams {
  cardId: number;
  centsToAmount: number;
}

interface IApproveCardResponse {
  updated: boolean;
}

export const approveCard = async (
  cardId: number,
  centsToAmount: number
): Promise<IApproveCardResponse> => makeRequest<IApproveCardParams, IApproveCardResponse>(
    'card.approveCard',
    { cardId, centsToAmount }
  );

import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { CardRepeatIcon } from 'assets';
import {
  FanterModal,
  IFanterModalRef,
} from 'components/modals/FanterModal/FanterModal';
import * as CardApi from 'core/FanterAPI/CardAPI';

import cn from './ConfirmTransactionModal.module.scss';

export interface IConfirmTransactionModalRef {
  open: (cardId: number) => void;
  close: () => void;
}

interface IProps {
  onSuccess: () => void;
}

export const ConfirmTransactionModal = forwardRef(
  (
    { onSuccess }: IProps,
    ref: React.Ref<IConfirmTransactionModalRef>
  ): JSX.Element => {
    const { t } = useTranslation();

    const prefix = (0)
      .toLocaleString('en-EN', {
        maximumFractionDigits: 1,
        minimumFractionDigits: 1,
      })
      .substring(0, 2);

    const [isLoading, setLoading] = useState<boolean>(false);
    const [cardId, setCardId] = useState<number>();
    const [amount, setAmount] = useState<number>();
    const [originAmount, setOriginAmount] = useState<string>(prefix);

    const fanterModal = useRef<IFanterModalRef | null>(null);

    useImperativeHandle(ref, () => ({
      open(_cardId: number) {
        fanterModal.current?.open();
        setCardId(_cardId);
        setAmount(undefined);
        setOriginAmount(prefix);
      },
      close() {
        fanterModal.current?.close();
      },
    }));

    const onClose = (): void => {
      fanterModal.current?.close();
    };

    const formatAmount = (value?: string): number | undefined => {
      if (value === undefined) {
        return undefined;
      }
      const formattedStr = value.replace(/(\.\d{2})\d+/g, '$1');
      const formattedValue = parseFloat(formattedStr);

      return !Number.isNaN(formattedValue) ? formattedValue : undefined;
    };

    const amountInputOnChangeHandler = (
      event: React.ChangeEvent<HTMLInputElement>
    ): void => {
      setOriginAmount(`${prefix}${event.target.value}`);
      const value = formatAmount(event.target.value);

      if (value === undefined || (value >= 0 && value <= 99)) {
        setAmount(value);
      }
    };

    const amountInputOnKeyDownHandler = (
      event: React.KeyboardEvent<HTMLInputElement>
    ): boolean => {
      if (['Enter', 'Backspace'].includes(event.key)) {
        return true;
      }

      if (
        ![
          '0',
          '1',
          '2',
          '3',
          '4',
          '5',
          '6',
          '7',
          '8',
          '9',
          '.',
          ',rr',
        ].includes(event.key)
      ) {
        event.preventDefault();
        return false;
      }
      if (
        event.key === '.' &&
        (amount === undefined || originAmount?.includes('.'))
      ) {
        event.preventDefault();
        return false;
      }
      if (
        event.key === ',' &&
        (amount === undefined || originAmount?.includes(','))
      ) {
        event.preventDefault();
        return false;
      }
      if (
        event.key === '0' &&
        originAmount &&
        originAmount[0] === '0' &&
        !originAmount.includes('.') &&
        !originAmount.includes(',')
      ) {
        event.preventDefault();
        return false;
      }
      if (originAmount === '0.00' || originAmount === '0,00') {
        event.preventDefault();
        return false;
      }
      if (
        originAmount !== undefined &&
        (event.key === '.' || event.key === ',') &&
        (!originAmount.includes('.') || !originAmount.includes(','))
      ) {
        setOriginAmount(`${originAmount},`);
        return false;
      }
      if (
        originAmount !== undefined &&
        originAmount.split('.')[1] &&
        originAmount.split('.')[1].length >= 2
      ) {
        event.preventDefault();
        return false;
      }
      if (
        originAmount !== undefined &&
        originAmount.split(',')[1] &&
        originAmount.split(',')[1].length >= 2
      ) {
        event.preventDefault();
        return false;
      }

      return true;
    };

    const onClickConfirmHandler = useCallback(() => {
      if (cardId && amount !== undefined && amount >= 1 && amount <= 99) {
        setLoading(true);
        const _amount =
          amount < 10 &&
          !originAmount.startsWith('0,0') &&
          !originAmount.startsWith('0.0')
            ? amount * 10
            : amount;
        CardApi.approveCard(cardId, _amount / 100)
          .then((res) => {
            if (res.updated) {
              onSuccess();
            }
          })
          .finally(() => setLoading(false));
      }
    }, [amount, originAmount, cardId]);

    return (
      <FanterModal
        ref={fanterModal}
        onClose={onClose}
        className={cn.fModal}
        overlayClassName={cn.fModalOverlay}
      >
        <div className={cn.content}>
          <div className={cn.content__column}>
            <CardRepeatIcon className={cn.content__cardIcon} />
            <span className={cn.content__title}>
              {t('ConfirmTransactionModal.title')}
            </span>
            <span className={cn.content__text}>
              {t('ConfirmTransactionModal.text')}
            </span>
            <div className={cn.content__inputContainer}>
              <p>{prefix}</p>
              <input
                type="number"
                name="amount"
                value={amount}
                pattern="[0-9]*"
                inputMode="decimal"
                lang="en"
                step="1"
                onChange={amountInputOnChangeHandler}
                onKeyDown={amountInputOnKeyDownHandler}
                autoComplete="off"
                autoFocus
                id="ConfirmTransaction_Amount"
              />
              <span>USD</span>
            </div>
            <button
              type="button"
              className={cn.content__btn}
              onClick={onClickConfirmHandler}
              disabled={
                isLoading ||
                !(amount !== undefined && amount >= 1 && amount <= 99)
              }
            >
              {t('ConfirmModal.Confirm')}
            </button>
          </div>
        </div>
      </FanterModal>
    );
  }
);

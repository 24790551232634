import { createAction } from '@reduxjs/toolkit';
import { TPgetMessages, TPsendMessage, TPsetReaded, TRgetMessag } from './api';

export const fetchGetChatList = createAction<
  undefined,
  'chat/fetchGetChatList'
>('chat/fetchGetChatList');

export const fetchCreateChat = createAction<string, 'chat/fetchCreateChat'>(
  'chat/fetchCreateChat'
);
export const fetchGetNotified = createAction<
  undefined,
  'chat/fetchGetNotified'
>('chat/fetchGetNotified');

export const fetchSendMessage = createAction<
  TPsendMessage,
  'chat/fetchSendMessage'
>('chat/fetchSendMessage');
export const fetchGetMessages = createAction<
  TPgetMessages & { reload?: boolean },
  'chat/fetchGetMessages'
>('chat/fetchGetMessages');

export const fetchUpdateMessage = createAction<
  TPgetMessages,
  'chat/fetchUpdateMessage'
>('chat/fetchUpdateMessage');

export const fetchSetReaded = createAction<TPsetReaded, 'chat/fetchSetReaded'>(
  'chat/fetchSetReaded'
);

export const fetchGetUnreadCount = createAction<
  undefined,
  'chat/fetchGetUnreadCount'
>('chat/fetchGetUnreadCount');

export const fetchSoftUpdateChatList = createAction<
  undefined,
  'chat/softUpdateChatList'
>('chat/softUpdateChatList');

export const optimistUpdateMessage = createAction<
  TRgetMessag,
  'chat/optimistUpdateMessage'
>('chat/optimistUpdateMessage');

export const fetchGetCollocutor = createAction<
  string,
  'chat/fetchGetCollocutor'
>('chat/fetchGetCollocutor');

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FetchedData, genFetchedData } from '../../core/api/fetchedData';
import { PUpdatePost } from './actions';
import type { TRContent, TRContentBuy } from './api';

export interface TimelineState {
  content: FetchedData<TRContent>;
  currentIdVideo: string;
  isPriorityVideo: boolean;
  createPost: FetchedData<boolean>;
  mCreatPost: boolean;
  contentBuy: FetchedData<TRContentBuy, PUpdatePost>;
}
export const initialState: TimelineState = {
  content: genFetchedData<TRContent>(null),
  currentIdVideo: '',
  isPriorityVideo: false,
  createPost: genFetchedData<boolean>(null),
  mCreatPost: false,
  contentBuy: genFetchedData<TRContentBuy, PUpdatePost>(null),
};
const timelineSlice = createSlice({
  name: 'timeline',
  initialState,
  reducers: {
    setContent(state, action: PayloadAction<TimelineState['content']>) {
      state.content = action.payload;
    },
    delContent(state) {
      state.content = initialState.content;
    },
    setCurrentIdVideo(
      state,
      action: PayloadAction<TimelineState['currentIdVideo']>
    ) {
      state.currentIdVideo = action.payload;
    },
    delCurrentIdVideo(state) {
      state.currentIdVideo = initialState.currentIdVideo;
    },
    setIsPriorityVideo(
      state,
      action: PayloadAction<TimelineState['isPriorityVideo']>
    ) {
      state.isPriorityVideo = action.payload;
    },
    setCreatePost(state, action: PayloadAction<TimelineState['createPost']>) {
      state.createPost = action.payload;
    },
    delCreatePost(state) {
      state.createPost = initialState.createPost;
    },
    setMCreatPost(state, action: PayloadAction<TimelineState['mCreatPost']>) {
      state.mCreatPost = action.payload;
    },
    setСontentBuy(state, action: PayloadAction<TimelineState['contentBuy']>) {
      state.contentBuy = action.payload;
    },
    delСontentBuy(state) {
      state.contentBuy = initialState.contentBuy;
    },
  },
});

export const {
  setContent,
  delContent,
  setCurrentIdVideo,
  delCurrentIdVideo,
  setIsPriorityVideo,
  setCreatePost,
  delCreatePost,
  setMCreatPost,
  setСontentBuy,
  delСontentBuy,
} = timelineSlice.actions;
export const timelineReducer = timelineSlice.reducer;

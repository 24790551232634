import React from 'react';

interface IProps {
  className?: string;
  onClick?: () => void;
}

function CopyIcon(props: IProps): JSX.Element {
  return <div {...props}>
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.3333 10.3333H14.2222C14.6518 10.3333 15 9.98511 15 9.55555V1.77778C15 1.34822 14.6518 1 14.2222 1H6.44444C6.01489 1 5.66667 1.34822 5.66667 1.77778V5.66667M9.55556 15L1.77778 15C1.34822 15 1 14.6518 1 14.2222L1 6.44444C1 6.01489 1.34822 5.66667 1.77778 5.66667H9.55556C9.98511 5.66667 10.3333 6.01489 10.3333 6.44444L10.3333 14.2222C10.3333 14.6518 9.98511 15 9.55556 15Z"
        stroke="#413E93"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
}

export default CopyIcon;

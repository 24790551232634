import React from 'react';

interface IProps {
  className?: string;
  onClick?: () => void;
}

function Icon(props: IProps): JSX.Element {
  return <div {...props}>
    <svg
      width="17"
      height="14"
      viewBox="0 0 17 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.78775 0C8.48919 0 10.1149 0.65004 11.3694 1.83125C11.7154 2.15813 11.7407 2.71531 11.4235 3.0719C11.1062 3.4285 10.5655 3.4545 10.2195 3.12762C9.27863 2.24357 8.06023 1.75696 6.78775 1.75696C3.98326 1.75696 1.69785 4.10825 1.69785 7.00186C1.69785 9.89175 3.97965 12.2467 6.78775 12.2467C8.06023 12.2467 9.27503 11.7601 10.2159 10.8798C10.5619 10.5529 11.1026 10.5789 11.4199 10.9355C11.7371 11.2921 11.7118 11.8493 11.3658 12.1762C10.1113 13.3537 8.48919 14 6.79136 14C3.04602 14 1.14441e-05 10.8612 1.14441e-05 7.00186C-0.00719643 3.13876 3.04242 0 6.78775 0Z"
        fill="white"
      />
      <path
        d="M14.4839 7.74104H6.59309C6.18936 7.74104 5.86133 7.40302 5.86133 6.987C5.86133 6.57097 6.18936 6.23295 6.59309 6.23295H14.4587L13.507 5.33033C13.2114 5.04802 13.1898 4.57257 13.4637 4.26798C13.7377 3.96339 14.1991 3.94481 14.4947 4.2234L16.7441 6.35925C16.8558 6.46697 16.9279 6.60069 16.9603 6.74556C16.9856 6.81985 17 6.90157 17 6.987C17 7.05758 16.9892 7.12444 16.9712 7.18758C16.9495 7.35474 16.8738 7.51446 16.7441 7.63704L14.4983 9.77288C14.3577 9.90661 14.1811 9.97347 14.0045 9.97347C13.8062 9.97347 13.6115 9.89175 13.4673 9.73202C13.1934 9.42743 13.2114 8.95198 13.5106 8.66967L14.4839 7.74104Z"
        fill="white"
      />
    </svg>
  </div>
}

export default Icon;

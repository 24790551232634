import React from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import BannedIcon from '../../../assets/post/BannedIcon';

import cn from './style.module.css';

interface IProps {
  className?: string;
}

export function BannedInfo({ className }: IProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className={classnames(cn.banned, className)}>
      <div className={cn.banned_tit}>
        <BannedIcon />
        {t('Post.Blocked')}
      </div>
    </div>
  );
}

import React, { useCallback, useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';

import * as FanterApi from '../../core/FanterAPI/FanterAPI';
import { TContent } from '../../types/TContent';
import { Post } from '../Post/Post';

import cn from './SinglePost.module.scss';
import { ArrowIcon } from '../../assets';
import {
  bodyAddNoScroll,
  bodyRemoveNoScroll,
} from '../../helper/domManipulation';

export function SinglePost(): JSX.Element {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const [isOpen, setOpen] = useState<boolean>(false);
  const [post, setPost] = useState<TContent>();

  const onClose = useCallback((): void => {
    setOpen(false);
    setPost(undefined);
    const search = queryString.parse(location.search);
    delete search.postId;
    history.push({ search: queryString.stringify(search) });
  }, [isOpen]);

  const fetchPost = async (postId: number) => {
    const res = await FanterApi.getPost(postId, false);
    console.debug(res);
    if (
      res.items[0]?.id === postId.toString() &&
      res.items[0]?.isBlocked !== '1'
    ) {
      setPost(res.items[0]);
      setOpen(true);
    } else {
      const resPrivate = await FanterApi.getPost(+postId, true);
      console.debug(resPrivate);
      if (
        resPrivate.items[0]?.id === postId.toString() &&
        resPrivate.items[0]?.isBlocked !== '1'
      ) {
        setPost(resPrivate.items[0]);
        setOpen(true);
      } else {
        onClose();
      }
    }
  };

  const loadPost = useCallback(() => {
    const postId = queryString.parse(location.search).postId;
    if (postId) {
      fetchPost(+postId).then();
    } else {
      onClose();
    }
  }, [location.search, onClose]);

  useEffect(() => {
    loadPost();
  }, [location.search]);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => bodyAddNoScroll(), 300);
    }
    if (!isOpen) {
      bodyRemoveNoScroll();
    }
  }, [isOpen]);

  return (
    <Modal className={cn.modal} overlayClassName={cn.overlay} isOpen={isOpen}>
      <button type="button" className={cn.backBtn} onClick={onClose}>
        <ArrowIcon className={cn.backBtn__icon} />
        {t('common.back')}
      </button>
      <div>
        {!!post && <Post content={post} isAuth isSingle onChanged={loadPost} />}
      </div>
    </Modal>
  );
}

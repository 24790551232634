import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Link, useHistory } from 'react-router-dom';
import TimeAgo, { LocaleData } from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import ru from 'javascript-time-ago/locale/ru';
import es from 'javascript-time-ago/locale/es';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';

import { getUserName } from '../../helper/function';
import { TContent } from '../../types/TContent';
import { TComment } from '../../types/TComment';
import { RootState } from '../../core/rootReducer';
import { UserState } from '../../domains/user/reduser';
import {
  fetchSetBlocked,
  fetchSetDisplayInMainContent,
  fetchUpdatePost,
} from '../../domains/timeLine/actions';
import * as FanterApi from '../../core/FanterAPI/FanterAPI';
import * as ProfileApi from '../../core/FanterAPI/ProfileApi';
import * as UserApi from '../../core/FanterAPI/UserApi';

import { Part } from './Part/Part';
import { BannedInfo } from './BannedInfo/BannedInfo';
import { VideoInfo } from './VideoInfo/VideoInfo';
import { Comment } from './Comment/Comment';

import { PayStub } from './PayStub/PayStub';
import { PayStubBtns } from './PayStub/PayStubBtns/PayStubBtns';
import { useIsMobile } from '../../domains/hooks';
import { ISendTipsRef, SendTips } from '../SendTips/SendTips';
import { TProfile } from '../../types/TProfile';
import { UserAvatar } from '../UserAvatar/UserAvata';
import { publicBook } from '../../books';
import EventEmmiter from '../../core/EventEmitter/EventEmitter';
import { ErrorProps } from '../../core/api/errorServeApi';
import { showAlert } from '../../utils/utils';

import {
  ApproveIcon,
  BlockBtnIcon,
  DisplayInMainContentIcon,
  EditIcon,
  NegativeIcon,
  RestoreBtnIcon,
} from '../../assets/post';
import IsBusinessIcon from '../../assets/profile/IsBusinessIcon';

import cn from './Post.module.scss';
import {
  BuyContentErrorModal,
  IBuyContentErrorModalRef,
} from '../modals/BuyContentErrorModal/BuyContentErrorModal';
import { TFilter } from '../../domains/timeLine/TFilter';
import { MoreActions } from './MoreActions/MoreActions';
import { PAYMENT_ERRORS } from '../../ListErrors';

import { contentBuyTG, profileSubscribeVip } from '../../core/api/payWallet';
import {
  ConfirmModal,
  IConfirmModelRef,
} from '../modals/ConfirmModal/ConfirmModal';
import { FanterModal } from '../modals/FanterModal/FanterModal';
import { Loader } from '../Loader/Loader';
import { openTelegramLink } from '../../core/openTelegramLink';

TimeAgo.addLocale(ru as LocaleData);
TimeAgo.addLocale(en as LocaleData);
TimeAgo.addLocale(es as LocaleData);
TimeAgo.setDefaultLocale(localStorage.i18nextLng);

interface IProps {
  content: TContent;
  noLinkProf?: boolean;
  isPrivate?: boolean;
  hashUser?: string;
  filter?: TFilter;
  isAuth?: boolean;
  isSingle?: boolean;
  onChanged?: () => void;
}

export function Post({
  content,
  noLinkProf,
  isPrivate,
  hashUser,
  filter,
  isAuth,
  isSingle,
  onChanged,
}: IProps): JSX.Element | null {
  const { t, i18n } = useTranslation();
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const history = useHistory();
  const isPayByTG = useRef(false);

  const [comments, setComments] = useState<TComment[]>([]);
  const [commentText, setCommentText] = useState<string>('');
  const [contentBuyInProgress, setContentBuyInProgress] =
    useState<boolean>(false);
  const [subscriptionBuyInProgress, setSubscriptionBuyInProgress] =
    useState<boolean>(false);
  const timeAgo = new TimeAgo(i18n.language);
  const buyContentErrorModalRef = useRef<IBuyContentErrorModalRef>(null);
  const sendTipsRef = useRef<ISendTipsRef>(null);
  const contentBuyTimer = useRef<ReturnType<typeof setInterval> | null>(null);
  const isModerationMode = useMemo(
    () => filter?.includes('moderate'),
    [filter],
  );
  const [isSendingComment, setSendingComment] = useState<boolean>(false);

  const profile = useSelector<RootState, UserState['profile']>(
    ({ userReducer }) => userReducer.profile,
    shallowEqual,
  );

  const profileData = useMemo(() => profile.get('data'), [profile]);
  const username = getUserName(
    content.firstName,
    content.lastName,
    content.nickname,
  );

  const contentOwnerProfile = {
    firstName: content.firstName,
    lastName: content.lastName,
    nickname: content.nickname,
    avatar: content.avatar,
    isBusiness: content.isBusiness,
  } as TProfile;

  const avatar = (
    <UserAvatar
      profile={contentOwnerProfile}
      className={cn.user_logo}
      size="54"
      imgSize="m"
    />
  );

  const getComments = async (contentId: string) => {
    const res = await FanterApi.getComments(contentId);
    setComments(res.items.sort((a, b) => a.createTStamp - b.createTStamp));
  };

  const stopPropagation = useCallback((e?: React.MouseEvent) => {
    e?.stopPropagation();
    e?.nativeEvent.stopImmediatePropagation();
  }, []);

  useEffect(() => {
    getComments(content.id).then();
    setContentBuyInProgress(false);
    setSubscriptionBuyInProgress(false);
  }, [content]);

  const updatePostBySubscription = useCallback(
    (contentOwnerId: string): void => {
      if (content.ownerId === contentOwnerId && !content.paidOptions?.buyed) {
        dispatch(
          fetchUpdatePost({
            id: content.id,
            private: isPrivate,
            fromUser: isPrivate ? undefined : { hashUser },
          }),
        );
      }
    },
    [content, hashUser],
  );

  const updatePost = useCallback(
    (contentId: string): void => {
      if (content.id === contentId) {
        console.debug(contentId, content.id, content.id === contentId);
        setTimeout(() => {
          dispatch(
            fetchUpdatePost({
              id: content.id,
              private: isPrivate,
              fromUser: isPrivate ? undefined : { hashUser },
            }),
          );
        }, 1500);
      }
    },
    [content, hashUser],
  );

  const updatePostComments = useCallback(() => {
    getComments(content.id).then();
  }, [content]);

  useEffect(() => {
    EventEmmiter.on('UpdatePostBySubscription', updatePostBySubscription);
    EventEmmiter.on('UpdatePost', updatePost);
    EventEmmiter.on('UpdatePostComments', updatePostComments);

    return () => {
      EventEmmiter.off('UpdatePostBySubscription', updatePostBySubscription);
      EventEmmiter.off('UpdatePostComments', updatePostComments);
    };
  }, []);

  const checkPurchased = (
    purchased: string,
    isSubscription?: boolean,
  ): void => {
    if (contentBuyTimer.current !== null) {
      clearInterval(contentBuyTimer.current);
    }

    contentBuyTimer.current = setInterval(async () => {
      try {
        const { nowState } = await FanterApi.getTransaction(purchased);

        if (nowState === '10' || nowState === '20') {
          if (isSubscription) {
            EventEmmiter.emit('UpdatePost', content.id);
            setSubscriptionBuyInProgress(false);
          } else {
            dispatch(
              fetchUpdatePost({
                id: content.id,
                private: isPrivate,
                fromUser: isPrivate ? undefined : { hashUser },
              }),
            );
            setContentBuyInProgress(false);
          }
          if (onChanged) {
            onChanged();
          }
        }
        if (contentBuyTimer.current !== null) {
          clearInterval(contentBuyTimer.current);
        }
      } catch (e) {
        console.error(e);
        if (contentBuyTimer.current !== null) {
          clearInterval(contentBuyTimer.current);
        }
      }
    }, 1000);
  };

  /** Handlers */

  const onClickBlockedBtnHandler = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.preventDefault();
      stopPropagation(event);
      dispatch(
        fetchSetBlocked({
          contentId: content.id,
          block: content.isBlocked === '1' ? 0 : 1,
          remove: filter && ['my-unmoderate', 'unmoderated'].includes(filter),
        }),
      );
      EventEmmiter.emit('UpdatePost', content.id);

      if (onChanged) {
        setTimeout(() => onChanged(), 500);
      }
    },
    [content],
  );

  const onClickDisplayInMainContent = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.preventDefault();
      stopPropagation(event);
      dispatch(
        fetchSetDisplayInMainContent({
          contentId: content.id,
          displayInMain: content.displayInMainContent === 1 ? 0 : 1,
          remove: false,
        }),
      );
      EventEmmiter.emit('UpdatePost', content.id);

      if (onChanged) {
        setTimeout(() => onChanged(), 500);
      }
    },
    [content],
  );

  const onClickApproveBtnHandler = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.preventDefault();
      stopPropagation(event);
      dispatch(
        fetchSetBlocked({
          contentId: content.id,
          block: 2,
          remove: filter && ['my-unmoderate', 'unmoderated'].includes(filter),
        }),
      );
      EventEmmiter.emit('UpdatePost', content.id);

      if (onChanged) {
        setTimeout(() => onChanged(), 500);
      }
    },
    [content],
  );

  const onClickNegativeBtnHandler = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.preventDefault();
      stopPropagation(event);
      dispatch(
        fetchSetBlocked({
          contentId: content.id,
          block: 3,
          remove: filter && ['my-unmoderate', 'unmoderated'].includes(filter),
        }),
      );
      EventEmmiter.emit('UpdatePost', content.id);

      if (onChanged) {
        setTimeout(() => onChanged(), 500);
      }
    },
    [content],
  );

  const onClickEditBtnHandler = useCallback(
    (event: React.MouseEvent) => {
      stopPropagation(event);
      history.push(`/post/${content.id}`);
    },
    [content],
  );

  const confirmModalRef = useRef<IConfirmModelRef | null>(null);

  const refTimerUpdatePost = useRef<NodeJS.Timeout>();
  const onListeningPost = () => {
    confirmModalRef?.current?.open();

    dispatch(
      fetchUpdatePost({
        id: content.id,
        private: isPrivate,
        fromUser: isPrivate ? undefined : { hashUser },
      }),
    );

    refTimerUpdatePost.current = setInterval(() => {
      dispatch(
        fetchUpdatePost({
          id: content.id,
          private: isPrivate,
          fromUser: isPrivate ? undefined : { hashUser },
        }),
      );
    }, 2000);
  };

  const onStopListeningPost = () => {
    clearInterval(refTimerUpdatePost.current);
    confirmModalRef?.current?.close();
  };

  useEffect(() => {
    onStopListeningPost();
  }, [
    content.id,
    isPrivate,
    content.paidOptions?.payed,
    content.paidOptions?.buyed,
  ]);

  useEffect(
    () => () => {
      clearInterval(refTimerUpdatePost.current);
    },
    [],
  );

  const onClickBuy = useCallback(
    async (event?: React.MouseEvent) => {
      stopPropagation(event);
      if (!isAuth) {
        history.push(
          `${publicBook.signIn}?u=${
            content.nickname
          }${window.location.search.substring(1)}`,
        );
        return;
      }

      setContentBuyInProgress(true);
      try {
        let purchased = '';
        if (isPayByTG.current) {
          const { payHash, directPayLink } = await contentBuyTG({
            contentId: +content.id,
          });
          purchased = payHash;
          openTelegramLink(directPayLink);
          onListeningPost();
        }

        if (!isPayByTG.current) {
          const res = await FanterApi.contentBuy(+content.id);
          purchased = res.purchased;
        }
        if (purchased) {
          checkPurchased(purchased);
        }
      } catch (err) {
        const error = err as ErrorProps;
        setContentBuyInProgress(false);
        if ([303, 3081, ...PAYMENT_ERRORS].includes(error.code)) {
          buyContentErrorModalRef.current?.open('post', error);
        } else {
          showAlert(
            t('errors.anyErr', error.errorLogCode),
            error.code,
            'error',
          );
        }
        onStopListeningPost();
      } finally {
        isPayByTG.current = false;
      }
    },
    [profileData],
  );

  const buySubscriptiond = async (
    _content?: TContent | null,
  ): Promise<void> => {
    if (!_content) {
      return;
    }

    if (!isAuth) {
      history.push(
        `${publicBook.signIn}?u=${
          _content.nickname
        }&${window.location.search.substring(1)}`,
      );
      return;
    }

    if (_content?.ownerId && _content?.nickname) {
      try {
        setSubscriptionBuyInProgress(true);
        if (
          _content?.trialUser?.trialActive === 1 &&
          !_content?.subscribeVipTrialTStamp &&
          _content?.paidOptions?.subscribeVip
        ) {
          await UserApi.subscribeTrial(_content.ownerId);

          EventEmmiter.emit('UpdatePostBySubscription', content.ownerId);
          setSubscriptionBuyInProgress(false);
          // @ts-ignore
          window.dataLayer?.push({ event: 'Subscribe_trial' });
        } else {
          let purchased = '';
          if (isPayByTG.current) {
            const { payHash, directPayLink } = await profileSubscribeVip({
              hashOpponent: _content.ownerId,
              type: 'month',
            });
            purchased = payHash;
            openTelegramLink(directPayLink);
          }

          if (!isPayByTG.current) {
            const { payHash } = await ProfileApi.subscribeVip(_content.ownerId);
            purchased = payHash;
          }
          checkPurchased(purchased, true);
          // @ts-ignore
          window.dataLayer?.push({ event: 'Subscribe_vip' });
        }
      } catch (err) {
        const error = err as ErrorProps;
        setSubscriptionBuyInProgress(false);
        if (contentBuyTimer.current !== null) {
          clearInterval(contentBuyTimer.current);
        }
        if ([303, 3081, ...PAYMENT_ERRORS].includes(error.code)) {
          buyContentErrorModalRef.current?.open('subscription', error);
        } else {
          showAlert(
            t('errors.anyErr', error.errorLogCode),
            error.code,
            'error',
          );
        }
      } finally {
        isPayByTG.current = false;
      }
    }
  };

  const onChangeCommentTextHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setCommentText(event.target.value || '');
  };

  const createComment = async (
    contentId: string,
    _commentText: string,
  ): Promise<void> => {
    setSendingComment(true);
    await FanterApi.createComment(contentId, _commentText);
    setCommentText('');
    EventEmmiter.emit('UpdatePostComments', {});
    setSendingComment(false);
  };

  const onClickSubmitCommentHandler = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>): void => {
      event.preventDefault();
      stopPropagation(event);
      if (!isSendingComment) {
        createComment(content.id, commentText).then();
      }
    },
    [isSendingComment, content, commentText],
  );

  const onKeyPressCommentTextHandler = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (!isAuth) {
        history.push(
          `${publicBook.signIn}?u=${
            content.nickname
          }${window.location.search.substring(1)}`,
        );
        return;
      }

      if (event.key === 'Enter' && commentText && !isSendingComment) {
        createComment(content.id, commentText).then();
      }
    },
    [isSendingComment, content, commentText],
  );

  const tipsOnClickHandler = useCallback((): void => {
    if (!isAuth) {
      history.push(
        `${publicBook.signIn}?u=${
          content.nickname
        }${window.location.search.substring(1)}`,
      );
      return;
    }
    if (sendTipsRef.current) {
      sendTipsRef.current.open();
    }
  }, [sendTipsRef, buyContentErrorModalRef, isAuth]);

  const onSuccessSendTipsHandler = (): void => {
    // dispatch(fetchUpdatePost({id: content.id, private: isPrivate, fromUser: isPrivate ? undefined : {hashUser}}));
    EventEmmiter.emit('UpdatePost', content.id);
    if (onChanged) {
      onChanged();
    }
  };

  const onCloseBuyFailedModal = useCallback(
    // @ts-ignore
    (success, type) => {
      if (success) {
        switch (type) {
          case 'post': {
            onClickBuy().then();
            break;
          }
          case 'subscription': {
            buySubscriptiond(content).then();
            break;
          }
          case 'tips': {
            tipsOnClickHandler();
            break;
          }
          default: {
            break;
          }
        }
      }
    },
    [content, tipsOnClickHandler, onClickBuy],
  );

  const onClickBuySubscription = useCallback(
    (event?: React.MouseEvent) => {
      stopPropagation(event);
      buySubscriptiond(content).then();
    },
    [content],
  );

  const onFailedSendTips = (_error: ErrorProps) => {
    if ([303, 3081, ...PAYMENT_ERRORS].includes(_error.code)) {
      buyContentErrorModalRef.current?.open('tips', _error);
    } else {
      showAlert(t('errors.anyErr', _error.errorLogCode), _error.code, 'error');
    }
  };

  const openSingle = useCallback(() => {
    if (!isSingle && content.isBlocked !== '1') {
      history.push({ search: `?postId=${content.id}` });
    }
  }, [isSingle, content]);

  const onDeleteCommentHandler = async (commentId: string): Promise<void> => {
    const res = await FanterApi.deleteComment(commentId);

    if (res) {
      EventEmmiter.emit('UpdatePostComments', {});
      showAlert(
        t('Post.deleteCommentSuccessfully'),
        'Post.deleteCommentSuccessfully',
        'success',
      );
    }
  };

  return (
    <div
      className={`${cn.post} ${cn.blurheightimg} ${
        content.isBlocked === '1' && cn.post_isBlocked
      }`}
      onClick={openSingle}
    >
      <FanterModal
        title={t('Post.waitingPay')}
        ref={confirmModalRef}
        onClose={onStopListeningPost}
      >
        <div className={cn.contentCenter}>
          <Loader />
        </div>
      </FanterModal>
      <SendTips
        ref={sendTipsRef}
        className={cn.post_tips}
        contentId={content.id}
        onSuccess={onSuccessSendTipsHandler}
        onFailed={onFailedSendTips}
      />
      <div onClick={stopPropagation}>
        <BuyContentErrorModal
          ref={buyContentErrorModalRef}
          onClose={onCloseBuyFailedModal}
        />
      </div>
      {content.isBlocked === '1' && <BannedInfo />}
      <div className={cn.post_header}>
        <div className={`${cn.user_info} ${cn.info}`}>
          <div className={cn.info__row}>
            <div className={cn.deflogo}>
              {noLinkProf ? (
                avatar
              ) : (
                <Link to={`/u/${content.nickname}`} onClick={stopPropagation}>
                  {avatar}
                </Link>
              )}
            </div>
            <div className={cn.user_about}>
              <div className={cn.about_name}>
                <Link to={`/u/${content.nickname}`} onClick={stopPropagation}>
                  {username}
                </Link>
                {contentOwnerProfile.isBusiness === 1 && (
                  <div className={cn.businessApproved}>
                    <IsBusinessIcon />
                  </div>
                )}
              </div>
              <div className={cn.about_time}>
                {timeAgo.format(+content.tStamp * 1000)}
              </div>
            </div>
          </div>
        </div>
        <div className={cn.topbttns}>
          {profileData?.isModerator === 1 && isModerationMode && (
            <div
              className={classnames(
                cn.postAction,
                cn.displayInMainContent,
                content.displayInMainContent === 1
                  ? cn.isDisplayInMainContent
                  : '',
              )}
              onClick={onClickDisplayInMainContent}
            >
              <DisplayInMainContentIcon />
              <div className={cn.tooltipText}>
                {t(
                  content.displayInMainContent === 0
                    ? 'Post.DisplayInMainContent'
                    : 'Post.UnDisplayInMainContent',
                )}
              </div>
            </div>
          )}
          {profileData?.isModerator === 1 && isModerationMode && (
            <div
              className={classnames(
                cn.postAction,
                cn.approve,
                content.isBlocked === '2' ? cn.isApprove : '',
              )}
              onClick={onClickApproveBtnHandler}
            >
              <ApproveIcon />
              <div className={cn.tooltipText}>{t('Post.ConfirmPost')}</div>
            </div>
          )}
          {profileData?.isModerator === 1 && isModerationMode && (
            <div
              className={classnames(
                cn.postAction,
                cn.negative,
                content.isBlocked === '3' ? cn.isNegative : '',
              )}
              onClick={onClickNegativeBtnHandler}
            >
              <NegativeIcon />
              <div className={cn.tooltipText}>{t('Post.Decline')}</div>
            </div>
          )}
          {profileData?.isModerator === 1 && (
            <div
              className={classnames(
                cn.postAction,
                content.isBlocked !== '1' && cn.postActionFill,
              )}
              onClick={onClickBlockedBtnHandler}
            >
              {content.isBlocked === '1' && <RestoreBtnIcon />}
              {content.isBlocked !== '1' && <BlockBtnIcon />}
              <div className={cn.tooltipText}>
                {t(
                  content.isBlocked === '1'
                    ? 'Post.UnblockPost'
                    : 'Post.BlockPost',
                )}
              </div>
            </div>
          )}
          {/* <div className={`${cn.postAction} ${cn.disabled}`}> */}
          {/*    <RepostIcon/> */}
          {/*    <div className={cn.tooltipText}>{t("Post.SharePost")}</div> */}
          {/* </div> */}
          {profileData?.hashUser === content.ownerId &&
            content.isBlocked !== '1' && (
              <div
                className={`${cn.postAction}`}
                onClick={onClickEditBtnHandler}
              >
                <EditIcon />
                <div className={cn.tooltipText}>{t('Post.EditPost')}</div>
              </div>
            )}
          <MoreActions className={cn.postAction} contentId={+content.id} />
        </div>
      </div>

      {content.mediaDetail !== undefined &&
        (content.mediaDetail.freeVideo !== 0 ||
          content.mediaDetail.payedVideo !== 0) &&
        content.isConverted === '-2' && (
          <VideoInfo
            error
            title={t('Post.ConvertingErrorTitle')}
            text={t('Post.ConvertingErrorText')}
          />
        )}
      {content.mediaDetail !== undefined &&
        (content.mediaDetail.freeVideo !== 0 ||
          content.mediaDetail.payedVideo !== 0) &&
        (content.isConverted === '-1' || content.isConverted === '0') && (
          <VideoInfo
            key="VideoInfo-isConverted"
            title={t('Post.VideoUploadingTitle')}
            text={t('Post.VideoUploadingText')}
          />
        )}

      {((!content.mediaDetail?.freeVideo && !content.mediaDetail?.payedVideo) ||
        ((content.mediaDetail?.freeVideo || content.mediaDetail?.payedVideo) &&
          content.isConverted === '1')) && (
        <>
          <Part
            cn={cn}
            content={content}
            tipsOnClickHandler={tipsOnClickHandler}
            isPrivate={isPrivate}
            isAuth={isAuth}
            isFree
            isSingle={isSingle}
          />

          {content.paidOptions?.payed && !content.paidOptions?.buyed && (
            <>
              <PayStub
                blurImg={content.blurPath}
                content={content}
                username={username}
                onClickBuy={onClickBuy}
                contentBuyInProgress={contentBuyInProgress}
                subscriptionBuyInProgress={subscriptionBuyInProgress}
                onClickSubscribersVIP={onClickBuySubscription}
                subscribeVipCostMonth={content.SubscribeVipCostMonth}
                trialUser={content.trialUser}
                isPayByTG={isPayByTG}
              />

              {isMobile && (
                <PayStubBtns
                  onClickBuy={onClickBuy}
                  paidOptions={content.paidOptions}
                  username={username}
                  payButtonText={`${
                    content.payButtonText?.substr(0, 30) || t('Post.BuyNow')
                  } ${content.paidOptions?.price} $`}
                  contentBuyInProgress={contentBuyInProgress}
                  subscriptionBuyInProgress={subscriptionBuyInProgress}
                  onClickSubscribersVIP={onClickBuySubscription}
                  subscribeVipCostMonth={content.SubscribeVipCostMonth}
                  trialUser={content.trialUser}
                  isPayByTG={isPayByTG}
                  price={content.paidOptions?.price}
                />
              )}
              <Part
                cn={cn}
                content={content}
                tipsOnClickHandler={tipsOnClickHandler}
                isPrivate={isPrivate}
                isAuth={isAuth}
                isFree
                onlyFreeText
                isSingle={isSingle}
              />
            </>
          )}

          {content.paidOptions?.payed && content.paidOptions?.buyed && (
            <Part
              cn={cn}
              content={content}
              tipsOnClickHandler={tipsOnClickHandler}
              isModerationMode={isModerationMode}
              isPrivate={isPrivate}
              isAuth={isAuth}
              isSingle={isSingle}
            />
          )}
        </>
      )}

      <div className={cn.post_content}>
        <div className={cn.post_comments}>
          {comments.map((comment) => {
            const isShowDeleteButton =
              (profileData !== null && profileData.isModerator === 1) ||
              (profileData !== null &&
                content.ownerId === profileData.hashUser &&
                profileData.isBusiness === 1) ||
              (profileData !== null &&
                comment.owner.hashUser === profileData.hashUser);

            return (
              <Comment
                key={comment.id}
                comment={comment}
                onClickDelete={
                  isShowDeleteButton ? onDeleteCommentHandler : undefined
                }
              />
            );
          })}
        </div>
        <div className={cn.addcoment}>
          <input
            placeholder={t('Post.TypeComment')}
            type="text"
            value={commentText}
            onChange={onChangeCommentTextHandler}
            onKeyPress={onKeyPressCommentTextHandler}
            onClick={stopPropagation}
            maxLength={500}
          />
          <button
            type="button"
            onClick={onClickSubmitCommentHandler}
            disabled={!commentText || isSendingComment}
          >
            {t('Post.Submit')}
          </button>
        </div>
      </div>
    </div>
  );
}

export const ListErrors: ReadonlyMap<number, string> = new Map<number, string>([
  [40, 'errors.40'],
  [101, 'errors.Something'],
  [102, 'errors.Something'],
  [103, 'errors.Something'],
  [104, 'errors.Something'],
  [105, 'errors.Something'],
  [106, 'errors.Something'],
  [107, 'errors.Something'],
  [108, 'errors.Something'],
  [109, 'errors.Something'],
  [110, 'errors.Something'],
  [111, 'errors.Something'],
  [112, 'errors.Something'],
  [300, 'errors.300'],
  [301, 'limit recover password'],
  [1000, 'errors.Something'],
  [1001, 'errors.Something'],
  [1002, 'email server error'],
  [1003, 'uncreated path'],
  [1004, 'uncreated file'],

  [3000, 'errors.3000'],
  [3001, 'errors.3001'],
  [3002, 'nickname must be 5 chars or more'],
  [3003, 'nickname must be lest 100 chars'],
  [3004, 'errors.3004'],
  [3005, 'first name must be 2 chars or more'],
  [3006, 'last name must be 2 chars or more'],
  [3007, 'first name must be less 100 chars'],
  [3008, 'last name must be less 100 chars'],
  [3009, 'nickname can not have @'],
  [3010, 'nickname contain unlegal symbols'],
  [3011, 'errors.3011'],

  [3012, 'no file in post'],
  [3013, 'wrong file type for avatar'],
  [3014, 'already uploaded this file'],
  [3015, 'token wrong'],
  [3016, 'errors.3016'],
  [3019, 'errors.3019'],
  [3017, 'regInvite param required'],
  [3018, 'regInvite already set'],
  [3021, 'errors.3021'],
  [3029, 'errors.3029'],
  [3030, 'errors.3030'],
  [3061, 'errors.3061'],

  [99999, 'errors.99999'],

  [10017, 'errors.3024'],

  [10001, 'commitToken param required'],
  [10002, 'commitToken wrong'],
  [10003, 'email param required'],
  [10004, 'recoverToken param required'],
  [10005, 'password param required'],
  [10006, 'confirmPassword param required'],
  [10007, 'confirmPassword and password must be equal'],
  [10008, 'wrong password'],
  [10011, 'errors.10011'],
  [10012, 'unsupported token type'],
  [10013, 'nowPassword param required'],
  [10014, 'now password wrong'],
  [10019, 'errors.10019'],
  [10020, 'errors.10020'],
  [10021, 'errors.10021'],
  [10022, 'errors.10022'],
  [10023, 'errors.10023'],
  [10025, 'errors.10025'],
  [10066, 'errors.10066'],
  [10082, 'AddPost.AccessDeclined'],
  [10091, 'errors.10091'],
  [10106, 'errors.10106'],
  [10110, 'errors.10110'],
  [10111, 'errors.10111'],
  [10128, 'errors.10128'],
  [10129, 'errors.10129'],
  [10140, 'errors.10140'],
  [10171, 'chat.youBlocked'],
  [10180, 'errors.10180'],
  [10204, 'errors.10204'],
  [10197, 'chat.cannotDeletePurchasedPost'],
  [10213, 'errors.10213'],
  [10215, 'errors.10215'],
  [10216, 'errors.10216'],
  [10220, 'errors.10220'],
  [10221, 'errors.10221'],
  [10222, 'errors.10223'],
  [10223, 'errors.10223'],
  [10225, 'errors.10225'],
  [10228, 'errors.10228'],
  [10229, 'errors.10229'],
  [10233, 'errors.10233'],
  [10230, 'errors.10230'],
  [10235, 'errors.10235'],
  [10224, 'errors.10224'],
  [10231, 'errors.10231'],
  [10232, 'errors.10232'],
  [10234, 'errors.10234'],
  [10227, 'errors.10227'],
  [10236, 'errors.10236'],
  [10226, 'errors.10226'],
]);

export const PAYMENT_ERRORS = [
  10066, 10225, 10228, 10229, 10233, 10230, 10235, 10224, 10231, 10232, 10234,
  10227, 10236, 10226,
];

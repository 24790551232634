import React from 'react';

interface IProps {
  className?: string;
  onClick?: () => void;
}

function Icon(props: IProps): JSX.Element {
  return <div {...props}>
    <svg
      width="25"
      height="27"
      viewBox="0 0 25 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.11594 26.6668H6.34783V14.3233H9.33333V11.2085H6.24638V10.1799C6.24638 9.07882 6.85507 8.47034 8.24638 8.47034C8.66667 8.47034 9.08696 8.49932 9.3913 8.55727V5.76116C8.89855 5.65975 8.01449 5.57282 7.23188 5.57282C3.55072 5.57282 2.11594 6.99261 2.11594 10.035V11.2085H0V14.3233H2.11594V26.6668Z"
        fill="white"
      />
      <path
        d="M16.7536 23.8562C15.3188 23.8562 14.3623 23.1318 14.3623 21.9728C14.3623 20.8717 15.2609 20.1618 16.8406 20.0459L20.1159 19.8431V20.9732C20.1159 22.6537 18.5942 23.8562 16.7536 23.8562ZM15.3623 26.9131C17.3043 26.9131 19.2319 25.9424 20.1014 24.2908H20.1884V26.6668H24.2754V15.9894C24.2754 12.8601 21.6812 10.8028 17.6957 10.8028C13.5797 10.8028 11.0145 12.8746 10.8551 15.888H14.7246C14.9275 14.7435 15.9275 13.9611 17.5072 13.9611C19.1014 13.9611 20.1159 14.8014 20.1159 16.2502V17.2788L16.2029 17.5106C12.3333 17.7424 10.1594 19.3795 10.1594 22.2046C10.1594 25.0007 12.4058 26.9131 15.3623 26.9131Z"
        fill="white"
      />
      <path
        d="M20.828 9.19544L17.9924 7.36623L15.143 9.17487L15.9249 5.7757L13.4068 3.4897L16.7264 3.22015L18.0179 0L19.2877 3.23044L22.6055 3.52674L20.0716 5.79216L20.828 9.19544Z"
        fill="white"
      />
    </svg>
  </div>
}

export default Icon;

import { ParsedQuery } from 'query-string';
import React from 'react';
import { toast } from 'react-toastify';

const _30DAYS_IN_MS = 30 * 24 * 60 * 60 * 1000;

export const dispatchForKeyCode = (
  event: React.KeyboardEvent<HTMLInputElement> & { keyIdentifier?: number }
): number => {
  if (event.key !== undefined) {
    return +event.key;
  }
  if (event.keyIdentifier !== undefined) {
    return event.keyIdentifier;
  }
  if (event.keyCode !== undefined) {
    return event.keyCode;
  }
  return -1;
};

export const normalizeImgSrc = (
  source: string | undefined | null,
  size?: 's' | 'm' | 'l' | 'xl' | 'xxl'
): string | undefined => {
  if (!source) {
    return undefined;
  }

  let srcUrl = source;
  if (source.startsWith('http://cdn.fanter.com')) {
    srcUrl = source.substring('http://cdn.fanter.com'.length);
  }

  if (source.startsWith('https://cdn.fanter.com')) {
    srcUrl = source.substring('https://cdn.fanter.com'.length);
  }

  if (source.startsWith('//cdn.fanter.com')) {
    srcUrl = srcUrl.substring('//cdn.fanter.com'.length);
  }

  if (srcUrl.startsWith('/')) {
    srcUrl = srcUrl.substring('/'.length);
  }

  const path = srcUrl.split('.');

  return `https://cdn.fanter.com/${path[0]}${size ? `-${size}` : ''}.${
    path[1] ?? 'jpg'
  }`;
};

export const showAlert = (
  msg: string,
  id: string | number,
  type?: 'success' | 'info' | 'warn' | 'error'
): void => {
  const idStr = id.toString();
  if (toast.isActive(idStr)) {
    toast.update(idStr);
  }

  switch (type) {
    case 'success': {
      toast.success(msg, { toastId: idStr });
      return;
    }
    case 'info': {
      toast.info(msg, { toastId: idStr });
      return;
    }
    case 'warn': {
      toast.warn(msg, { toastId: idStr });
      return;
    }
    case 'error': {
      toast.error(msg, { toastId: idStr });
      return;
    }
    default: {
      toast.info(msg, { toastId: idStr });
    }
  }
};

export const getFormatedAmount = (
  amount: number | string | undefined
): string => {
  if (amount === undefined) {
    return '-';
  }

  return (+amount).toLocaleString('en-EN', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });
};

export const getUtms = (search: ParsedQuery<string>): string => {
  const utmsLS: { [key: string]: string } = {};

  Object.keys(localStorage).forEach((key) => {
    if (
      [
        'utm_source',
        'utm_medium',
        'utm_campaign',
        'utm_term',
        'utm_content',
        'esub',
        'set',
        'land',
      ].includes(key)
    ) {
      const val = localStorage.getItem(key);
      const time = localStorage.getItem(`${key}_time`) ?? '0';
      if (val !== null && +time + _30DAYS_IN_MS > new Date().getTime()) {
        utmsLS[key] = val;
      } else {
        localStorage.removeItem(key);
        localStorage.removeItem(`${key}_time`);
      }
    }
  });

  const utmsQ: { [key: string]: string } = {};
  Object.keys(search).forEach((key) => {
    if (
      key.toLowerCase().startsWith('utm_') ||
      key === 'esub' ||
      key === 'set' ||
      key === 'land'
    ) {
      utmsQ[key] = search[key] as string;
    }
  });

  const utms = { ...utmsLS, ...utmsQ };

  const initialLink = localStorage.getItem('INITIAL_LINK');
  if (initialLink !== null) {
    utms.initialLink = `"${initialLink}"`;
  }

  return Object.keys(utms).reduce((str: string, key: string) => {
    const amp = str !== '' ? '&' : '';

    return `${str}${amp}${key}=${utms[key]}`;
  }, '');
};

export const isVideo = (file?: File | null): boolean => (
    !!file &&
    (file.name.split('.').pop()?.toLocaleLowerCase() === 'mp4' ||
      file.name.split('.').pop()?.toLocaleLowerCase() === 'mov')
  );

export const getCardType = (cardNumber?: string): string => {
  const re: { [key: string]: RegExp } = {
    test: /^[0]{12}[0-9]{4}$/,
    visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
    mastercard: /^5[1-5][0-9]{14}$/,
    discover:
      /^65[4-9][0-9]{13}|64[4-9][0-9]{13}|6011[0-9]{12}|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])[0-9]{10})$/,
    american_express: /^3[4|7][0-9]{14}$/,
  };

  let type = '';

  if (cardNumber) {
    Object.keys(re).forEach((key: string) => {
      if (re[key]?.test(cardNumber.replace(/\s/g, ''))) {
        type = key;
      }
    });
  }

  return type === 'test' ? 'visa' : type;
};

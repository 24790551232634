import { takeEvery, takeLatest } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import {
  workerChangePassword,
  workerCreateCard,
  workerDepositForChat,
  workerDropCard,
  workerFetchGetComplaintsList,
  workerFetchGetComplaintsListNotReload,
  workerFetchGetProfile,
  workerFetchGetProfileNotReload,
  workerFetchGetThatProfileNotReload,
  workerFetchSendMessage,
  workerFetchSetLanguage,
  workerFollow,
  workerGetBankDocs,
  workerGetBankOptions,
  workerGetCards,
  workerGetPayments,
  workerGetPaysStatistic,
  workerGetPurchases,
  workerGetReferals,
  workerGetSubscriptions,
  workerGetUserActions,
  workerImpersonateUser,
  workerLogOut,
  workerNicknameAvailable,
  workerResendEmailConfirm,
  workerSetActive,
  workerSetAutoRenewal,
  workerSetBankOptions,
  workerSetBusiness,
  workerSetCost,
  workerSetMessageFee,
  workerSetNewFee,
  workerSetUserActions,
  workerThatProfile,
  workerUpdateNickname,
  workerUpdateProfile,
  workerUploadBankDoc,
} from './workers';
import {
  fetchChangePassword,
  fetchCreateCard,
  fetchDepositForChat,
  fetchDropCard,
  fetchFollow,
  fetchGetBankDocs,
  fetchGetBankOptions,
  fetchGetCards,
  fetchGetComplaintsList,
  fetchGetComplaintsListNotReload,
  fetchGetPayments,
  fetchGetPaysStatistic,
  fetchGetProfile,
  fetchGetProfileNotReload,
  fetchGetPurchases,
  fetchGetReferals,
  fetchGetSubscriptions,
  fetchGetThatProfile,
  fetchGetThatProfileNotReload,
  fetchGetUserActions,
  fetchNicknameAvailable,
  fetchResendEmailConfirm,
  fetchSend,
  fetchSetActive,
  fetchSetAutoRenewal,
  fetchSetBankOptions,
  fetchSetBusiness,
  fetchSetCost,
  fetchSetLanguage,
  fetchSetMessageFee,
  fetchSetNewFee,
  fetchSetUserActions,
  fetchUpdateNickname,
  fetchUpdateProfile,
  fetchUploadBankDoc,
  impersonate,
  logOut,
} from '../actions';
import { workerContentBuy } from '../../timeLine/sagas/workers';
import { fetchСontentBuy } from '../../timeLine/actions';

export default function* watchUser(): SagaIterator {
  yield takeEvery<typeof fetchGetProfile>(
    fetchGetProfile,
    workerFetchGetProfile
  );
  yield takeEvery<typeof fetchGetProfileNotReload>(
    fetchGetProfileNotReload,
    workerFetchGetProfileNotReload
  );

  yield takeEvery<typeof fetchGetThatProfileNotReload>(
    fetchGetThatProfileNotReload,
    workerFetchGetThatProfileNotReload
  );
  yield takeEvery<typeof logOut>(logOut, workerLogOut);
  yield takeEvery<typeof impersonate>(impersonate, workerImpersonateUser);
  yield takeEvery<typeof fetchSetLanguage>(
    fetchSetLanguage,
    workerFetchSetLanguage
  );
  yield takeEvery<typeof fetchResendEmailConfirm>(
    fetchResendEmailConfirm,
    workerResendEmailConfirm
  );
  yield takeEvery<typeof fetchChangePassword>(
    fetchChangePassword,
    workerChangePassword
  );
  yield takeEvery<typeof fetchSetActive>(fetchSetActive, workerSetActive);
  yield takeEvery<typeof fetchGetReferals>(fetchGetReferals, workerGetReferals);
  yield takeEvery<typeof fetchСontentBuy>(fetchСontentBuy, workerContentBuy);
  yield takeLatest<typeof fetchNicknameAvailable>(
    fetchNicknameAvailable,
    workerNicknameAvailable
  );
  yield takeEvery<typeof fetchUpdateProfile>(
    fetchUpdateProfile,
    workerUpdateProfile
  );
  yield takeEvery<typeof fetchUpdateNickname>(
    fetchUpdateNickname,
    workerUpdateNickname
  );
  yield takeEvery<typeof fetchGetThatProfile>(
    fetchGetThatProfile,
    workerThatProfile
  );
  yield takeEvery<typeof fetchSend>(fetchSend, workerFetchSendMessage);
  yield takeEvery<typeof fetchSetBusiness>(fetchSetBusiness, workerSetBusiness);
  yield takeEvery<typeof fetchSetCost>(fetchSetCost, workerSetCost);
  yield takeEvery<typeof fetchSetMessageFee>(
    fetchSetMessageFee,
    workerSetMessageFee
  );
  yield takeEvery<typeof fetchSetNewFee>(fetchSetNewFee, workerSetNewFee);
  yield takeEvery<typeof fetchDepositForChat>(
    fetchDepositForChat,
    workerDepositForChat
  );
  yield takeEvery<typeof fetchFollow>(fetchFollow, workerFollow);
  yield takeEvery<typeof fetchGetUserActions>(
    fetchGetUserActions,
    workerGetUserActions
  );
  yield takeEvery<typeof fetchSetUserActions>(
    fetchSetUserActions,
    workerSetUserActions
  );
  yield takeEvery<typeof fetchGetCards>(fetchGetCards, workerGetCards);
  yield takeEvery<typeof fetchCreateCard>(fetchCreateCard, workerCreateCard);
  yield takeEvery<typeof fetchDropCard>(fetchDropCard, workerDropCard);
  yield takeEvery<typeof fetchGetSubscriptions>(
    fetchGetSubscriptions,
    workerGetSubscriptions
  );
  yield takeEvery<typeof fetchSetAutoRenewal>(
    fetchSetAutoRenewal,
    workerSetAutoRenewal
  );
  yield takeEvery<typeof fetchGetPayments>(fetchGetPayments, workerGetPayments);
  yield takeEvery<typeof fetchGetPurchases>(
    fetchGetPurchases,
    workerGetPurchases
  );
  yield takeEvery<typeof fetchGetPaysStatistic>(
    fetchGetPaysStatistic,
    workerGetPaysStatistic
  );
  yield takeEvery<typeof fetchSetBankOptions>(
    fetchSetBankOptions,
    workerSetBankOptions
  );
  yield takeEvery<typeof fetchGetBankOptions>(
    fetchGetBankOptions,
    workerGetBankOptions
  );
  yield takeEvery<typeof fetchUploadBankDoc>(
    fetchUploadBankDoc,
    workerUploadBankDoc
  );
  yield takeEvery<typeof fetchGetBankDocs>(fetchGetBankDocs, workerGetBankDocs);
  yield takeEvery<typeof fetchGetComplaintsList>(
    fetchGetComplaintsList,
    workerFetchGetComplaintsList
  );
  yield takeEvery<typeof fetchGetComplaintsListNotReload>(
    fetchGetComplaintsListNotReload,
    workerFetchGetComplaintsListNotReload
  );
}

import React from 'react';

interface IProps {
  className?: string;
  onClick?: () => void;
}

function Icon(props: IProps): JSX.Element {
  return <div {...props}>
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 0C3.365 0 0 3.365 0 7.5C0 11.635 3.365 15 7.5 15C11.635 15 15 11.635 15 7.5C15 3.365 11.635 0 7.5 0ZM1.13 7.5C1.13 3.99 3.99 1.13 7.5 1.13C8.93 1.13 10.25 1.605 11.315 2.405L2.715 11.695C1.73 10.575 1.13 9.105 1.13 7.5ZM7.5 13.87C6.07 13.87 4.75 13.395 3.685 12.595L12.285 3.305C13.27 4.425 13.87 5.895 13.87 7.5C13.87 11.01 11.01 13.87 7.5 13.87Z"
        fill="white"
      />
    </svg>
  </div>
}

export default Icon;

import React from 'react';

interface IProps {
  className?: string;
  onClick?: () => void;
}

function UserIcon(props: IProps): JSX.Element {
  return <div {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.96 21.96">
      <title>Asset 14</title>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path
            fill="none"
            stroke="#a09ec9"
            strokeMiterlimit="10"
            strokeWidth="2.1px"
            d="M10.88,9.68A3.72,3.72,0,0,1,7,6.23V4.5a3.72,3.72,0,0,1,3.93-3.45A3.72,3.72,0,0,1,14.81,4.5V6.23A3.72,3.72,0,0,1,10.88,9.68Z"
          />
          <path
            fill="none"
            stroke="#a09ec9"
            strokeMiterlimit="10"
            strokeWidth="2.1px"
            d="M7.14,12.27a.89.89,0,0,1,.59.26A4.13,4.13,0,0,0,11,14a4.26,4.26,0,0,0,3.24-1.47.63.63,0,0,1,.59-.26c3.34,0,6.1,2.42,6.1,5.36V20a.94.94,0,0,1-1,.87H2a.93.93,0,0,1-1-.87V17.63C1.05,14.69,3.8,12.27,7.14,12.27Z"
          />
        </g>
      </g>
    </svg>
  </div>
}

export default UserIcon;

import { takeEvery } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/types';
import {
  fetchAddPostWithTus,
  fetchEditPostWithTus,
  fetchAddPost,
  fetchEditPost,
} from '../actions';
import {
  workerAddPostWithTus,
  workerEditPostWithTus,
  workerAddPost,
  workerEditPost,
} from './workers';

export default function* watchAddPostWithTus(): SagaIterator {
  yield takeEvery<typeof fetchAddPost>(fetchAddPost, workerAddPost);
  yield takeEvery<typeof fetchEditPost>(fetchEditPost, workerEditPost);
  yield takeEvery<typeof fetchAddPostWithTus>(
    fetchAddPostWithTus,
    workerAddPostWithTus
  );
  yield takeEvery<typeof fetchEditPostWithTus>(
    fetchEditPostWithTus,
    workerEditPostWithTus
  );
}

import React from 'react';

interface IProps {
  className?: string;
  onClick?: () => void;
}

function AddPostIcon(props: IProps): JSX.Element {
  return <div {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.9 22.04">
      <title>Asset 32</title>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <rect
            fill="none"
            stroke="#a09ec9"
            strokeWidth="2.2px"
            x="1.1"
            y="1.1"
            width="22.7"
            height="19.84"
            rx="6.58"
            ry="6.58"
          />
          <path
            fill="#a09ec9"
            d="M11.5,6.48a1,1,0,1,1,1.9,0V16a1,1,0,1,1-1.9,0Z"
          />
          <path
            fill="#a09ec9"
            d="M7.68,10.29a1,1,0,0,0,0,1.91h9.54a1,1,0,0,0,0-1.91Z"
          />
        </g>
      </g>
    </svg>
  </div>
}

export default AddPostIcon;

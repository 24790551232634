import { takeEvery } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { fetchGetNotifications, fetchHaveUnread } from '../actions';
import { workerFetchGetNotifications, workerHaveUnread } from './workers';

export default function* watchNotifications(): SagaIterator {
  yield takeEvery<typeof fetchGetNotifications>(
    fetchGetNotifications,
    workerFetchGetNotifications
  );
  yield takeEvery<typeof fetchHaveUnread>(fetchHaveUnread, workerHaveUnread);
}

import { createAction } from '@reduxjs/toolkit';
import { TContentFile } from '../../types/TContent';
import { TPost } from './TPost';

export const fetchAddPost = createAction<
  TPost & { leftFreeFiles: TContentFile[]; leftPayedFiles: TContentFile[] },
  'addPostWithTus/fetchAddPost'
>('addPostWithTus/fetchAddPost');
export const fetchEditPost = createAction<
  TPost & { leftFreeFiles: TContentFile[]; leftPayedFiles: TContentFile[] },
  'addPostWithTus/fetchEditPost'
>('addPostWithTus/fetchEditPost');

export const fetchAddPostWithTus = createAction<
  TPost & { leftFreeFiles: TContentFile[]; leftPayedFiles: TContentFile[] },
  'addPostWithTus/fetchAddPostWithTus'
>('addPostWithTus/fetchAddPostWithTus');
export const fetchEditPostWithTus = createAction<
  TPost & { leftFreeFiles: TContentFile[]; leftPayedFiles: TContentFile[] },
  'addPostWithTus/fetchEditPostWithTus'
>('addPostWithTus/fetchEditPostWithTus');

import React from 'react';

interface IProps {
  className?: string;
  onClick?: () => void;
}

function Icon(props: IProps): JSX.Element {
  return <div {...props}>
    <svg
      width="97"
      height="97"
      viewBox="0 0 97 97"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M88.0673 26.0174C91.8603 32.6309 94 40.3142 94 48.4838C94 73.6409 73.6409 94 48.4838 94C23.3915 94.0324 3 73.6409 3 48.5162C3 23.3915 23.3915 3 48.5162 3C57.6259 3 66.0549 5.65835 73.187 10.2294M29.2269 47.5436L45.0474 63.3316L71.793 36.5536"
        stroke="#413E93"
        strokeWidth="6"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
}

export default Icon;

import React from 'react';
import { useTranslation } from 'react-i18next';

import { TipsIcon, LikeIcon } from '../../../assets/post';

import cn from './style.module.css';

interface IProps {
  className?: string;
  likes?: number;
  tips?: number;
  islLkesActive?: boolean;
  likesOnClickHandler?: () => void;
  tipsOnClickHandler?: () => void;
}

export function Iconlist({
  className,
  likes,
  tips,
  islLkesActive,
  likesOnClickHandler,
  tipsOnClickHandler,
}: IProps): JSX.Element {
  const { t } = useTranslation();
  return (
    <ul
        className={`${cn.iconlist} ${className}`}
        onClick={(event) => event.stopPropagation()}
      >
        <li className={`${cn.likes} ${islLkesActive ? cn.active : ''} `}>
          <div className={cn.events} onClick={likesOnClickHandler}>
            <LikeIcon />
          </div>
          <span>{likes}</span>
        </li>
        <li className={`${cn.likes}`}>
          <div className={cn.events} onClick={tipsOnClickHandler}>
            <TipsIcon />
          </div>
          <span onClick={tipsOnClickHandler}>
            {tips !== undefined && tips > 0 ? `$${tips}` : t('Post.SendTips')}
          </span>
        </li>
      </ul>
  );
}

import React, { useCallback, useMemo } from 'react';
import Avatar from 'react-avatar';
import { useWindowSize } from 'react-use';
import { useHistory } from 'react-router-dom';

import { TProfile } from '../../types/TProfile';
import { getUserName } from '../../helper/function';
import { useIsMobile } from '../../domains/hooks';

import IsBusinessIcon from '../../assets/profile/IsBusinessIcon';
import { normalizeImgSrc } from '../../utils/utils';

import cn from './UserAvatar.module.scss';

interface IProps {
  profile: TProfile | null;
  size?: string;
  mobileSize?: string;
  imgSize?: 's' | 'm' | 'l' | 'xl' | 'xxl';
  largeSize?: string;
  className?: string;
  withBusinessMarker?: boolean;
  withStatusMarker?: boolean;
  disabled?: boolean;
}

export const UserAvatar = React.memo(
  ({
    profile,
    size,
    mobileSize,
    largeSize,
    className,
    withBusinessMarker,
    withStatusMarker,
    imgSize,
    disabled,
  }: IProps): JSX.Element | null => {
    const isMobile = useIsMobile();
    const history = useHistory();
    const windowSize = useWindowSize();

    const displayedName = useMemo(
      () =>
        getUserName(profile?.firstName, profile?.lastName, profile?.nickname),
      [profile],
    );

    const avatarSrc = useMemo(() => {
      if (!profile) {
        return undefined;
      }

      return profile?.nickname === undefined
        ? profile.avatar
        : normalizeImgSrc(profile.avatar, imgSize);
    }, [profile]);

    const avatarSize = useMemo((): string => {
      if (isMobile && mobileSize) {
        return mobileSize;
      }

      if ((windowSize.width > 1280 || windowSize.height > 768) && largeSize) {
        return largeSize;
      }

      return size ?? '';
    }, [isMobile, windowSize, size, mobileSize, largeSize]);

    const isShowStatus = useMemo(
      () =>
        withStatusMarker &&
        !!profile?.nickname &&
        profile?.lastOnline?.flagOnline &&
        [1, 2].includes(profile.lastOnline.flagOnline),
      [withStatusMarker, profile],
    );

    const statusStyle = useMemo(() => {
      const styleList = [cn.status];

      if (profile?.lastOnline?.flagOnline === 1) {
        styleList.push(cn.status_available);
      }

      if (profile?.lastOnline?.flagOnline === 2) {
        styleList.push(cn.status_justNow);
      }

      return styleList.join(' ');
    }, [profile]);

    const onClickUserAvatarHandler = useCallback((): void => {
      if (profile?.nickname !== undefined && !disabled) {
        history.push(`/u/${profile.nickname}`);
      }
    }, [profile]);

    if (profile === null) {
      return null;
    }

    return (
      <div
        style={{
          cursor: profile?.nickname !== undefined ? 'pointer' : 'default',
        }}
        className={`${cn.userAvatar} ${className}`}
        onClick={onClickUserAvatarHandler}
      >
        {withBusinessMarker && profile.isBusiness === 1 && (
          <div className={cn.businessApproved}>
            <IsBusinessIcon />
          </div>
        )}

        <Avatar
          name={displayedName}
          color="#D8D8EB"
          size={avatarSize}
          src={avatarSrc}
          textSizeRatio={2}
          round
        />

        {isShowStatus && (
          <div
            className={statusStyle}
            style={{ width: `${+avatarSize + 10}px` }}
          >
            <div />
          </div>
        )}
      </div>
    );
  },
);

/**
 * User Fanter API
 */

import { TChatCharacter, TRolesAllowed } from '../../types/TProfile';
import { makeRequest } from './FanterAPICore';

type TSetAutoRenewalParams = {
  reneval: boolean;
  hashOpponent: string;
};
type TSetAutoRenewalResponse = {
  update: boolean;
};
export const setAutoRenewal = async (
  reneval: boolean,
  hashOpponent: string
): Promise<TSetAutoRenewalResponse> => makeRequest<TSetAutoRenewalParams, TSetAutoRenewalResponse>(
    'user.setAutoReneval',
    { reneval, hashOpponent }
  );

export type TSetBusinessParams = {
  isBusiness: boolean;
};
export type TSetBusinessResponse = {
  result: boolean;
};
export const setBusiness = async (
  isBusiness: boolean
): Promise<TSetBusinessResponse> => makeRequest<TSetBusinessParams, TSetBusinessResponse>(
    'user.setBusiness',
    { isBusiness }
  );

export type TCreateChatRoleParams = {
  /**  Название роли.строка до 200 символов */
  roleName: string;
  /** пост галлереи, который будет отправлен в чат после выбора роли */
  rolePost?: number;
  /** текст, который будет отправлен в чат после выбора роли.Строка до 200 символов */
  roleSendText?: string;
  /** адрес картинки, которая будет отправлена в чат после выбора роли.Не ведется проверка факта загрузки данной картинки именно от этого пользователя.Ограничения на картинку такие же как при загрузке контента. */
  rolePhoto?: string;
};
export type TCreateChatRoleResponse = {
  /** id роли, если создание прошло успешно */
  roleId: string;
};
export const createChatRole = async (
  roleName: string,
  rolePost: number | undefined,
  roleSendText: string | undefined,
  rolePhoto: string | undefined
): Promise<TCreateChatRoleResponse> => makeRequest<TCreateChatRoleParams, TCreateChatRoleResponse>(
    'user.createChatRole',
    {
      roleName,
      rolePost,
      roleSendText,
      rolePhoto,
    }
  );

export type TEditChatRoleParams = {
  roleId: string;
  /**  Название роли.строка до 200 символов */
  roleName: string;
  /** пост галлереи, который будет отправлен в чат после выбора роли */
  rolePost?: number;
  /** текст, который будет отправлен в чат после выбора роли.Строка до 200 символов */
  roleSendText?: string;
  /** адрес картинки, которая будет отправлена в чат после выбора роли.Не ведется проверка факта загрузки данной картинки именно от этого пользователя.Ограничения на картинку такие же как при загрузке контента. */
  rolePhoto?: string;
};
export const editChatRole = async (
  roleId: string,
  roleName: string,
  rolePost: number | undefined,
  roleSendText: string | undefined,
  rolePhoto: string | undefined
): Promise<boolean> => makeRequest<TEditChatRoleParams, boolean>('user.editChatRole', {
    roleId,
    roleName,
    rolePost,
    roleSendText,
    rolePhoto,
  });

export type TDeleteChatRoleParams = {
  roleId: string;
};
export const deleteChatRole = async (roleId: string): Promise<boolean> => makeRequest<TDeleteChatRoleParams, boolean>('user.deleteChatRole', {
    roleId,
  });

export type TGetChatRoleListParams = {
  hashUser: string;
};
export const getChatRoleList = async (
  hashUser: string
): Promise<TChatCharacter[]> => makeRequest<TGetChatRoleListParams, TChatCharacter[]>(
    'user.getChatRoleList',
    { hashUser }
  );

export type TSetTrialStateParams = {
  active: 0 | 1;
  duration: number;
};
export const setTrialState = async (
  active: 0 | 1,
  duration: number
): Promise<boolean> => makeRequest<TSetTrialStateParams, boolean>('user.setTrialState', {
    active,
    duration,
  });

type TSubscribeTrialParams = {
  hashUser: string;
};
type TSubscribeTrialResponse = {
  result: boolean;
};
export const subscribeTrial = async (
  hashUser: string
): Promise<TSubscribeTrialResponse> => makeRequest<TSubscribeTrialParams, TSubscribeTrialResponse>(
    'user.subscribeTrial',
    { hashUser },
    false
  );

/**
 * Рассылка писем с постом среди пользователей, с которыми есть какие-то взаимоотношения.
 */
type TCreateListMessagesResponse = {
  unsended: string[];
  sended: string[];
};
export type TCreateListMessagesPayload = {
  contentId: number;
  recipientType:
    | 'followers'
    | 'subscribersVip'
    | 'followers-subscribersVip'
    | 'usersList';
  listId?: string;
  price?: number;
  message?: string;
};
export const createListMessages = async (
  payload: TCreateListMessagesPayload
): Promise<TCreateListMessagesResponse> => makeRequest<TCreateListMessagesPayload, TCreateListMessagesResponse>(
    'user.createListMessages',
    payload
  );

/**
 * Метод проверяет корректность заданного regInvite кода. Если пользователь с таким кодом существует, то возвращает основные данные о нем.
 */
type TCheckRegInviteResponse = {
  firstName: string;
  lastName: string;
  nickname: string;
  avatar: string;
};
export type TCheckRegInvitePayload = {
  regInvite: string;
};
export const checkRegInvite = async (
  regInvite: string
): Promise<TCheckRegInviteResponse> => makeRequest<TCheckRegInvitePayload, TCheckRegInviteResponse>(
    'user.checkRegInvite',
    { regInvite }
  );

export type TCreateLandingRolesSetParams = {
  rolesSetName: string;
};
export const createLandingRolesSet = async (
  rolesSetName: string
): Promise<string> => makeRequest<TCreateLandingRolesSetParams, string>(
    'user.createLandingRolesSet',
    { rolesSetName }
  );

export type TCreateLandingRoleParams = {
  rolesSetId: string;
  roleName: string;
  rolePhoto: string;
};
export type TCreateLandingRoleResponse = {
  roleId: string;
};
export const createLandingRole = async (
  rolesSetId: string,
  roleName: string,
  rolePhoto: string
): Promise<TCreateLandingRoleResponse> => makeRequest<TCreateLandingRoleParams, TCreateLandingRoleResponse>(
    'user.createLandingRole',
    {
      rolesSetId,
      roleName,
      rolePhoto,
    }
  );

type TDeleteLandingRoleParams = {
  roleId: string;
};
export const deleteLandingRole = async (roleId: string): Promise<void> => makeRequest<TDeleteLandingRoleParams, void>('user.deleteLandingRole', {
    roleId,
  });

type TDeleteLandingParams = {
  rolesSetId: string;
};
export const deleteLandingRolesSet = async (
  rolesSetId: string
): Promise<void> => makeRequest<TDeleteLandingParams, void>('user.deleteLandingRolesSet', {
    rolesSetId,
  });

type TConnectRAParams = {
  /** - идентификатор рекламного агентства. */
  idRA: string;
};

type TConnectRAResponse = {
  update: boolean;
};

export const connectRA = async (idRA: string): Promise<TConnectRAResponse> => makeRequest<TConnectRAParams, TConnectRAResponse>('user.connectRA', {
    idRA,
  });

type TDisconnectRAParams = {
  /** - идентификатор рекламного агентства. */
  idRA: string;
};

type TDisconnectRAResponse = {
  update: boolean;
};
export const disconnectRA = async (
  idRA: string
): Promise<TDisconnectRAResponse> => makeRequest<TDisconnectRAParams, TDisconnectRAResponse>(
    'user.disconnectRA',
    { idRA }
  );

type TSetOptionsParams = {
  /** При установке блокировок для создания чата и отправке сообщений. Доступные значения:
   *  0 - разрешено всем
   *  1 - разрешено только пользователям с вип-подпиской или триальной подпиской.
   */
  blockChats?: 0 | 1;
  /** настройки доступности выбора роли в чате с моделью. Доступные значения:
   *  0 - сброс
   *  1 - доступно всем
   *  2 - доступно только подписчикам
   *  3 - доступно только вип-подписчикам.
   */
  rolesAllowed?: TRolesAllowed;
};
type TSetOptionsResponse = {
  update: boolean;
};
export const setOptions = async (
  params: TSetOptionsParams
): Promise<TSetOptionsResponse> => makeRequest<TSetOptionsParams, TSetOptionsResponse>(
    'user.setOptions',
    params
  );

type TApproveSetBankOptionsParams = {
  uniqCode: string;
};
type TApproveSetBankOptionsResponse = {
  updated: boolean;
};
export const approveSetBankOptions = async (
  uniqCode: string
): Promise<TApproveSetBankOptionsResponse> => makeRequest<
    TApproveSetBankOptionsParams,
    TApproveSetBankOptionsResponse
  >('user.approveSetBankOptions', { uniqCode });

type TResendSetBankOptionsResponse = {
  sended: boolean;
};

export const resendSetBankOptions =
  async (): Promise<TResendSetBankOptionsResponse> => makeRequest<undefined, TResendSetBankOptionsResponse>(
      'user.resendSetBankOptions',
      undefined
    );

import React from 'react';

interface IProps {
  className?: string;
  onClick?: () => void;
}

function PlusCircleIcon(props: IProps): JSX.Element {
  return <div {...props}>
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.33333 5C8.33333 4.53976 7.96024 4.16667 7.5 4.16667C7.03976 4.16667 6.66667 4.53976 6.66667 5V6.66667H5C4.53976 6.66667 4.16667 7.03976 4.16667 7.5C4.16667 7.96024 4.53976 8.33333 5 8.33333H6.66667V10C6.66667 10.4602 7.03976 10.8333 7.5 10.8333C7.96024 10.8333 8.33333 10.4602 8.33333 10V8.33333H10C10.4602 8.33333 10.8333 7.96024 10.8333 7.5C10.8333 7.03976 10.4602 6.66667 10 6.66667H8.33333V5Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 7.5C15 11.6421 11.6421 15 7.5 15C3.35786 15 0 11.6421 0 7.5C0 3.35786 3.35786 0 7.5 0C11.6421 0 15 3.35786 15 7.5ZM13.3333 7.5C13.3333 10.7217 10.7217 13.3333 7.5 13.3333C4.27834 13.3333 1.66667 10.7217 1.66667 7.5C1.66667 4.27834 4.27834 1.66667 7.5 1.66667C10.7217 1.66667 13.3333 4.27834 13.3333 7.5Z"
        fill="white"
      />
    </svg>
  </div>
}

export default PlusCircleIcon;

// eslint-disable-next-line no-shadow
export enum EComplaint {
  NONE,
  NOT_ORIGINAL_CONTENT,
  EXTREMISM,
  SPAM_OR_MISLEADING,
  FRAUD,
  CHILD_PORNOGRAPHY,
  VIOLENT_OR_UNPLEASANT_SCENES,
  TERRORISM_PROPAGANDA,
}

export const complaintI18n: Map<EComplaint, string> = new Map([
  [EComplaint.NOT_ORIGINAL_CONTENT, 'Complaint.NotOriginalContent'],
  [EComplaint.EXTREMISM, 'Complaint.Extremism'],
  [EComplaint.SPAM_OR_MISLEADING, 'Complaint.SpamOrMisleading'],
  [EComplaint.FRAUD, 'Complaint.Fraud'],
  [EComplaint.CHILD_PORNOGRAPHY, 'Complaint.ChildPornography'],
  [
    EComplaint.VIOLENT_OR_UNPLEASANT_SCENES,
    'Complaint.ViolentOrUnpleasantScenes',
  ],
  [EComplaint.TERRORISM_PROPAGANDA, 'Complaint.TerrorismPropaganda'],
]);

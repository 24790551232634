export const publicBook = {
  signUp: '/sign-up',
  signIn: '/sign-in',
  interEmail: '/inter-email',
  contacts: '/contacts',
  documents: '/documents',
  pay: '/pay',
  successPay: '/success-pay',
  passwordreset: '/passwordreset/:token',
  emailconfirm: '/emailconfirm/:token',
  nitafans: '/nitafans',
  landing: '/v2/:nickname',
  tusUpload: '/tus-upload',
};

export const book = {
  root: '/',
  chat: '/chat',
  chatID: '/chat/:chatId',
  u: '/u/:nickname',
  listChat: '/list-chat',
  notifications: '/notifications',
  user: '/user',
  userMenu: '/user/menu',
  userAccount: '/user/account',
  userInvite: '/user/invite',
  group: '/user/groups/:groupId',
  groups: '/user/groups',
  userProfileInfo: '/user/profileInfo',
  userBillingBalance: '/user/billingBalance',
  userActions: '/user/actions',
  promoNewsletter: '/user/promo-newsletter',
  userPaidContent: '/user/paidcontent',
  userGetPaid: '/user/getpaid',
  statisticsSummary: '/user/statistics-summary',
  statisticsTransactions: '/user/statistics-transactions',
  statisticsYourSubscribers: '/user/statistics-your-subscribers',
  landing: '/user/landing',
  userPromote: '/user/promote',
};

export const fanterLinks = {
  term_of_use: 'https://cdn.fanter.com/docs/term_of_use.pdf',
  privacy_policy: 'https://cdn.fanter.com/docs/privacy_policy.pdf',
  return_refund:
    'https://cdn.fanter.com/download/return-refund-policy-fanter.pdf',
  special_rules_for_creators:
    'https://cdn.fanter.com/download/special_rules_for_creators.pdf',
};

export const fanterxLinks = {
  term_of_use: 'https://cdn.fanter.com/download/term_of_use.pdf',
  privacy_policy: 'https://cdn.fanter.com/download/privacy_policy.pdf',
  return_refund:
    'https://cdn.fanter.com/download/return-refund-policy-fanter.pdf',
  special_rules_for_creators:
    'https://cdn.fanter.com/download/special_rules_for_creators.pdf',
};

export const fanterUKLinks = {
  term_of_use: 'https://cdn.fanter.com/docs/term_of_use_uk.pdf',
  privacy_policy: 'https://cdn.fanter.com/docs/privacy_policy_uk.pdf',
  return_refund:
    'https://cdn.fanter.com/download/return_refund_policy_fanter_uk.pdf',
  special_rules_for_creators:
    'https://cdn.fanter.com/docs/special_rules_for_creators_uk.pdf',
};

import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import Modal from 'react-modal';
import classnames from 'classnames';

import CloseIcon from 'assets/profile/CloseIcon';
import {
  blockScroll,
  bodyAddNoScroll,
  bodyRemoveNoScroll,
  removeBlockScroll,
} from 'helper/domManipulation';

import cn from './FanterModal.module.scss';

export interface IFanterModalRef {
  open: () => void;
  close: () => void;
}

interface IProps {
  title?: string;
  onClose?: () => void;
  children?: JSX.Element;
  actions?: JSX.Element;
  className?: string;
  overlayClassName?: string;
  hideCloseBtn?: boolean;
}

export const FanterModal = forwardRef(
  (
    {
      title,
      onClose,
      children,
      actions,
      className,
      overlayClassName,
      hideCloseBtn,
    }: IProps,
    ref: React.Ref<IFanterModalRef>,
  ): JSX.Element => {
    const [isOpen, setOpen] = useState<boolean>(false);

    useImperativeHandle(ref, () => ({
      open() {
        setOpen(true);
      },
      close() {
        setOpen(false);
      },
    }));

    useEffect(() => {
      if (isOpen) {
        blockScroll();
        return;
      }
      removeBlockScroll();
    }, [isOpen]);

    return (
      <Modal
        className={`${cn.modal} ${className}`}
        overlayClassName={`${cn.overlay} ${overlayClassName}`}
        isOpen={isOpen}
        onRequestClose={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onClose?.();
        }}
        shouldCloseOnOverlayClick
      >
        <div className={cn.modalContent}>
          {!hideCloseBtn && (
            <CloseIcon
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setOpen(false);
                onClose?.();
              }}
              className={cn.modalContent__closeIcon}
            />
          )}
          <div className={cn.modalContent__column}>
            {!!title && <div className={cn.modalContent__header}>{title}</div>}
            <div className={cn.modalContent__content}>{children}</div>
            {!!actions && (
              <div className={classnames(cn.modalContent__actions, cn.actions)}>
                {actions}
              </div>
            )}
          </div>
        </div>
      </Modal>
    );
  },
);

import React from 'react';

interface IProps {
  className?: string;
  onClick?: () => void;
}

function Icon(props: IProps): JSX.Element {
  return <div {...props}>
    <svg
      width="55"
      height="55"
      viewBox="0 0 55 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37.7 17.3L17.3 37.7M17.3 17.3L37.7 37.7M53 36.2975C53 37.19 52.6388 38.0613 52.0013 38.6988L38.6988 52.0013C38.0613 52.6388 37.19 53 36.2975 53H18.7025C17.81 53 16.9388 52.6388 16.3013 52.0013L2.99875 38.6988C2.36125 38.0613 2 37.19 2 36.2975V18.7025C2 17.81 2.36125 16.9388 2.99875 16.3013L16.3013 2.99875C16.9388 2.36125 17.81 2 18.7025 2H36.2763C37.1688 2 38.04 2.36125 38.6775 2.99875L51.98 16.3013C52.6175 16.9388 52.9788 17.81 52.9788 18.7025V36.2975H53Z"
        stroke="#CF142B"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
}

export default Icon;

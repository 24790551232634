import React from 'react';

interface IProps {
  className?: string;
  onClick?: () => void;
}

function Icon(props: IProps): JSX.Element {
  return <div {...props}>
    <svg
      width="6"
      height="10"
      viewBox="0 0 6 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.95636 4.91629L1.24727 1.05028C1.18909 0.997563 1.10182 0.997563 1.04364 1.05028C0.985455 1.103 0.985455 1.20844 1.04364 1.26115L4.65091 5.00415L1.04364 8.76472C0.985455 8.81744 0.985455 8.92288 1.04364 8.9756C1.07273 9.01074 1.10182 9.01074 1.14545 9.01074C1.18909 9.01074 1.21818 8.99317 1.24727 8.9756L4.95636 5.12716C5.01455 5.05687 5.01455 4.96901 4.95636 4.91629Z"
        fill="#0E1114"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
    </svg>
  </div>
}

export default Icon;

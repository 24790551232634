import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FetchedData, genFetchedData } from '../../core/api/fetchedData';

export interface AddPostState {
  addPost: FetchedData<boolean>;
}

export const initialState: AddPostState = {
  addPost: genFetchedData<boolean>(null),
};

const slice = createSlice({
  name: 'addPost',
  initialState,
  reducers: {
    setAddPost(state, action: PayloadAction<AddPostState['addPost']>) {
      state.addPost = action.payload;
    },
  },
});

export const { setAddPost } = slice.actions;

export const addPostReducer = slice.reducer;

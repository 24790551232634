import React, { useEffect, useMemo, useRef } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { useTracking } from 'react-tracking';
import queryString from 'query-string';
import eruda from 'eruda';
import useFetchPersonalData, { useSelectLg } from '../hooks';
import { RootState } from '../../../core/rootReducer';
import { UserState } from '../reduser';
import { publicBook } from '../../../books';
import Preload from './Preload';
import { fetchGetUnreadCount } from '../../chat/actions';
import { fetchHaveUnread } from '../../notifications/actions';
import { RegistrationState } from '../../registrations/reduser';
import { decodeURLSafeBase64 } from '../../../helper/URLSafeBase64';
import { ApiUrlSingleton } from '../../../core/api/ApiUrlSingleton';
import {
  fetchLoginTelegramm,
  PFetchLoginTelegramm,
} from '../../registrations/actions';
import { getUtm, setUtm, UTMSet } from '../utm';
import { userSetUTM } from '../../../core/api/payWallet';

interface IProps {
  children: JSX.Element;
}

export function ChekedUser({ children }: IProps): JSX.Element {
  const fetchPersonalData = useFetchPersonalData();
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { trackEvent } = useTracking();
  const idInterval = useRef<ReturnType<typeof setInterval> | null>(null);

  const profileState = useSelector<RootState, UserState['profile']>(
    ({ userReducer }) => userReducer.profile,
  );
  const profile = useMemo(() => profileState.get('data'), [profileState]);

  const login = useSelector<RootState, RegistrationState['login']>(
    ({ registrationReducer }) => registrationReducer.login,
    shallowEqual,
  );

  useEffect(() => {
    if (process.env.REACT_APP_IS_PRODUCTION === 'true') return;
    eruda.init();
  }, []);
  useEffect(() => {
    if (!Telegram?.WebApp.initData) return;
    trackEvent({ action: 'tryToLogin' });

    let user: string | null | undefined = null;
    let postId: string | null | undefined = null;
    let chatLogin: string | null | undefined = null;

    try {
      const start_param = Telegram?.WebApp?.initDataUnsafe?.start_param;
      const params = start_param
        ? new URLSearchParams(decodeURLSafeBase64(start_param))
        : null;
      user = params?.get('u');
      postId = params?.get('p');
      chatLogin = params?.get('c');
    } catch (e) {
      console.warn(e);
    }

    const userTd = Telegram.WebApp?.initDataUnsafe?.user?.id;
    const lastUserTd = localStorage.getItem('lastTGUserId') || undefined;
    const isTheSameUser = userTd?.toString() === lastUserTd;
    if (
      JSON.parse(expire_refresh ?? '0') * 1000 + 6000 <= Date.now() ||
      !isTheSameUser
    ) {
      const payload: PFetchLoginTelegramm = {
        toProfile: 'true',
        profileAction: 'true',
      };

      if (user && !postId) {
        payload.toProfile = user;
        payload.profileAction = 'profile';
        user = null;
      }
      if (postId && !user) {
        payload.toProfile = postId;
        payload.profileAction = 'post';
        postId = null;
      }
      if (chatLogin) {
        payload.toProfile = chatLogin;
        payload.profileAction = 'chat';
        chatLogin = null;
      }

      if (postId && user) {
        payload.toProfile = `/u/${user}?postId=${postId}`;
        payload.profileAction = 'userPost';
        postId = null;
        user = null;
      }

      dispatch(fetchLoginTelegramm(payload));
    }

    if (postId && user) {
      const url = `/u/${user}?postId=${postId}`;
      history.push(url);
      return;
    }
    if (user) {
      history.push(`/u/${user}`);
      return;
    }
    if (postId) {
      history.push(`/?postId=${postId}`);
      return;
    }
    if (chatLogin) {
      const url = ['', 'all'].includes(chatLogin)
        ? '/chat'
        : `/chat?u=${chatLogin}`;
      history.push(url, { location: { search: `u=${chatLogin}` } });
      return;
    }
  }, [Telegram?.WebApp]);

  useEffect(() => {
    const hashUser = login.get('data')?.hashUser;

    if (hashUser && login.get('isLoading') === false) {
      trackEvent({ page: '/', action: 'LoginSuccess' });
    }
  }, [login, profile]);

  useEffect(() => {
    if (!profile) return;
    const hashUser = profile.hashUser;
    if (!hashUser) return;
    console.log({ hashUser });
    try {
      const start_param = Telegram?.WebApp?.initDataUnsafe?.start_param;
      const params = start_param
        ? new URLSearchParams(decodeURLSafeBase64(start_param))
        : null;

      const uso = params?.get('uso') || undefined;
      const uca = params?.get('uca') || undefined;
      const uco = params?.get('uco') || undefined;

      setUtm({ uso, uca, uco }, hashUser);

      const utms = getUtm(hashUser);
      if (!Object.keys(utms ?? {}).length) return;

      setTimeout(() => {
        userSetUTM({
          utm_source: utms?.uso?.value,
          utm_campaign: utms?.uca?.value,
          utm_content: utms?.uco?.value,
        }).catch((e) => {
          userSetUTM({
            utm_source: utms?.uso?.value,
            utm_campaign: utms?.uca?.value,
            utm_content: utms?.uco?.value,
          }).catch(console.warn);
        });
      }, 500);
    } catch (e) {
      console.warn(e);
    }
  }, [profile]);

  useEffect(() => {
    const search = queryString.parse(location.search);

    if (search.regInvite) {
      localStorage.setItem('regInvite', search.regInvite as string);
    }

    Object.keys(search).forEach((key) => {
      if (
        [
          'utm_source',
          'utm_medium',
          'utm_campaign',
          'utm_term',
          'utm_content',
          'esub',
          'set',
          'land',
        ].includes(key)
      ) {
        localStorage.setItem(key, decodeURIComponent(search[key] as string));
        localStorage.setItem(`${key}_time`, new Date().getTime().toString());
      }
    });

    if (
      location.pathname === '/' ||
      location.pathname === '/notifications' ||
      location.pathname.startsWith('/chat') ||
      location.pathname.startsWith('/u/') ||
      location.pathname.startsWith('/user')
    ) {
      // @ts-ignore
      window.dataLayer?.push({ event: 'ViewContent' });
    }
  }, [location]);

  // useSelectLg();
  const expire_refresh: string | null =
    window.localStorage.getItem('expire_refresh');

  useEffect(() => {
    if (profile === null) {
      return undefined;
    }

    if (idInterval.current !== null) {
      clearInterval(idInterval.current);
      idInterval.current = null;
    }

    dispatch(fetchGetUnreadCount());
    dispatch(fetchHaveUnread());

    idInterval.current = setInterval(
      () => {
        // if (document.hidden) return undefined;
        dispatch(fetchGetUnreadCount());
        dispatch(fetchHaveUnread());
        return undefined;
      },
      ApiUrlSingleton.getInstance().isProd() ? 3000 : 60000,
    );

    return () => {
      if (idInterval.current !== null) {
        clearInterval(idInterval.current);
      }
      // dispatch(delUnreadCount());
    };
  }, [profile, idInterval.current]);

  useEffect(() => {
    if (profile?.lang !== undefined && profile.lang !== i18n.language) {
      i18n.changeLanguage(profile.lang);
    }
  }, [profile?.lang]);

  useEffect(() => {
    if (JSON.parse(expire_refresh ?? '0') * 1000 + 6000 <= +new Date()) {
      return;
    }
    fetchPersonalData();
  }, []);

  const ismatchemailconfirm = useRouteMatch(publicBook.emailconfirm);
  const isPasswordreset = useRouteMatch(publicBook.passwordreset);

  if (
    [publicBook.signIn, publicBook.signUp].includes(location.pathname) &&
    profileState?.get('LTU') === 0 &&
    expire_refresh &&
    JSON.parse(expire_refresh ?? '0') * 1000 + 6000 > +new Date() &&
    !localStorage.getItem('CheckedUserPreventRedirect')
  ) {
    history.push('/');
    return <></>;
  }

  if (isPasswordreset) {
    return children;
  }
  if (ismatchemailconfirm) {
    return children;
  }

  // если нет инфы о токене кидаем на вход ...
  if (
    [
      publicBook.signIn,
      publicBook.signUp,
      '/restore',
      publicBook.interEmail,
      publicBook.contacts,
      publicBook.documents,
      publicBook.pay,
      publicBook.successPay,
      publicBook.nitafans,
      publicBook.tusUpload,
    ].includes(location.pathname) ||
    location.pathname.startsWith('/u/') ||
    location.pathname.startsWith('/v2/')
  ) {
    return children;
  }

  if (JSON.parse(expire_refresh ?? '0') * 1000 + 6000 <= +new Date()) {
    history.push(publicBook.signIn);
    return <Preload />;
  }

  if (profile === null) {
    return <Preload />;
  }

  return children;
}

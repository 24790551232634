import React from 'react';

import { TContentFile } from '../../../types/TContent';
import { VideoItem } from './VideoItem/VideoItem';
import { ImageItem } from './ImageItem/ImageItem';
import { normalizeImgSrc } from '../../../utils/utils';

import PlayIcon from '../../../assets/post/PlayIcon';

import cn from './Slide.module.css';

interface IProps {
  file: TContentFile;
  onClick?: () => void;
  isFullScreen?: boolean;
  onSwipeLeft?: () => void;
  onSwipeRight?: () => void;
  onZoom?: (scale: number) => void;
  autoPlay?: boolean;
  onHoverVideoControlBar?: (isHover: boolean) => void;
  onOutsideClick: () => void;
}

export function Slide({
  file,
  onClick,
  isFullScreen,
  onSwipeLeft,
  onSwipeRight,
  onZoom,
  autoPlay,
  onHoverVideoControlBar,
  onOutsideClick,
}: IProps): JSX.Element {
  const isVideo = file.concept === 3;
  const isImage = file.concept === 2;

  const onClickSlideHandler = (
    event: React.MouseEvent<HTMLDivElement>,
  ): void => {
    if (onClick) {
      if (!isFullScreen) {
        event.preventDefault();
        onClick();
      }
    }
  };

  return (
    <div className={cn.slide} onClick={onClickSlideHandler}>
      {isFullScreen && isVideo && (
        <VideoItem
          key="file.path"
          videoSrc={file.path}
          previewSrc={file.path.replace('.m3u8', '.jpg')}
          autoPlay={autoPlay}
          onHoverControlBar={onHoverVideoControlBar}
        />
      )}
      {(!isFullScreen || isImage) && (
        <ImageItem
          src={isVideo ? file.path.replace('.m3u8', '.jpg') : file.path}
          className={[cn.postimage, isFullScreen ? cn.fullscreen : ''].join(
            ' ',
          )}
          isFullScreen={isFullScreen}
          onSwipeLeft={onSwipeLeft}
          onSwipeRight={onSwipeRight}
          onZoom={onZoom}
          onOutsideClick={onOutsideClick}
        />
      )}
      {!isFullScreen && (
        <img
          src={normalizeImgSrc(
            isVideo ? file.path.replace('.m3u8', '.jpg') : file.path,
          )}
          className={`${cn.postimage} ${cn.blurimg}`}
          alt=""
        />
      )}
      {!isFullScreen && isVideo && (
        <button type="button" className={cn.post_videoplay}>
          <PlayIcon />
        </button>
      )}
    </div>
  );
}

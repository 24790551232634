import React from 'react';

interface IProps {
  className?: string;
  onClick?: () => void;
}

function Icon(props: IProps): JSX.Element {
  return <div {...props}>
    <svg
      width="55"
      height="51"
      viewBox="0 0 55 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.5 40.6071V38.9286M27.5 32.2143V17.1071M52.3625 41.5723L31.9625 4.62277C31.0913 3.07009 29.4338 2 27.5 2C25.5663 2 23.9088 3.07009 23.0375 4.62277L2.6375 41.5723C2.255 42.2857 2 43.083 2 43.9643C2 46.7549 4.27375 49 7.1 49H47.9C50.7263 49 53 46.7549 53 43.9643C53 43.104 52.7663 42.2857 52.3625 41.5723Z"
        stroke="#CF142B"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
}

export default Icon;

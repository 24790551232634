import { createAction } from '@reduxjs/toolkit';
import { TContentFile } from '../../types/TContent';
import { TPost } from './TPost';

export const fetchAddPost = createAction<
  TPost & { leftFreeFiles: TContentFile[]; leftPayedFiles: TContentFile[] },
  'addPost/fetchAddPost'
>('addPost/fetchAddPost');
export const fetchEditPost = createAction<
  TPost & { leftFreeFiles: TContentFile[]; leftPayedFiles: TContentFile[] },
  'addPost/fetchEditPost'
>('addPost/fetchEditPost');

/* eslint-disable no-template-curly-in-string */

export default {
  translations: {
    common: {
      back: 'Back',
      Save: 'Save',
      TypeNote: 'Type note',
      Search: 'Search',
      Empty: 'Empty',
      Group: 'Group',
      Groups: 'Groups',
      countOfParticipants: '{{count}} participants',
      Send: 'Send',
      Done: 'Done',
      VIP_Subscription: 'VIP Subscription',
      AmountUSD: 'Amount, USD',
      errorAmountMin: 'Enter a minimum of ${{count}}',
      errorAmountMax: 'Max amount is ${{count}}',
      CreateFanterAccount: 'Create<br />fanter account',
      SignInFanterAccount: 'Sign in<br />fanter account ',
      pleaseAuth:
        "Please <span id='postStubSignIn'>login</span> in or <span id='postStubSignUp'>register</span> to receive content.",
    },
    alerts: {
      success: {
        setNote: 'Note saved successfully',
      },
    },
    landing: {
      NitaKuzminaV2: {
        ExclusiveGame: 'Exclusive game',
        text0: 'First select a look!',
        text1: 'Do you want some unique content that i will shoot for you?',
        text2: 'Get access to exclusive content only on fanter',
        signUp: 'Sign up now',
        PlayGame: 'Play the game',
      },
      Landing: 'Landing page',
      Sets: 'Set of landing pages',
      AddSet: 'Add a set of characters for a landing page',
      setName: 'Name',
      Add: 'Add',
      Cancel: 'Cancel',
      AddSetCharacter: 'Add character',
      SetSuccessfullyCreated: 'Set {{name}} is successfully created',
      SetSuccessfullyDeleted: 'Set {{name}} is successfully deleted',
    },
    onlineStatuses: {
      '1': 'Online',
      '2': 'Away',
      '3': 'Offline',
      '4': 'was less than an hour ago',
      '5': 'was an hour ago',
      '6': 'was {{count}} hour ago',
      '6_plural': 'was {{count}} hours ago',
      '7': 'was {{count}} day ago',
      '7_plural': 'was {{count}} days ago',
      '8': 'was {{count}} week ago',
      '8_plural': 'was {{count}} weeks ago',
      '9': 'was long time ago',
    },
    authorization: {
      SNCWRS: 'Social network to connect with rising stars',
      CAABS: 'Create an account and become the star',
      Password: 'Password',
      Confirm_password: 'Confirm password',
      Sign_up: 'Sign up',
      invite: 'Invite',
      Create_account: 'Create account',
      ARL: 'Already registered? Login',
      Check_your_email: 'Check your emails',
      letter_password:
        "A letter with a link to reset your password has been sent to your email address. If you don't get an email for a long time, check your Spam folder.",
      Login: 'Login',
      Forgot_password: 'Forgot password?',
      Sign_in: 'Sign in',
      nickname: 'Nickname',
      IhaveInvideCode: 'Do you have Invite code?',
      NotValidCode: 'Not valid code',
      Activated: 'Activated',
      Activate: 'Activate',
      BackToLogin: 'Return to the login page',
    },
    invite: {
      title: 'Do you have an invitation code?',
      text: 'Enter the invitation code, if you are registering by invitation. You can skip this step.',
      enter: 'Enter your invite code',
      activate: 'Activate',
      skip: 'Skip',
      succes: 'You have successfully entered the invite code:',
      succes_text: 'Welcome to fanter!',
      go_to: 'Go to Timeline',
    },
    forgot_password: {
      re_send: 'Reset',
      title_new_p: 'Enter a new password',
      activate: 'Activate',
      no_valid: 'The page is not valid',
      repeat: 'Repeat the request to change password.',
      home: 'Home',
    },
    e_mail_confirmed: {
      confirmed: 'Thank you, email confirmed',
      created: 'Your account has been created',
      done: 'Done',
      repeat: 'Repeat the request to confirm email.',
    },
    header: {
      Timeline: 'Timeline',
      Chats: 'Chats',
      Hello: 'Hello',
    },
    Post: {
      Blocked: 'Blocked',
      ConvertingErrorTitle: 'Video processing error',
      ConvertingErrorText: 'The video cannot be uploaded',
      VideoUploadingTitle: 'Your video uploading...',
      VideoUploadingText:
        'The video will be available in your feed soon;Only you can see this message',
      TypeComment: 'Write a comment',
      Submit: 'Send',
      PaidContent: 'Paid content',
      BuyNow: 'Buy now',
      Photos: 'Photos',
      Videos: 'Videos',
      FreeForFanterVIP: 'Free with a Fanter subscription',
      FreeForSubscribersVIP: 'Free with a subscription',
      FreeForSubscribersVIPWithTrial: 'Get free access for {{count}} days',
      ThenForSubscribersVIPWithTrial: 'then ${{then}}/month',
      Tips: 'Tips',
      SendTips: 'Send tips',
      BlockPost: 'Block the post',
      UnblockPost: 'Unblock the post',
      ConfirmPost: 'Confirm the post',
      Decline: 'Decline the post',
      SharePost: 'Share',
      EditPost: 'Edit',
      DisplayInMainContent: 'Show in the feed',
      UnDisplayInMainContent: 'Remove from the feed',
      SuccessfullyBlocked: 'The post has been successfully blocked',
      SuccessfullyUnblocked: 'The post has been successfully unblocked',
      SuccessfullyConfirmed: 'The post has been successfully confirmed',
      SuccessfullyDecline: 'Post was successfully declined',
      SuccessfullySetDisplayInMainContent:
        'The post has been successfully added to the feed',
      SuccessfullyUnSetDisplayInMainContent:
        'The post has been successfully removed from the feed',
      FreeForVIP: 'Free for VIP',
      deleteComment: 'Delete a comment',
      deleteCommentSuccessfully: 'Comment successfully deleted',
      waitingPay: 'Waiting for payment system acknowledge',
    },
    AddPost: {
      PublicPost: 'Public post',
      PrivatePost: 'Private post',
      NewPost: 'New post',
      EditPost: 'Editing a publication',
      Title: 'Title',
      Description: 'Description',
      UploadPV: 'Upload a photo or a video',
      UploadP: 'Upload a photo',
      PaidContent: 'Paid content',
      TextBuyButton: 'Text on the Buy button',
      PaidContentDescription: 'Paid content description',
      PaidContentIsFreeFor: 'Paid content is free for',
      HidePaidContent: 'Hide paid content',
      ChatText: 'Chat text',
      ChatDescription:
        'This text will be shown with the media materials if you send a post in the chat',
      Post: 'Post',
      Prewiew: 'Preview',
      Prise: 'Price',
      MedeaFormat: 'Photo format JPEG, JPG, PNG, MP4, MOV',
      PhotoFormat: 'Photo format JPEG, JPG, PNG',
      FanterVIPs: 'Fanter	VIPs',
      YourVIPs: 'Your VIPs',
      OnlyForSubscribers: 'Only for subscribers',
      HideChatText: 'Hide chat text',
      AddPaidContent: 'Add paid content',
      AddChatText: 'Add chat text',
      Progress: 'Progress {{PROGRESS}}%',
      Errors: {
        TitleRequired: 'Fill out the post title',
        TitleLong: 'The title is too long (300 characters max)',
        DescriptionRequred: 'Fill out the post text',
        TextLong: 'The post is too long (2500 characters max)',
        BuyButten: 'The button text is too long (250 characters max)',
        PriceRequired: "Specify the price of the post's paid part ",
        AmountMin: 'You cannot set the price of paid content below $5',
        FreeMedeaContentRequired: 'Add free photos or videos',
        PayedMedeaContentRequired: 'Add paid photos or videos',
      },
      Save: 'Save edits',
      NotFound: 'Page not found. It may have been deleted.',
      AccessDeclined: 'Access denied',
      OnlyForSubscribersToolTip:
        'First, select "Your VIP-persons" and/or "Fanters VIP"',
    },
    CreatePost: {
      placeholder: 'Type something',
      Publication_error: 'Publication error',
      successfully: 'Post successfully published',
      editSuccessfully: 'The post is successfully edited',
      New_post: 'New post',
      Choose_image: 'Choose an image',
      Title: 'Title',
      Text: 'Text',
      Share: 'Post',
      Drag_image: 'Drag image here, or ',
      browse: 'browse',
      text_required: 'Field text is required',
      Image_required: 'Post Image is required',
      Paid_content: 'Paid content',
      Your_VIP: 'Your VIPs',
      Fanter_VIP: 'Fanter VIP',
      Free: 'Free',
      Public_post: 'Public post',
      Public_txt:
        '​After publication, this post will get into your feed\n' +
        'and will be available to users for free or for a fee/subscription',
      Private_post: 'Private post',
      Private_txt:
        'After publication, you can send this\n' +
        'post to a user in the chat for free or with an offer to purchase',
      Choose_p_t: 'Choose post type',
    },
    SettingsMenu: {
      MyProfile: 'My Profile',
      Profile: 'Profile',
      Profile_info: 'Profile info',
      Account: 'Account',
      BandB: 'Billing & Balance',
      Invite: 'Invite others and earn profit',
      Pro_Features: 'Pro-Features',
      Paid_content: 'Paid content',
      Get_Paid: 'Get Paid',
      Status: 'Statistics',
      Promote: 'Promote',
      to_your_profile: 'Link to your profile',
      log_out: 'Sign out',
      Get_Business_account: 'Get Business account',
      Summary: 'Summary',
      Transactions: 'Transactions',
    },
    Pay: {
      Payment: 'Payment',
      PaymentMethod: 'Payment method',
      Thank: 'Thank you,; for paying for your order',
      PaymentSuccessful: 'Payment successful',
      Done: 'Done',
    },
    AccountActions: {
      Actions: 'Actions',
      NewFollower: "You've got a new follower",
      DoNothing: 'Nothing',
      SendMessage: 'Message',
      TypeMessage: 'Type a message',
      Save: 'Save edits',
      Interaction: 'Interaction with your followers',
      Post: 'Post',
      ChoiceContent: 'Choice content',
      Public: 'Public',
      All: 'All',
      NotPurchased: 'Not purchased',
      Purchased: 'Purchased',
      PostPurchased: 'Purchased',
      NotBought: 'Not bought',
      Private: 'Private',
      Select: 'Select',
      ChatGuisesLabel: 'Chat chatacters',
      ChatGuisesSubLabel:
        'Users can choice some characters in your private chat',
      AddCharecter: 'Add character',
      EditCharecter: 'Edit character {{CHARACTER_NAME}}',
      CharacterName: 'Character name',
      CharacterPhoto: 'Character photo',
      SaveCharacter: 'Save a character',
      UpdateCharacter: 'Update character',
      CharacterSuccessfullyCreated:
        'Сharacter {{CHARACTER_NAME}} is successfully created',
      CharacterSuccessfullyEdited:
        'Сharacter {{CHARACTER_NAME}} is successfully edited',
      CharacterSuccessfullyDeleted:
        'Сharacter {{CHARACTER_NAME}} is successfully deleted',
      DeleteRole: 'Do you really want to delete this role?',
      WhenСhoiceСharacter: 'When user choice character',
      CharecterName: 'Character name',
      VIP_Subscription: 'VIP Subscription',
      UploadMedia: 'Upload',
      Gallery: 'Gallery',
    },
    AccountPromoNewsletter: {
      PromoNewsletter: 'Promo newsletter',
      Text: 'Create a post to share with everyone',
      Send: 'Send everyone',
      SuccessAlert: 'Promo newsletter was successfully sent',
      ShareTo: 'Share to',
      EveryoneOrChoiceGroup: 'Everyone or choose a group',
      CustomContentPrice: 'Custom content price',
      toFollowers: 'Followers',
      followers: 'Followers',
      subscribersVip: 'VIP subscribers',
      Everyone: 'Everyone subscribers',
      AttachText: 'Attach text',
      PriceUSD: 'Price, USD',
    },
    AccountPaidContent: {
      title: 'Paid content',
      enable: 'Activate',
      enabled: 'Activated',
      disable: 'Deactivate',
      disabled: 'Deactivated',
      durationText:
        'after a trial period, a user will be charged monthly payment',
      Price: 'Price',
      UsdMonth: 'usd / month',
      Save: 'Save',
      SubscrTit: 'Subscription',
      SuccessAlert: 'Subscription price was successfully set',
      mo: 'mo',
      discountSaved: 'New price was successfully created',
      Trial: 'Trial',
      Enabled: 'Enable',
      Days: 'Days',
      SuccessfullySaveTrialState: 'The trial was successfully changed',
      changePriceWarning:
        'Attention! After the price of the VIP subscription increases auto-renewal will be automatically deactivated for your subscribers.',
      commission: 'Fanter’s commission',
      tariff: {
        standard: 'Standard',
        free: 'Free',
      },
      fellText:
        'The Fanter receives a standard commission of {{commission}}% of the payment. Payments less than 10 cents are not counted in billing.',
      PaidMessages: 'Paid messages',
      EachMessagePriceCost: 'Cost per message',
      UsersShouldByPayToMessageYou:
        'The user should pay for each message to you',
      setMessageFeeSuccessAlert:
        'The price for the message was successfully set',
    },
    SexGame: {
      LinkToPage: 'Link to the page',
      SexGame: 'Sex game',
      WhenUserChoiceCharacter: 'Who can choose a role in the chat',
      Everyone: 'Everyone',
      OnlyVIPSubscribers: 'Only VIP subscribers',
      PleaseSetPriceForYourVIPSubscription:
        'Please set the price for your VIP subscription',
      AccessSettingsHaveBeenSavedSuccessfully:
        'Access settings have been saved successfully',
    },
    AccountGetPaid: {
      Account: 'Bank details',
      FIO: 'Full name of payee',
      AccountNumber: 'Bank account number',
      Bank: 'Bank name',
      bikBank: 'RCBIC',
      innBank: 'Bank TIN',
      kppBank: 'Bank TRRC',
      korrBank: 'Corresponding bank account',
      IdentityVerification: 'Identity verification',
      Upload: 'Upload photo',
      SaveDataSuccessAlert: 'Bank details were successfully set',
      UploadSuccessAlert:
        'Documents for identity verification were successfully uploaded',
      AvailableToWithdraw: 'Available to withdraw',
      AmountInUSD: 'Amount in usd',
      withdrawBtn: 'Withdraw',
      withdrawText1: '- you can withdraw no less than $20',
      withdrawText2: '- the withdrawal takes from 3 to 5 working days',
      withdrawSuccessfuly: 'The withdrawal request has been successfully sent',
      withdrawWarn:
        'To make a withdrawal transaction, first fill in the input fields for the details.',
      Promotions: 'Promotions',
      PromoMonthlyPrice: 'Promo monthly price',
      period: 'Period',
      DateOfBirth: 'Date of birth',
      AddressOfResidence: 'Address of residence',
      StreetAddress: 'Street address',
      City: 'City',
      State: 'State',
      ZipCode: 'ZIP code',
      ChoiceOneTypeWithdrawal: 'Choice one type withdrawal',
      BankDetails: 'Bank details',
      CardDetails: 'Card details',
      CardNumber: 'Card number',
      cardHolder: 'Cardholder name',
      WeSentCodeYour: 'We sent a code to your e-mail address',
      timeToResend: '15 min to confirm',
      SendNewCode: 'Send a new code',
      EditConfirmation: 'Edit confirmation',
      timeIsUp: 'Time is up',
      TheCodeWasSuccessfullySent: 'The code was successfully sent',
    },
    AccountBilling: {
      BillingBalance: 'Billing & Balance',
      AddCard: 'Add card',
      CardNumber: 'Card number',
      CardholderName: 'Cardholder name',
      ExpirationDate: 'Expiration date',
      CvcRequirements: 'The last 3 digits on back of the card',
      YourCards: 'Your cards',
      ValidThru: 'VALID THRU',
      Active: 'Active',
      Blocked: 'Blocked',
      Expired: 'Expired',
      Inactive: 'Inactive',
      InvalidCardNumber: 'Invalid card number',
      EnterCardHolderName: "Enter the cardholder's name",
      EnterCardExpirationDate: 'Enter the card expiration date',
      EnterCVVCode: 'Enter the CVV code',
      InvalidCVVCode: 'Invalid CVV code',
      CardExpired: 'The card has expired',
      OrderId: 'Order id',
      Sum: 'Sum',
      Send: 'Send',
      AccountBalance: 'Balance',
      TopUpBalance: 'Top up',
      YouCanUseYourFundsOnlyInMessagesContent:
        'This balance can only be used to pay for messages.',
      fetchDepositForChatSuccessAlert:
        'Your balance has been successfully topped up',
      WeNotAcceptingRussianCard: 'We are not accepting Russian bank card',
      enterAnotherCard: 'Enter another card',
      enterRussianCard:
        'The card number has not been verified. Enter the card number belonging to the Bank of the Russian Federation.',
      YouNeedConfirmEmail: 'You need to confirm the email to add the card',
    },
    AccountStatistics: {
      Summary: 'Summary',
      Period: 'Period',
      Month: 'Month',
      Week: 'Week',
      Day: 'Day',
      Operations: 'Operations',
      AllOperations: 'All operations',
      PaidContent: 'Paid content',
      Tips: 'Tips',
      Date: 'Date',
      Description: 'Description',
      Quantity: 'Number',
      Amount: 'Amount',
      SummaryColumn: 'Summary',
      Username: 'Author',
      Content: 'Content',
      Pages1: '{{PAGES}} page',
      Pages234: '{{PAGES}} pages',
      Pages: '{{PAGES}} pages',
      payType0: 'Paid content',
      payType1: 'Tips',
      payType2: 'Subscription',
      payType3: 'Withdraw',
      payType4: 'Trial',
      payType10: 'Paid message',
      Transactions: 'Transactions',
      YourSubscribers: 'Your subscribers',
      HideFilters: 'Hide filters',
      ShowFilters: 'Show filters',
      User: 'User',
      TotalSpent: 'Total spent',
      SubscribersCount: '{{count}} users are subscribed to you',
    },
    AccountSettings: {
      E_mail: 'E-mail address',
      Your_e_mail: 'Your e-mail',
      is_confirmed: 'is confirmed!',
      Confirm_your_e_mail: 'Confirm your e-mail',
      Confirmation_has_been_sent: 'Confirmation has been sent',
      already_confirmation: 'We already sent to your email confirmation',
      Re_sendrofile: 'Re-send confirmation',
      Change_y_password: 'Change your password',
      Old_password: 'Old password',
      Current_password: 'Current password',
      New_password: 'New password',
      confirmPassword: 'Confirm password',
      Change_password: 'Change password',
      Your_language: 'Your language',
      Language: 'Language',
      Deactivate_your_account: 'Deactivate your account',
      Deactivate_txt:
        'If you deactivate your account people don’t be able to find you or your posts until your re-activate your account',
      Deactivate_Account: 'Deactivate Account',
      Activate_txt:
        'Your account is deactivated. Other users will not be able to access your page directly or via search until you activate it.',
      Activate_Account: 'Activate Account',
      Save: 'Save',
      Password_changed: 'Password changed successfully.',
      Business_account: 'Business account',
      PrivacySettings: 'Privacy settings',
      WhoCanSendMessages: 'Who can send me private messages',
      All: 'All',
      OnlyPaidSubscribers: 'Only paid subscribers',
      blockChatsSuccess:
        'Settings for limiting the message sending are successfully saved',
    },
    Business_account: {
      activate_txt:
        'Activate your business account and get access to the Pro-Features settings section',
      activate_btn: 'Activate business account',
      deActivate_txt:
        'If you deactivate your business account, all the settings in the Pro-Features section will no longer be available in your account',
      deActivate_btn: 'Deactivate business account',
      requested_txt:
        'Being moderated! Commerce section will be available once your request is confirmed by the administrator.',
      uploadDocRequested_txt:
        'You have to complete verification procedure to change account type. You can upload your documents and place and submit a request in the block below.',
      uploadDocRequested_btn: 'Cancel the application',
      ConfirmCancelRequestTitle: 'Cancel the application',
      ConfirmCancelRequestDescription: 'All uploaded documents will be deleted',
      docsRejected: 'Documents have not passed verification. Try again later.',
      docsApproved:
        'Activate a business account and access the settings section "Commerce"',
      SendOnReview: 'Send for verification',
      requirementsTit: 'Upload two photos to verify your identity:',
      requirementsTitDoc: 'A photo of one of the following documents:',
      requirementsTextId: 'identification card',
      requirementsTextId2: "driver's license",
      requirementsRulesId: 'Document requirement:',
      requirementsRules1:
        'the photo must be taken in sufficient light and must not be blurred',
      requirementsRules2: 'the photo must be over 800 x 600 pixels',
      requirementsPhotoHand: 'Upload a photo with the document in your hands:',
      requirementsPhotoHand1:
        'upload a photo where your face and the document are clearly visible',
      requirementsPhotoHand2: 'no one must be in the frame but you',
      requirementsPhotoHand3: 'the passport number must be clearly visible',
      requirementsPhotoHand4:
        'the photo must not be darkened or blurred (no filters used)',
      requirementsPhotoHand5:
        'the photo must be taken no earlier than one month prior to the application',
      requirementsLastTit:
        'Account verification takes up to five working days. If you have questions, the support team will contact you additionally.',
      photoYourID: 'Photo of your ID',
      publishedPost: 'Published post',
      selfieYourID: 'Selfie with your ID',
    },
    ConfirmModal: {
      Confirm: 'Confirm',
      Cancel: 'Cancel',
    },
    ConfirmTransactionModal: {
      title: 'Transaction confirmation',
      text: 'We have deducted a small amount from your bank card (up to 99 cents). To use your card in the system, enter the amount that was charged from your bank card below.',
      successAlert:
        'The amount entered is correct. Your card is successfully linked.',
    },
    InviteSettings: {
      m_title: 'Invite others and earn profit',
      title_InviteBlock: 'Invite your friends to Fanter and earn money!',
      txt_InviteBlock:
        'You will get 50% of commission charged by Fanter for the friends you invited.   ',
      Invite_code_copied: 'The invite code was copied.',
      footer_txt_InviteBlock:
        'Share this link to your friends, they should enter this invite code',
      Statistic: 'Statistics',
      Registred_by_your_invite: 'Registred by your invite',
      users: 'users',
      Earned: 'Earned from your refferals',
      Last: 'Last registred users',
      Showing: 'Showing 10 last registred users',
      Share_link: 'Share link',
      Copy: 'Copy',
      Invite_code: 'Invite code',
      Link_copied: 'The link is copied',
    },
    TimelineMenu: {
      Popular: 'Popular',
      PopularForIsView: 'Popular',
      Following: 'Following',
      Subscriptions: 'Subscriptions',
      Starred: 'Starred',
      Liked: 'Liked',
      Private: 'Private',
      Moderation: 'Moderation',
    },
    Timeline: {
      Show: 'Show comments',
      comments: '',
      less: 'hide',
      more: 'more',
      Paid_content: 'Paid content',
      // eslint-disable-next-line no-template-curly-in-string
      Buy: 'Buy now for ${{price}}',
      comment: 'Write a comment',
      Submit: 'Send',
    },
    Profile_info: {
      Profile_image: 'Profile image',
      User_picture: 'User picture',
      Upload: 'Upload a JPEG or PNG file.',
      Drag1: 'Drag an image here or',
      Drag2: 'choose a file',
      Maximum: 'Maximum file size: 2MB',
      Progress: 'Progress',
      background: 'Profile background',
      Save: 'Save',
      Cancel: 'Cancel',
      Personal: 'Personal information',
      Public: 'Public account ID',
      Your_ID: 'Your ID',
      Name: 'Name',
      First_name: 'First name',
      Last_name: 'Last name',
      birthday: 'Your birthday',
      Select: 'Select',
      Save_public_ID: 'Save public ID',
      Edition_information: 'Edition information',
      Location: 'Location',
      Country: 'Country',
      City: 'City',
      About_you: 'About you',
      loading: 'Availability checking',
      redhint: 'ID is taken',
      greenhint: 'ID is changed',
      fullwidth: 'Save public ID',
      copied: 'The link is copied',
      SocialNetworks: 'Social networks',
      InstagramProfile: 'Instagram Profile',
      TwitterProfile: 'Twitter Profile',
      TiktokProfile: 'Tiktok Profile',
      FacebookProfile: 'Facebook Profile',
    },
    Profile: {
      Available: 'Available for',
      Photos: 'Photos',
      Videos: 'Videos',
      And: 'and',
      // eslint-disable-next-line no-template-curly-in-string
      ContentNotPurchad: 'Paid content, ${{PRICE}}, not purchased',
      // eslint-disable-next-line no-template-curly-in-string
      ContentPurchad: 'Paid content, ${{PRICE}}, purchased ${{BUYED_PRICE}}',
      Become: 'Become Fanter VIP for {{price}}/month',
      Special: 'Special offers',
      Posts: 'Posts',
      Followers: 'Followers',
      Follow: 'Follow',
      Unfollow: 'Unfollow',
      Message: 'Message',
      Premium: 'Premium',
      content: 'content for VIP’s',
      month: 'month',
      Subscribe: 'Subscribe',
      AvailableUN: 'Available for {{USERNAME}} VIPs',
      // eslint-disable-next-line no-template-curly-in-string
      RateText: 'Available for ${{AMOUNT}}/month',
      RateUntil: '${{AMOUNT}}/month until {{DATE}}',
      StubRateText: 'Available for purchase soon',
      RateInfo: '{{PHOTOS}} photos and {{VIDEOS}} videos',
      StubRateInfo: '',
      years: 'y.o.',
      noPostsForMe: "You don't have posts yet",
      noPosts: 'The user has no posts yet',
      subscribedTxt: 'You have successfully subscribed',
      BuySubscription: 'Buy a subscription',
      AccessToPremium: 'Access to premium',
      NotFound_1: 'Oops, user not found',
      NotFound_2: '',
    },
    chat: {
      MemberInfo: 'Member info',
      NewMessage: 'New message',
      Select_chat: 'Select a chat',
      Online: 'Online',
      Support: 'Support',
      CreatorsSupport: 'Support for creators',
      Search: 'Search',
      Type_message: 'Type in a message',
      Start_chatting: 'Start chatting',
      Public_chats: 'Public chats',
      Private_chats: 'Private chats',
      min: 'min',
      Your_message: 'Your message history will be displayed here.',
      Today: 'Today',
      SelectCharacter: 'Select character',
      YouSelectedCharacter:
        'You have chosen the character {{CHARACTER_NAME}}, {{DATE}}',
      OpponentSelectedCharacter:
        '{{OPPONENT}} has chosen the character {{CHARACTER_NAME}}, {{DATE}}',
      SendPost: 'Send post',
      SendTipp: 'Send tipp',
      Select: 'Select',
      Cancel: 'Cancel',
      RolePlayOver: 'Roleplay is over',
      ArchiveCharacter: 'Archive character is not available anymore',
      message: {
        read: 'Has been read',
        unread: "Hasn't been read",
      },
      UnblockChat: 'Unblock a user',
      BlockChat: 'Block a user',
      blockedSuccessfully: 'This user has been successfully blocked',
      unblockedSuccessfully: 'This user has been successfully unblocked',
      youBlocked:
        'Your message cannot be sent due to this user’s privacy settings.',
      youUnblocked: 'Now, you can send messages',
      chatIsBlocked:
        'You can send your message only after getting the VIP subscription',
      Changed: 'Changed',
      MessageSuccessfullyDeleted: 'Message is successfully deleted',
      MessageSuccessfullyEdited: 'Message is successfully edited',
      cannotDeletePurchasedPost: 'You cannot delete a purchased post',
      MessageIsDeleted: 'Message is deleted',
      MessageIsEdited: 'Message is edited',
      Recover: 'Recover',
      AddToGroup: 'Add to the group',
      AddDescription: 'Add description',
      userSuccessfullyAdded:
        'The user has been successfully added to the group',
      userSuccessfullyRemoved:
        'The user has been successfully removed from the group',
      groupSuccessfullyCreated: 'The group has been successfully created',
      groupSuccessfullyRemoved: 'The group has been successfully removed',
      CostPerMessage: 'Cost per message is {{messageFee}}',
      SetCostPerMessage:
        'This user changed the price for messages. Cost per message is {{messageFee}}',
      YouSetCostPerMessage:
        'You have changed the price of the messages. The cost of one message is {{messageFee}}',
      fetchPaidMessageSuccessfully: 'Paid message successfully sent',
      SetPaidMessages: 'Set price for messages',
    },
    Information: {
      Information: 'Information',
      EmailAddress: 'E-mail address:',
      PhoneNumber: 'Phone number:',
      TermsOfUse: 'Terms of use',
      PrivacyPolicy: 'Privacy Policy',
      ReturnRefund: 'Refund Policy',
      PaymentMethod: 'Payment method',
      US18: 'Compliance with the 18 U.S. code §2247',
      ContentManagement: 'Content Management Policy',
      ContentPolicy: 'Complaint Policy and Procedure',
      Documents: 'Documents',
      OtherDocs: 'Other documents',
      DMCAPilicy: 'DMCA takedown policy',
      CardNumber: 'Card number',
    },
    fanterInformation: {
      SubsterInc: 'Subster Inc.',
      ContactUs: 'Contacts',
      SpecialRulesForCreators: 'Special rules for creators',
      AgreeRules: 'by submitting an application, you agree to the rules',
      CaliforniaCorporation: 'a California corporation',
      Address:
        'ADS BOOST LTD. Address: Prodromou, 75, Oneworld Parkview House, 4th floor, 2063, Nicosia',
    },
    fanterxInformation: {
      SubsterInc: 'ARAY HUNGARY Kft.',
      CaliforniaCorporation: '',
      Address: 'Address: Hungary, 2161 Csomád, Kossuth Lajos út 79.',
    },
    fanterUKInformation: {
      SubsterInc: 'Subster Uk Ltd',
      CaliforniaCorporation: '',
      Address:
        'Address: 41 Devonshire Street, Ground Floor, London, England, W1G 7AJ',
    },
    buyPostModal: {
      linkCardTitle: 'We failed to process your payment',
      linkCardMessage: 'Please add a card to make payments and send tips',
      forTrialSubscription: 'Please add a card to start your trial',
    },
    SendTips: {
      SendTips: 'Send tips',
      QuickAmount: 'Quick amount',
      EnterAmount: 'Enter amount, USD',
      Comment: 'Comment',
      Success: 'Success!',
      HasBeenSentInTips: '${{AMOUNT}} has been sent in tips!',
      OK: 'OK!',
      InsufficientFundsInYourAccount: 'Insufficient funds in your account',
      YourBalance: 'Your balance: {{BALANCE}}',
      TopUpYourBalance: 'Top up your balance',
      errorAmountRequired: 'Amount is required',
      errorAmountMin: 'Enter a minimum of $5',
      errorAmountMax: 'Max amount is $500',
      InvalidCardNumber: 'Invalid card number',
      EnterCVVCode: 'Enter the CVV code',
      InvalidCVVCode: 'Invalid CVV code',
      EnterCardHolderName: "Enter the cardholder's name",
      CardholderName: 'Cardholder name',
      CardNumber: 'Card number',
      cardDateRequired: 'Введите срок действия',
      cardDateInvalid: 'Неверный срок действия',
      CardExpired: 'The card has expired',
      Required: 'Required field',
      IncorrectData: 'incorrect data',
      ErrorClientReferenceId: 'Invalid link',
    },
    Notifications: {
      Notifications: 'Notifications',
      NoNotifications: 'You will see your notifications here',
      NothingMore: 'Nothing more',
      ShowMore: 'Show more',
      t_0: '<UserLink>{{USER_NAME}}</UserLink> liked your post <PostLink>{{POST_SUBJECT}}</PostLink>',
      t_1: '<UserLink>{{USER_NAME}}</UserLink> subscribed to you',
      t_2: '<UserLink>{{USER_NAME}}</UserLink> signed up for ${{PAY_SUM}}',
      t_3: '<UserLink>{{USER_NAME}}</UserLink> tipped you with ${{PAY_SUM}} for your post <PostLink>{{POST_SUBJECT}}</PostLink>',
      t_4: '<UserLink>{{USER_NAME}}</UserLink> bought post <PostLink>{{POST_SUBJECT}}</PostLink>',
      t_5: '<UserLink>{{USER_NAME}}</UserLink> left a comment on your post <PostLink>{{POST_SUBJECT}}</PostLink>',
      t_6: 'business account is activated',
      t_7: 'business account is deactivated',
      t_8: 'request for business account is denied',
    },
    Subscriptions: {
      title: 'Active subscriptions',
      Active: 'Active',
      Expired: 'Expired',
      Pay: 'Pay',
      Cancel: 'Cancel',
      PriceForMonth: 'Price for month ${{PRICE}}',
      AutoRenewal: 'Auto-renewal',
      until: 'until {{DATE}}',
      newPriceTitlt: 'Price changed',
      newPriceDiscription: 'Subscription price changes to ${{PRICE}}',
      paySuccess: '{{USER_NAME}} VIP Subscription payed',
      trial: 'Free {{count}} days - then ${{then}}/mo',
    },
    errors: {
      anyErr: 'An error has occurred. {{logCode}}',
      40: 'Email already in use',
      Something:
        'The server is unavailable, please try again later. {{logCode}}',
      Try_again: 'Try again',
      Repeat_your_request: 'Repeat your request',
      3000: 'The selected language is currently not available',
      3004: 'Please enter at least 5 characters',
      Required: 'Required',
      3011: 'Wrong email',
      wrong_password: 'Wrong password',
      Chars: 'Password must be 5 characters or more',
      match: "Passwords don't match",
      3001: 'Not valid code',
      emailNot: 'User with this email address is not registered',
      Profile_not_found: 'Profile not found',
      10011: 'Not valid code',
      10014: 'Wrong current password',
      300: 'User with this email address is not registered',
      3006: 'Please enter at least 2 characters',
      3024: 'Please enter at least 2 characters',
      3010: 'Unacceptable symbols',
      3016: 'The message is sent to your e-mail. Try again later if needed.',
      3019: 'Wrong email',
      3021: 'Wrong file type for image content',
      a3021: 'The maximum file upload size is 2GB',
      3029: 'The text is too long: reduce it to 2600 symbols',
      3030: 'The title is too long: reduce it to 300 symbols',
      3061: 'You can create a payed post with a business account only.',
      3066: 'Invalid CVV code',
      10019: 'The image {{NAME}} is too small. Minimum size: 600x600 pixels',
      10020: 'The image is too small. Minimum size: 600x600 pixels',
      10021: 'Unacceptable image {{NAME}} ratio',
      10022: 'Unacceptable image ratio',
      10023: 'Unacceptable image ratio',
      10025: 'Wrong file type for image content',
      10017: 'Invalid symbols',
      10066:
        'Issuing bank declined a transaction, please contact your bank or try another card',
      10091: 'Network error #10091: "Network subsystem is unusable"',
      10106:
        'Network error #10106: "Cannot load or initialize the necessary service provider"',
      10110: 'A duplicate customer payment profile already exists.',
      10111: 'Incorrect data entered',
      10128: 'Nickname already in use',
      10129: 'Email already in use',
      10140:
        'The date you selected is already taken. Please select another date.',
      10180: 'Can chat only VIP subscribers',
      10204: 'Please top up your internal balance in order to send a message.',
      10213: 'The amount entered is incorrect. Please try again.',
      10215: 'The time for input has expired.',
      10216: 'The number of input attempts has been exhausted.',
      10220: 'The confirmation code has expired.',
      10221:
        'The limit on the number of code entries for confirmation has been reached.',
      10223: 'The code is entered incorrectly. Please try again.',
      10225:
        'We tried to charge a small amount from your card, but received a refusal from your bank. Please check that there are enough funds in the account. If there are enough funds in the account, please contact your bank to clarify the reason for the rejection of the transaction.',
      10228:
        'We tried to charge a small amount from your card, but received a refusal from your bank. Please check that there are enough funds in the account. If there are enough funds in the account, please contact your bank to clarify the reason for the rejection of the transaction.',
      10229:
        'We tried to charge a small amount from your card, but received a refusal from your bank. Please check that there are enough funds in the account. If there are enough funds in the account, please contact your bank to clarify the reason for the rejection of the transaction.',
      10233:
        'We tried to charge a small amount from your card, but received a refusal from your bank. Please contact your bank to clarify the reason for the rejection of the transaction.',
      10230:
        'Unfortunately, at the moment the payment system is not responding. This usually takes some time. Please try to return to the payment in 10-30 minutes.',
      10235:
        'Unfortunately, at the moment the payment system is not responding. This usually takes some time. Please try to return to the payment in 10-30 minutes.',
      10224:
        'Unfortunately, you will not be able to link a new card. Try deleting old cards in your profile or write to us in the support chat.',
      10231:
        'Unfortunately, you will not be able to link a new card. Try deleting old cards in your profile or write to us in the support chat.',
      10232:
        'Unfortunately, you will not be able to link a new card. Try deleting old cards in your profile or write to us in the support chat.',
      10234:
        'An error occurred while trying to link the map. Please write to us in the support chat and we will help you.',
      10227:
        'The card has expired. Please try to link another card or contact your bank for more details.',
      10236:
        'Unfortunately, this type of card is not supported. Please try to link another card.',
      10226: 'Please check the card number. You may have made a mistake.',
      unavailebleCharacters:
        'Enter a nickname that consists of letters, numbers or one of the symbols: - , _ . When entering a nickname, only Latin letters are allowed (A-z). No spaces in the middle of the login are allowed.',
      10251: 'Payment has not yet been paid',
    },
    PaymentErrorModal: {
      tryAgain: 'Try again',
      cancel: 'Cancel',
      ok: 'Ok',
      10111: {
        0: 'Incorrect',
        1: 'data entered #{{logCode}}',
        d: 'Check your data correct',
      },
      10211: {
        0: 'You need to confirm the email to successfully add the card,',
        1: '',
        d: '',
      },
      oops: {
        0: 'Not sufficient funds or the transaction was denied by a bank',
        1: '№{{logCode}}',
        d: 'Contact your bank / or use another card',
      },
    },
    SelectCharacterModal: {
      header: 'Select character',
      text0: 'Chat with {{name}} in any Character!',
      text1: 'Subscribe to unlock Characters feature',
      button: 'Subscribe now for {{amount}}$/month',
      addMode: {
        header: 'Monthly subscribe - {{amount}} $',
        subHeader: 'You can cancel reveal any time in your setting',
        text: 'Please add your payment card',
        button: 'Add card and pay {{amount}} $',
        trialButton: 'Add card and get free access for {{count}} days',
      },
    },
    ModeratorFilters: {
      label: 'Showing',
      all: 'All posts',
      myUnModerated: 'My unmoderated posts',
      moderated: 'Passed moderation',
      unModerated: 'Unmoderated posts',
    },
    Rate: {
      left: '{{count}} days left',
      trialDurationDays: 'Free {{count}} days',
      then: 'then ${{amount}}/mo',
    },
    UploadMediaModal: {
      header: 'Upload a file',
      Drag1: 'Drag media file here, or',
      Drag2: 'choose',
      // FormatsMessage: "Only jpg, png files are accepted for uploading images. Only mp4, mov files are accepted for uploading video",
      FormatsMessage: 'Only jpg, png files are accepted for uploading images.',
      Attach: 'Upload',
      Cancel: 'Cancel',
    },
    Promote: {
      title: 'Connecting an agency',
      label:
        'Working with an agency gives you a chance to increase your profits',
      selectAgency: 'Select an agency',
      SuccessfullyConnectAA: 'Connection to the agency was successful',
      SuccessfullyDisconnectAA: 'Disconnection from the agency was successful',
      placeholder: 'Enter a name or select from the list',
    },
    Complaint: {
      Complaint: 'Complaint',
      Report: 'Report',
      PleaseSelectReason: 'Please select the reason for the report',
      Send: 'Send',
      ReportSent: 'Your report has been sent',
      ThankYouForYourMessage: 'Thank you for your message',
      NotOriginalContent: 'Not original content (violation of my rights)',
      Extremism: 'Extremism',
      SpamOrMisleading: 'Spam or misleading',
      Fraud: 'Fraud',
      ChildPornography: 'Child pornography',
      ViolentOrUnpleasantScenes: 'Violent or unpleasant scenes',
      TerrorismPropaganda: 'Terrorism propaganda',
    },
  },
};

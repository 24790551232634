import request from '../../core/api/request';
import { TGetPurchasesFilter, TPurchases } from '../../types/TPurchases';
import { TGetPaymentsFilter, TPayments } from '../../types/TPayments';
import { TProfile } from '../../types/TProfile';
import { TBankOptions } from '../../types/TBankOptions';
import { TBankDoc } from '../../types/TBankDoc';
import { TSubscription } from '../../types/TSubscription';
import {
  TGetPayStatisticFilter,
  TPayStatistic,
} from '../../types/TPayStatistic';

/** Деавторизация пользователя. Обязательная авторизация. */
export const logout = request<boolean>({
  method: 'user.logout',
});

/** Получение последнего установленного языка в профиле текущего пользователя */
export const getLanguage = request<{ lang: string }>({
  method: 'user.getLanguage',
});

/** Установка языка в профиле текущего пользователя. Обязательная авторизация. */
export const setLanguage = request<boolean, { lang: string }>({
  method: 'user.setLanguage',
});

export type TPChangePassword = {
  /** Текущий пароль. */
  nowPassword: string;
  /** Новый пароль */
  password: string;
  /** Новый пароль еще раз. */
  confirmPassword: string;
};

/** Изменение пароля пользователя после авторизации, если пользователь знает старый пароль.  */
export const changePassword = request<boolean, TPChangePassword>({
  method: 'user.changePassword',
  showError: false,
});

/** Установка активности пользователя. Обязательная авторизация */
export const setActive = request<boolean, { active: boolean }>({
  method: 'user.setActive',
});

export type TReferalsList = {
  email: string;
  nickname: string;
  firstName: string;
  lastName: string;
  hashUser: string;
  avatar: string;
  regTStamp: number;
};
export type TRGetReferals = {
  referalsCount: number;
  referalsList: TReferalsList[];
};
export type TPGetReferals = { fullList?: boolean };

/** Получение рефералов 1-ого уровня и их списка. */
export const getReferals = request<TRGetReferals, TPGetReferals>({
  method: 'user.getReferals',
});
/** Устанавливает аватар пользователя. */
export const uploadAvatar = request<{ token: string }>({
  method: 'user.uploadAvatar',
});
/** Устанавливает бегграунд картинку пользователя.. */
export const uploadBackground = request<{ token: string }>({
  method: 'user.uploadBackground',
});

export type TPUpdateProfile = {
  /** имя. от 2 до 100 букв */
  firstName?: string;
  /** фамилия. от 2 до 100 букв. */
  lastName?: string;
  /** никнейм. от 2 до 100 букв */
  nickname?: string;
  /** от 2 до 100 букв. Контроль что страна существует не ведется */
  country?: string;
  /** от 2 до 100 букв. Контроль, что город существует не ведется */
  town?: string;
  /** био пользователя. от 2 до 500 букв */
  bio?: string;
  /** дата рождения пользователя. формат: “YYYY-MM-DD“ */
  birthDay?: string;
  /** массив с перечислением ссылок на профили в соц.сетях */
  social?: {
    /** ссылка на профиль в instagram */
    instagram?: string;
    /** ссылка на профиль в facebook */
    facebook?: string;
    /** ссылка на профиль в twitter */
    twitter?: string;
    /** ссылка на профиль в tiktok */
    tiktok?: string;
    /** ссылка на профиль в google */
    google?: string;
    /** ссылка на профиль в youtube */
    youtube?: string;
  };
};
/** Обновление профиля пользователя */
export const updateProfile = request<boolean, TPUpdateProfile>({
  method: 'user.updateProfile',
});

export type TPisNicknameAvailable = {
  nickname: string;
};
export type TRisNicknameAvailable = {
  nicknameAvailable: boolean;
};
/** Проверка доступности (не занятости другим пользователем) никнейма. */
export const isNicknameAvailable = request<
  TRisNicknameAvailable,
  TPisNicknameAvailable
>({
  method: 'user.isNicknameAvailable',
  showError: false,
});

export type TPGetThatProfile = {
  /** никнейм пользователя, профиль которого хотим показать */
  nickname: string;
};

/** Получение профиля любого пользователя */
export const getThatProfile = request<TProfile, TPGetThatProfile>({
  method: 'profile.get',
});

export type TRSetBusiness = {
  result: boolean;
};

export type TPSetBusiness = {
  isBusiness: boolean;
};

/** Получение профиля любого пользователя */
export const setBusiness = request<TRSetBusiness, TPSetBusiness>({
  method: 'user.setBusiness',
});

export type TPFollow = {
  /** никнейм пользователя, профиль которого хотим показать  */
  hashUser: string;
  /** true/false установить или убрать фоллов для указанного в hashUser пользователя  */
  follow: boolean;
};
export type TRFollow = {
  relation: boolean;
};

/** Установить или убрать бесплатную подписку для пользователя. Обязательная авторизация. */
export const follow = request<TRFollow, TPFollow>({
  method: 'profile.follow',
});

export type TUserAction = {
  followerText?: string;
  followerPost?: number;
  subscribeText?: string;
  subscribePost?: string;
};

export type TUserActions = {
  actions: TUserAction;
};

export type TRUserActions = {
  update: boolean;
};

/** Получение действия текущего пользователя. Обязательная авторизация. */
export const getUserActions = request<TUserActions>({
  method: 'user.getUserAction',
});

/** Обновить действия текущего пользователя. Обязательная авторизация. */
export const updateUserActions = request<TRUserActions, TUserAction>({
  method: 'user.setUserAction',
});

export type TCard = {
  // Порядковый номер карты.
  id: string;
  // строка, в которой последние 4 символа - номер карты, остальные заменены *
  cardNumber: string;
  // держатель карты
  cardHolder: string;
  // тип карты (0 - виза, 1 - мастеркард, 2 - мир)
  cardType: string;
  // месяц окончания срока действия карты
  cardMonth: string;
  // год окончания срока действия карты
  cardYear: string;
  // текущий статус карты (0 - активна, 1 - заблокирована, 2 - истекла)
  cardStatus: string;
  // 0/1 флаг основной карты
  cardMain: string;
};

/** Получить список кредиток текущего пользователя */
export const getListCards = request<TCard[]>({
  method: 'card.getList',
});

export type TNewCard = {
  // Строка. Возможно разделение пробелами. Должна состоять из 16 цифр.
  cardNumber?: string;
  //  Имя владельца карты.
  cardHolder?: string;
  // трехзначное число. cvv код карты.
  cardCvv?: string;
  // двухзначное число. Месяц окончания действия карты (1-12)
  cardMonth?: string;
  // двухзначное число. Год окончания действия карты (21-99)
  cardYear?: string;
  // Тип карты (возможные варианты: visa, mastercard, mir)
  cardType?: string;
  showId?: string;
  sum?: number;
};

export type TCreateCardResponse = {
  created: boolean;
  testPay: string;
  cardId: number;
};

/** Создание новой платежной карты пользователя. Обязательная авторизация. */
export const createCard = request<TCreateCardResponse, TNewCard>({
  method: 'card.createCard',
  showError: false,
});

/** Пометка карты пользователя как не используемой. Обязательная авторизация. */
export const dropCard = request<{ updated: boolean }, { id: string }>({
  method: 'card.dropCard',
});

/** Получить список пользователей, на которых оформлена платная подписка. Обязательная авторизация. */
export const getSubscribeVip = request<TSubscription[]>({
  method: 'user.getSubscribeVip',
});

/** Установка/снятие флага автоматического продления платной подписки на пользователя. Подписка при этом должна быть оформлена. Обязательная авторизация. */
export const setAutoRenewal = request<
  { updated: true },
  { reneval: boolean; hashOpponent: string }
>({
  method: 'user.setAutoRenewal',
});

/**
 * Получение списка платежей.
 * Метод возвратит именно информацию кому и за что мы платили.
 * Авторизация обязательна
 */
export const getPayments = request<TPayments, TGetPaymentsFilter>({
  method: 'payments.getPayments',
});

/*
 * Получение списка покупок у пользователя. Метод возвратит именно информацию кто и за что нам платил.
 * Показываются только платежи, которые успешны.
 * Авторизация обязательна.
 */
export const getPurchases = request<TPurchases, TGetPurchasesFilter>({
  method: 'payments.getPurchased',
});

/*
 * Получение статистики оплат от покупателей за период времени.
 * Результат упорядочен по убыванию суммы платежей. Обязательная авторизация.
 */
export const getPaysStatistic = request<
  TPayStatistic[],
  TGetPayStatisticFilter
>({
  method: 'user.getPaysStatistic',
});

/**
 * Установка цены за подписку в профиль текущего пользователя. Обязательная авторизация.
 * cost* - Цена за действие.
 * type* - Тип действия за которое устанавливается цены. Доступные варианты: subscribeVip - платная подписка на месяц
 */
export type TPSetCost = { cost: number; type: 'subscribeVip' };
export type TRSetCost = { result: boolean };
export const setCost = request<TRSetCost, TPSetCost>({
  method: 'user.setCost',
});

/**
 * Установка цены за отправленное сообщение в чате. Обязательная авторизация.
 * messageFee* - Цена за сообщение.
 */
export type TPSetMessageFee = { messageFee: number };
export type TRSetMessageFee = { result: boolean };
export const setMessageFee = request<TRSetMessageFee, TPSetMessageFee>({
  method: 'user.setMessageFee',
});

/**
 * Установка цены за отправленное сообщение в чате. Обязательная авторизация.
 * messageFee* - Цена за сообщение.
 */
export type TPSetNewFee = { messageFee: number; chatId: number };
export type TRSetNewFee = { result: boolean };
export const setNewFee = request<TRSetNewFee, TPSetNewFee>({
  method: 'chat.setNewFee',
});

/**
 * Пополнение с карты внутреннего баланса для чата.
 * Данный баланс используется в данное время только для отправки сообщений в чаты. Обязательная авторизация.
 * amount* - сумма пополнения
 */
export type TPDepositForChat = { amount: number };
export type TRDepositForChat = { purchased: string };
export const depositForChat = request<TRDepositForChat, TPDepositForChat>({
  method: 'user.depositForChat',
});

/**
 * Установка текущих настроек для банковских выплат пользователя. Обязательная авторизация.
 */
type TRSetBankOptions = {
  saved: boolean;
};
export const setBankOptions = request<TRSetBankOptions, TBankOptions>({
  method: 'user.setBankOptions',
});

/**
 * Получение текущих настроек для банковских выплат пользователя. Обязательная авторизация.
 */
export const getBankOptions = request<TBankOptions>({
  method: 'user.getBankOptions',
});

/**
 * Получение загруженных документов для банковских выплат. Обязательная авторизация.
 */
export const getBankDocs = request<TBankDoc[]>({
  method: 'user.getBankDoc',
});

/**
 * Получить токен для загрузки документа для выплат. Авторизация обязательна. Принимаются только файлы jpg, jpeg, png
 */
export const uploadBankDoc = request<{ token: string }>({
  method: 'user.uploadBankDoc',
});

export type TComplaint = {
  id: string;
  userId: string;
  tStamp: string;
  contentId: string;
  reasonId: string;
  status: string;
  ownerPost: string;
};

export type TPGetComplaintsList = {
  status?: number;
  dateFrom?: number;
  dateTo?: number;
};

/** Получение профиля любого пользователя */
export const getComplaintsList = request<TComplaint[], TPGetComplaintsList>({
  method: 'user.getComplaintsList',
});

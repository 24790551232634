import React from 'react';

interface IProps {
  className?: string;
  onClick?: () => void;
}

function ChatIcon(props: IProps): JSX.Element {
  return <div {...props}>
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.884 13.032C19.884 13.9448 19.1434 14.6852 18.2306 14.6852C17.3178 14.6852 16.5772 13.9447 16.5772 13.0319C16.5772 12.119 17.3178 11.3785 18.2306 11.3785C19.1456 11.3785 19.8864 12.1189 19.884 13.032ZM19.884 13.032C19.884 13.032 19.884 13.0321 19.884 13.0321L19.784 13.0319H19.884C19.884 13.0319 19.884 13.0319 19.884 13.032ZM14.5905 13.0319C14.5905 13.9447 13.8499 14.6852 12.9371 14.6852C12.0242 14.6852 11.2837 13.9447 11.2837 13.0319C11.2837 12.119 12.0242 11.3785 12.9371 11.3785C13.852 11.3785 14.5928 12.1188 14.5905 13.0319ZM14.5905 13.0319H14.4905L14.5905 13.0321C14.5905 13.032 14.5905 13.0319 14.5905 13.0319ZM9.42406 13.0319C9.42406 13.9447 8.68349 14.6852 7.77066 14.6852C6.85783 14.6852 6.11727 13.9447 6.11727 13.0319C6.11727 12.119 6.85783 11.3785 7.77066 11.3785C8.68557 11.3785 9.42637 12.1188 9.42406 13.0319ZM9.42406 13.0319H9.32406L9.42406 13.0321C9.42406 13.032 9.42406 13.0319 9.42406 13.0319ZM18.3424 20.8091L18.3834 20.7806L18.4308 20.7963L21.6416 21.8597L21.6418 21.8597C21.7024 21.8799 21.7604 21.8668 21.8082 21.8209C21.854 21.7747 21.8677 21.7151 21.8484 21.6587L21.8478 21.6567L20.8122 18.4089L20.7973 18.3622L20.8251 18.3218C21.9027 16.7592 22.5342 14.8625 22.5342 12.8192C22.5342 7.45839 18.1847 3.11109 12.8215 3.11109C7.45835 3.11109 3.11109 7.45835 3.11109 12.8215C3.11109 18.1846 7.45835 22.5319 12.8215 22.5319C14.874 22.5319 16.7752 21.8959 18.3424 20.8091ZM24.0455 25.1218L24.0451 25.1216L18.5318 23.2884C16.8366 24.2148 14.8898 24.743 12.8215 24.743C6.23833 24.743 0.9 19.4047 0.9 12.8215C0.9 6.23832 6.23833 0.9 12.8215 0.9C19.407 0.9 24.743 6.23835 24.743 12.8238C24.743 14.9844 24.1667 17.0121 23.16 18.7608L25.0515 24.0959C25.0515 24.096 25.0515 24.0961 25.0515 24.0962C25.1571 24.3893 25.0876 24.6998 24.8694 24.9232L24.869 24.9236C24.6504 25.1449 24.3426 25.219 24.0455 25.1218Z"
        fill="#A0A0C7"
        stroke="#A0A0C7"
        strokeWidth="0.2"
      />
    </svg>
  </div>
}

export default ChatIcon;

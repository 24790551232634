import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ValueType } from 'react-select';

import {
  FanterModal,
  IFanterModalRef,
} from '../../../modals/FanterModal/FanterModal';
import { complaintI18n, EComplaint } from './EComplaint';
import { RSelect } from '../../../Select/Select';
import * as ContentApi from '../../../../core/FanterAPI/ContentApi';
import { showAlert } from '../../../../utils/utils';
import { fetchGetComplaintsList } from '../../../../domains/user/actions';

import { CloseIcon, HornIcon } from '../../../../assets';

import cn from './ComplaintModal.module.scss';
import { Loader } from '../../../Loader/Loader';
import {
  bodyAddNoScroll,
  bodyRemoveNoScroll,
} from '../../../../helper/domManipulation';

export interface IComplaintModalRef {
  open: () => void;
  close: () => void;
}

interface IComplaintOption {
  value: string;
  label: string;
}

interface IProps {
  contentId: number;
}

export const ComplaintModal = forwardRef(
  ({ contentId }: IProps, ref: React.Ref<IComplaintModalRef>): JSX.Element => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [complaint, setComplaint] = useState<EComplaint>();
    const [isLoading, setLoading] = useState<boolean>(false);
    const fanterModal = useRef<IFanterModalRef | null>(null);

    const complaintOptions = useMemo(
      () =>
        Array.from(complaintI18n.keys()).map((key) => ({
          value: key,
          label: t(complaintI18n.get(key) as string),
        })),
      [],
    );

    useImperativeHandle(ref, () => ({
      open() {
        setComplaint(undefined);
        setLoading(false);
        fanterModal.current?.open();
        bodyAddNoScroll();
      },
      close() {
        fanterModal.current?.close();
        bodyRemoveNoScroll();
      },
    }));

    const onClose = (): void => {
      fanterModal.current?.close();
      bodyRemoveNoScroll();
    };

    const onChangeComplaintHandler = useCallback(
      (selected: ValueType<IComplaintOption, false>): void => {
        setComplaint(
          (selected as IComplaintOption).value as unknown as EComplaint,
        );
      },
      [],
    );

    const onClickSendBtnHandler = useCallback(async () => {
      if (complaint) {
        setLoading(true);
        try {
          const res = await ContentApi.setComplaint(contentId, complaint);
          if (res) {
            dispatch(fetchGetComplaintsList({}));
            showAlert(
              t('Complaint.ReportSent'),
              'Complaint.ReportSent',
              'success',
            );
          }
        } finally {
          onClose();
          setLoading(false);
        }
      }
    }, [contentId, complaint]);

    return (
      <FanterModal
        ref={fanterModal}
        onClose={onClose}
        hideCloseBtn
        className={cn.fModal}
      >
        <div className={cn.content}>
          <div className={cn.content__column}>
            <div className={`${cn.content__header} ${cn.header}`}>
              <div className={cn.header__row}>
                <HornIcon className={cn.header__icon} />
                <div className={cn.header__text}>
                  {t('Complaint.Complaint')}
                </div>
                <CloseIcon className={cn.header__closeIcon} onClick={onClose} />
              </div>
            </div>
            <div className={cn.content__reason}>
              {!isLoading && t('Complaint.PleaseSelectReason')}
            </div>

            {isLoading && <Loader className={cn.content__loader} />}
            {!isLoading && (
              <RSelect
                options={complaintOptions}
                selectProps={{
                  onChange: onChangeComplaintHandler,
                  isSearchable: false,
                  menuPosition: 'fixed',
                  placeholder: t('Complaint.PleaseSelectReason'),
                }}
                ClassName={cn.sel}
              />
            )}

            <button
              onClick={onClickSendBtnHandler}
              disabled={!complaint}
              className={cn.content__btn}
              name="send"
              type="button"
            >
              {t('Complaint.Send')}
            </button>
          </div>
        </div>
      </FanterModal>
    );
  },
);

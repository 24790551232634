import React, { lazy, Suspense, useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Route, Switch, useLocation } from 'react-router-dom';
import classnames from 'classnames';

import { RootState } from '../core/rootReducer';
import { UserState } from '../domains/user/reduser';
import { book, publicBook } from '../books';

import cn from './Routes.module.scss';
import { Loader } from '../components/Loader/Loader';

const SignIn = lazy(() => import('../domains/registrations/signIn'));
const SignUP = lazy(() => import('../domains/registrations/signUp'));
const InterEmail = lazy(
  () => import('../domains/registrations/restorePassword/InterEmail')
);
const Contacts = lazy(() => import('../domains/Contacts/Contacts'));
const Documents = lazy(() => import('../domains/Contacts/Documents'));
const Pay = lazy(() => import('../domains/Pay/Pay'));
const SuccessPay = lazy(() => import('../domains/Pay/SuccessPay'));
const Restorepassword = lazy(
  () => import('../domains/registrations/restorePassword')
);
const SucessRestore = lazy(
  () => import('../domains/registrations/sucessRestore')
);
const Nitafans = lazy(() => import('../domains/Landings/Nitafans'));
const NitaKuzminaV2 = lazy(
  () => import('../domains/Landings/NitaKuzminaV2/NitaKuzminaV2')
);
const Profile = lazy(() => import('../domains/user/profile/Profile'));
const userRouting = lazy(() => import('../domains/user/userRouting'));
const Chats = lazy(() => import('../domains/chat/Chats'));
// const AddPost = lazy(() => import("../domains/AddPost/AddPost"));
const AddPostWithTus = lazy(
  () => import('../domains/AddPostWithTus/AddPostWithTus')
);
const Notifications = lazy(
  () => import('../domains/notifications/Notifications')
);
const Timeline = lazy(() => import('../domains/timeLine/TimeLine'));
const TusUpload = lazy(() => import('../domains/TusUpload/TusUpload'));

const Header = lazy(() => import('./Header/Header'));
const MobileMenu = lazy(() => import('../components/mobileMenu'));

export function FanterRoutes(): JSX.Element | null {
  const location = useLocation();
  const profile = useSelector<RootState, UserState['profile']>(
    ({ userReducer }) => userReducer.profile,
    shallowEqual
  );

  const isAuth = useMemo(() => {
    if (profile.get('isLoading')) {
      return undefined;
    }

    return profile.get('data') !== null;
  }, [profile]);

  const showControls = useMemo(() => {
    if (isAuth && location.pathname.startsWith('/passwordreset/')) {
      return false;
    }

    return (
      (isAuth &&
        ![
          publicBook.contacts,
          publicBook.documents,
          publicBook.pay,
          publicBook.successPay,
          publicBook.nitafans,
          publicBook.tusUpload,
        ].includes(location.pathname) &&
        !location.pathname.startsWith('/v2/')) ||
      (!isAuth && location.pathname.startsWith('/u/'))
    );
  }, [isAuth, location]);

  const fallback = (
    <div className={cn.fallback}>
      <Loader />
    </div>
  );

  return (
    <div className={cn.container}>
      {showControls && (
        <Suspense fallback={null}>
          <Header />
        </Suspense>
      )}
      {showControls && <div id="fanterHeaderStub" className={cn.headerStub} />}
      <div
        id="fanterContent"
        className={classnames(cn.privateContent, {
          [cn.isHeader]: isAuth && showControls,
        })}
      >
        <Suspense fallback={fallback}>
          <Switch>
            <Route path={publicBook.tusUpload} component={TusUpload} />
            <Route path={publicBook.signIn} component={SignIn} />
            <Route path={publicBook.signUp} component={SignUP} />
            <Route path={publicBook.interEmail} component={InterEmail} />
            <Route path={publicBook.contacts} component={Contacts} />
            <Route path={publicBook.documents} component={Documents} />
            <Route path={publicBook.pay} component={Pay} />
            <Route path={publicBook.successPay} component={SuccessPay} />
            <Route
              path={publicBook.passwordreset}
              component={Restorepassword}
            />
            <Route path={publicBook.emailconfirm} component={SucessRestore} />
            <Route path={publicBook.nitafans} component={Nitafans} />
            <Route path={publicBook.landing} component={NitaKuzminaV2} />
            <Route path={book.u} component={Profile} />
            {isAuth && (
              <>
                <Route path={book.user} component={userRouting} />
                <Route path={['/chat/:chatId', '/chat']} component={Chats} />
                <Route
                  path={['/post/:postId', '/post']}
                  component={AddPostWithTus}
                />
                <Route path={book.notifications} component={Notifications} />
                <Route path={book.root} component={Timeline} exact />
              </>
            )}
          </Switch>
        </Suspense>
      </div>
      {isAuth && showControls && <div className={cn.mobileMenuStub} />}
      {isAuth && showControls && (
        <Suspense fallback={null}>
          <MobileMenu />
        </Suspense>
      )}
    </div>
  );
}

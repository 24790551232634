import React from 'react';

interface IProps {
  className?: string;
  onClick?: () => void;
}

function NumIcon(props: IProps): JSX.Element {
  return <div {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="5"
      fill="none"
      viewBox="0 0 24 5"
    >
      <path
        fill="#9D9AAA"
        d="M4.138 2.069a2.069 2.069 0 11-4.138 0 2.069 2.069 0 014.138 0zM10.759 2.069a2.069 2.069 0 11-4.138 0 2.069 2.069 0 014.138 0zM17.38 2.069a2.069 2.069 0 11-4.139 0 2.069 2.069 0 014.138 0zM24 2.069a2.069 2.069 0 11-4.138 0 2.069 2.069 0 014.138 0z"
      />
    </svg>
  </div>
}

export default NumIcon;

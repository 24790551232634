import React from 'react';

interface IProps {
  className?: string;
  onClick?: () => void;
}

function Icon(props: IProps): JSX.Element {
  return <div {...props}>
    <svg
      width="69"
      height="69"
      viewBox="0 0 69 69"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.035714"
        d="M42.1219 0.84668L39.9839 10.329C40.8863 10.5372 41.7887 10.7871 42.6495 11.0926L45.8427 1.9157C44.6348 1.48531 43.3714 1.12435 42.1219 0.84668Z"
        fill="#3A3B8D"
      />
      <path
        opacity="0.071429"
        d="M47.6474 13.4948L52.8119 5.26194C51.7152 4.58166 50.5767 3.94303 49.4244 3.3877L45.2178 12.1481C46.0508 12.5507 46.8699 12.995 47.6474 13.4948Z"
        fill="#3A3B8D"
      />
      <path
        opacity="0.1071"
        d="M51.9931 16.9516L58.8515 10.0655C57.9352 9.16307 56.9773 8.28842 55.9638 7.49707L49.9106 15.1051C50.6465 15.6743 51.3406 16.2991 51.9931 16.9516Z"
        fill="#3A3B8D"
      />
      <path
        opacity="0.1429"
        d="M55.464 21.2833L63.6829 16.091C63.0026 15.0081 62.239 13.9529 61.4338 12.9395L53.8535 19.0203C54.4227 19.7423 54.9781 20.5059 55.464 21.2833Z"
        fill="#3A3B8D"
      />
      <path
        opacity="0.1786"
        d="M57.8796 26.2675L67.0426 23.0465C66.6122 21.8387 66.1124 20.6308 65.557 19.4785L56.8105 23.7129C57.2132 24.532 57.5741 25.3928 57.8796 26.2675Z"
        fill="#3A3B8D"
      />
      <path
        opacity="0.2143"
        d="M59.129 31.6676L68.7918 30.5708C68.653 29.2936 68.4309 28.0024 68.1393 26.7529L58.6709 28.9187C58.8653 29.835 59.018 30.7513 59.129 31.6676Z"
        fill="#3A3B8D"
      />
      <path
        opacity="0.25"
        d="M69 34.4443H59.2816V34.4999C59.2816 35.4301 59.2261 36.3602 59.1289 37.2765L68.7917 38.3594C68.9305 37.0822 69 35.791 69 34.4999V34.4443Z"
        fill="#3A3B8D"
      />
      <path
        opacity="0.2857"
        d="M57.8936 42.691L67.0704 45.898C67.5008 44.6763 67.8618 43.4268 68.1394 42.1773L58.6571 40.0254C58.4628 40.9139 58.199 41.8163 57.8936 42.691Z"
        fill="#3A3B8D"
      />
      <path
        opacity="0.3214"
        d="M55.4917 47.6757L63.7106 52.8542C64.3909 51.7713 65.0295 50.6189 65.5849 49.4666L56.8245 45.2461C56.4358 46.0791 55.9776 46.8982 55.4917 47.6757Z"
        fill="#3A3B8D"
      />
      <path
        opacity="0.3571"
        d="M52.021 52.021L58.8932 58.8932C59.7957 57.9908 60.6703 57.019 61.4755 56.0055L53.8814 49.9385C53.2983 50.6743 52.6735 51.3685 52.021 52.021Z"
        fill="#3A3B8D"
      />
      <path
        opacity="0.3929"
        d="M47.6895 55.4913L52.8679 63.7103C53.9508 63.03 55.0198 62.2664 56.0194 61.475L49.9524 53.8809C49.2305 54.4501 48.4669 54.9915 47.6895 55.4913Z"
        fill="#3A3B8D"
      />
      <path
        opacity="0.4286"
        d="M42.6914 57.893L45.8985 67.0699C47.1063 66.6395 48.3142 66.1397 49.4804 65.5844L45.2598 56.8379C44.4268 57.2266 43.5661 57.5876 42.6914 57.893Z"
        fill="#3A3B8D"
      />
      <path
        opacity="0.4643"
        d="M37.2905 59.129L38.3734 68.7918C39.6507 68.653 40.9418 68.4309 42.1913 68.1532L40.0394 58.6709C39.137 58.8653 38.2068 59.018 37.2905 59.129Z"
        fill="#3A3B8D"
      />
      <path
        opacity="0.5"
        d="M31.7375 59.1289L30.6685 68.7917C31.9318 68.9305 33.223 69 34.5003 69H34.5141V59.2816H34.5003C33.5701 59.2816 32.6399 59.2261 31.7375 59.1289Z"
        fill="#3A3B8D"
      />
      <path
        opacity="0.5357"
        d="M23.1294 67.0841C24.3511 67.5145 25.6006 67.8616 26.8501 68.1531L29.002 58.6708C28.0996 58.4626 27.2111 58.2127 26.3364 57.9072L23.1294 67.0841Z"
        fill="#3A3B8D"
      />
      <path
        opacity="0.5714"
        d="M21.3248 55.4912L16.1602 63.724C17.2431 64.4043 18.3954 65.0429 19.5477 65.5983L23.7682 56.8379C22.9352 56.4353 22.1161 55.991 21.3248 55.4912Z"
        fill="#3A3B8D"
      />
      <path
        opacity="0.6071"
        d="M16.9933 52.0352L10.1211 58.9074C11.0235 59.8098 11.9953 60.6845 13.0088 61.4758L19.062 53.8816C18.34 53.3124 17.6459 52.6877 16.9933 52.0352Z"
        fill="#3A3B8D"
      />
      <path
        opacity="0.6429"
        d="M13.5222 47.7031L5.30322 52.8816C5.98351 53.9645 6.74709 55.0335 7.53844 56.0331L15.1326 49.9661C14.5495 49.2442 14.0081 48.4806 13.5222 47.7031Z"
        fill="#3A3B8D"
      />
      <path
        opacity="0.6786"
        d="M11.1068 42.7051L1.94385 45.926C2.37423 47.1339 2.87403 48.3417 3.42936 49.5079L12.1759 45.2735C11.7732 44.4405 11.4123 43.5797 11.1068 42.7051Z"
        fill="#3A3B8D"
      />
      <path
        opacity="0.7143"
        d="M9.87129 37.3047L0.208496 38.3876C0.347329 39.6649 0.569463 40.956 0.861012 42.2055L10.3294 40.0397C10.1351 39.1512 9.98236 38.221 9.87129 37.3047Z"
        fill="#3A3B8D"
      />
      <path
        opacity="0.75"
        d="M9.71833 34.5005C9.71833 33.5842 9.77386 32.654 9.87104 31.7516L0.20825 30.6826C0.0694166 31.946 0 33.2233 0 34.5005V34.5283H9.71833V34.5005Z"
        fill="#3A3B8D"
      />
      <path
        opacity="0.7857"
        d="M11.0926 26.3367L1.9157 23.1436C1.48531 24.3653 1.13823 25.6148 0.84668 26.8643L10.329 29.0023C10.5372 28.1138 10.7871 27.2114 11.0926 26.3367Z"
        fill="#3A3B8D"
      />
      <path
        opacity="0.8214"
        d="M13.4948 21.3384L5.26194 16.1738C4.58166 17.2567 3.94303 18.409 3.3877 19.5614L12.1481 23.768C12.5507 22.9489 13.0088 22.1298 13.4948 21.3384Z"
        fill="#3A3B8D"
      />
      <path
        opacity="0.8571"
        d="M16.9516 16.9931L10.0794 10.1348C9.16307 11.0372 8.3023 12.009 7.49707 13.0225L15.1051 19.0756C15.6743 18.3537 16.2991 17.6456 16.9516 16.9931Z"
        fill="#3A3B8D"
      />
      <path
        opacity="0.8929"
        d="M21.297 13.5224L16.1046 5.31738C15.0217 5.99767 13.9666 6.76125 12.9531 7.56648L19.0201 15.1468C19.7421 14.5637 20.5057 14.0222 21.297 13.5224Z"
        fill="#3A3B8D"
      />
      <path
        opacity="0.9286"
        d="M26.2811 11.1073L23.0602 1.94434C21.8524 2.37472 20.6445 2.87452 19.4922 3.42985L23.7266 12.1763C24.5457 11.7737 25.4065 11.4128 26.2811 11.1073Z"
        fill="#3A3B8D"
      />
      <path
        opacity="0.9643"
        d="M31.6818 9.8708L30.585 0.208008C29.3077 0.346841 28.0166 0.568974 26.7671 0.860524L28.9329 10.329C29.8492 10.1346 30.7655 9.98187 31.6818 9.8708Z"
        fill="#3A3B8D"
      />
      <path
        d="M34.5001 0H34.4585V9.71833H34.5001C35.4164 9.71833 36.3327 9.77386 37.2352 9.87104L38.2903 0.20825C37.0547 0.0694166 35.7635 0 34.5001 0Z"
        fill="#3A3B8D"
      />
    </svg>
  </div>
}

export default Icon;

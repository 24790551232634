export class Locker {
  static lock(): void {
    localStorage.setItem('LOCKER:REFRESHING_TOKENS_IS_LOCKED', 'true');
  }

  static unlock(): void {
    localStorage.setItem('LOCKER:REFRESHING_TOKENS_IS_LOCKED', 'false');
  }

  static waitUnlock(): Promise<void> {
    return new Promise((resolve, reject) => {
      const start = new Date().getTime();
      const _isLocked = localStorage.getItem(
        'LOCKER:REFRESHING_TOKENS_IS_LOCKED'
      );
      if (_isLocked === null || _isLocked === 'false') {
        resolve();
        return;
      }

      const timer = setInterval(() => {
        const isLocked = localStorage.getItem(
          'LOCKER:REFRESHING_TOKENS_IS_LOCKED'
        );
        const now = new Date().getTime();

        if (now - start > 30000) {
          clearInterval(timer);
          localStorage.setItem('LOCKER:REFRESHING_TOKENS_IS_LOCKED', 'false');
          reject(new Error('Timeout REFRESHING_TOKENS_IS_LOCKED'));
        }
        if (isLocked === null || isLocked === 'false') {
          clearInterval(timer);
          resolve();
        }
      }, 10);
    });
  }
}

import React from 'react';

interface IProps {
  className?: string;
  onClick?: () => void;
}

function Icon(props: IProps): JSX.Element {
  return <div {...props}>
    <svg
      width="26"
      height="24"
      viewBox="0 0 26 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 4.06588C9.01435 -1.89786 1 1.59886 1 8.09891C1 12.4966 5.00717 15.6501 8.00178 18.0099C11.1257 20.4769 12.009 21.0132 13 22C13.991 21.0132 14.8312 20.434 17.9982 18.0099C21.0574 15.693 25 12.4537 25 8.05601C25.0215 1.59887 17.0072 -1.89786 13 4.06588Z"
        stroke="#413E93"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </svg>
  </div>
}

export default Icon;

import React, { useCallback, useState } from 'react';
import Draggable from 'react-draggable';

import { ApiUrlSingleton } from '../../core/api/ApiUrlSingleton';

import cn from './EnvBtm.module.scss';

export function EnvBtn(): JSX.Element | null {
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const isProd = ApiUrlSingleton.getInstance().isProd();

  const onClickSetEnv = useCallback(() => {
    if (isDragging) {
      return;
    }
    ApiUrlSingleton.getInstance().setProd(!isProd);
  }, [isProd, isDragging]);

  const eventControl = (event: { type: string }) => {
    if (event.type === 'mousemove' || event.type === 'touchmove') {
      setIsDragging(true);
    }

    if (event.type === 'mouseup' || event.type === 'touchend') {
      setTimeout(() => {
        setIsDragging(false);
      }, 100);
    }
  };

  if (process.env.REACT_APP_IS_PRODUCTION === 'true') {
    return null;
  }

  return (
    <Draggable bounds="parent" onDrag={eventControl} onStop={eventControl}>
      <button
        type="button"
        className={cn.env}
        onClick={onClickSetEnv}
        onTouchEndCapture={onClickSetEnv}
      >
        {isProd ? 'To DEV' : 'To PROD'}
      </button>
    </Draggable>
  );
}

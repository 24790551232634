import React from 'react';

interface IProps {
  className?: string;
  onClick?: () => void;
}

function HornIcon(props: IProps): JSX.Element {
  return <div {...props}>
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.26087 9.84536L16.0957 13.0454C16.5826 13.1845 17 12.8367 17 12.4193V1.70623C17 1.21927 16.5826 0.941013 16.1652 1.01058L7.26087 3.58449M7.26087 9.84536V3.58449M7.26087 9.84536H3.08696M7.26087 3.58449H3.08696C1.90435 3.58449 1 4.48884 1 5.67145V7.75841C1 8.87145 1.90435 9.84536 3.08696 9.84536M3.08696 9.84536V14.1584C3.08696 14.5062 3.36522 14.7845 3.64348 14.8541L5.03478 15.2019C5.45217 15.2715 5.86957 14.9236 5.86957 14.5062V9.84536H3.08696Z"
        stroke="#413E93"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
}

export default HornIcon;

import React from 'react';

interface IProps {
  className?: string;
  onClick?: () => void;
}

function Icon(props: IProps): JSX.Element {
  return <div {...props}>
    <svg
      width="58"
      height="60"
      viewBox="0 0 58 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse
        cx="28.9311"
        cy="29.5218"
        rx="28.8937"
        ry="29.5218"
        fill="#D21010"
      />
      <path
        d="M23.7019 26.5853C24.7577 26.5853 25.6137 25.7294 25.6137 24.6735C25.6137 23.6177 24.7577 22.7617 23.7019 22.7617C22.646 22.7617 21.7901 23.6177 21.7901 24.6735C21.7901 25.7294 22.646 26.5853 23.7019 26.5853Z"
        fill="white"
      />
      <path
        d="M35.4164 26.5853C36.4723 26.5853 37.3282 25.7294 37.3282 24.6735C37.3282 23.6177 36.4723 22.7617 35.4164 22.7617C34.3606 22.7617 33.5047 23.6177 33.5047 24.6735C33.5047 25.7294 34.3606 26.5853 35.4164 26.5853Z"
        fill="white"
      />
      <path
        d="M35.631 38.3584C35.158 38.3584 34.719 38.0511 34.5776 37.5732C34.2606 36.5198 33.5973 35.5687 32.7146 34.9006C31.8026 34.2129 30.7101 33.8472 29.564 33.8472C28.4179 33.8472 27.3255 34.2129 26.4135 34.9006C25.5308 35.5687 24.8675 36.5149 24.5505 37.5732C24.3749 38.1535 23.7604 38.4852 23.1752 38.3096C22.5948 38.134 22.2632 37.5195 22.4387 36.9343C22.8923 35.4371 23.8287 34.0861 25.087 33.14C26.3842 32.1597 27.9351 31.6428 29.564 31.6428C31.193 31.6428 32.7438 32.1597 34.0411 33.14C35.2945 34.0861 36.2358 35.4322 36.6893 36.9343C36.8649 37.5195 36.5333 38.134 35.9529 38.3096C35.8456 38.3438 35.7383 38.3584 35.631 38.3584Z"
        fill="white"
      />
      <path
        d="M29.5592 47.7417C24.6919 47.7417 20.1173 45.8446 16.679 42.4063C13.2407 38.9631 11.3436 34.3885 11.3436 29.5213C11.3436 24.6589 13.2407 20.0843 16.679 16.6411C20.1222 13.2028 24.6968 11.3057 29.5592 11.3057C34.4215 11.3057 39.001 13.2028 42.4393 16.6411C45.8825 20.0843 47.7748 24.6589 47.7748 29.5213C47.7748 34.3885 45.8776 38.9631 42.4393 42.4014C39.001 45.8446 34.4264 47.7417 29.5592 47.7417ZM29.5592 13.5101C25.282 13.5101 21.2585 15.178 18.2348 18.2017C15.211 21.2255 13.5431 25.249 13.5431 29.5261C13.5431 33.8033 15.211 37.8268 18.2348 40.8505C21.2585 43.8743 25.282 45.5422 29.5592 45.5422C33.8363 45.5422 37.8598 43.8743 40.8836 40.8505C43.9073 37.8268 45.5752 33.8033 45.5752 29.5261C45.5752 25.249 43.9073 21.2255 40.8836 18.2017C37.8598 15.1731 33.8363 13.5101 29.5592 13.5101Z"
        fill="white"
      />
    </svg>
  </div>
}

export default Icon;

import React from 'react';

interface IProps {
  className?: string;
  onClick?: () => void;
}

function Icon(props: IProps): JSX.Element {
  return <div {...props}>
    <svg
      width="32"
      height="37"
      viewBox="0 0 32 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.51583 0.319069L30.8291 16.0878C32.3903 16.993 32.3903 19.2495 30.8291 20.1547L3.51583 35.9235C1.9547 36.8286 0 35.7004 0 33.89V2.35248C0 0.542088 1.9547 -0.586127 3.51583 0.319069Z"
        fill="white"
      />
    </svg>
  </div>
}

export default Icon;

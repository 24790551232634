import React from 'react';

interface IProps {
  className?: string;
  onClick?: () => void;
}

function ArrowDownIcon(props: IProps): JSX.Element {
  return <div {...props}>
    <svg
      width="14"
      height="8"
      viewBox="0 0 14 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.14168 6.93455L12.9407 1.37091C13.0198 1.28364 13.0198 1.15273 12.9407 1.06545C12.8616 0.978182 12.7035 0.978182 12.6244 1.06545L7.00989 6.47636L1.36903 1.06545C1.28995 0.978182 1.1318 0.978182 1.05272 1.06545C1 1.10909 1 1.15273 1 1.21818C1 1.28364 1.02636 1.32727 1.05272 1.37091L6.82537 6.93455C6.93081 7.02182 7.0626 7.02182 7.14168 6.93455Z"
        fill="#413E93"
        stroke="#413E93"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </svg>
  </div>
}

export default ArrowDownIcon;

import { all, call } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import watchRegistration from '../domains/registrations/sagas/watchers';
import watcherToken from './sagas/token';
import watchUser from '../domains/user/sagas/watchers';
import watchTimeLine from '../domains/timeLine/sagas/watchers';
import notifications from '../domains/notifications/sagas/watchers';
import watchChat from '../domains/chat/sagas/watchers';
import watchAddPost from '../domains/AddPost/saga/watchers';
import watchAddPostWithTus from '../domains/AddPostWithTus/saga/watchers';

export default function* rootSaga(): SagaIterator<void> {
  yield all([
    call(watcherToken),
    call(watchRegistration),
    call(watchUser),
    call(watchTimeLine),
    call(watchChat),
    call(watchAddPost),
    call(watchAddPostWithTus),
    call(notifications),
  ]);
}

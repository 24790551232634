import React from 'react';

interface IProps {
  className?: string;
  onClick?: () => void;
}

function Icon(props: IProps): JSX.Element {
  return <div {...props}>
    <svg
      width="59"
      height="60"
      viewBox="0 0 59 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse
        cx="29.6748"
        cy="30.4662"
        rx="28.8937"
        ry="29.5218"
        fill="#413E93"
      />
      <path
        d="M38.4685 38.8529C38.4685 43.3754 38.4685 43.7071 38.4685 43.7071C38.4685 44.3704 37.94 44.9131 37.2941 44.9131H22.0555C21.4096 44.9131 20.8811 44.3704 20.8811 43.7071C20.8811 43.7071 20.8811 43.3453 20.8811 38.8228C20.8811 34.3003 26.2542 32.1295 26.2542 29.808C26.2542 27.4864 20.8811 25.2855 20.8811 20.7931C20.8811 16.3008 20.8811 15.9691 20.8811 15.9691C20.8811 15.3058 21.4096 14.7631 22.0555 14.7631H37.2941C37.94 14.7631 38.4685 15.3058 38.4685 15.9691C38.4685 15.9691 38.4685 16.3309 38.4685 20.7931C38.4685 25.2553 33.2422 27.2151 33.2422 29.808C33.2422 32.4009 38.4685 34.3305 38.4685 38.8529ZM30.8933 29.808C30.8933 27.6975 32.3614 26.1598 33.6826 24.8332C35.1507 23.3257 36.1196 22.2102 36.1196 20.7931V17.7781H35.4737L31.3631 21.8182C30.4235 22.7227 28.8967 22.7227 27.9865 21.8182L23.8759 17.7781H23.23V20.7931C23.23 22.3911 24.5806 23.7478 25.8725 25.0443C27.2231 26.401 28.6031 27.8181 28.6031 29.808C28.6031 31.7979 27.2231 33.2149 25.8725 34.5717C24.287 36.1696 23.23 37.3454 23.23 38.8529V41.8679H36.1196V38.8529C36.1196 37.3756 35.1507 36.2902 33.6533 34.7526C32.3614 33.426 30.8933 31.9185 30.8933 29.808Z"
        fill="white"
      />
    </svg>
  </div>
}

export default Icon;

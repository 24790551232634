import React from 'react';

interface IProps {
  className?: string;
  onClick?: () => void;
}

function TimelineIcon(props: IProps): JSX.Element {
  return <div {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.2 22.07">
      <title>Asset 41</title>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path
            fill="none"
            stroke="#a09ec9"
            strokeWidth="2.2px"
            d="M1.12,0V1.1a3.14,3.14,0,0,0,1,2.26,3.34,3.34,0,0,0,2.33.94H19.8a3.32,3.32,0,0,0,2.33-.94,3.14,3.14,0,0,0,1-2.26V0m0,22V21a3.16,3.16,0,0,0-1-2.27,3.39,3.39,0,0,0-2.35-1H4.4a3.31,3.31,0,0,0-2.31,1,3.16,3.16,0,0,0-1,2.27V22m2.56-7.68H20.42a2.55,2.55,0,0,0,1.77-.64,2.35,2.35,0,0,0,.8-1.66V10a2.38,2.38,0,0,0-.79-1.67,2.52,2.52,0,0,0-1.78-.65H3.68a2.53,2.53,0,0,0-1.78.64,2.45,2.45,0,0,0-.57.76A2.27,2.27,0,0,0,1.1,10v2a2.43,2.43,0,0,0,.8,1.67A2.52,2.52,0,0,0,3.68,14.35Z"
          />
        </g>
      </g>
    </svg>
  </div>
}

export default TimelineIcon;

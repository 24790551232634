import { createAction } from '@reduxjs/toolkit';
import {
  TMessageType,
  TPContent,
  TPContentSetBlocked,
  TPContentSetDisplayInMainContent,
  TPCreatePost,
} from './api';

export type TRfetchСontent = TPContent & { reload?: boolean; isDev?: string };
export const fetchСontent = createAction<
  TRfetchСontent,
  'timeline/fetchСontent'
>('timeline/fetchСontent');

export const canselСontent = createAction('timeline/canselСontent');

export const fetchCreatePost = createAction<
  TPCreatePost,
  'timeline/fetchCreatePost'
>('timeline/fetchCreatePost');

export type PUpdatePost = {
  id: string;
  private?: boolean;
  fromUser?: { hashUser?: string };
  filters?: {
    messageTypes?: TMessageType[];
  };
};

export const fetchСontentBuy = createAction<
  PUpdatePost,
  'timeline/fetchСontentBuy'
>('timeline/fetchСontentBuy');

export const fetchContentSetLike = createAction<
  PUpdatePost,
  'timeline/contentSetLike'
>('timeline/contentSetLike');
export const fetchContentUnsetLike = createAction<
  PUpdatePost,
  'timeline/contentUnsetLike'
>('timeline/contentUnsetLike');

export const fetchUpdatePost = createAction<PUpdatePost, 'timeline/UpdatePost'>(
  'timeline/UpdatePost'
);

export const fetchSetBlocked = createAction<
  TPContentSetBlocked,
  'timeline/setBlocked'
>('timeline/setBlocked');

export const fetchSetDisplayInMainContent = createAction<
  TPContentSetDisplayInMainContent,
  'timeline/setDisplayInMainContent'
>('timeline/setDisplayInMainContent');

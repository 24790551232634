import { takeEvery } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import {
  workerFetchEmailCommit,
  workerFetchIsTokenValidPass,
  workerFetchLogin,
  workerFetchPasswordRecover,
  workerFetchRecoverCommit,
  workerFetchRegister,
  workerFetchTelegrammVerifyInitData,
} from './workers';
import {
  fetchEmailCommit,
  fetchIsTokenValidPass,
  fetchLogin,
  fetchLoginTelegramm,
  fetchPasswordRecover,
  fetchRecoverCommit,
  fetchRegister,
} from '../actions';

export default function* watchRegistration(): SagaIterator {
  yield takeEvery<typeof fetchRegister>(fetchRegister, workerFetchRegister);
  yield takeEvery<typeof fetchLogin>(fetchLogin, workerFetchLogin);
  yield takeEvery<typeof fetchEmailCommit>(
    fetchEmailCommit,
    workerFetchEmailCommit,
  );
  yield takeEvery<typeof fetchPasswordRecover>(
    fetchPasswordRecover,
    workerFetchPasswordRecover,
  );
  yield takeEvery<typeof fetchRecoverCommit>(
    fetchRecoverCommit,
    workerFetchRecoverCommit,
  );
  yield takeEvery<typeof fetchIsTokenValidPass>(
    fetchIsTokenValidPass,
    workerFetchIsTokenValidPass,
  );
  yield takeEvery<typeof fetchLoginTelegramm>(
    fetchLoginTelegramm,
    workerFetchTelegrammVerifyInitData,
  );
}

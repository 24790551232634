import React from 'react';

interface IProps {
  className?: string;
  onClick?: () => void;
}

function Icon(props: IProps): JSX.Element {
  return <div {...props}>
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.26695 0.462979C6.39556 0.180047 6.69135 0 7 0C7.30865 0 7.60444 0.180047 7.74591 0.462979L9.26345 3.5495C9.37919 3.79385 9.61068 3.96104 9.88075 3.99962L13.2888 4.48832C13.5974 4.53976 13.8547 4.74553 13.9575 5.05418C14.0604 5.34997 13.9704 5.68435 13.7518 5.90298L11.2825 8.30789C11.0896 8.5008 10.9996 8.77087 11.0511 9.04094L11.6298 12.4361C11.6812 12.7448 11.5526 13.0534 11.3083 13.2463C11.0511 13.4264 10.7167 13.4521 10.4338 13.3106L7.38582 11.6902C7.14147 11.5616 6.85853 11.5616 6.61418 11.6902L3.56624 13.2978C3.28331 13.4392 2.94894 13.4135 2.69173 13.2335C2.43452 13.0534 2.30591 12.7319 2.37022 12.4233L2.9618 9.02808C3.01324 8.75801 2.92322 8.48794 2.73031 8.29503L0.248231 5.90298C0.0296019 5.68435 -0.0604217 5.36283 0.0424624 5.05418C0.145346 4.75839 0.402557 4.53976 0.711209 4.48832L4.11925 3.99962C4.38932 3.96104 4.62081 3.79385 4.73655 3.5495L6.26695 0.462979Z"
        fill="white"
      />
    </svg>
  </div>
}

export default Icon;

import React from 'react';

interface IProps {
  className?: string;
  onClick?: () => void;
}

function Icon(props: IProps): JSX.Element {
  return <div {...props}>
    <svg
      width="50"
      height="60"
      viewBox="0 0 50 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40.3903 27.025C41.7786 27.025 43.0966 27.5368 44.0883 28.4645C45.0864 29.3922 45.6302 30.6206 45.6302 31.913V50.6078C45.6302 51.9066 45.08 53.1286 44.0883 54.0563C43.0902 54.984 41.7786 55.4958 40.3903 55.4958H9.60972C8.22137 55.4958 6.90339 54.984 5.91171 54.0563C4.91363 53.1286 4.3698 51.9002 4.3698 50.6078V31.913C4.3698 30.6142 4.92003 29.3922 5.91171 28.4645C6.90979 27.5368 8.22137 27.025 9.60972 27.025H40.3903ZM40.3903 22.9495H9.60972C4.32502 22.9495 0 26.9802 0 31.913V50.6078C0 55.5406 4.32502 59.5713 9.60972 59.5713H40.3903C45.675 59.5713 50 55.5342 50 50.6078V31.913C50 26.9802 45.675 22.9495 40.3903 22.9495Z"
        fill="white"
      />
      <path
        d="M11.35 21.3052C11.6763 21.3052 11.945 21.0365 11.945 20.7102V15.9181C11.945 14.3314 12.2585 12.7831 12.8791 11.3308C13.4805 9.92323 14.3378 8.65003 15.4319 7.55598C16.5259 6.46193 17.7927 5.60461 19.2067 5.0032C20.659 4.3826 22.2073 4.0691 23.794 4.0691H26.2188C27.8055 4.0691 29.3538 4.3826 30.8061 5.0032C32.2137 5.60461 33.4869 6.46193 34.5809 7.55598C35.675 8.65003 36.5323 9.91683 37.1337 11.3308C37.7543 12.7831 38.0678 14.3314 38.0678 15.9181V20.7102C38.0678 21.0365 38.3365 21.3052 38.6628 21.3052H41.5547C41.881 21.3052 42.1433 21.0429 42.1433 20.7166V15.9245C42.1305 7.16571 34.9648 0 26.206 0H23.7876C15.0352 0 7.86948 7.16571 7.86948 15.9245V20.7166C7.86948 21.0429 8.1382 21.3052 8.45809 21.3052H11.35Z"
        fill="white"
      />
      <path
        d="M28.5989 38.3173C28.5989 36.3276 26.9866 34.7153 24.9968 34.7153C23.007 34.7153 21.3948 36.3276 21.3948 38.3173C21.3948 39.6737 22.1433 40.8573 23.2566 41.4715C23.2438 41.5547 23.2374 41.6379 23.2374 41.7211V46.5771C23.2374 47.5432 24.0307 48.3365 24.9968 48.3365C25.9629 48.3365 26.7562 47.5432 26.7562 46.5771V41.7211C26.7562 41.6379 26.7498 41.5483 26.737 41.4715C27.8503 40.8573 28.5989 39.6737 28.5989 38.3173Z"
        fill="white"
      />
    </svg>
  </div>
}

export default Icon;

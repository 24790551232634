import React, { useCallback, useEffect, useRef, useState } from 'react';
import LinesEllipsis from 'react-lines-ellipsis';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';
import VisibilitySensor from 'react-visibility-sensor';

import cn from './input.module.scss';

const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

export interface PInput {
  // /** опции реакт компонента инпут  */
  inputOpt?: React.InputHTMLAttributes<HTMLInputElement>;
  /** ошибкой  */
  isError?: boolean;
  /** текст ошибки  */
  errorText?: string;
  /** значение  */
  // val?: string;
  /** иконка  */
  icon?: 'EVE_CLOSE' | 'EVE_OPEN' | 'copy' | 'calendar' | 'trash';
  iconElement?: JSX.Element;
  /** событие клик по иконке  */
  onIconClick?: (v: PInput['icon']) => void;
  /** ширина 100%  */
  width?: '100%';
  /** без плейсхолдера  */
  offHolder?: 'offHolder';
  className?: string;
  innerRef?: React.Ref<HTMLInputElement>;
}

// @ts-ignore
const mapTypes: Map<PInput['width'], ['offHolder'], string> = new Map([
  ['100%', cn.fullwidth],
  ['offHolder', cn.offHolder],
]);

function getIconClass(icon: PInput['icon']): string | undefined {
  if (icon === 'EVE_OPEN') return `${cn.eye} ${cn.eye_act}`;
  if (icon === 'EVE_CLOSE') return `${cn.eye} `;
  if (icon === 'copy') return `${cn.copyIcon} `;
  if (icon === 'calendar') return `${cn.calendarIcon} `;
  if (icon === 'trash') return `${cn.trashIcon} `;
  return undefined;
}

function Input({
  isError,
  errorText,
  icon,
  onIconClick,
  width,
  className,
  offHolder,
  innerRef,
  inputOpt,
}: PInput): JSX.Element {
  const [isFocused, setFocused] = useState<boolean>(false);
  const placeholderRef = useRef<HTMLParagraphElement>(null);
  const [winWidth, setWinWidth] = useState<number>();

  const inputRef = useRef<HTMLInputElement>(null);

  const memoIconClick = useCallback(
    (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      event.preventDefault();
      if (!onIconClick) return;
      onIconClick(icon);
    },
    [icon, onIconClick],
  );
  const cursor = useRef<number | null>(null);

  useEffect(() => {
    setWinWidth(undefined);
    setTimeout(() => {
      const newWidth = placeholderRef.current?.clientWidth ?? 0;
      if (newWidth !== 0) {
        setWinWidth(newWidth);
      }
    }, 300);
  }, [inputOpt?.placeholder, placeholderRef.current]);

  useEffect(() => {
    const newWidth = placeholderRef.current?.clientWidth ?? 0;
    if (newWidth !== 0 && winWidth !== newWidth) {
      setWinWidth(newWidth);
    }
  }, [isFocused, inputOpt?.value, winWidth]);

  useEffect(
    () => () => {
      setWinWidth(undefined);
    },
    [],
  );

  const onVisibleProfile = useCallback(
    (isVisible: boolean) => {
      if (isVisible && winWidth) {
        setTimeout(() => {
          const newWidth = placeholderRef.current?.clientWidth ?? 0;
          if (newWidth !== 0) {
            setWinWidth(newWidth);
          }
        }, 300);
      }
      if (isVisible && !winWidth) {
        const newWidth = placeholderRef.current?.clientWidth ?? 0;
        if (newWidth !== 0) {
          setWinWidth(newWidth);
        }
      }
    },
    [inputOpt?.placeholder, placeholderRef.current, winWidth],
  );

  return (
    <VisibilitySensor onChange={onVisibleProfile} partialVisibility>
      <div
        className={`${cn.inputContainer}  ${mapTypes.get(
          offHolder,
        )} ${mapTypes.get(width)} ${isError ? cn.input_error : ''} ${
          inputOpt?.disabled ? cn.input_disabled : ''
        } ${className}`}
      >
        <label className={cn.label} htmlFor={inputOpt?.id}>
          {errorText && <span className={cn.error}>{errorText}</span>}

          <input
            {...inputOpt}
            value={inputOpt?.value ?? ''}
            placeholder=""
            ref={innerRef ?? inputRef}
            onChange={(event) => {
              if (inputOpt && inputOpt.type !== 'number') {
                cursor.current = event.target.selectionStart;
              }
              if (inputOpt?.onChange) {
                inputOpt.onChange(event);
              }
            }}
            onFocus={(event) => {
              setFocused(true);
              if (inputOpt?.onFocus) {
                inputOpt.onFocus(event);
              }
              if (inputOpt && inputOpt.type !== 'number') {
                // eslint-disable-next-line no-param-reassign
                event.target.selectionStart = cursor.current;
              }
            }}
            onBlur={(event) => {
              setFocused(false);
              if (inputOpt?.onBlur) {
                inputOpt.onBlur(event);
              }
            }}
            onWheel={(e: React.WheelEvent<HTMLInputElement>) => {
              e.currentTarget?.blur();
              e.stopPropagation();
              setTimeout(() => {
                e.currentTarget?.focus();
              });
            }}
          />
          {!!inputOpt?.placeholder && winWidth !== undefined && (
            <div className={`placeholder ${cn.placeholder}`}>
              <ResponsiveEllipsis
                text={inputOpt?.placeholder}
                maxLine={inputOpt?.value || isFocused ? 1 : 2}
                ellipsis="..."
                trimRight
                style={{ width: `${winWidth + 10}px` }}
              />
            </div>
          )}

          <div className={cn.hiddenPlaceholder} ref={placeholderRef}>
            {inputOpt?.placeholder}
          </div>
          {getIconClass(icon) && (
            <span className={getIconClass(icon)} onClick={memoIconClick} />
          )}
        </label>
      </div>
    </VisibilitySensor>
  );
}
export default React.memo(Input);

import { SagaIterator } from 'redux-saga';
import { put, call, SagaReturnType, fork, all } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  setHashUser,
  setEmailCommit,
  setPasswordRecover,
  setRecoverCommit,
  setLogin,
  setRegister,
  setIsTokenValid,
} from '../reduser';
import {
  fetchEmailCommit,
  fetchIsTokenValidPass,
  fetchLogin,
  fetchLoginTelegramm,
  fetchPasswordRecover,
  fetchRecoverCommit,
  fetchRegister,
} from '../actions';
import {
  emailCommit,
  isTokenValid,
  login,
  passwordRecover,
  recoverCommit,
  register,
  resendEmailConfirm,
  RTelegrammVerifyInitData,
  telegrammVerifyInitData,
  TRregister,
} from '../api';
import { getScreenSize, setToken } from '../../../core/sagas/helper';
import { workerFetchGetProfile } from '../../user/sagas/workers';

import { makeReqWithRD, TMakeReqWithRD } from '../../../core/api/makeReqWithRD';
import { FetchedData, genFetchedData } from '../../../core/api/fetchedData';
import { book } from '../../../books';
import { TToken } from '../../../types/TToken';
import { ErrorProps } from '../../../core/api/errorServeApi';
import { getUtm } from '../../user/utm';
import { decodeURLSafeBase64 } from '../../../helper/URLSafeBase64';

export function* workerFetchLogin({
  payload,
}: ReturnType<typeof fetchLogin>): SagaIterator<void> {
  localStorage.setItem('CheckedUserPreventRedirect', 'prevent');
  let receivedData: FetchedData<TToken> = yield call(genFetchedData, null);
  receivedData = receivedData.set('isLoading', true);
  yield put(setLogin(receivedData));
  try {
    const res: SagaReturnType<typeof login> = yield call(login, {
      ...payload,
      ...{ screenSize: getScreenSize() },
    });
    receivedData = receivedData.set('data', res);
    yield put(setLogin(receivedData));
    yield call(setToken, res);
    yield call(workerFetchGetProfile);
    if (payload.toProfile) {
      switch (payload.profileAction) {
        case 'profile': {
          yield put(push(`/u/${payload.toProfile}`));
          break;
        }
        case 'chat': {
          yield put(push(`/chat?u=${payload.toProfile}`));
          break;
        }
        default: {
          yield put(push(`/u/${payload.toProfile}`));
          break;
        }
      }
    } else {
      yield put(push(book.root));
    }
  } catch (err) {
    const error = err as ErrorProps;
    receivedData = receivedData.set('error', {
      isError: true,
      message: error.message,
      code: error.code,
    });
    yield put(setLogin(receivedData));
  } finally {
    localStorage.removeItem('CheckedUserPreventRedirect');
    receivedData = receivedData.set('isLoading', false).set('LTU', Date.now());
    yield put(setLogin(receivedData));
  }
}

export function* workerFetchRegister({
  payload,
}: ReturnType<typeof fetchRegister>): SagaIterator<void> {
  let receivedData: FetchedData<TRregister> = yield call(genFetchedData, null);
  receivedData = receivedData.set('isLoading', true);
  yield put(setRegister(receivedData));
  try {
    const data = { ...payload };
    delete data.toProfile;
    delete data.profileAction;
    const res: SagaReturnType<typeof register> = yield call(register, data);
    window.localStorage.removeItem('regInvite');
    receivedData = receivedData
      .set('data', res)
      .set('isLoading', false)
      .set('LTU', Date.now());
    // @ts-ignore
    if (!window.isDev) {
      // @ts-ignore
      window.dataLayer?.push({
        event: 'CompleteRegistration',
        reg_mail: data.email,
        reg_name: data.nickname,
      });
      // @ts-ignore
      window.KTracking?.reportConversion(0, 'lead', {
        sub_id_1: data.email,
        sub_id_2: data.nickname,
      });
    }
    yield all([put(setRegister(receivedData)), put(setHashUser(res.hashUser))]);
    yield call(
      workerFetchLogin,
      fetchLogin({
        email: payload.email,
        password: payload.password,
        toProfile: payload.toProfile,
        profileAction: payload.profileAction,
      }),
    );
    yield fork(resendEmailConfirm);
  } catch (err) {
    const error = err as ErrorProps;
    receivedData = receivedData
      .set('error', {
        isError: true,
        message: error.message,
        code: error.code,
      })
      .set('isLoading', false)
      .set('LTU', Date.now());
    yield put(setRegister(receivedData));
  }
}

export function* workerFetchEmailCommit({
  payload,
}: ReturnType<typeof fetchEmailCommit>): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof emailCommit>>(makeReqWithRD, {
      fetcher: emailCommit,
      fill: setEmailCommit,
      parameters: payload,
    });
  } catch (e) {
    console.error(e);
  }
}

export function* workerFetchPasswordRecover({
  payload,
}: ReturnType<typeof fetchPasswordRecover>): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof passwordRecover>>(makeReqWithRD, {
      fetcher: passwordRecover,
      fill: setPasswordRecover,
      parameters: payload,
    });
  } catch (e) {
    console.error(e);
  }
}
export function* workerFetchRecoverCommit({
  payload,
}: ReturnType<typeof fetchRecoverCommit>): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof recoverCommit>>(makeReqWithRD, {
      fetcher: recoverCommit,
      fill: setRecoverCommit,
      parameters: payload,
    });
  } catch (e) {
    console.error(e);
  }
}

export function* workerFetchIsTokenValidPass({
  payload,
}: ReturnType<typeof fetchIsTokenValidPass>): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof isTokenValid>>(makeReqWithRD, {
      fetcher: isTokenValid,
      fill: setIsTokenValid,
      parameters: { token: payload, type: 'passwordRecover' },
    });
  } catch (e) {
    console.error(e);
  }
}

export function* workerFetchTelegrammVerifyInitData({
  payload,
}: ReturnType<typeof fetchLoginTelegramm>): SagaIterator<void> {
  localStorage.setItem('CheckedUserPreventRedirect', 'prevent');
  let receivedData: FetchedData<TToken> = yield call(genFetchedData, null);
  receivedData = receivedData.set('isLoading', true);
  yield put(setLogin(receivedData));

  const utm: Record<string, string | undefined> = {};
  let externalClickId: undefined | string;
  try {
    const start_param = Telegram?.WebApp?.initDataUnsafe?.start_param;
    const params = start_param
      ? new URLSearchParams(decodeURLSafeBase64(start_param))
      : null;

    utm.uso = params?.get('uso') || undefined;
    utm.uca = params?.get('uca') || undefined;
    utm.uco = params?.get('uco') || undefined;
    externalClickId = params?.get('cid') || undefined;
  } catch (e) {
    console.warn(e);
  }

  try {
    const res: RTelegrammVerifyInitData = yield call(telegrammVerifyInitData, {
      initData: btoa(Telegram.WebApp.initData),
      autoLogin: true,
      autoRegister: true,
      externalClickId,
      ...utm,
    });

    const userTd = Telegram.WebApp.initDataUnsafe.user?.id;
    if (userTd) localStorage.setItem('lastTGUserId', userTd.toString());

    receivedData = receivedData.set('data', res);
    yield put(setLogin(receivedData));
    yield call(setToken, res);
    yield call(workerFetchGetProfile);

    if (payload.toProfile) {
      switch (payload.profileAction) {
        case 'profile': {
          yield put(push(`/u/${payload.toProfile}`));
          break;
        }
        case 'chat': {
          if (['', 'all'].includes(payload.toProfile)) {
            yield put(push(`/chat`));
            break;
          }
          yield put(push(`/chat/?u=${payload.toProfile}`));
          break;
        }
        case 'post': {
          yield put(push(`/?postId=${payload.toProfile}`));
          break;
        }
        case 'userPost': {
          yield put(push(payload.toProfile));
          break;
        }

        default: {
          yield put(push(`/u/${payload.toProfile}`));
          break;
        }
      }

      return;
    }
    yield put(push(book.root));
  } catch (err) {
    console.error(err);
    const error = err as ErrorProps;
    receivedData = receivedData.set('error', {
      isError: true,
      message: error.message,
      code: error.code,
    });
    yield put(setLogin(receivedData));
  } finally {
    localStorage.removeItem('CheckedUserPreventRedirect');
    receivedData = receivedData.set('isLoading', false).set('LTU', Date.now());
    yield put(setLogin(receivedData));
  }
}

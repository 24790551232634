import { DependencyList, useEffect } from 'react';

export function preventDefault(e: TouchEvent): void {
  e.preventDefault();
}

let scrollPosition = 0;
function onBlockScroll(e: Event) {
  window.scrollTo({ top: scrollPosition });
  e.preventDefault();
}
export function blockScroll() {
  scrollPosition = window.scrollY;
  window.addEventListener('scroll', onBlockScroll);
}
export function removeBlockScroll() {
  window.removeEventListener('scroll', onBlockScroll);
}

/**
 * @deprecated since 0.1.41.53
 */
export function bodyAddNoScroll(): void {
  window.document?.body.classList.add('noScroll');
  window.document.documentElement.classList.add('noScroll');
}

export function bodyRemoveNoScroll(): void {
  window.document?.body.classList.remove('noScroll');
  window.document.documentElement.classList.remove('noScroll');
}

export function hasNoScroll(): boolean {
  return (
    window.document?.body.classList.contains('noScroll') ||
    window.document.documentElement.classList.contains('noScroll')
  );
}

export function useNoScroll(deps: DependencyList = []): void {
  useEffect(() => {
    bodyAddNoScroll();
    return () => {
      bodyRemoveNoScroll();
    };
  }, deps);
}

export function bodyAddHeight100(): void {
  window.document.documentElement.setAttribute('style', 'height:100%');
  window.document?.body.classList.add('height100');
  window.document.getElementById('root')?.classList.add('height100');
}

export function bodyRemoveHeight100(): void {
  window.document.documentElement.removeAttribute('style');
  window.document?.body.classList.remove('height100');
  window.document.getElementById('root')?.classList.remove('height100');
}

export function useHeight100(deps: DependencyList = []): void {
  useEffect(() => {
    bodyAddHeight100();
    return () => {
      bodyRemoveHeight100();
    };
  }, deps);
}

export function disableScroll(): void {
  // @ts-ignore
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  // @ts-ignore
  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;

  window.onscroll = function () {
    window.scrollTo(scrollLeft, scrollTop);
  };
}

export function enableScroll(): void {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  window.onscroll = function () {};
}

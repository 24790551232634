import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import CardIcon from 'assets/chat/CardIcon';
import { InfoCircleIcon } from 'assets';
import Input from 'components/input';
import { Loader } from 'components/Loader/Loader';
import {
  IPaymentErrorModalRef,
  PaymentErrorModal,
} from 'components/modals/PaymentErrorModal/PaymentErrorModal';
import SetBtn from 'components/Settings/SetBtn';
import { genFetchedData } from 'core/api/fetchedData';
import { removeEmpty } from 'core/utils';
import { RootState } from 'core/rootReducer';
import { TCreateCardResponse, TNewCard } from 'domains/user/api';
import { setCreateCard, UserState } from 'domains/user/reduser';
import { fetchGetCards } from 'domains/user/actions';
import { getCardType } from 'utils/utils';
import { book } from 'books';

import { CardNumberInput } from './components/CardNumberInput';
import {
  ConfirmTransactionModal,
  IConfirmTransactionModalRef,
} from './components/ConfirmTransaction/ConfirmTransactionModal';

import cn from './AddCard.module.scss';

interface IProps {
  onSubmit?: (card: TNewCard) => void;
  className?: string;
  isPublic?: boolean;
  onCanceledError?: () => void;
  onSuccessRequestClose?: () => void;
  btnText?: string;
}

export function AddCard({
  onSubmit,
  isPublic,
  className,
  onCanceledError,
  onSuccessRequestClose,
  btnText,
}: IProps) {
  const INITIAL_CARD = {
    cardNumber: '',
    cardHolder: '',
    cardCvv: '',
    cardMonth: undefined,
    cardYear: undefined,
    cardType: '',
    showId: '',
    sum: 0,
  };

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const profileState = useSelector<RootState, UserState['profile']>(
    ({ userReducer }) => userReducer.profile,
    shallowEqual,
  );
  const profile = useMemo(() => profileState.get('data'), [profileState]);

  const createCard = useSelector<RootState, UserState['createCard']>(
    ({ userReducer }) => userReducer.createCard,
    shallowEqual,
  );

  const [isInvalidCardNumber, setInvalidCardNumber] = useState<boolean>(false);

  const formik = useFormik<TNewCard>({
    initialValues: INITIAL_CARD,
    validateOnBlur: false,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: (v) => {
      if (onSubmit) {
        const month = v.cardMonth?.replace(/[^0-9]/gi, '');

        onSubmit({
          ...v,
          ...{
            cardNumber: v.cardNumber
              ?.replace(/[^0-9]/gi, '')
              .replace(/\s/g, '')
              .replace(/(\d{4})/g, '$1 ')
              .trim(),
            cardHolder: v.cardHolder
              ?.replace(/[^A-Za-z\s]/gi, '')
              .toUpperCase()
              .trim(),
            cardType: getCardType(v.cardNumber),
            cardCvv: v.cardCvv?.replace(/[^0-9]/gi, ''),
            cardYear: v.cardYear?.replace(/[^0-9]/gi, ''),
            cardMonth: month && month.length === 1 ? `0${month}` : month,
          },
        });
      }
    },
  });

  const paymentErrorModalRef = useRef<IPaymentErrorModalRef>(null);
  const confirmTransactionModalRef = useRef<IConfirmTransactionModalRef>(null);

  const createCardInProgress = useMemo<boolean>(
    () => createCard.get('isLoading'),
    [createCard],
  );

  const cardNumber = useMemo<string>(() => {
    if (!formik.values.cardNumber) {
      return '';
    }

    return formik.values.cardNumber
      .replace(/[^0-9]/gi, '')
      .replace(/\s/g, '')
      .replace(/(\d{4})/g, '$1 ')
      .trim();
  }, [formik.values.cardNumber]);

  const isValid = useMemo((): boolean => {
    if (isInvalidCardNumber) {
      return false;
    }

    if (getCardType(formik.values.cardNumber) === '') {
      return false;
    }

    const holder = formik.values.cardHolder
      ?.replace(/[^A-Za-z\s]/gi, '')
      .trim();
    if (!holder || holder.length < 2) {
      return false;
    }

    if (
      !formik.values.cardMonth ||
      +formik.values.cardMonth > 12 ||
      !formik.values.cardYear ||
      !/^[0-9]{2}$/.test(formik.values.cardYear)
    ) {
      return false;
    }

    const currentMonth = new Date().getMonth() + 1;
    const currentYear = new Date().getFullYear() - 2000;
    if (
      +formik.values.cardYear < currentYear ||
      (+formik.values.cardYear === currentYear &&
        +formik.values.cardMonth < currentMonth)
    ) {
      return false;
    }

    return !(
      !formik.values.cardCvv || !/^[0-9]{3}$/.test(formik.values.cardCvv)
    );
  }, [formik.values, isInvalidCardNumber]);

  const onCancelClickHandler = useCallback((): void => {
    paymentErrorModalRef.current?.close();
    dispatch(setCreateCard(genFetchedData<TCreateCardResponse>(null)));
    formik.setValues(INITIAL_CARD);

    if (onCanceledError) {
      onCanceledError();
    }
  }, [paymentErrorModalRef, formik, onCanceledError]);

  const onTryAgainClickHandler = useCallback((): void => {
    paymentErrorModalRef.current?.close();
    dispatch(setCreateCard(genFetchedData<TCreateCardResponse>(null)));
  }, [paymentErrorModalRef]);

  const onErrorCardNumberHandler = useCallback(
    (errorText?: string) => {
      setInvalidCardNumber(!!errorText);
      formik.setErrors(
        removeEmpty({ ...formik.errors, cardNumber: errorText }),
      );
    },
    [formik, setInvalidCardNumber],
  );

  const onSuccessConfirmTransaction = useCallback(() => {
    dispatch(setCreateCard(genFetchedData<TCreateCardResponse>(null)));
    formik.setValues(INITIAL_CARD);
    dispatch(fetchGetCards());
    confirmTransactionModalRef.current?.close();
    if (onSuccessRequestClose) {
      onSuccessRequestClose();
    }
  }, []);

  useEffect(() => {
    const target = document.getElementById('cardNumber');

    if (target) {
      target.addEventListener('paste', (event) => {
        // @ts-ignore
        const paste = (event.clipboardData || window.clipboardData)
          .getData('text')
          .replace(/[^0-9]/gi, '')
          .replace(/\s/g, '')
          .replace(/(\d{4})/g, '$1 ')
          .trim();
        formik.setFieldValue('cardNumber', paste);
        event.preventDefault();
      });
    }

    return () => {
      dispatch(setCreateCard(genFetchedData<TCreateCardResponse>(null)));
    };
  }, []);

  useEffect(() => {
    const error = createCard.get('error');
    const data = createCard.get('data');

    if (data !== null && data.created) {
      console.debug(data.testPay); // Keep this to pair tests cards
      dispatch(setCreateCard(genFetchedData<TCreateCardResponse>(null)));
      formik.setValues(INITIAL_CARD);
      confirmTransactionModalRef.current?.open(data.cardId);
    }

    if (error && !!error.code) {
      paymentErrorModalRef.current?.open();
    }
  }, [createCard]);

  if (profile?.mailCommit !== 1 && !isPublic) {
    return (
      <div className={cn.addCard__youNeedConfirmEmail}>
        {t('AccountBilling.YouNeedConfirmEmail')}
        {
          // @ts-ignore
          <NavLink
            to={book.userAccount}
            className={cn.addCard__youNeedConfirmEmail_link}
          >
            {t('SettingsMenu.Account')}
          </NavLink>
        }
      </div>
    );
  }

  return (
    <div className={`${cn.addCard} ${className}`}>
      <PaymentErrorModal
        ref={paymentErrorModalRef}
        code={createCard.get('error')?.code}
        logCode={createCard.get('error')?.errorLogCode}
        onCancelClick={onCancelClickHandler}
        onTryAgainClick={onTryAgainClickHandler}
      />
      <ConfirmTransactionModal
        ref={confirmTransactionModalRef}
        onSuccess={onSuccessConfirmTransaction}
      />
      <form>
        <div className={cn.overCard}>
          <div className={cn.frontCard}>
            <div className={cn.frontTit}>{t('AccountBilling.CardNumber')}</div>
            <CardNumberInput
              className={`${cn.input} ${
                formik.errors.cardNumber ? cn.input_error : ''
              }`}
              cardIconClassName={cn.cardType}
              value={cardNumber}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              onError={onErrorCardNumberHandler}
              disabled={createCardInProgress}
            />
            <div className={cn.input}>
              <input
                id="cardHolder"
                type="text"
                placeholder={t('AccountBilling.CardholderName')}
                maxLength={26}
                value={
                  formik.values.cardHolder
                    ?.replace(/[^A-Za-z\s]/gi, '')
                    .toUpperCase()
                    .trimLeft() ?? ''
                }
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                disabled={createCardInProgress}
              />
            </div>
            <div className={cn.expiration}>
              <div className={`${cn.frontTit} ${cn.frontTitEx}`}>
                {t('AccountBilling.ExpirationDate')}
              </div>
              <div className={cn.expSelects}>
                <div className={`${cn.input} ${cn.sel}`}>
                  <input
                    id="cardMonth"
                    placeholder="MM"
                    type="text"
                    minLength={2}
                    maxLength={2}
                    value={
                      formik.values.cardMonth?.replace(/[^0-9]/gi, '') ?? ''
                    }
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    disabled={createCardInProgress}
                  />
                </div>
                <div className={cn.slash}>/</div>
                <div className={`${cn.input} ${cn.sel}`}>
                  <input
                    id="cardYear"
                    placeholder="YY"
                    type="text"
                    minLength={2}
                    maxLength={2}
                    value={
                      formik.values.cardYear?.replace(/[^0-9]/gi, '') ?? ''
                    }
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    disabled={createCardInProgress}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={cn.backCard}>
            <div className={cn.backLine} />
            <div className={cn.cvc}>
              <div className={cn.cvcBlock}>
                <div className={cn.cvcTit}>CVC</div>
                <div className={cn.cvcOverInp}>
                  <div className={cn.cvcInp}>
                    <input
                      id="cardCvv"
                      type="text"
                      maxLength={3}
                      value={
                        formik.values.cardCvv?.replace(/[^0-9]/gi, '') ?? ''
                      }
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      disabled={createCardInProgress}
                    />
                  </div>
                  <div className={cn.cvcText}>
                    {t('AccountBilling.CvcRequirements')}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {!isPublic && (
          <div className={cn.addCardBtn}>
            {!createCardInProgress && (
              <SetBtn
                onClick={() => formik.submitForm()}
                disabled={!isValid}
                styleB={isValid ? undefined : 'disabled'}
                text={btnText ?? t('AccountBilling.AddCard')}
                icon={<CardIcon />}
              />
            )}
            {createCardInProgress && <Loader className={cn.loader} />}
          </div>
        )}

        {isPublic && (
          <div className={`${cn.payBlock} ${cn.payInputs}`}>
            <Input
              inputOpt={{
                type: 'text',
                name: 'showId',
                id: 'showId',
                placeholder: t('AccountBilling.OrderId'),
                value: formik.values.showId,
                onBlur: formik.handleBlur,
                onChange: formik.handleChange,
              }}
            />
            <Input
              inputOpt={{
                type: 'number',
                name: 'sum',
                id: 'sum',
                placeholder: t('AccountBilling.Sum'),
                min: 0,
                value: formik.values.sum,
                onBlur: formik.handleBlur,
                onChange: formik.handleChange,
              }}
            />
            <SetBtn
              onClick={() => formik.submitForm()}
              disabled={!isValid}
              styleB={isValid ? undefined : 'disabled'}
              text={t('AccountBilling.Send')}
            />
          </div>
        )}

        {!!formik.errors.cardNumber && (
          <div className={cn.addCard__invalidCard}>
            <InfoCircleIcon />
            <span>{formik.errors.cardNumber}</span>
          </div>
        )}
      </form>
    </div>
  );
}

import React from 'react';

interface IProps {
  className?: string;
  onClick?: () => void;
}

function ArrowIcon(props: IProps): JSX.Element {
  return <div {...props}>
    <svg
      width="9"
      height="15"
      viewBox="0 0 9 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.92364 7.34651L1.43273 1.06425C1.33091 0.978583 1.17818 0.978583 1.07636 1.06425C0.974545 1.14992 0.974545 1.32125 1.07636 1.40692L7.38909 7.48929L1.07636 13.6002C0.974545 13.6859 0.974545 13.8572 1.07636 13.9429C1.12727 14 1.17818 14 1.25455 14C1.33091 14 1.38182 13.9714 1.43273 13.9429L7.92364 7.68918C8.02545 7.57496 8.02545 7.43218 7.92364 7.34651Z"
        fill="#413E93"
        stroke="#413E93"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </svg>
  </div>
}

export default ArrowIcon;

import React from 'react';

interface IProps {
  className?: string;
  onClick?: () => void;
}

function Icon(props: IProps): JSX.Element {
  return <div {...props}>
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 0C5.384 0 0 5.384 0 12C0 18.616 5.384 24 12 24C18.616 24 24 18.616 24 12C24 5.384 18.616 0 12 0ZM1.808 12C1.808 6.384 6.384 1.808 12 1.808C14.288 1.808 16.4 2.568 18.104 3.848L4.344 18.712C2.768 16.92 1.808 14.568 1.808 12ZM12 22.192C9.712 22.192 7.6 21.432 5.896 20.152L19.656 5.288C21.232 7.08 22.192 9.432 22.192 12C22.192 17.616 17.616 22.192 12 22.192Z"
        fill="#413E93"
      />
    </svg>
  </div>
}

export default Icon;

const KEY = '_utm';

export type UtmItem = {
  name: string;
  value: string;
  date: number;
  maxTime: number;
};
export type UTMs = {
  uso?: UtmItem;
  uca?: UtmItem;
  uco?: UtmItem;
};
export function getUtm(login: string): UTMs | undefined {
  const str = window.localStorage.getItem(`${KEY}_${login}`);
  if (!str) return undefined;
  try {
    const utms: UTMs = JSON.parse(str);
    return utms;
  } catch (e) {
    return undefined;
  }
}

export type UTMSet = {
  uso?: string;
  uca?: string;
  uco?: string;
};
export function setUtm({ uso, uca, uco }: UTMSet, login: string) {
  const currentDate = Date.now();
  const maxTime = currentDate + 30 * 24 * 60 * 60 * 1000;
  const oldData = getUtm(login) ?? {};
  const keys = Object.keys(oldData) as (keyof UTMs)[];
  keys.forEach((item) => {
    const element = oldData?.[item];
    if (!element) {
      return;
    }
    if (element.maxTime < currentDate) {
      delete oldData?.[item];
    }
  });

  if (uso) {
    oldData.uso = {
      name: 'uso',
      value: uso,
      date: currentDate,
      maxTime,
    };
  }
  if (uca) {
    oldData.uca = {
      name: 'uca',
      value: uca,
      date: currentDate,
      maxTime,
    };
  }
  if (uco) {
    oldData.uco = {
      name: 'uco',
      value: uco,
      date: currentDate,
      maxTime,
    };
  }

  window.localStorage.setItem(`${KEY}_${login}`, JSON.stringify(oldData));
}

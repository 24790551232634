import React from 'react';

interface IProps {
  className?: string;
  onClick?: () => void;
}

function Icon(props: IProps): JSX.Element {
  return <div {...props}>
    <svg
      width="13"
      height="15"
      viewBox="0 0 13 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.9 5.4V12.55C10.9 13.155 10.405 13.65 9.8 13.65H3.2C2.595 13.65 2.1 13.155 2.1 12.55V5.4M4.3 7.05V12M8.7 7.05V12M6.5 7.05V12M12 4.3H1V3.75C1 3.145 1.495 2.65 2.1 2.65H10.9C11.505 2.65 12 3.145 12 3.75V4.3ZM9.25 2.65H3.75C3.75 1.715 4.465 1 5.4 1H7.6C8.535 1 9.25 1.715 9.25 2.65Z"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
}

export default Icon;

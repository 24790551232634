import { put, take, actionChannel } from 'redux-saga/effects';
import { SagaIterator, buffers, Channel } from 'redux-saga';

import { TToken } from '../../types/TToken';

export const getScreenSize = (): string => {
  const width =
    window.innerWidth ||
    window.document.documentElement.clientWidth ||
    window.document.body.clientWidth;
  const height =
    window.innerHeight ||
    window.document.documentElement.clientHeight ||
    window.document.body.clientHeight;

  return `${width}x${height}`;
};

export const setToken = (tokens: TToken): void => {
  if (tokens.hashUser !== null) {
    localStorage.setItem('hashUser', tokens.hashUser);
  }
  if (tokens.access_token !== null) {
    localStorage.setItem('access_token', tokens.access_token);
  }
  if (tokens.refresh_token !== null) {
    localStorage.setItem('refresh_token', tokens.refresh_token);
  }
  if (tokens.durationAccess !== null) {
    localStorage.setItem('durationAccess', `${tokens.durationAccess}`);
    localStorage.setItem(
      'expire_access',
      `${new Date().getTime() + tokens.durationAccess * 1000}`
    );
  }
  if (tokens.durationRefresh !== null) {
    localStorage.setItem('durationRefresh', `${tokens.durationRefresh}`);
    localStorage.setItem(
      'expire_refresh',
      `${new Date().getTime() + tokens.durationRefresh * 1000}`
    );
  }
};

export const getTokenFromLS = (): TToken => {
  const hashUser: string | null = window.localStorage.getItem('hashUser');
  const access_token: string | null =
    window.localStorage.getItem('access_token');
  const refresh_token: string | null =
    window.localStorage.getItem('refresh_token');
  const expire_access: string | null =
    window.localStorage.getItem('expire_access');
  const expire_refresh: string | null =
    window.localStorage.getItem('expire_refresh');
  const durationAccess: string | null =
    window.localStorage.getItem('durationAccess');
  const durationRefresh: string | null =
    window.localStorage.getItem('durationRefresh');

  return {
    hashUser,
    access_token,
    refresh_token,
    expire_access: expire_access !== null ? +expire_access : null,
    expire_refresh: expire_refresh !== null ? +expire_refresh : null,
    durationAccess: durationAccess !== null ? +durationAccess : null,
    durationRefresh: durationRefresh !== null ? +durationRefresh : null,
  };
};

export function clearToken(): void {
  localStorage.removeItem('hashUser');
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');
  localStorage.removeItem('expire_access');
  localStorage.removeItem('expire_refresh');
  localStorage.removeItem('durationAccess');
  localStorage.removeItem('durationRefresh');
}

export interface TokenS {
  token: string;
  ttl: number;
}

export function* getToken(): SagaIterator<string> {
  const endChanel: Channel<'END_GET_TOKEN'> = yield actionChannel(
    'END_GET_TOKEN',
    buffers.sliding(1)
  );
  const { chanel } = yield put({ type: 'REQUEST', chanel: endChanel });
  yield take(chanel);
  const access_token: string | null =
    window.localStorage.getItem('access_token');
  if (!access_token) {
    return '';
  }

  return access_token;
}

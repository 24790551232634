import { takeEvery, takeLatest } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import {
  workerCreateChat,
  workerGetChatList,
  workerGetMessages,
  workerGetNotified,
  workerSendMessage,
  workerSetReaded,
  workerUnreadCount,
  workerUpdateMessage,
  softUpdateChatList,
  workerGetCollocutor,
} from './workers';
import {
  fetchCreateChat,
  fetchGetChatList,
  fetchGetCollocutor,
  fetchGetMessages,
  fetchGetNotified,
  fetchGetUnreadCount,
  fetchSendMessage,
  fetchSetReaded,
  fetchSoftUpdateChatList,
  fetchUpdateMessage,
} from '../actions';

export default function* watchChat(): SagaIterator {
  yield takeEvery<typeof fetchGetChatList>(fetchGetChatList, workerGetChatList);
  yield takeEvery<typeof fetchCreateChat>(fetchCreateChat, workerCreateChat);
  yield takeEvery<typeof fetchGetNotified>(fetchGetNotified, workerGetNotified);
  yield takeEvery<typeof fetchSendMessage>(fetchSendMessage, workerSendMessage);
  yield takeEvery<typeof fetchGetMessages>(fetchGetMessages, workerGetMessages);
  yield takeLatest<typeof fetchUpdateMessage>(
    fetchUpdateMessage,
    workerUpdateMessage
  );
  yield takeEvery<typeof fetchSetReaded>(fetchSetReaded, workerSetReaded);
  yield takeLatest<typeof fetchGetUnreadCount>(
    fetchGetUnreadCount,
    workerUnreadCount
  );
  yield takeLatest<typeof fetchSoftUpdateChatList>(
    fetchSoftUpdateChatList,
    softUpdateChatList
  );

  yield takeLatest<typeof fetchGetCollocutor>(
    fetchGetCollocutor,
    workerGetCollocutor
  );
}

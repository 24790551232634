import axios, { AxiosRequestConfig, AxiosInstance } from 'axios';

import errorServeApi, { ErrorProps } from './errorServeApi';
import { ApiUrlSingleton } from './ApiUrlSingleton';

const axiosOptions: AxiosRequestConfig = {
  headers: { 'Content-Type': 'text/plain;charset=UTF-8' },
  baseURL: ApiUrlSingleton.getInstance().getURL(),
};

type TPRequest = {
  method: string;
  showError?: boolean;
  token?: boolean;
  aXconfig?: AxiosRequestConfig;
};

function request<R>({
  method,
  showError,
  token,
  aXconfig,
}: TPRequest): () => Promise<R>;
function request<R, P extends Record<string, unknown>>({
  method,
  showError,
  token,
  aXconfig,
}: TPRequest): (params: P) => Promise<R>;

function request<R, P extends Record<string, unknown> | undefined = undefined>({
  method,
  showError,
  token = true,
  aXconfig = axiosOptions,
}: TPRequest): (params?: P) => Promise<R> {
  return async function (params?: P) {
    const instance: AxiosInstance = axios.create(aXconfig);
    instance.interceptors.request.use((config: AxiosRequestConfig) => {
      const access_token: string | null =
        window.localStorage.getItem('access_token');
      const clonedConfig = config;

      clonedConfig.headers = {
        ...clonedConfig.headers,
        LogReferer: window.location.href,
      };

      if (access_token && token) {
        clonedConfig.headers.common = {
          ...clonedConfig.headers,
          Authorization: `Bearer ${access_token}`,
        };
      }

      return clonedConfig;
    });
    try {
      const response = await instance.post(`?${method}`, {
        id: 1,
        method,
        jsonrpc: '2.0',
        params: params ?? {},
      });

      if (response?.data?.error) {
        throw response.data.error;
      }

      if (!response?.data?.result) {
        const error = { code: 1000 };
        throw error;
      }
      return response.data.result;
    } catch (err) {
      if (!window.navigator.onLine) {
        throw err;
      }
      errorServeApi(err as ErrorProps, showError);

      throw err;
    }
  };
}

export default request;

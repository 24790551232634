import React from 'react';

interface IProps {
  className?: string;
  onClick?: () => void;
}

function Icon(props: IProps): JSX.Element {
  return <div {...props}>
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.91304 1V5.78261M2.91304 5.78261H7.69565M2.91304 5.78261C4.92174 2.91304 8.17391 1 12 1C18.1217 1 23 5.87826 23 12C23 18.1217 18.1217 23 12 23C5.87826 23 1 18.1217 1 12"
        stroke="#413E93"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  </div>
}

export default Icon;

import React from 'react';
import { useWindowSize } from 'react-use';
import { useTranslation } from 'react-i18next';

import { PayStubBtns } from './PayStubBtns/PayStubBtns';
import { TContent } from '../../../types/TContent';
import { normalizeImgSrc } from '../../../utils/utils';

import LockIcon from '../../../assets/post/LockIcon';

import cn from './style.module.css';
import SetBtn from '../../Settings/SetBtn';

export type PPayStub = {
  blurImg?: string;
  content: TContent;
  username?: string;
  onClickBuy?: (event: React.MouseEvent) => void;
  contentBuyInProgress?: boolean;
  subscriptionBuyInProgress?: boolean;
  onClickSubscribersVIP?: (event: React.MouseEvent) => void;
  subscribeVipCostMonth?: number;
  trialUser?: {
    trialActive: 0 | 1;
    trialDurationDays: number;
  };
  isPayByTG: React.MutableRefObject<boolean>;
};

export function PayStub({
  blurImg,
  content,
  username,
  onClickBuy,
  contentBuyInProgress,
  subscriptionBuyInProgress,
  onClickSubscribersVIP,
  subscribeVipCostMonth,
  trialUser,
  isPayByTG,
}: PPayStub): JSX.Element {
  const { t } = useTranslation();
  const { width } = useWindowSize();

  return (
    <div className={`${cn.post_img} ${cn.paypostbgdiv} ${cn.paypostbottom}`}>
      <img
        src={normalizeImgSrc(blurImg)}
        className={`${cn.postimage} ${cn.blurimg} ${cn.blurimg40}`}
        alt=""
        loading="lazy"
      />
      <div className={cn.paidcontent}>
        <div className={cn.paydlock}>
          <LockIcon className={cn.paypostLock} />
          <div className={cn.paydtit}>{t('Post.PaidContent')}</div>
        </div>
        <div className={cn.paybtns}>
          {width > 1023 && (
            <>
              <PayStubBtns
                onClickBuy={onClickBuy}
                paidOptions={content.paidOptions}
                username={username}
                payButtonText={`${
                  content.payButtonText?.substr(0, 30) || t('Post.BuyNow')
                } ${content.paidOptions?.price} $`}
                contentBuyInProgress={contentBuyInProgress}
                subscriptionBuyInProgress={subscriptionBuyInProgress}
                onClickSubscribersVIP={onClickSubscribersVIP}
                subscribeVipCostMonth={subscribeVipCostMonth}
                trialUser={trialUser}
                isPayByTG={isPayByTG}
                price={content.paidOptions?.price}
              />
            </>
          )}
        </div>
        <div className={`${cn.payPlus} ${cn.plus18PayPost}`}>
          {!!content.mediaDetail?.payedPhoto && (
            <span>
              {t('Post.Photos')} <br /> {content.mediaDetail?.payedPhoto}
            </span>
          )}
          {!!content.mediaDetail?.payedVideo && (
            <span>
              {t('Post.Videos')} <br /> {content.mediaDetail?.payedVideo}
            </span>
          )}
        </div>
      </div>
    </div>
  );
}

import React, { useCallback, useRef } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import QuickPinchZoom, {
  make3dTransformValue,
} from 'react-swipe-quick-pinch-zoom';

import { normalizeImgSrc } from '../../../../utils/utils';

import cn from './ImageItem.module.css';

interface IProps {
  src?: string;
  className?: string;
  isFullScreen?: boolean;
  onSwipeLeft?: () => void;
  onSwipeRight?: () => void;
  onZoom?: (scale: number) => void;
  onOutsideClick: () => void;
}

export function ImageItem({
  src,
  className,
  isFullScreen = false,
  onSwipeLeft,
  onSwipeRight,
  onZoom,
  onOutsideClick,
}: IProps): JSX.Element {
  const imgRef = useRef<HTMLImageElement>(null);
  const pinchZoomRef = useRef<QuickPinchZoom>(null);

  const onVisible = useCallback(
    (isVisible: boolean) => {
      if (!isVisible) {
        pinchZoomRef.current?.scaleTo({
          x: 0,
          y: 0,
          scale: 1,
        });

        return;
      }
      imgRef.current?.removeAttribute('loading');
    },
    [imgRef.current],
  );

  const onUpdateHandler = useCallback(
    // @ts-ignore
    ({ x, y, scale }) => {
      if (onZoom) {
        onZoom(scale);
      }

      if (imgRef.current) {
        const value = make3dTransformValue({ x, y, scale });
        imgRef.current.style.setProperty('transform', value);
      }
    },
    [isFullScreen, onZoom],
  );

  const onDoubleTapHandler = (event: React.MouseEvent<HTMLImageElement>) => {
    // @ts-ignore
    const zoom = (pinchZoomRef.current?._zoomFactor || 0) + 1;
    const maxZoom = pinchZoomRef.current?.props.maxZoom ?? 1;
    pinchZoomRef.current?.scaleTo({
      x: event.clientX,
      y: event.clientY,
      scale: zoom <= maxZoom ? zoom : 1,
    });
  };

  const computeOutsideClick = (
    img: HTMLImageElement,
    clientX: number,
    clientY: number,
  ): void => {
    const matrix = window.getComputedStyle(img).getPropertyValue('transform');
    const matrixArray = matrix
      .replace('matrix(', '')
      .replace(')', '')
      .split(',');
    const scaleX = parseFloat(matrixArray[0]);
    const scaleY = parseFloat(matrixArray[3]);
    const translateX = parseFloat(matrixArray[4]);
    const translateY = parseFloat(matrixArray[5]);

    const positionArray = window
      .getComputedStyle(img)
      .getPropertyValue('object-position')
      .split(' ');
    const position = parseInt(positionArray[0], 10);
    const origenRatio = img.width / img.height;
    const clientRatio = img.naturalWidth / img.naturalHeight;

    const width =
      origenRatio > clientRatio ? img.height * clientRatio : img.width;
    const height =
      origenRatio > clientRatio ? img.height : img.width / clientRatio;
    const left = (img.width - width) * (position / 100) * scaleX;
    const right = width * scaleX + left;
    const top = (img.height - height) * (position / 100) * scaleY;
    const bottom = height * scaleY + top;

    if (
      clientX - translateX < left ||
      clientX - translateX > right ||
      clientY - translateY < top ||
      clientY - translateY > bottom
    ) {
      onOutsideClick();
    }
  };

  const onClickImgHandler = (event: React.MouseEvent<HTMLImageElement>) => {
    computeOutsideClick(
      event.target as HTMLImageElement,
      event.clientX,
      event.clientY,
    );
  };

  const onTouchImgHandler = (event: React.TouchEvent<HTMLImageElement>) => {
    computeOutsideClick(
      event.target as HTMLImageElement,
      event.touches[0].clientX,
      event.touches[0].clientY,
    );
  };

  return (
    <VisibilitySensor onChange={onVisible}>
      <div
        className={[isFullScreen ? cn.fullscreen : '', className]
          .join(' ')
          .trim()}
      >
        {!isFullScreen && (
          <img
            ref={imgRef}
            alt=""
            src={normalizeImgSrc(src)}
            onDoubleClick={onDoubleTapHandler}
          />
        )}
        {isFullScreen && (
          <QuickPinchZoom
            ref={pinchZoomRef}
            maxZoom={2}
            onUpdate={onUpdateHandler}
            onSwipeLeft={onSwipeLeft}
            onSwipeRight={onSwipeRight}
            enabled
          >
            <img
              ref={imgRef}
              alt=""
              src={normalizeImgSrc(src)}
              onDoubleClick={onDoubleTapHandler}
              onClick={onClickImgHandler}
              onTouchStart={onTouchImgHandler}
            />
          </QuickPinchZoom>
        )}
      </div>
    </VisibilitySensor>
  );
}

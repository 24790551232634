import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import dayjs from 'dayjs';
import dayRu from 'dayjs/locale/ru';
import dayEn from 'dayjs/locale/en';
import dayEs from 'dayjs/locale/es';
import { ru, en, es } from './translations';

function defLng(): 'ru' | 'en' | 'es' {
  const l = navigator.language.split('-')[0];
  if (['ru', 'en', 'es'].includes(l)) {
    return l as 'ru' | 'en' | 'es';
  }
  return 'en';
}
i18n.on('languageChanged', (lng) => {
  if (lng === 'ru') return dayjs.locale(dayRu);
  if (lng === 'en') return dayjs.locale(dayEn);
  if (lng === 'es') return dayjs.locale(dayEs);

  return undefined;
});

i18n.use(LanguageDetector).init({
  resources: {
    ru,
    en,
    es,
  },

  lng: defLng(),
  fallbackLng: 'en',
  whitelist: ['ru', 'en', 'es'],
  load: 'languageOnly',
  nonExplicitWhitelist: true,
  nsSeparator: false,
  debug: false,

  // have a common namespace used around the full app
  ns: ['translations'],
  defaultNS: 'translations',

  // keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ',',
  },

  react: {
    wait: true,
    useSuspense: false,
  },
});

export default i18n;

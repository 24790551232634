import { actionChannel, call, SagaReturnType, take } from 'redux-saga/effects';
import { Channel, SagaIterator } from 'redux-saga';
import { clearToken, getScreenSize, getTokenFromLS, setToken } from './helper';
import { refreshToken } from '../../domains/registrations/api';
import { workerLogOut } from '../../domains/user/sagas/workers';
import { TToken } from '../../types/TToken';
import { Locker } from '../Locker';

function* handleRequest(chanel: Channel<'END_GET_TOKEN'>) {
  try {
    yield Locker.waitUnlock();
    Locker.lock();
    const token: TToken = getTokenFromLS();
    if (
      !token.access_token ||
      !token.refresh_token ||
      !token.expire_access ||
      !token.expire_refresh
    ) {
      Locker.unlock();
      return chanel.put('END_GET_TOKEN');
    }

    if (token.expire_refresh - 5 * 60 * 1000 <= +new Date()) {
      yield call(workerLogOut);
      Locker.unlock();
      return chanel.put('END_GET_TOKEN');
    }

    if (token.expire_access - 5 * 60 * 1000 <= +new Date()) {
      const payload = {
        refresh_token: token.refresh_token,
        screenSize: getScreenSize(),
      };
      const rfToken: SagaReturnType<typeof refreshToken> = yield call(
        refreshToken,
        payload
      );
      setToken(rfToken);
      Locker.unlock();
      return chanel.put('END_GET_TOKEN');
    }

    Locker.unlock();
    return chanel.put('END_GET_TOKEN');
  } catch (e) {
    console.error(e);
    clearToken();
    Locker.unlock();
    return chanel.put('END_GET_TOKEN');
  }
}

export default function* watcherToken(): SagaIterator<void> {
  const requestChan = yield actionChannel('REQUEST');

  while (true) {
    const { chanel } = yield take(requestChan);
    yield call(handleRequest, chanel);
  }
}

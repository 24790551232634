import React from 'react';

interface IProps {
  className?: string;
  onClick?: () => void;
}

function Icon(props: IProps): JSX.Element {
  return <div {...props}>
    <svg
      width="19"
      height="13"
      viewBox="0 0 19 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.2436 10.4181C11.7806 10.4181 7.31766 10.4181 2.85469 10.4181C2.56877 10.4181 2.3454 10.6057 2.34094 10.8469C2.33647 11.2177 2.34094 11.5841 2.34094 11.9549C2.34094 12.071 2.39901 12.1648 2.48836 12.2497C2.61792 12.3659 2.77874 12.3837 2.95297 12.3837C5.15542 12.3837 7.3534 12.3837 9.55584 12.3837C11.7404 12.3837 13.9295 12.3837 16.114 12.3837C16.1542 12.3837 16.1989 12.3837 16.2391 12.3837C16.5429 12.3748 16.7573 12.1916 16.7618 11.9281C16.7663 11.5751 16.7663 11.2177 16.7618 10.8648C16.7573 10.6057 16.534 10.4181 16.2436 10.4181Z"
        fill="white"
      />
      <path
        d="M19 3.26123C19 2.5241 18.4014 1.921 17.6598 1.921C16.9226 1.921 16.3195 2.51963 16.3195 3.26123C16.3195 3.68563 16.5206 4.06537 16.8288 4.31107C16.3776 4.76675 15.9353 5.2269 15.4663 5.66471C14.6532 6.42417 13.3532 6.31695 12.6696 5.4324C11.8968 4.42723 11.1462 3.40419 10.3868 2.38561C10.6906 2.1399 10.8871 1.76464 10.8871 1.34023C10.8871 0.603104 10.2885 0 9.54691 0C8.80531 0 8.20668 0.598636 8.20668 1.34023C8.20668 1.75123 8.39431 2.12203 8.68916 2.36774C8.59534 2.49283 8.50153 2.61792 8.40771 2.743C7.75547 3.60969 7.09875 4.47637 6.44651 5.34305C5.68704 6.35269 4.34681 6.44651 3.45333 5.55302C3.03339 5.13308 2.61345 4.70868 2.19798 4.28874C2.49283 4.04303 2.68046 3.67223 2.68046 3.26123C2.68046 2.5241 2.08182 1.921 1.34023 1.921C0.603104 1.921 0 2.51963 0 3.26123C0 3.91347 0.464613 4.4585 1.08559 4.57465C1.166 4.90971 1.24195 5.24477 1.32683 5.57536C1.61274 6.73689 1.90313 7.89396 2.19351 9.05549C2.26499 9.34141 2.45262 9.48436 2.74747 9.48436C5.20903 9.48436 7.67059 9.48883 10.1366 9.48436C11.5036 9.48436 12.8662 9.47096 14.2332 9.46649C14.9436 9.46203 15.6539 9.45756 16.3642 9.44862C16.6323 9.44416 16.811 9.3012 16.878 9.03762C17.1103 8.10839 17.3426 7.17917 17.5749 6.25441C17.7134 5.69151 17.8608 5.13308 17.9904 4.57019C17.9904 4.56572 17.9904 4.56572 17.9904 4.56125C18.5711 4.41383 19 3.88667 19 3.26123Z"
        fill="white"
      />
    </svg>
  </div>
}

export default Icon;
